import React, { useMemo } from "react";
import {
  GalleryImage,
  MessageCenterMessageDetails,
} from "common/messages/stores/messages/types";
import TailwindFlex from "library/components/_tailwind/flex";
import { modalStore } from "library/core/stores/modal";
import MessageCenterImage from "./message-reader-image";
import TailwindImage from "library/components/_tailwind/image";

type Props = {
  message: MessageCenterMessageDetails;
  backgroundColor: string;
  imageOptions?: {};
  onImageLoad?: (event) => void;
};

const MessageCenterMessageThumbnails: React.ComponentType<Props> = ({
  message,
  backgroundColor,
  imageOptions,
  onImageLoad,
}) => {
  const images = useMemo(() => message?.signed_urls || [], [message]);

  const galleryMap = useMemo(() => {
    const bottomLeft = images.length == 3 ? 2 : images.length - 2;
    return images.map((img: string, idx: number) => {
      let data: GalleryImage;

      switch (idx % 5) {
        case 0:
          data = {
            width: "calc( 60% - 0.0625rem )",
            height: "180px",
            imageURL: img,
            className:
              idx === bottomLeft ? "MessagesReader__GalleryGrid-bl" : "",
          };
          break;
        case 1:
          data = {
            width: "calc( 40% - 0.0625rem )",
            height: "180px",
            imageURL: img,
            className:
              idx === bottomLeft ? "MessagesReader__GalleryGrid-bl" : "",
          };
          break;
        case 2:
          data = {
            width: "100%",
            height: "206px",
            imageURL: img,
            className:
              idx === bottomLeft ? "MessagesReader__GalleryGrid-bl" : "",
          };
          break;
        case 3:
          data = {
            width: "calc( 40% - 0.0625rem )",
            height: "180px",
            imageURL: img,
            className:
              idx === bottomLeft ? "MessagesReader__GalleryGrid-bl" : "",
          };
          break;
        case 4:
          data = {
            width: "calc( 60% - 0.0625rem )",
            height: "180px",
            imageURL: img,
            className:
              idx === bottomLeft ? "MessagesReader__GalleryGrid-bl" : "",
          };
          break;
        default:
          data = {
            width: "100%",
            height: "206px",
            imageURL: img,
            className:
              idx === bottomLeft ? "MessagesReader__GalleryGrid-bl" : "",
          };
      }
      return data;
    });
  }, [images]);

  const singleImage = useMemo(
    () => (
      <TailwindImage
        src={images?.length > 0 ? images[0] : ""}
        onLoad={onImageLoad}
        style={{
          height: "auto",
          width: "100%",
          maxWidth: "420px",
          maxHeight: "340px",
          objectFit: "cover",
          cursor: "pointer",
          borderRadius: "1rem",
        }}
        boxProps={{
          position: "relative",
          flex: "flex-auto",
        }}
        {...imageOptions}
        onClick={() => onClickPhoto?.(images?.length > 0 ? images[0] : "")}
      />
    ),
    [images, imageOptions]
  );

  const quadGallery = useMemo(
    () => (
      <TailwindFlex width={"w-80"} height={"h-96"} gap='gap-0.5'>
        <TailwindFlex
          className={["MessagesReader__GalleryGrid__col"]}
          flexDirection='flex-col'
          style={{
            width: "calc(70% - 0.0625rem)",
          }}>
          <TailwindImage
            src={images?.length > 0 ? images[0] : ""}
            onLoad={onImageLoad}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              cursor: "pointer",
              borderRadius: "1rem 0px 0 1rem",
            }}
            boxProps={{
              position: "relative",
              width: "w-full",
              style: {
                height: `100%`,
              },
            }}
            {...imageOptions}
            onClick={() => onClickPhoto?.(images?.length > 0 ? images[0] : "")}
          />
        </TailwindFlex>
        <TailwindFlex
          className={["MessagesReader__GalleryGrid__col"]}
          flexDirection='flex-col'
          gap='gap-0.5'
          style={{
            width: "calc(30% - 0.0625rem)",
          }}>
          {images?.map((img, index) =>
            index > 0 ? (
              <TailwindImage
                key={`gallery-thumbnail-${index}`}
                src={img}
                onLoad={onImageLoad}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  cursor: "pointer",
                  borderRadius:
                    index === 1
                      ? "0 1rem 0 0"
                      : index === 2
                      ? "0"
                      : "0 0 1rem 0",
                }}
                boxProps={{
                  position: "relative",
                  width: "w-full",
                  height: "h-1/3",
                }}
                {...imageOptions}
                onClick={() => onClickPhoto?.(img)}
              />
            ) : null
          )}
        </TailwindFlex>
      </TailwindFlex>
    ),
    [images, imageOptions]
  );

  const standardGallery = useMemo(() => {
    return galleryMap?.map((img, index) => (
      <TailwindImage
        key={`gallery-thumbnail-${index}`}
        src={img.imageURL}
        onLoad={onImageLoad}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          cursor: "pointer",
        }}
        boxProps={{
          className: img?.className ? [img?.className] : [],
          position: "relative",
          width: "w-full",
          style: {
            width: images.length === 3 && index === 2 ? "100%" : img.width,
            height: img.height,
          },
        }}
        {...imageOptions}
        onClick={() => onClickPhoto?.(img.imageURL)}
      />
    ));
  }, [images]);

  const onClickPhoto = imageURL => {
    modalStore.openPrimaryModal(<MessageCenterImage imageURL={imageURL} />);
  };

  if (!message?.signed_urls?.length) {
    return null;
  }

  return (
    <TailwindFlex className={["MessagesReader__GalleryGrid"]}>
      <TailwindFlex
        borderRadius={"rounded-2xl"}
        placeItems={"place-items-center"}
        flexDirection={"flex-row"}
        flexWrap={"flex-wrap"}
        gap='gap-0.5'
        position='relative'
        width={images.length > 1 && images.length !== 4 ? "w-80" : "w-auto"}
        style={{
          backgroundColor,
        }}>
        {images.length === 4
          ? quadGallery
          : images.length > 1
          ? standardGallery
          : images.length === 1
          ? singleImage
          : null}
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default MessageCenterMessageThumbnails;
