import React, { useCallback, useMemo } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import MessageStore from "./stores/messages/MessageStore";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindHeading from "library/components/_tailwind/heading";
import TailwindButton from "library/components/_tailwind/button";
import ModalStore from "library/core/stores/modal";

type Props = {
  messageStore?: MessageStore;
  modalStore?: ModalStore;
};

const MessageCenterDeleteModal: React.ComponentType<
  Props & WrappedComponentProps
> = ({ messageStore, modalStore, intl }) => {
  const {
    selectedConversations,
    deleteConversations,
    activeConversationId,
    activeNewsAndPolicy,
    deleteNewsAndPolicy,
  } = messageStore!;
  const { closePrimaryModal } = modalStore!;

  const selectedConversationLength = useMemo(
    () =>
      selectedConversations && Array.isArray(selectedConversations)
        ? selectedConversations.length
        : 1,
    [selectedConversations]
  );

  const handleDelete = useCallback(() => {
    if (
      activeNewsAndPolicy &&
      activeNewsAndPolicy.id === activeConversationId
    ) {
      deleteNewsAndPolicy(activeNewsAndPolicy);
    } else {
      deleteConversations();
    }
    closePrimaryModal();
  }, [activeNewsAndPolicy, activeConversationId]);

  const title = useMemo(() => {
    if (selectedConversationLength <= 1) {
      return intl.formatMessage({ id: "verbiage.conversation", defaultMessage: "conversation" }).toLocaleLowerCase();
    } else {
      return `${selectedConversationLength} ${intl
        .formatMessage({ id: "verbiage.conversations", defaultMessage: "conversations" })
        .toLocaleLowerCase()}`;
    }
  }, [selectedConversationLength]);

  return (
    <TailwindFlex
      backgroundColor={"bg-primary-bg-color"}
      flexDirection={"flex-col"}
      padding={["p-8"]}
      borderRadius={"rounded-xl"}>
      <TailwindHeading level={6}>
        {selectedConversationLength <= 1
          ? intl.formatMessage({
              id: "verbiage.remove_these_conversations",
              defaultMessage: "Remove this conversation",
            })
          : intl.formatMessage(
              {
                id: "verbiage.remove_these_conversations",
                defaultMessage: "Remove these {count} conversations",
              },
              {
                count: selectedConversationLength,
              }
            )}
        ?
      </TailwindHeading>
      <TailwindFlex>
        {intl.formatMessage({
          id: "verbiage.removeConVersationSubHeader",
          defaultMessage:
            "This will remove {title} for you."
        }, {title})}
      </TailwindFlex>
      <TailwindFlex
        margin={["mt-8"]}
        justifyContent={"justify-end"}
        alignItems={"items-end"}>
        <TailwindFlex width={"w-auto"} margin={["mr-4"]}>
          <TailwindButton
            fullWidth
            onClick={closePrimaryModal}
            backgroundColor={"bg-transparent"}
            textProps={{
              textColor: "text-modal-button-color",
            }}
            borderStyle={"border-solid"}
            justifyContent={"justify-center"}>
            {intl.formatMessage({
              id: "verbiage.cancel",
              defaultMessage: "Cancel",
            })}
          </TailwindButton>
        </TailwindFlex>
        <TailwindFlex width={"w-auto"}>
          <TailwindButton
            padding={["px-6"]}
            backgroundColor={"bg-modal-button-color"}
            textProps={{ textColor: "text-white" }}
            justifyContent={"justify-center"}
            onClick={handleDelete}>
            {intl.formatMessage({
              id: "verbiage.remove",
              defaultMessage: "Remove",
            })}
          </TailwindButton>
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject("messageStore", "modalStore")(observer(MessageCenterDeleteModal))
);
