import config, { enableFrequentSocketLogs } from "core/config";
import { setTimeout } from "timers";
import { INotificationSocketMethods } from "./interfaces";
import { logger } from "library/core/utility";
import {
  SocketMiddlewareCallback,
  SocketOnAuthFailedCallback,
  SocketOnConnectedCallback,
  SocketOnDisconnectedCallback,
} from "common/broadcast/_stores/common/types";
import { broadcastStore } from "core/stores";
const NOTIFICATION_NAMESPACE = "/ws/models/socket/";
const pongsMissedLimit = 4;

const logPrefix = "[BroadcastSocket]:";

const maxReloadRetryCount = 5;

class NotificationSocket {
  public isConnected: boolean = false;
  private webSocket: WebSocket | undefined = undefined;
  private pongsMissed: number = 0;
  private pingTimeout: NodeJS.Timeout | undefined = undefined;
  private reloadRetryCount: number = 0;
  private isRetrying: boolean = false;
  private accessToken: string = "";
  private onConnectedCallback: SocketOnConnectedCallback = undefined;
  private onDisconnectedCallback: SocketOnDisconnectedCallback = undefined;
  private onAuthFailedCallback: SocketOnAuthFailedCallback = undefined;
  private middlewareCallback: SocketMiddlewareCallback = undefined;
  private listeners: INotificationSocketMethods | undefined = undefined;

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  public connect = (
    accessToken: string,
    onAuthFailedCallback: SocketOnAuthFailedCallback,
    onConnectedCallback: SocketOnConnectedCallback,
    onDisconnectedCallback: SocketOnDisconnectedCallback,
    middlewareCallback: SocketMiddlewareCallback,
    listeners: INotificationSocketMethods
  ) => {
    this.log("connect started");
    this.reloadRetryCount = 0;
    this.accessToken = accessToken;
    this.onAuthFailedCallback = onAuthFailedCallback;
    this.onConnectedCallback = onConnectedCallback;
    this.onDisconnectedCallback = onDisconnectedCallback;
    this.middlewareCallback = middlewareCallback;
    this.listeners = listeners;
    this.openSocket(accessToken);
    this.log("connect finished");
  };

  private ping = (): void => {
    if (enableFrequentSocketLogs) {
      this.log("ping started");
    }
    this.pingTimeout = setTimeout(() => {
      if (this.pongsMissed < pongsMissedLimit) {
        this.pongsMissed++;
        if (this.isConnected) {
          this.webSocket?.send(JSON.stringify({ command: "ping" }));
          this.ping();
        }
      } else {
        this.reload();
      }
    }, 4000);
    if (enableFrequentSocketLogs) {
      this.log("ping finished");
    }
  };

  onOpenSocket = () => {
    this.log("onOpenSocket started");
    this.isConnected = true;
    this.setIsRetrying(false);
    this.ping();
    this.log("onOpenSocket finished");
    this.onConnectedCallback && this.onConnectedCallback();
  };

  onCloseSocket = async e => {
    this.log("onCloseSocket started", e);
    this.isConnected = false;
    const code = parseInt(e?.code);
    if (code === 4003 || code === 1006) {
      if (code === 1006) {
        // retry only once when its 1006
        this.reloadRetryCount = maxReloadRetryCount;
      }
      await this.reload();
    } else if (this.onDisconnectedCallback) {
      await this.onDisconnectedCallback();
    }
    if (this.middlewareCallback) {
      this.middlewareCallback("close", e);
    }
    this.log("onCloseSocket finished");
  };

  private setIsRetrying = (isRetrying: boolean) => {
    if (enableFrequentSocketLogs) {
      this.log("setIsRetrying started", isRetrying);
    }
    this.isRetrying = isRetrying;
    broadcastStore.onIsRetryingChanged(isRetrying);
    if (enableFrequentSocketLogs) {
      this.log("setIsRetrying finished");
    }
  };

  public closeSocket = () => {
    this.pingTimeout && clearTimeout(this.pingTimeout);
    this.webSocket && this.webSocket.close();
    this.webSocket = undefined;
  };

  private reload = async (): Promise<void> => {
    try {
      this.log("reload started");
      if (
        this.onAuthFailedCallback &&
        this.onConnectedCallback &&
        this.onDisconnectedCallback
      ) {
        this.setIsRetrying(true);
        const newAccessToken = await this.onAuthFailedCallback();
        this.accessToken = newAccessToken;
        this.log("reload got accessToken", newAccessToken);
        await this.onDisconnectedCallback();
        this.closeSocket();
        // give disconnect 2 secs to finish
        setTimeout(() => {
          this.openSocket(this.accessToken);
          this.log("reload finished");
        }, 2000);
      }
    } catch (error) {
      this.log("reload failed", error);
      this.reloadRetryCount += 1;
      if (this.reloadRetryCount < maxReloadRetryCount) {
        this.log(
          "reload failed & started retrying with current iteration",
          this.reloadRetryCount,
          "and max retry count",
          maxReloadRetryCount
        );
        await this.reload();
      } else {
        this.setIsRetrying(false);
        this.log("reload failed, reached retry limit");
      }
    } finally {
      this.log("reload finished");
    }
  };

  onErrorSocket = async e => {
    this.log("onErrorSocket received", e);
    if (this.middlewareCallback) {
      this.middlewareCallback("error", e);
    }
  };

  onMessageSocket = (e) => {
    const data = JSON.parse(e.data);
    switch(data.message_type) {
      case "new_message_center_message":
        this.triggerEvents("new_message_center_message", data?.data);
        break;
      case "rejected_message_center_message":
        this.triggerEvents("rejected_message_center_message", data?.data);
        break;
      case "pong":
        this.pongsMissed = 0;
        break;
      default:
        break;
    }
  }

  private triggerEvents = (
    method: keyof INotificationSocketMethods,
    data: any
  ): void => {
    this.log("triggerEvents started", method);
    const args = [data.payload || data, data.properties, data.reason];
    this.listeners?.[method]?.(...args);
    this.log("triggerEvents finished", method);
  };

  private openSocket = (accessToken: string): void => {
    this.log("openSocket started");
    this.pongsMissed = 0;
    if (!this.isRetrying) {
      this.reloadRetryCount = 0;
    }
    if (!this.webSocket) {
      this.webSocket = new WebSocket(
        `${config.broadcastApiSocket}${NOTIFICATION_NAMESPACE}?jwt=${accessToken}`
      );

      Object.assign(this.webSocket, {
        onopen: this.onOpenSocket,
        onclose: this.onCloseSocket,
        onerror: this.onErrorSocket,
        onmessage: this.onMessageSocket,
      });
    }
    this.log("openSocket finished");
  };
}

export default NotificationSocket;