import { inject, observer } from "mobx-react";
import React, { FunctionComponent, useCallback, useMemo } from "react";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import ProductDropdown from "../broadcast-pricing-product-dropdown";
import PricingStore from "../_stores/pricing/PricingStore";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindFlex from "library/components/_tailwind/flex";
import StudioModelAccessRights from "../../studio-models-access-rights";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import TailwindSwitch from "library/components/_tailwind/switch";
import TailwindBox from "library/components/_tailwind/box";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import { IShowType, ShowTab } from "../_stores/broadcast/enums";
import LanguageStore from "library/core/stores/language/LanguageStore";

interface TypeIfShowSelector {
  broadcastStore?: BroadcastStore;
  pricingStore?: PricingStore;
  profileStore?: ProfileStore;
  layoutStore?: LayoutStore;
  languageStore?: LanguageStore;
}

const BroadcastPrivateSettings: FunctionComponent<
  TypeIfShowSelector
> = ({ pricingStore, broadcastStore, profileStore, layoutStore, languageStore }) => {
  const {
    submitField,
    submitEnabled,
    modelProducts,
    voyeurPriceOptions,
    privateChatPriceOptions,
    partyChatPriceOptions,
    formatPricesByID,
    submitPrice,
    submitVoyeurPrice
  } = pricingStore!;
  const { isMobileScreenSize } = layoutStore!;
  const { intl } = languageStore!;

  const {
    modelProfile: { access_rights },
    isStudioModel,
  } = profileStore!;
  const { isShowStarted, currentShowTab, currentShowType } = broadcastStore!;
  const { is_private_allowed } = modelProducts;

  const isAutoRecordOn = useMemo(() => {
    return modelProducts?.chat_settings?.is_recording_all_private_shows;
  }, [modelProducts?.chat_settings?.is_recording_all_private_shows]);


  const onChangeVoyeurChatPrice = useCallback((value: any) => {
    submitVoyeurPrice(value);
  }, []);

  const onChangePrivateChatPrice = useCallback((value: any) => {
    submitPrice("private_chat", value);
  }, []);

  const onChangePartyChatPrice = useCallback(
    (value: any) => submitPrice("party_chat", value),
    []
  );

  const onChangePrivateConsentNeeded = useCallback(() => {
    submitField(
      "is_private_consent_needed",
      !modelProducts?.is_private_consent_needed
    );
  }, [modelProducts]);

  const isPrivateShowEnabled = useMemo(() => {
    return is_private_allowed === undefined || is_private_allowed === true;
  }, [is_private_allowed]);

  const onChangeTogglePrivateShow = useCallback(() => {
    submitEnabled("is_private_allowed");
  }, []);

  const canPrivateToggle = useMemo(() => {
    return (
      (isShowStarted && currentShowType !== IShowType.FREE) ||
      currentShowTab !== ShowTab.FREE
    );
  }, [isShowStarted, currentShowType, currentShowTab]);

  return (
    <>
      <TailwindFlex
        flexDirection='flex-col'
        minHeight={isMobileScreenSize ? "min-h-0" : "min-h-80"}>
        <TailwindTranslatedText
          textColor='text-main-color'
          fontWeight={"font-semibold"}
          descriptor={{
            id: "free-to-enter-private.pricing",
            defaultMessage: "Pricing:",
          }}
        />
        <StudioModelAccessRights
          isIndividualModel={!isStudioModel}
          shouldShow={!!access_rights?.is_pricing_settings_change_allowed}>
          <TailwindFlex justifyContent='justify-between' margin={["mb-3"]}>
            <TailwindTranslatedText
              padding={["pt-2"]}
              textColor='text-main-color'
              margin={["mr-1"]}
              descriptor={{
                id: "free-to-enter-private.private",
                defaultMessage: "Private show",
              }}
            />
            <ProductDropdown
              dataTestId="private-chat-price-dropdown"
              className={["h-10"]}
              fullHeight={true}
              disabled={isShowStarted}
              options={privateChatPriceOptions}
              onChange={onChangePrivateChatPrice}
              price={modelProducts?.private_chat}
              currency={intl.formatMessage({
                id: `common.units.tokens-long`,
                defaultMessage: "tokens",
              })}
              unit={intl.formatMessage({
                id: `common.units.minute`,
                defaultMessage: "minute",
              })}
              direction={"bottom"}
            />
          </TailwindFlex>
          <TailwindFlex justifyContent='justify-between' margin={["mb-3"]}>
            <TailwindTranslatedText
              padding={["pt-2"]}
              textColor='text-main-color'
              margin={["mr-1"]}
              descriptor={{
                id: "free-to-enter-private.party",
                defaultMessage: "Party show",
              }}
            />
            <ProductDropdown
              dataTestId="party-chat-price-dropdown"
              className={["h-10"]}
              fullHeight={true}
              disabled={isShowStarted}
              options={partyChatPriceOptions}
              onChange={onChangePartyChatPrice}
              direction={"bottom"}
              price={formatPricesByID("party_chat", "is_party_allowed")}
              currency={intl.formatMessage({
                id: `common.units.tokens-long`,
                defaultMessage: "tokens",
              })}
              unit={intl.formatMessage({
                id: `common.units.minute`,
                defaultMessage: "minute",
              })}
            />
          </TailwindFlex>
          <TailwindFlex justifyContent='justify-between' margin={["mb-1"]}>
            <TailwindTranslatedText
              padding={["pt-2"]}
              margin={["mr-1"]}
              textColor='text-main-color'
              descriptor={{
                id: "free-to-enter-private.voyeur",
                defaultMessage: "Voyeurs can watch",
              }}
            />
            <ProductDropdown
              dataTestId="voyeur-chat-price-dropdown"
              className={["h-10"]}
              fullHeight={true}
              disabled={isShowStarted}
              options={voyeurPriceOptions}
              onChange={onChangeVoyeurChatPrice}
              direction={"bottom"}
              price={formatPricesByID("voyeur_chat", "is_voyeur_allowed")}
              currency={intl.formatMessage({
                id: `common.units.tokens-long`,
                defaultMessage: "tokens",
              })}
              unit={intl.formatMessage({
                id: `common.units.minute`,
                defaultMessage: "minute",
              })}
            />
          </TailwindFlex>
          <TailwindBox margin={["mb-4"]} display="flex" justifyContent="justify-end">
          {isAutoRecordOn && !Number(formatPricesByID("voyeur_chat", "is_voyeur_allowed")) && 
            <TailwindTranslatedText
            textColor={"text-gray-500"}
            fontWeight={"font-light"}
            fontSize={"text-sm"}
            fontStyle={"italic"}
              descriptor={{
                id: "free-to-enter-private.disableRecordingshows",
                defaultMessage: "Automatically record my paid shows is disabled",
              }}
            />
          }
        </TailwindBox>
        </StudioModelAccessRights>

        <TailwindFlex
          textColor={
            !isPrivateShowEnabled ? "text-gray-500" : "text-main-color"
          }
          justifyContent='justify-between'
          margin={["mb-3"]}>
          <TailwindTranslatedText
            whiteSpace='whitespace-nowrap'
            paragraphProps={{
              fontWeight: "font-semibold",
            }}
            descriptor={{
              id: "free-to-enter-private.allow-private-shows",
              defaultMessage: "Allow private shows",
            }}
          />

          <TailwindBox height='h-5'>
            <TailwindSwitch
              data-testid="private-show-toggle"
              onLabel={intl.formatMessage({
                id: "settings.on",
                defaultMessage: "On",
              })}
              offLabel={intl.formatMessage({
                id: "settings.off",
                defaultMessage: "Off",
              })}
              size={"xs-wide"}
              onChange={onChangeTogglePrivateShow}
              isChecked={isPrivateShowEnabled}
              offBackgroundColor={"bg-toggle-disabled"}
              offLabelProps={{
                textColor: "text-white",
              }}
              onBackgroundColor={"bg-earnings-bg-color"}
              onLabelProps={{
                textColor: "text-main-color",
              }}
              pillBackgroundColor={
                isPrivateShowEnabled
                  ? "bg-green-500"
                  : "bg-gray-200"
              }
              disabled={canPrivateToggle}
            />
          </TailwindBox>
        </TailwindFlex>

        <TailwindFlex
          textColor={
            !isPrivateShowEnabled ? "text-gray-500" : "text-main-color"
          }
          justifyContent='justify-between'
          margin={["mb-3"]}>
          <TailwindTranslatedText
            whiteSpace='whitespace-nowrap'
            paragraphProps={{
              fontWeight: "font-semibold",
            }}
            descriptor={{
              id: "free-to-enter-private.member-takes-me-private-automatically",
              defaultMessage: "Ask me before going private",
            }}
          />

          <TailwindBox height='h-5'>
            <TailwindSwitch
              data-testid="private-show-consent-toggle"
              onLabel={intl.formatMessage({
                id: "settings.on",
                defaultMessage: "On",
              })}
              offLabel={intl.formatMessage({
                id: "settings.off",
                defaultMessage: "Off",
              })}
              size={"xs-wide"}
              onChange={onChangePrivateConsentNeeded}
              isChecked={modelProducts?.is_private_consent_needed}
              offBackgroundColor={"bg-toggle-disabled"}
              offLabelProps={{
                textColor: "text-white",
              }}
              onBackgroundColor={"bg-earnings-bg-color"}
              onLabelProps={{
                textColor: "text-main-color",
              }}
              pillBackgroundColor={
                modelProducts?.is_private_consent_needed
                  ? "bg-green-500"
                  : "bg-gray-200"
              }
              disabled={!isPrivateShowEnabled}
            />
          </TailwindBox>
        </TailwindFlex>
      </TailwindFlex>
    </>
  );
};
export default inject(
    "pricingStore",
    "broadcastStore",
    "profileStore",
    "layoutStore",
    "languageStore",
  )(observer(BroadcastPrivateSettings));
