export enum CustomIconName {
  "add-friend" = "add-friend",
  "alcohol-glass" = "alcohol-glass",
  "anatomy" = "anatomy",
  "arrows-pointing-center" = "arrows-pointing-center",
  "chat" = "chat",
  "chat-bubble" = "chat-bubble",
  "notes-pencil" = "notes-pencil",
  "comments" = "comments",
  "contests" = "contests",
  "gallery" = "gallery",
  "support" = "support",
  "photos" = "photos",
  "messages" = "messages",
  "newspaper" = "newspaper",
  "referals" = "referals",
  "topmodels" = "topmodels",
  "giftcards" = "giftcards",
  "earnings" = "earnings",
  "profile" = "profile",
  "fans" = "fans",
  "dashboard" = "dashboard",
  "double-hearts" = "double-hearts",
  "emoji" = "emoji",
  "eye" = "eye",
  "setting_side" = "setting_side",
  "logout" = "logout",
  "settings_extra" = "settings_extra",
  "filter-narrow-down" = "filter-narrow-down",
  "fire-and-pepper" = "fire-and-pepper",
  "flag" = "flag",
  "flirt" = "flirt",
  "friends" = "friends",
  "gift" = "gift",
  "gold-crown" = "gold-crown",
  "crown" = "crown",
  "heart-outline" = "heart-outline",
  "horizontal-dots" = "horizontal-dots",
  "kebab-icon" = "kebab-icon",
  "fire" = "fire",
  "medal" = "medal",
  "pin" = "pin",
  "points" = "points",
  "profile-silhouette" = "profile-silhouette",
  "scroll-top" = "scroll-top",
  "search" = "search",
  "search-alt" = "search-alt",
  "star" = "star",
  "token" = "token",
  "verified" = "verified",
  "vertical-dots" = "vertical-dots",
  "video-camera" = "video-camera",
  "clock" = "clock",
  "tips" = "tips",
  "winking-emoji" = "winking-emoji",
  "person-holding-hands-up" = "person-holding-hands-up",
  "two-people-icon" = "two-people-icon",
  "three-people-icon" = "three-people-icon",
  "show-type" = "show-type",
  "dice" = "dice",
  "info-circle" = "info-circle",
  "question-mark" = "question-mark",
  "cancel-circle" = "cancel-circle",
  "buzzmode" = "buzzmode",
  "optimization" = "optimization",
  "person" = "person",
  "people-icon" = "people-icon",
  "private-message" = "private-message",
  "invite-to-private" = "invite-to-private",
  "ban" = "ban",
  "notes" = "notes",
  "kick" = "kick",
  "spinner" = "spinner",
  "masonry-layout" = "masonry-layout",
  "column-layout" = "column-layout",
  "language-icon" = "language-icon",
  "text-size-icon" = "text-size-icon",
  "wheel-of-fun-active" = "wheel-of-fun-active",
  "wheel-of-fun-inactive" = "wheel-of-fun-inactive",
  "close-thicker" = "close-thicker",
  "arrowLeft" = "arrowLeft",
  "mute" = "mute",
  "unmute" = "unmute",
  "nudeListing" = "nudeListing",
  "pineapple-icon" = "pineapple-icon",
  "confirm-circle" = "confirm-circle",
  "upload-icon" = "upload-icon",
  "remove-icon" = "remove-icon",
  "filterIcon" = "filter-icon",
  "whisper" = "whisper"
}
