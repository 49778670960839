import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";

type Props = {
    imgSrc: string;
    id: string;
    isView?: boolean;
    removeImg?: (id: string) => void;
    width?: number;
    height?: number;
};

const ImgContainer: React.ComponentType<Props> = ({imgSrc, id, removeImg}) => {

    return (
        <TailwindFlex className={['img-container']} position="relative" style={{
            backgroundImage: `url(${imgSrc})`
        }}>
            {removeImg &&
                <TailwindButton
                    position="absolute"
                    inset={["-right-3", "-top-2", "left-auto"]}
                    height={"h-auto"}
                    padding={["p-0"]}
                    rightIconProps={{
                        fontSize: "text-base",
                        name: CustomIconName["remove-icon"],
                    }}
                    onClick={() => removeImg(id)}
                    fullWidth={false}
                />
            }
        </TailwindFlex>
    );
};

export default ImgContainer;