import { TBackgroundColor } from "tailwindcss-classnames";

export const InitialData = {
  conversations: [],
  selectedConversations: [],
  selectedContacts: [],
  contacts: [],
  isComposing: false,
  isSearchingView: false,
  isDeleting: false,
  isBulkMessageView: false,
  activeConversationMessages: [],
  message: "",
  contactsSearchTerm: "",
  conversationsSearchTerm: "",
  contactsFilterTypes: [],
  nextContactsUrl: "",
  isContactsLoading: false,
  isContactsLoadingMore: false,
  isSearchingContacts: false,
  nextMessagesUrl: "",
  futureMessagesUrl: "",
  isMessagesLoading: false,
  isMessagesLoadingMore: false,
  isActiveConversationMessagesRefreshing: false,
  nextConversationsUrl: "",
  isConversationsLoading: false,
  isConversationsLoadingMore: false,
  isSearchingConversations: false,
  searchingMessagesStartDate: "",
  isActiveConversationBlocked: false,
  activeConversationBlockedType: null,
  activeConversationBlockedCountryId: "",
  activeConversationBlockedCountryLabel: "",
  requestTimeout: null,
  contactsSearchTermReaction: undefined,
  conversationsSearchTermReaction: undefined,
  isMessagesMenuInNavbarOpen: false,
  unreadConversationMessagesCount: 0,
  activeConversation: null,
  activeSearchedWord: "",
  newsAndPolicies: [],
  audits: [],
  activeNewsAndPolicy: null,
  unreadNewsAndPolicyCount: 0,
  systemMessageUser: null,
  sendMessageError: null,
};
export const SEARCH_POLLING_TIMEOUT_IN_SECS = 1;
export const SEARCH_START_REQUEST_AFTER_N_LETTERS = 1;
export const INITIAL_MESSAGE_FONT_SIZE = "text-sm";
export const MESSAGE_CENTER_AUDIT_NOTIFICATION_ID = "2257-audit-id";
export const MSG_CENTER_MAX_IMAGE_SIZE = 20;
export const MESSAGES_PER_REQUEST = 20;

export enum MessageTabEnum {
  MESSAGE = "MESSAGE",
  POLICY = "POLICY",
}

export enum LoyalFanFiltersEnum {
  fan_club = "fanclub_members",
  bounty = "bounty_members",
  top_admirer = "top_admirers",
  top_spenders = "top_spenders",
  recent_visitors = "recent_visitors",
}

export const LoyalFanFiltersKey = {
  fanclub_members: "is_fanclub_member",
  top_admirers: "is_top_admirer",
  top_spenders: "is_top_spender",
  recent_visitors: "is_recent_visitor",
  bounty_members: "is_bounty_member",
};

export const LOYAL_FANS_FILTERS = [
  {
    name: "Bounty/Referral",
    custom_label: "B/R",
    background: "bg-lightPink-bg-color" as TBackgroundColor,
    type: "bounty_members",
  },
  {
    name: "Fan Club",
    background: "bg-lightGreen-bg-color" as TBackgroundColor,
    type: "fanclub_members",
  },

  {
    name: "Recent Visitors",
    background: "bg-lightGray-bg-color" as TBackgroundColor,
    type: "recent_visitors",
  },
  {
    name: "Top Spenders",
    background: "bg-lightPurple-bg-color" as TBackgroundColor,
    type: "top_spenders",
  },
  {
    name: "Top Admirer",
    background: "bg-lightRed-bg-color" as TBackgroundColor,
    type: "top_admirers",
  },
];

export const ConversationBlockedErrors = {
  member: "you_blocked_member_from_contacting_you",
  country: "you_blocked_member_country_from_contacting_you",
  state: "you_blocked_member_state_from_contacting_you",
};
