import React from "react";
import { IChatNotification } from "common/broadcast/_stores/chat/interfaces";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { BroadcastType } from "common/broadcast/_stores/broadcast/enums";
import { IBroadcastData } from "common/broadcast/_stores/broadcast/interfaces";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import LocalBar from "@material-ui/icons/LocalBar";
import TailwindIcon from "library/components/_tailwind/icon";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import { inject, observer } from "mobx-react";
import ChatStore from "common/broadcast/_stores/chat/ChatStore";

const broadcastTypes = new Map<BroadcastType, IBroadcastData>([
  [
    BroadcastType.Tipped,
    {
      className: "notificationTipped",
      icon: (fontSize: number) => (
        <MonetizationOnOutlinedIcon
          style={{ width: fontSize, height: fontSize }}
        />
      ),
    } as IBroadcastData,
  ],
  [
    BroadcastType.Gift,
    {
      className: "notificationGift",
      icon: (fontSize: number) => (
        <LocalBar style={{ width: fontSize, height: fontSize }} />
      ),
    } as IBroadcastData,
  ],
  [
    BroadcastType.Buzz,
    {
      className: "notificationBuzz",
      icon: (fontSize: number) => (
        <svg
          className=''
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          xmlSpace='preserve'
          x='0px'
          y='0px'
          viewBox='0 0 64 64'
          style={{ width: fontSize, height: fontSize }}>
          <polygon points='42.4,29.1 32,29.1 32,12.9 21.6,34.1 32,34.1 32,50.3 '></polygon>
          <g>
            <path d='M16.5,31.6c0-2.7,0.7-5.2,1.9-7.4c0.2-0.4,0.2-1-0.2-1.3l-3.1-3.1c-0.5-0.5-1.3-0.4-1.7,0.2 c-2.1,3.4-3.4,7.4-3.4,11.7c0,4.2,1.2,8.2,3.3,11.5c0.4,0.6,1.2,0.7,1.7,0.2l3.2-3.2c0.3-0.3,0.4-0.9,0.2-1.3 C17.2,36.8,16.5,34.3,16.5,31.6z'></path>
            <path d='M47.5,31.6c0,2.6-0.7,5.1-1.8,7.3c-0.2,0.4-0.2,1,0.2,1.3l3.2,3.2c0.5,0.5,1.3,0.4,1.7-0.2 c2.1-3.4,3.3-7.3,3.3-11.5c0-4.3-1.3-8.3-3.4-11.7c-0.4-0.6-1.2-0.7-1.7-0.2l-3.1,3.1c-0.3,0.3-0.4,0.9-0.2,1.3 C46.8,26.4,47.5,29,47.5,31.6z'></path>
            <path d='M57.5,31.6c0,5.4-1.7,10.4-4.5,14.5c-0.3,0.4-0.3,1,0.1,1.4l3.1,3.1c0.5,0.5,1.2,0.4,1.6-0.1 c3.9-5.3,6.2-11.8,6.2-18.9c0-7.1-2.3-13.7-6.3-19c-0.4-0.5-1.2-0.6-1.6-0.1L53,15.6c-0.4,0.4-0.4,1-0.1,1.4 C55.8,21.1,57.5,26.2,57.5,31.6z'></path>
            <path d='M6.5,31.6c0-5.4,1.7-10.5,4.6-14.7c0.3-0.4,0.3-1-0.1-1.4l-3.1-3.1c-0.5-0.5-1.2-0.4-1.6,0.1 C2.3,17.9,0,24.5,0,31.6c0,7.1,2.3,13.6,6.2,18.9c0.4,0.5,1.2,0.6,1.6,0.1l3.1-3.1c0.4-0.4,0.4-0.9,0.1-1.4C8.2,42,6.5,37,6.5,31.6 z'></path>
          </g>
        </svg>
      ),
    } as IBroadcastData,
  ],
  [
    BroadcastType.SuperBuzz,
    {
      className: "notificationSuperBuzz",
      icon: (fontSize: number) => (
        <span className='SuperBuzzIconAnimated'>
          <svg
            className='notificationSuperBuzz__svg'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            xmlSpace='preserve'
            x='0px'
            y='0px'
            viewBox='0 0 64 64'
            style={{ width: fontSize, height: fontSize }}>
            <polygon
              id='lightning-icon'
              points='42.4,29.1 32,29.1 32,12.9 21.6,34.1 32,34.1 32,50.3 '></polygon>
            <g>
              <path
                className='waves'
                id='left-inner'
                d='M16.5,31.6c0-2.7,0.7-5.2,1.9-7.4c0.2-0.4,0.2-1-0.2-1.3l-3.1-3.1c-0.5-0.5-1.3-0.4-1.7,0.2 c-2.1,3.4-3.4,7.4-3.4,11.7c0,4.2,1.2,8.2,3.3,11.5c0.4,0.6,1.2,0.7,1.7,0.2l3.2-3.2c0.3-0.3,0.4-0.9,0.2-1.3 C17.2,36.8,16.5,34.3,16.5,31.6z'></path>
              <path
                className='waves'
                id='left-outer'
                d='M6.5,31.6c0-5.4,1.7-10.5,4.6-14.7c0.3-0.4,0.3-1-0.1-1.4l-3.1-3.1c-0.5-0.5-1.2-0.4-1.6,0.1 C2.3,17.9,0,24.5,0,31.6c0,7.1,2.3,13.6,6.2,18.9c0.4,0.5,1.2,0.6,1.6,0.1l3.1-3.1c0.4-0.4,0.4-0.9,0.1-1.4C8.2,42,6.5,37,6.5,31.6 z'></path>
              <path
                className='waves'
                id='right-inner'
                d='M47.5,31.6c0,2.6-0.7,5.1-1.8,7.3c-0.2,0.4-0.2,1,0.2,1.3l3.2,3.2c0.5,0.5,1.3,0.4,1.7-0.2 c2.1-3.4,3.3-7.3,3.3-11.5c0-4.3-1.3-8.3-3.4-11.7c-0.4-0.6-1.2-0.7-1.7-0.2l-3.1,3.1c-0.3,0.3-0.4,0.9-0.2,1.3 C46.8,26.4,47.5,29,47.5,31.6z'></path>
              <path
                className='waves'
                id='right-outer'
                d='M57.5,31.6c0,5.4-1.7,10.4-4.5,14.5c-0.3,0.4-0.3,1,0.1,1.4l3.1,3.1c0.5,0.5,1.2,0.4,1.6-0.1 c3.9-5.3,6.2-11.8,6.2-18.9c0-7.1-2.3-13.7-6.3-19c-0.4-0.5-1.2-0.6-1.6-0.1L53,15.6c-0.4,0.4-0.4,1-0.1,1.4 C55.8,21.1,57.5,26.2,57.5,31.6z'></path>
            </g>
          </svg>
        </span>
      ),
    } as IBroadcastData,
  ],
  [
    BroadcastType.BroadcastStart,
    {
      className: "notificationBroadcastingInHD",
    } as IBroadcastData,
  ],
  [
    BroadcastType.BroadcastStop,
    {
      className: "notificationBroadcastingStop",
    } as IBroadcastData,
  ],
  [
    BroadcastType.ShowStart,
    {
      className: "notificationShowStart",
    } as IBroadcastData,
  ],
  [
    BroadcastType.ShowStop,
    {
      className: "notificationShowStop",
    } as IBroadcastData,
  ],
  [
    BroadcastType.WheelOfFun,
    {
      className: "notificationWheel",
      icon: (fontSize: number) => (
        <img
          src='/images/broadcast/notifications/wheel-of-fun.png'
          alt='Wheel of Fun'
          style={{ width: fontSize, height: fontSize }}
        />
      ),
    } as IBroadcastData,
  ],
  [
    BroadcastType.TopAdmirer,
    {
      className: "notificationAdmirer",
      icon: (fontSize: number) => (
        <TailwindIcon
          name={CustomIconName.medal}
          iconProps={{ iconrange: 1 }}
          style={{ fontSize: `${fontSize}px` }}
        />
      ),
    } as IBroadcastData,
  ],
]);

type Props = {
  notification: IChatNotification;
  chatStore?: ChatStore;
};

const ChatNotification: React.ComponentType<Props & WrappedComponentProps> = ({
  notification,
  chatStore,
  intl,
}) => {
  const { chatFontSize: fontSize } = chatStore!;
  const getText = (notification: IChatNotification): string => {
    const result = notification.fixedContentKey
      ? intl.formatMessage(
          {
            id: notification.fixedContentKey,
            defaultMessage: notification.content,
          },
          notification.fixedContentParams || {}
        )
      : notification.content;

    return result || "";
  };

  const getImage = (notification: IChatNotification, fontSize: number) => {
    if (notification.image) {
      return (
        <img
          src={notification.image}
          alt={notification.content}
          style={{ width: 64, height: 64, margin: '5px 10px' }}
        />
      );
    } else if (broadcastTypes.get(notification.broadcastType!)?.icon) {
      return broadcastTypes.get(notification.broadcastType!)?.icon(fontSize);
    } else {
      return null;
    }
  };

  return (
    <div
      className={`ChatNotification ${
        broadcastTypes.get(notification.broadcastType!)?.className || ""
      }`}
      data-testid={"chat-message-notification"}
      style={{ fontSize }}>
      [System]:&nbsp;
      {getImage(notification, fontSize)}&nbsp;
      <div
        style={{ fontSize }}
        dangerouslySetInnerHTML={{
          __html: getText(notification),
        }}
      />
    </div>
  );
};

export default injectIntl(inject("chatStore")(observer(ChatNotification)));
