import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import BroadcastChatMessages from "./chat-message-list";
import BroadcastChatInput from "./chat-input-form";
import { IChatMessageOrNotification } from "../_stores/chat/interfaces";
import ChatStore from "../_stores/chat/ChatStore";
import TailwindFlex from "library/components/_tailwind/flex";
import { ChatModeEnum } from "../_stores/chat/enums";
import BroadcastStore from "common/broadcast/_stores/broadcast/BroadcastStore";
import { BroadcastStreamState } from "common/broadcast/_stores/broadcast/enums";
import NodeChatStore from "../_stores/nodechat/NodeChatStore";

type Props = {
  messages: IChatMessageOrNotification[];
  children?: React.ReactChild | React.ReactChildren;
  whisperMemberUsername?: string;
  whisperMemberId?: string;
  broadcastStore?: BroadcastStore;
  nodeChatStore?: NodeChatStore;
  chatStore?: ChatStore;
  chatMode?: ChatModeEnum;
};

export const BROADCAST_CHAT_INPUT_MAX_CHARS: number = 255;
export const BROADCAST_CHAT_MESSAGES_LIMIT: number = 10;

const BroadcastChatTab: React.ComponentType<Props & WrappedComponentProps> = ({
  messages,
  whisperMemberUsername,
  whisperMemberId,
  children,
  nodeChatStore,
  chatStore,
  broadcastStore,
  chatMode = ChatModeEnum.PUBLIC,
}) => {
  const { streamState, isShowStarted } = broadcastStore!;
  const { resetSupportChatMessageUnread } = chatStore!;
  const { sendMessage } = nodeChatStore!;

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [showLimitMessage, setShowLimitMessage] = useState(false);
  // const [isMaxChars, setIsMaxChars] = useState(false);
  const [shouldAddToSavedMessages, setShouldAddToSavedMessages] =
    useState(false);

  //const sendQuickReply = useCallback(
  //quickReply => {
  //sendMessage(
  //quickReply.message,
  //chatMode,
  //whisperMemberUsername,
  //whisperMemberId
  // );
  // },
  // [chatMode, whisperMemberUsername, whisperMemberId]
  //);

  const onSendMessage = useCallback(
    (message: string) => {
      showLimitMessage && setShowLimitMessage(false);
      sendMessage(message, chatMode, whisperMemberUsername, whisperMemberId);
    },
    [chatMode, whisperMemberUsername, whisperMemberId]
  );

  useEffect(() => {
    if (chatMode === ChatModeEnum.ADMIN) {
      resetSupportChatMessageUnread();
    }
  }, [chatMode]);

  const isStreamingAndShowStarted = useMemo(() => {
    return streamState == BroadcastStreamState.started && isShowStarted;
  }, [streamState, isShowStarted]);

  return (
    <TailwindFlex
      data-testid={"chat-wall"}
      className={["BroadcastChatTab"]}
      flexDirection={"flex-col"}
      padding={["px-4"]}
      backgroundColor='bg-tertiary-bg-color'
      height={"h-full"}>
      {children}
      <BroadcastChatMessages
        chatMode={chatMode}
        messages={messages}
        isStreamingAndShowStarted={isStreamingAndShowStarted}
      />
      {/* <BroadcastChatQuickText
        sendQuickReply={sendQuickReply}
        showLimitMessage={showLimitMessage}
        setShowLimitMessage={setShowLimitMessage}
        isMaxChars={isMaxChars}
        setIsMaxChars={setIsMaxChars}
  />*/}

      <BroadcastChatInput
        sendMessage={onSendMessage}
        setShowLimitMessage={setShowLimitMessage}
        shouldAddToSavedMessages={shouldAddToSavedMessages}
        disableInput={streamState !== BroadcastStreamState.started}
        inputRef={inputRef}
        setShouldAddToSavedMessages={setShouldAddToSavedMessages}
      />
    </TailwindFlex>
  );
};

export default injectIntl(
  inject(
    "nodeChatStore",
    "chatStore",
    "broadcastStore"
  )(observer(BroadcastChatTab))
);
