import { WEBRTC_MAX_CONSECUTIVE_MISSED_VIDEO_REPORTS_BEFORE_ERROR } from "./consts";

export type WebRTCOnError = {
  error: any;
  code: number;
};
export interface viewerSetup {
  modelName: string;
  applicationName: string;
  streamName: string;
  wssUrl: string;
}

export default class WebrtcViewer {
  private maxIceRetries = 10;
  private applicationName;
  private streamName;
  private wssUrl;
  private wsConnection;
  private videoEl: HTMLVideoElement | null = null;
  private streamInfo: any = {};
  private videoBitrate = 1000; // in kbps
  private trackStats: any = {};
  private highestBitrate = 0;
  private highestBitrateTrackId = "";
  private peerConnection: RTCPeerConnection | null = null;
  private iceRetryCount: number = 0;
  private retryInterval;
  private statsInterval;
  private onPlay;
  private onClose;
  private onError: (error: WebRTCOnError) => void;
  private onVideoError;
  private repeaterRetryCount = 0;
  private consecutiveMissedVideoReports = 0;

  private wsRetries = 0;
  private maxWsRetires = 3;

  private userData = {
    iceServers: [],
  };

  constructor(config) {
    this.videoEl = config.videoEl;

    this.applicationName = config.setup.applicationName;
    this.streamName = config.setup.streamName;
    this.wssUrl = config.setup.wssUrl;

    this.onPlay = config.onPlay || null;
    this.onClose = config.onClose || null;
    this.onError = config.onError || null;
    this.onVideoError = config.onVideoError || null;

    this.repeaterRetryCount = 0;

    this.streamInfo = {
      applicationName: this.applicationName,
      streamName: this.streamName,
    };

    // Initialize the video element and establish WebSocket connection
    this.peerConnection = new RTCPeerConnection(this.userData);
    this.peerConnection.addEventListener("iceconnectionstatechange", () => {
      this.pcIceConnectionStateChangeHandler();
    });

    this.wsConnect();
    this.statsInterval = setInterval(() => {
      this.tracePcState();
    }, 1000);
  }

  wsConnect() {
    this.repeaterRetryCount = 0;
    this.consecutiveMissedVideoReports = 0;
    try {
      this.wsConnection = new WebSocket(this.wssUrl);
      this.wsConnection.binaryType = "arraybuffer";

      if (this.wsConnection) {
        this.wsConnection.addEventListener("open", () => {
          this.wsOpenHandler();
        });
        this.wsConnection.addEventListener("close", event => {
          this.wsCloseHandler(event);
        });
      }
    } catch (e) {
      console.log(`wsConnect: `, e);
      this.wsReconnect();
    }
  }

  wsReconnect() {
    if (this.wsRetries < this.maxWsRetires) {
      this.wsRetries = this.wsRetries + 1;
      const _timeout = this.wsRetries * 1000;
      // clear event listener
      if (this.wsConnection != null) {
        this.wsConnection.onopen = null;
        this.wsConnection.onclose = null;

        this.wsConnection = null;
      }
      setTimeout(() => {
        this.wsConnect();
      }, _timeout);
    } else {
      if (this.onError != null) {
        this.onError({
          code: 502,
          error: new Error("Webscoket connection failed"),
        });
      }
    }
  }

  wsOpenHandler() {
    this.wsConnection.addEventListener("error", error => {
      this.wsErrorHandler(error);
    });

    this.wsConnection.addEventListener("message", event => {
      this.wsMessageHandler(event);
    });

    // this.peerConnection?.addEventListener("icecandidate", (event) => { this.pcIceCandidateHandler(event); } );
    this.peerConnection?.addEventListener("track", event => {
      this.pcTrackHandler(event);
    });
    this.wsSendPlayGetOffer();
    this.wsRetries = 0;
  }

  wsErrorHandler(error) {
    if (this.onError != null) {
      this.onError({ code: 502, error: error });
    }
  }

  wsCloseHandler(event) {
    if (event.wasClean) {
      console.log(
        `WebSocket connection closed cleanly, code=${event.code}, reason=${event.reason}`
      );
    } else {
      console.error("WebSocket connection died");
      this.wsReconnect();
    }
  }

  wsMessageHandler(event) {
    const msgJSON = JSON.parse(event.data);
    const msgStatus = Number(msgJSON["status"]);
    const msgCommand = msgJSON["command"];

    if (msgStatus == 514 || msgStatus == 504) {
      // repeater stream not ready
      this.repeaterRetryCount++;
      if (this.repeaterRetryCount < 10) {
        setTimeout(this.wsSendPlayGetOffer, 500);
      } else {
        this.stop();
      }
    } else if (msgStatus != 200) {
      this.stop();
      return; // Stop script execution
    } else {
      const streamInfoResponse = msgJSON["streamInfo"];
      if (streamInfoResponse !== undefined) {
        this.streamInfo.sessionId = streamInfoResponse.sessionId;
      }

      const sdpData = msgJSON["sdp"];
      if (sdpData != null) {
        this.pcSDPexchange(sdpData);
      }

      const iceCandidates = msgJSON["iceCandidates"];
      if (iceCandidates != null) {
        this.pcAddIceCandidate(iceCandidates);
      }
    }

    if ("sendResponse".localeCompare(msgCommand) == 0) {
      if (this.wsConnection != null) {
        this.wsConnection.close();
      }
      this.wsConnection = null;
    }
  }

  wsSendPlayGetOffer = () => {
    const _msg = {
      direction: "play",
      command: "getOffer",
      streamInfo: this.streamInfo,
      userData: this.userData,
    };
    this.wsSendMessage(_msg);
  };

  wsSendMessage(msg) {
    if (!this.wsConnection) {
      return;
    }
    try {
      this.wsConnection.send(JSON.stringify(msg));
    } catch (e) {
      if (this.onError != null) {
        this.onError({ code: 502, error: e });
      }
    }
  }

  reconnect() {
    if (this.retryInterval) {
      clearInterval(this.retryInterval);
    }

    // Check if maximum retries reached
    if (this.iceRetryCount >= this.maxIceRetries) {
      clearInterval(this.retryInterval); // Stop retrying
      return;
    }

    this.retryInterval = setInterval(() => {
      if (!this.wsConnection) {
        this.wsReconnect();
      } else {
        this.wsSendPlayGetOffer(); // Retry the play offer
      }
      /*
            // Increment retry count and attempt to re-establish the ICE connection
            this.wsConnection.addEventListener('open', () => {
                // Increment retry count and attempt to re-establish the ICE connection only after WebSocket is open
                console.log('WebSocket is open, retrying ICE...');
                this.iceRetryCount ++;
                this.wsSendPlayGetOffer();  // Retry the play offer
            });
            */
    }, 5000);
  }

  pcIceCandidateHandler(event) {
    if (event.candidate && this.wsConnection) {
      this.wsSendMessage({
        direction: "play",
        command: "sendIceCandidate",
        streamInfo: this.streamInfo,
        iceCandidate: event.candidate,
      });
    }
  }

  pcIceConnectionStateChangeHandler() {
    if (
      this.peerConnection?.iceConnectionState === "failed" ||
      this.peerConnection?.iceConnectionState === "disconnected"
    ) {
      this.reconnect();
    } else if (
      this.peerConnection?.iceConnectionState === "connected" ||
      this.peerConnection?.iceConnectionState === "completed"
    ) {
      if (this.retryInterval) {
        clearInterval(this.retryInterval); // Clear the retry interval if connected
      }
      this.iceRetryCount = 0; // Reset retry count if connected
    }
  }

  pcSDPexchange(sdp) {
    sdp.sdp = this.mungeSDP(sdp.sdp);
    // Enhance here if Safari is a published stream.
    if (this.peerConnection == null) {
      return;
    }
    this.peerConnection
      .setRemoteDescription(new RTCSessionDescription(sdp))
      .then(() => {
        if (this.peerConnection == null) {
          return;
        }
        this.peerConnection.createAnswer().then(description => {
          this.peerConnection
            ?.setLocalDescription(description)
            .then(() => {
              this.wsSendMessage({
                direction: "play",
                command: "sendResponse",
                streamInfo: this.streamInfo,
                sdp: description,
                userData: this.userData,
              });
            })
            .catch(err => {
              if (this.onError != null) {
                this.onError({ code: 504, error: err });
              }
            });
        });
      })
      .catch(err => {
        if (this.onError != null) {
          this.onError({ code: 503, error: err });
        }
      });
  }

  pcAddIceCandidate(iceCandidates) {
    for (const index in iceCandidates) {
      this.peerConnection?.addIceCandidate(
        new RTCIceCandidate(iceCandidates[index])
      );
    }
  }

  pcTrackHandler(event) {
    if (!this.videoEl) {
      return;
    }

    try {
      this.videoEl.srcObject = event.streams[0];
    } catch (error) {
      this.videoEl.src = window.URL.createObjectURL(event.streams[0]);
    }
    if (event.track.kind == "video") {
      this.videoEl
        .play()
        .then(() => {
          if (this.onPlay) {
            this.onPlay();
          }
          setInterval(this.tracePcState, 1000);
        })
        .catch(error => {
          if (this.onError != null) {
            if (error.name == "NotAllowedError") {
              this.onError({ code: 501, error: error });
            }
          }
        });
    }
  }

  mungeSDP(sdpStr) {
    // For greatest playback compatibility,
    // force H.264 playback to baseline (42e01f).
    const sdpLines = sdpStr.split(/\r\n/);
    let sdpStrRet = "";

    for (const sdpIndex in sdpLines) {
      var sdpLine = sdpLines[sdpIndex];

      if (sdpLine.length == 0) continue;

      if (sdpLine.includes("profile-level-id")) {
        // The profile-level-id string has three parts: XXYYZZ, where
        //   XX: 42 baseline, 4D main, 64 high
        //   YY: constraint
        //   ZZ: level ID
        // Look for codecs higher than baseline and force downward.
        const profileLevelId = sdpLine.substr(
          sdpLine.indexOf("profile-level-id") + 17,
          6
        );
        let profile = Number("0x" + profileLevelId.substr(0, 2));
        let constraint = Number("0x" + profileLevelId.substr(2, 2));
        let level = Number("0x" + profileLevelId.substr(4, 2));
        if (profile > 0x42) {
          profile = 0x42;
          constraint = 0xe0;
          level = 0x1f;
        }
        const newProfileLevelId =
          ("00" + profile.toString(16)).slice(-2).toLowerCase() +
          ("00" + constraint.toString(16)).slice(-2).toLowerCase() +
          ("00" + level.toString(16)).slice(-2).toLowerCase();

        sdpLine = sdpLine.replace(profileLevelId, newProfileLevelId);
      }

      sdpStrRet += sdpLine;
      sdpStrRet += "\r\n";
    }

    return sdpStrRet;
  }

  stop() {
    if (this.peerConnection != null) {
      this.peerConnection.close();
    }
    if (this.wsConnection != null) {
      this.wsConnection.close();
    }
    this.peerConnection = null;
    this.wsConnection = null;
    if (this.videoEl != null) {
      this.videoEl.src = "";
    }
    if (this.onClose) {
      this.onClose();
    }
    if (this.retryInterval) {
      clearInterval(this.retryInterval);
    }
    if (this.statsInterval) {
      clearInterval(this.statsInterval);
    }
  }

  tracePcState() {
    if (!this.peerConnection) {
      return;
    }

    this.peerConnection.getStats(null).then(stats => {
      let foundVideoReport = false;

      stats.forEach(report => {
        if (report.type === "inbound-rtp" && report.kind === "video") {
          foundVideoReport = true;
        } else if (report.type === "candidate-pair") {
          var id = report.id;
          var bytesReceived = report.bytesReceived;
          if (!this.trackStats[id]) {
            this.trackStats[id] = {
              previousBytesReceived: bytesReceived,
            };
          }
          var bytesReceivedPerSecond =
            (bytesReceived - this.trackStats[id].previousBytesReceived) / 1000;
          if (bytesReceivedPerSecond < 0) {
            this.trackStats[id].previousBytesReceived = bytesReceived;
          }
          var videoBitsReceivedPerSecond =
            bytesReceivedPerSecond * 8 * (this.videoBitrate / 1000);
          if (videoBitsReceivedPerSecond > this.highestBitrate) {
            this.highestBitrate = videoBitsReceivedPerSecond;
            this.highestBitrateTrackId = id;
          }
          this.trackStats[id].previousBytesReceived = bytesReceived;
        }
      });
      console.log(
        "Kbps of track " + this.highestBitrateTrackId + ":",
        this.highestBitrate
      );

      if (foundVideoReport) {
        this.consecutiveMissedVideoReports = 0;
      } else {
        this.consecutiveMissedVideoReports++;
      }

      if (
        this.consecutiveMissedVideoReports >=
          WEBRTC_MAX_CONSECUTIVE_MISSED_VIDEO_REPORTS_BEFORE_ERROR &&
        this.onVideoError
      ) {
        // throw video error when we find 10 consecutive missing video reports
        this.onVideoError();
      }
    });
  }
}
