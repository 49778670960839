import React, { useMemo } from "react";
import ChatItemUsernameTooltip from "common/broadcast/broadcast-chat/chat-item-username-tooltip";
import { IChatMessage } from "common/broadcast/_stores/chat/interfaces";
import ChatStore from "common/broadcast/_stores/chat/ChatStore";
import { inject, observer } from "mobx-react";
import { useIntl } from "react-intl";
import TailwindBox from "library/components/_tailwind/box";
type Props = {
  message: IChatMessage;
  chatStore?: ChatStore;
};

const ChatMessageText: React.ComponentType<Props> = ({
  message,
  chatStore,
}) => {
  const { chatFontSize: fontSize } = chatStore!;
  const intl = useIntl();

  const cleanUsername = useMemo(() => {
    if(message.from) {
      return message.from.split(/\+|@/)[0]
    }
  }, [message?.from])
  
  const cleanTranslation = useMemo(() => {
    if(message?.text?.includes("(auto-translated)")) {
      return message.text.replace("(auto-translated)", "")
    }
    return message.text
  }, [message?.text])

  return (
    <div
      className={`ChatItem ${message.isAdmin ? "ChatItem--admin" : ""}`}
      style={{ fontSize }}>
      <div style={{ position: "relative", display: "inline-block" }}>
        <ChatItemUsernameTooltip userId={message.userId} chatTooltip={true}>
          <span
            data-testid={"chat-message-text"}
            className={`mr-2 ChatItem__from ${
              message.isModel ? "ChatItem__from--model" : ""
            }`}>
            {message.isAdmin && `[Admin]`}
            {message.isModel
              ? intl.formatMessage({
                  id: "broadcast.chatItem.me",
                  defaultMessage: "Me",
                })
              : cleanUsername ? cleanUsername : message.from}:
          </span>
        </ChatItemUsernameTooltip>
      </div>
      {/* Do not refactor to TailwindText as we want the parent's font-size to take effect */}
      <TailwindBox
        as={"span"}
        wordBreak={"break-all"}
        style={{
          color: message.isSentSuccessfully
            ? "var(--text-main-color)"
            : "#6b7280",
        }}>
        {cleanTranslation}
      </TailwindBox>
    </div>
  );
};

export default inject("chatStore")(observer(ChatMessageText));
