import React, { useMemo } from "react";
import { CustomIconName } from "./enums";
import AddFriendIcon from "./add-friend-icon";
import AlcoholGlassIcon from "./alcohol-glass-icon";
import AnatomyIcon from "./anatomy-icon";
import CommentsIcon from "./comments-icon";
import ChatIcon from "./chat";
import DashboardIcon from "./dashboard-icon";
import EmojiIcon from "./emoji";
import EyeIcon from "./eye-icon";
import FlagIcon from "./flag-icon";
import FlirtIcon from "./flirt-icon";
import GiftIcon from "./gift-icon";
import FriendsIcon from "./friends-icon";
import FireIcon from "./fire-icon";
import MedalIcon from "./medal-icon";
import PinIcon from "./pin-icon";
import PointsIcon from "./points-icon";
import SearchIcon from "./search-icon";
import StarIcon from "./star-icon";
import TokenIcon from "./token-icon";
import VerifiedIcon from "./verified-icon";
import VideoCameraIcon from "./video-camera-icon";
import ArrowsPointingCenterIcon from "./arrows-pointing-center-icon";
import ChatBubbleIcon from "./chat-bubble-icon";
import DoubleHeartsIcon from "./double-hearts-icon";
import FilterNarrowDownIcon from "./filter-narrow-down-icon";
import FireAndPepperIcon from "./fire-and-pepper-icon";
import GoldCrownIcon from "./gold-crown-icon";
import HorizontalDotsIcon from "./horizontal-dots-icon";
import ProfileSilhouetteIcon from "./profile-silhouette";
import ScrollTopIcon from "./scroll-top-icon";
import VerticalDotsIcon from "./vertical-dots-icon";
import ClockIcon from "./clock-icon";
import TipsIcon from "./tips-icon";
import classnames from "classnames";
import WinkingEmojiIcon from "./winking-emoji-icon";
import PersonHoldingHandsUpIcon from "./person-holding-hands-up";
import ThreePeopleIcon from "./three-people-icon";
import ShowTypeIcon from "./showtype-icon";
import DiceIcon from "./dice-icon";
import InfoCircleIcon from "./info-circle-icon";
import QuestionMarkIcon from "./question-mark-icon";
import CancelCircleIcon from "./cancel-circle-icon";
import BuzzmodeIcon from "./buzzmode-icon";
import OptimizationIcon from "./optimization-icon";
import PersonIcon from "./person-icon";
import PrivateMessageIcon from "./private-message-icon";
import InviteToPrivateIcon from "./invite-to-private-icon";
import BanIcon from "./ban-icon";
import NotesIcon from "./notes-icon";
import KickIcon from "./kick-icon";
import Icon from "@material-ui/core/Icon";
import MasonryLayoutIcon from "./masonry-layout-icon";
import ColumnLayoutIcon from "./column-layout";
import LanguageIcon from "./language-icon";
import TextSizeIcon from "./text-size-icon";
import CrownIcon from "./crown-icon";
import HeartOutlineIcon from "./heart-outline-icon";
import WheelOfFunActive from "./wheel-of-fun-active";
import WheelOfFunInactive from "./wheel-of-fun-inactive";
import TailwindSpinner from "../../spinner";
import CloseThickerIcon from "./close-thicker";
import ArrowLeft from "./arrowLeft";
import ContestsIcon from "./contests-icon";
import MuteIcon from "./mute-icon";
import NudeListingIcon from "./nude-listing-icon";
import UnmuteIcon from "./unmute-icon";
import SettingsIcon from "./settings-icon";
import GalleryIcon from "./gallery_icon";
import ProfileIcon from "./profile-icon";
import EarningsIcon from "./earnings-icon";
import FansIcon from "./fans-icon";
import PhotosIcon from "./photos-icon";
import MessagesIcon from "./messages-icon";
import NewsPaperIcon from "./newspaper-icon";
import ReferalsIcon from "./referals-icon";
import GiftCardIcon from "./giftcard-icon";
import TopsModelsIcon from "./top-models-icon";
import SettingsSideIcon from "./settings-side-icon";
import LogoutIcon from "./logout-icon";
import SupportIcon from "./live-support-icon";
import NotesPencilIcon from "./notes-pencile-icon";
import PineappleIcon from "./pineapple-icon";
import CheckMarkIcon from "./checkmark-icon";
import SearchIconAlt from "./search-icon-alt";
import UploadIcon from "./upload-icon";
import RemoveIcon from "./remove-icon";
import PeopleIcon from "./people-icon";
import KebabIcon from "./kebab-icon";
import FilterIcon from "./filter-icon";
import WhisperIcon from "./whisper-icon";

export type CustomIconProps = Object;

type Props = {
  name: CustomIconName | string | React.ReactNode;
  className?: string;
  props?: CustomIconProps;
};

const CustomIcon: React.ComponentType<Props> = ({ name, props, className }) => {
  const icon = useMemo(() => {
    let _icon: any = undefined;

    if (typeof name !== "string") {
      _icon = name;
    } else {
      switch (name) {
        case CustomIconName["add-friend"]:
          _icon = <AddFriendIcon />;
          break;
        case CustomIconName["alcohol-glass"]:
          _icon = <AlcoholGlassIcon />;
          break;
        case CustomIconName.support:
          _icon = <SupportIcon />;
          break;
        case CustomIconName.anatomy:
          _icon = <AnatomyIcon />;
          break;
        case CustomIconName.chat:
          _icon = <ChatIcon />;
          break;
        case CustomIconName.gallery:
          _icon = <GalleryIcon />;
          break;
        case CustomIconName.profile:
          _icon = <ProfileIcon />;
          break;
        case CustomIconName.earnings:
          _icon = <EarningsIcon />;
          break;
        case CustomIconName.fans:
          _icon = <FansIcon />;
          break;
        case CustomIconName.photos:
          _icon = <PhotosIcon />;
          break;
        case CustomIconName.messages:
          _icon = <MessagesIcon />;
          break;
        case CustomIconName.newspaper:
          _icon = <NewsPaperIcon />;
          break;
        case CustomIconName.referals:
          _icon = <ReferalsIcon />;
          break;
        case CustomIconName.giftcards:
          _icon = <GiftCardIcon />;
          break;
        case CustomIconName.topmodels:
          _icon = <TopsModelsIcon />;
          break;
        case CustomIconName.comments:
          _icon = <CommentsIcon />;
          break;
        case CustomIconName.contests:
          _icon = <ContestsIcon />;
          break;
        case CustomIconName.dashboard:
          _icon = <DashboardIcon />;
          break;
        case CustomIconName.emoji:
          _icon = <EmojiIcon />;
          break;
        case CustomIconName.eye:
          _icon = <EyeIcon />;
          break;
        case CustomIconName["upload-icon"]:
          _icon = <UploadIcon />;
          break;
        case CustomIconName["remove-icon"]:
          _icon = <RemoveIcon />;
          break;
        case CustomIconName.flag:
          _icon = <FlagIcon />;
          break;
        case CustomIconName.settings_extra:
          _icon = <SettingsIcon />;
          break;
        case CustomIconName.setting_side:
          _icon = <SettingsSideIcon />;
          break;
        case CustomIconName.logout:
          _icon = <LogoutIcon />;
          break;
        case CustomIconName.flirt:
          _icon = <FlirtIcon />;
          break;
        case CustomIconName.gift:
          _icon = <GiftIcon />;
          break;
        case CustomIconName.friends:
          _icon = <FriendsIcon />;
          break;
        case CustomIconName.fire:
          _icon = <FireIcon />;
          break;
        case CustomIconName.medal:
          _icon = <MedalIcon />;
          break;
        case CustomIconName.pin:
          _icon = <PinIcon />;
          break;
        case CustomIconName.points:
          _icon = <PointsIcon />;
          break;
        case CustomIconName.search:
          _icon = <SearchIcon />;
          break;
        case CustomIconName["search-alt"]:
          _icon = <SearchIconAlt />;
          break;
        case CustomIconName.star:
          _icon = <StarIcon />;
          break;
        case CustomIconName.token:
          _icon = <TokenIcon />;
          break;
        case CustomIconName.verified:
          _icon = <VerifiedIcon />;
          break;
        case CustomIconName["video-camera"]:
          _icon = <VideoCameraIcon />;
          break;
        case CustomIconName["arrows-pointing-center"]:
          _icon = <ArrowsPointingCenterIcon />;
          break;
        case CustomIconName["chat-bubble"]:
          _icon = <ChatBubbleIcon />;
          break;
        case CustomIconName["double-hearts"]:
          _icon = <DoubleHeartsIcon />;
          break;
        case CustomIconName["filter-narrow-down"]:
          _icon = <FilterNarrowDownIcon />;
          break;
        case CustomIconName["fire-and-pepper"]:
          _icon = <FireAndPepperIcon />;
          break;
        case CustomIconName["crown"]:
          _icon = <CrownIcon />;
          break;
        case CustomIconName["heart-outline"]:
          _icon = <HeartOutlineIcon />;
          break;
        case CustomIconName["notes-pencil"]:
          _icon = <NotesPencilIcon />;
          break;
        case CustomIconName["gold-crown"]:
          _icon = <GoldCrownIcon />;
          break;
        case CustomIconName["horizontal-dots"]:
          _icon = <HorizontalDotsIcon />;
          break;
        case CustomIconName["profile-silhouette"]:
          _icon = <ProfileSilhouetteIcon />;
          break;
        case CustomIconName["scroll-top"]:
          _icon = <ScrollTopIcon />;
          break;
        case CustomIconName["vertical-dots"]:
          _icon = <VerticalDotsIcon />;
          break;
        case CustomIconName.clock:
          _icon = <ClockIcon />;
          break;
        case CustomIconName.tips:
          _icon = <TipsIcon />;
          break;
        case CustomIconName["winking-emoji"]:
          _icon = <WinkingEmojiIcon />;
          break;
        case CustomIconName["person-holding-hands-up"]:
          _icon = <PersonHoldingHandsUpIcon />;
          break;
        case CustomIconName["show-type"]:
          _icon = <ShowTypeIcon />;
          break;
        case CustomIconName["dice"]:
          _icon = <DiceIcon />;
          break;
        case CustomIconName["info-circle"]:
          _icon = <InfoCircleIcon />;
          break;
        case CustomIconName["question-mark"]:
          _icon = <QuestionMarkIcon />;
          break;
        case CustomIconName["cancel-circle"]:
          _icon = <CancelCircleIcon />;
          break;
        case CustomIconName.buzzmode:
          _icon = <BuzzmodeIcon />;
          break;
        case CustomIconName.optimization:
          _icon = <OptimizationIcon />;
          break;
        case CustomIconName.person:
          _icon = <PersonIcon />;
          break;
        case CustomIconName["people-icon"]:
          _icon = <PeopleIcon />;
          break;
        case CustomIconName["private-message"]:
          _icon = <PrivateMessageIcon />;
          break;
        case CustomIconName["invite-to-private"]:
          _icon = <InviteToPrivateIcon />;
          break;
        case CustomIconName.ban:
          _icon = <BanIcon />;
          break;
        case CustomIconName.kick:
          _icon = <KickIcon />;
          break;
        case CustomIconName.notes:
          _icon = <NotesIcon />;
          break;
        case CustomIconName["three-people-icon"]:
          _icon = <ThreePeopleIcon />;
          break;
        case CustomIconName.spinner:
          _icon = <TailwindSpinner {...(props as any)} />;
          break;
        case CustomIconName["masonry-layout"]:
          _icon = <MasonryLayoutIcon />;
          break;
        case CustomIconName["column-layout"]:
          _icon = <ColumnLayoutIcon />;
          break;
        case CustomIconName["language-icon"]:
          _icon = <LanguageIcon />;
          break;
        case CustomIconName["text-size-icon"]:
          _icon = <TextSizeIcon />;
          break;
        case CustomIconName["wheel-of-fun-active"]:
          _icon = <WheelOfFunActive />;
          break;
        case CustomIconName["wheel-of-fun-inactive"]:
          _icon = <WheelOfFunInactive />;
          break;
        case CustomIconName["close-thicker"]:
          _icon = <CloseThickerIcon />;
          break;
        case CustomIconName.arrowLeft:
          _icon = <ArrowLeft />;
          break;
        case CustomIconName.mute:
          _icon = <MuteIcon />;
          break;
        case CustomIconName.unmute:
          _icon = <UnmuteIcon />;
          break;
        case CustomIconName.nudeListing:
          _icon = <NudeListingIcon />;
          break;
        case CustomIconName["pineapple-icon"]:
          _icon = <PineappleIcon />;
          break;
        case CustomIconName["confirm-circle"]:
          _icon = <CheckMarkIcon />;
          break;
        case CustomIconName["kebab-icon"]:
          _icon = <KebabIcon />;
          break;
        case CustomIconName.filterIcon:
          _icon = <FilterIcon />;
          break;
        case CustomIconName.whisper:
          _icon = <WhisperIcon />;
          break;
        default:
          _icon = (
            <Icon
              style={{
                display: "inline-flex",
                fontSize: "inherit",
                lineHeight: "inherit",
                height: "inherit",
                width: "inherit",
                overflow: "inherit",
              }}>
              {name}
            </Icon>
          );
          break;
      }
    }

    return _icon;
  }, [name, props]);

  return React.cloneElement(icon, {
    className: classnames(
      "CustomIcon",
      className && className,
      typeof icon === "object" ? icon.props.className : ""
    ),
    ...props,
  });
};

export default CustomIcon;
