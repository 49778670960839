import { TailwindDropdownItem } from "library/components/_tailwind/dropdown";

export const PAYMENT_COUNTRY_DROPDOWN_ITEMS: TailwindDropdownItem[] = [
  {
    label: "United States",
    value: 0,
  },
  {
    label: "International",
    value: 1,
  },
];

export const ACCEPTED_FILE_TYPES_FOR_TAX_FORM = ["image/jpeg", "image/jpg", "image/png"];

export const ACCEPTED_IMAGES = ["image/jpeg", "image/jpg", "image/png"];
