import { inject, observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CHAT_MEMBER_COLOR_MAPPING } from "../_stores/chat/consts";
import TailwindIcon from "library/components/_tailwind/icon";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import { ClickAwayListener, Popper } from "@material-ui/core";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import MedalIcon from "library/components/_tailwind/icon/icons/medal-icon";
import NodeChatStore from "../_stores/nodechat/NodeChatStore";

interface Props {
  nodeChatStore?: NodeChatStore;
  broadcastStore?: BroadcastStore;
  layoutStore?: LayoutStore;
  userId?: string;
  chatTooltip?: boolean;
  onUserTierColor?: (color: string) => void;
}

const ChatItemUsernameTooltip: React.FunctionComponent<Props> = ({
  children,
  userId,
  nodeChatStore,
  broadcastStore,
  layoutStore,
  chatTooltip = true,
  onUserTierColor,
}) => {
  if (!userId) {
    return null;
  }
  const [nameColor, setNameColor] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);
  const [popperWidth, setPopperWidth] = useState(260);
  const { isPrivateConsentOpen } = broadcastStore!;
  const { screenSize } = layoutStore!;

  const memberName = nodeChatStore?.allMemberHash?.[userId]?.username;

  const spendingTier = nodeChatStore?.allMemberHash?.[userId]?.sort;

  const spendingTierText = useMemo(() => {
    if (spendingTier) {
      if (spendingTier >= 0.8) {
        return "Spent 1000+ tokens in the past two weeks";
      } else if (spendingTier >= 0.6) {
        return "Spent 250+ tokens in the past two weeks";
      } else if (spendingTier >= 0.4) {
        return "Spent 50+ tokens in the past two weeks";
      }
    }
  }, [spendingTier]);
  const isFanClub = !!nodeChatStore?.allMemberHash?.[userId]?.isFanClub;
  const isPremiere = !!nodeChatStore?.allMemberHash?.[userId]?.isPremiere;
  const hasToken = !!nodeChatStore?.allMemberHash?.[userId]?.hasTokens;
  const isBounty = !!nodeChatStore?.allMemberHash?.[userId]?.isBounty;

  const memberRank = nodeChatStore?.allMemberHash[userId]?.topAdmirer || 0;

  const isTopAdmirer = memberRank >= 1 && memberRank <= 5;
  const showTooltip = useMemo(() => {
    return !!nodeChatStore?.allMemberHash[userId];
  }, [userId, nodeChatStore?.allMemberHash]);

  useEffect(() => {
    if (isTopAdmirer) {
      setNameColor(CHAT_MEMBER_COLOR_MAPPING.TOP_ADMIRER);
    } else if (isBounty) {
      setNameColor(CHAT_MEMBER_COLOR_MAPPING.BOUNTY_COLOR_DEFAULT);
    } else if (isPremiere) {
      setNameColor(CHAT_MEMBER_COLOR_MAPPING.PREM_COLOR_DEFAULT);
    } else if (isFanClub) {
      setNameColor(CHAT_MEMBER_COLOR_MAPPING.FAN_COLOR_DEFAULT);
    } else if (spendingTier && spendingTier >= 0.8) {
      setNameColor(CHAT_MEMBER_COLOR_MAPPING.SPENT_TIER_THREE);
    } else if (spendingTier && spendingTier >= 0.6) {
      setNameColor(CHAT_MEMBER_COLOR_MAPPING.SPENT_TIER_TWO);
    } else if (spendingTier && spendingTier >= 0.4) {
      setNameColor(CHAT_MEMBER_COLOR_MAPPING.SPENT_TIER_ONE);
    } else if (hasToken) {
      setNameColor(CHAT_MEMBER_COLOR_MAPPING.PAID_COLOR_DEFAULT);
    } else {
      setNameColor(CHAT_MEMBER_COLOR_MAPPING.DEFAULT_COLOR_DEFAULT);
    }
  }, [isFanClub, isPremiere, hasToken, isBounty, spendingTier, isTopAdmirer]);

  useEffect(() => {
    if (nameColor) {
      onUserTierColor?.(nameColor);
    }
  }, [nameColor]);

  const hideTooltip = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const renderAdmirerBadge = (badgeLevel: number = 1) => {
    return <MedalIcon iconrange={badgeLevel as any} />;
  };

  useEffect(() => {
    if (isPrivateConsentOpen) {
      setAnchorEl(null);
    }
  }, [isPrivateConsentOpen]);

  useEffect(() => {
    setAnchorEl(null);
  }, [screenSize]);
  return showTooltip ? (
    <>
      <span
        onClick={event => {
          let target;

          if (chatTooltip) {
            target = event.target;
          } else {
            target = event.currentTarget.closest(
              ".BroadcastViewers__member-item"
            );
            setPopperWidth(target.clientWidth - 30);
          }

          setAnchorEl(target as any);
        }}
        className='ChatItem__UsernameLink'
        style={{ color: nameColor }}>
        {isTopAdmirer && memberRank ? renderAdmirerBadge(memberRank) : null}
        {children}
      </span>

      <Popper
        style={
          chatTooltip
            ? { marginBottom: 10, maxWidth: 260 }
            : { width: popperWidth, marginLeft: 15, marginTop: -25 }
        }
        placement={chatTooltip ? "top" : "bottom-start"}
        className='ChatItem__UsernameWrapper'
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={hideTooltip}>
          <div
            onClick={e => {
              e.stopPropagation();
            }}
            className={`ChatItem__UsernameTooltip ${
              chatTooltip ? "" : "ChatItem__MemberListTooltip"
            }`}>
            <div
              className='ChatItem__UsernameTooltipHeader'
              style={{ color: nameColor }}>
              {memberName}
            </div>

            <div
              onClick={hideTooltip}
              className='ChatItem__UsernameTooltipClose'>
              <TailwindIcon name={"close"} />
            </div>
            <div className='ChatItem__UsernameTooltipBody'>
              {chatTooltip && isTopAdmirer && (
                <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--TopAdmirer'>
                  Top Admirer #{memberRank}
                </div>
              )}

              {chatTooltip && isFanClub && (
                <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--FanClub'>
                  Fan Club
                </div>
              )}

              {chatTooltip && isPremiere && (
                <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--Premiere'>
                  Premiere
                </div>
              )}
              {chatTooltip && hasToken && (
                <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--HasToken'>
                  Has tokens
                </div>
              )}
              {chatTooltip && !hasToken && (
                <div className='ChatItem__UsernameTooltipBadge'>No tokens</div>
              )}
              {chatTooltip && isBounty && (
                <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--Bounty'>
                  Bounty
                </div>
              )}

              {!chatTooltip && (isTopAdmirer || isBounty || isFanClub) && (
                <div className='ChatItem__UsernameTooltipSection'>
                  {isTopAdmirer && (
                    <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--TopAdmirer'>
                      Top Admirer #{memberRank}
                    </div>
                  )}
                  {isBounty && (
                    <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--Bounty'>
                      Bounty
                    </div>
                  )}
                  {isFanClub && (
                    <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--FanClub'>
                      Fan Club
                    </div>
                  )}
                </div>
              )}

              {!chatTooltip && (hasToken || spendingTier) && (
                <div className='ChatItem__UsernameTooltipSection'>
                  {spendingTier && spendingTier > 0 && spendingTierText ? (
                    <div
                      className={`ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--SpendingTier ChatItem__UsernameTooltipBadge--SpendingTier-${spendingTier}`}>
                      {`${spendingTierText}`}
                    </div>
                  ) : (
                    hasToken && (
                      <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--HasToken'>
                        Has tokens
                      </div>
                    )
                  )}
                </div>
              )}

              {!chatTooltip &&
                !isTopAdmirer &&
                !isBounty &&
                !isFanClub &&
                !hasToken &&
                !spendingTier && (
                  <div className='ChatItem__UsernameTooltipSection'>
                    Check Back Later
                  </div>
                )}
            </div>

            {chatTooltip &&
              (spendingTier && spendingTier > 0 ? (
                <div
                  className={`ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--SpendingTier ChatItem__UsernameTooltipBadge--SpendingTier-${spendingTier}`}>
                  {`${spendingTierText}`}
                </div>
              ) : null)}
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  ) : (
    <span className='ChatItem__UsernameLink' style={{ color: nameColor }}>
      {children}
    </span>
  );
};

export default inject(
  "nodeChatStore",
  "broadcastStore",
  "layoutStore"
)(observer(ChatItemUsernameTooltip));
