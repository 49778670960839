import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import { MessageCenterConversationDetails } from "./stores/messages/types";

import TailwindFlex from "library/components/_tailwind/flex";
import MessageCenterInput from "./messages-reader-textarea";
import BroadcastChatFontSizePopover from "../broadcast/broadcast-chat/chat-font-size-popover";
import TailwindButton from "library/components/_tailwind/button";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import MessageStore from "./stores/messages/MessageStore";
import { TFontSize } from "library/styles/tailwind-classnames";
import TailwindBox from "library/components/_tailwind/box";
import useOutsideClick from "library/core/utility/hooks/useOutsideClick";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { ACCEPTED_IMAGES } from "common/account-settings/upload-tax-form-modal/const";
import FileUploadMessages from "library/components/file-upload-messages";
import TailwindIcon from "library/components/_tailwind/icon";
import ImgContainer from "./message-image-container";
import SnackbarStore from "library/core/stores/snackbar/SnackbarStore";
import { ISnackbarProps } from "library/core/stores/snackbar/interfaces";
import { SnackbarVariants } from "library/core/stores/snackbar/enums";
import { Icon } from "@material-ui/core";
import { MSG_CENTER_MAX_IMAGE_SIZE } from "./stores/messages/consts";
import TailwindText from "library/components/_tailwind/text";
import LayoutStore from "library/core/stores/layout/LayoutStore";

type Props = {
  messageStore?: MessageStore;
  layoutStore?: LayoutStore;
  setMessage: (message: string) => void;
  message: string;
  sendMessage: (noticeBlock?: string) => void;
  conversation: MessageCenterConversationDetails | null;
  hasActiveConversation: boolean;
  snackbarStore?: SnackbarStore;
};

export type imageType = {
  key: string;
  view_url: string;
};

const IMAGETYPE = ["image/jpeg", "image/jpg", "image/png"];

const MessageCenterReaderFooter: React.ComponentType<
  Props & WrappedComponentProps
> = ({
  messageStore,
  // layoutStore,
  snackbarStore,
  message,
  setMessage,
  sendMessage,
  hasActiveConversation,
  intl,
}) => {
  const {
    messageFontSize,
    setMessageFontSize,
    uploadImages,
    setAttachImagesToBeSent,
    attachedImagesToBeSent,
    activeConversationId,
    setimageKeysTobeSubmitted,
    canCurrentMemberReceiveMsg,
    canTempMemberReceiveMsg,
  } = messageStore!;
  // const { windowWidth } = layoutStore!;

  const messageInputRef = useRef<HTMLTextAreaElement>(null);

  const onSetMessageFontSize = (font: number | TFontSize) => {
    setMessageFontSize(font as TFontSize);
  };

  const [showEmojiMenu, setShowEmojiMenu] = useState(false);
  const emojiBoxRef = useRef<HTMLDivElement>(null);

  const [images, setImages] = useState<imageType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [attachmentWidth, setAttachmentWidth] = useState<string>("0px");

  const { enqueueSnackbar } = snackbarStore!;

  useOutsideClick(emojiBoxRef, () => {
    setShowEmojiMenu(false);
  });

  const isSendButtonDisabled = useMemo(
    () => message.trim() === "" && attachedImagesToBeSent.length === 0,
    [message, attachedImagesToBeSent]
  );

  const toastErrorAttachmentMsg = useMemo(() => {
    return {
      message: {
        id: "upload.error",
        default: (
          <span className='upload_toast_errorMsg'>
            <strong>Upload Error</strong>
            <ul>
              <li>File formats allowed: jpeg, jpg, and png.</li>
              <li>Number of images allowed: 5 per message.</li>
              <li>File size allowed: 20MB maximum per image.</li>
            </ul>
          </span>
        ),
      },
      variant: SnackbarVariants.ERROR,
      closeButton: true,
      options: {
        duration: 8000,
        className: "customUploadError",
        icon: <Icon fontSize='large'>cancelrounded</Icon>,
      },
    };
  }, []);

  const canMembeReciveMsg = useMemo(() => {
    return (
      activeConversationId &&
      !canTempMemberReceiveMsg.includes(activeConversationId) &&
      canCurrentMemberReceiveMsg
    );
  }, [
    activeConversationId,
    canTempMemberReceiveMsg,
    canCurrentMemberReceiveMsg,
    message,
  ]);

  const onSelectEmoji = useCallback(
    event => {
      const emoji = event?.native;
      const _ref = messageInputRef as any;
      const inputEl: HTMLInputElement = _ref.current;
      const selectionStart = inputEl.selectionStart;
      let newMessage = message;
      if (selectionStart !== null) {
        const selectionEnd = inputEl.selectionEnd;
        const messageFirstPart = message.substr(0, selectionStart);

        if (selectionEnd !== null) {
          const messageSecondPart = message.substr(
            selectionStart,
            message.length
          );
          newMessage = messageFirstPart + emoji + messageSecondPart;
        } else {
          newMessage = message + emoji;
        }
      }
      inputEl.focus();
      setMessage(newMessage);
      setShowEmojiMenu(false);
    },
    [message, messageInputRef]
  );

  const isValidAttachment = (filesCount: number) => {
    if (filesCount > 5) {
      enqueueSnackbar(toastErrorAttachmentMsg as unknown as ISnackbarProps);
      return false;
    }
    return true;
  };
  const isValidImage = (notValidfileType: boolean) => {
    if (notValidfileType) {
      enqueueSnackbar(toastErrorAttachmentMsg as unknown as ISnackbarProps);
      return false;
    }
    return true;
  };

  const isValidSize = (fileSize: number) => {
    if (fileSize >= MSG_CENTER_MAX_IMAGE_SIZE) {
      enqueueSnackbar(toastErrorAttachmentMsg as unknown as ISnackbarProps);
      return false;
    }
    return true;
  };

  const msgUploadImages = useCallback(
    async (files: File[]) => {
      // it will return 0 if the type is not supported
      if (files.length === 0) {
        isValidImage(true);
        return;
      }
      const filesCount = attachedImagesToBeSent.length + files.length;
      if (!isValidAttachment(filesCount)) return;
      files.forEach(async file => {
        const fileSize = (file.size / 1024 ** 2).toFixed(
          2
        ) as unknown as number; //max 20MB
        if (
          !isValidSize(fileSize) ||
          !isValidImage(IMAGETYPE.indexOf(file.type) === -1)
        )
          return;
        setLoading(true);
        const { view_url, key }: imageType = await uploadImages(
          file,
          file.name,
          file.size
        );
        setImages(prev => [...prev, { view_url, key }]);
        setLoading(false);
      });
    },
    [attachedImagesToBeSent]
  );

  useEffect(() => {
    setImages([]);
  }, [activeConversationId]);

  useEffect(() => {
    const getImgSrc = images.map(img => img.view_url);
    const getImgKeys = images.map(img => img.key);
    setAttachImagesToBeSent(getImgSrc);
    setimageKeysTobeSubmitted(getImgKeys);
  }, [images]);

  const updateAttachmentWidth = () => {
    setAttachmentWidth(`${messageInputRef?.current?.clientWidth || 0}px`);
  };

  useEffect(() => {
    updateAttachmentWidth();
  }, [messageInputRef?.current, attachedImagesToBeSent]);

  useEffect(() => {
    window.addEventListener("resize", updateAttachmentWidth);
    return () => window.removeEventListener("resize", updateAttachmentWidth);
  });

  const removeImg = useCallback(
    key => {
      setImages(images.filter(img => img.key !== key));
    },
    [images]
  );

  const sendMsg = () => {
    if (!canMembeReciveMsg) return;
    sendMessage();
    setTimeout(() => {
      setImages([]);
    }, 0);
  };

  if (hasActiveConversation) {
    return (
      <TailwindFlex flexDirection={"flex-col"} padding={["py-2"]}>
        <TailwindFlex
          width='w-full'
          padding={
            attachedImagesToBeSent.length > 0 ? ["pb-0", "pt-2"] : ["pb-4"]
          }>
          {canMembeReciveMsg ? (
            <TailwindFlex
              backgroundColor='bg-primary-bg-color'
              borderRadius={"rounded-md"}
              flexDirection={"flex-col"}
              margin={["m-4"]}>
              <MessageCenterInput
                inputRef={messageInputRef}
                onChange={setMessage}
                value={message}
                placeholder={intl.formatMessage({
                  id: "message-center.type-your-message-here",
                  defaultMessage: "Type your message here",
                })}
                enterPressCallback={sendMsg}
                disabled={!hasActiveConversation || loading}
                loading={loading}
                boxProps={{
                  padding: ["p-0"],
                }}
                maxRows={attachedImagesToBeSent.length > 0 && !loading ? 2 : 3}
              />
              {attachedImagesToBeSent.length > 0 && !loading && (
                <TailwindFlex
                  className={["MessagesReader__attachments"]}
                  margin={["mb-2", "mx-4"]}>
                  <TailwindBox
                    style={{
                      width: attachmentWidth,
                      paddingTop: "4px",
                    }}
                    position={"absolute"}
                    whiteSpace='whitespace-nowrap'
                    display={"block"}
                    overflow={["overflow-x-auto", "overflow-y-hidden"]}>
                    {images?.map(img => (
                      <ImgContainer
                        removeImg={removeImg}
                        key={img.key}
                        imgSrc={img.view_url}
                        id={img.key}
                      />
                    ))}
                  </TailwindBox>
                </TailwindFlex>
              )}
            </TailwindFlex>
          ) : (
            <TailwindBox
              display='flex'
              borderStyle='border-solid'
              borderColor='border-gray-400'
              borderWidth={["border"]}
              borderRadius={["rounded-md"]}
              margin={["mx-5", "my-2"]}
              padding={["px-5", "py-3"]}
              width='w-full'>
              <TailwindBox display='flex' alignItems='items-baseline'>
                <TailwindIcon
                  name={"!"}
                  textColor='text-gray-400'
                  margin={["mr-3"]}
                  className={"exclamationMark-icon"}
                />
                <TailwindText
                  fontSize='text-lg'
                  fontStyle='italic'
                  textColor='text-gray-400'>
                  {
                    "To prevent spamming please wait 24 hours and try again or until the member has responded."
                  }
                </TailwindText>
              </TailwindBox>
            </TailwindBox>
          )}
        </TailwindFlex>
        {/* {attachedImagesToBeSent.length > 0 && !loading && (
          <TailwindFlex
            borderRadius={"rounded-b-md"}
            space='space-x-3'
            padding={["pb-2", "px-4"]}
            margin={["-mt-1"]}>
            <TailwindFlex
              width='w-full'
              backgroundColor='bg-white'
              borderRadius={"rounded-b-md"}
              padding={["pb-2"]}>
              {images?.map(img => (
                <ImgContainer
                  removeImg={removeImg}
                  key={img.key}
                  imgSrc={img.view_url}
                  id={img.key}
                />
              ))}
            </TailwindFlex>
          </TailwindFlex>
        )} */}
        <TailwindFlex
          padding={["pb-2", "px-4"]}
          space='space-x-3'
          alignItems={"items-end"}
          justifyContent={"justify-end"}>
          <TailwindFlex
            justifyContent={"justify-start"}
            alignItems={"items-start"}
            space='space-x-2'>
            <TailwindBox>
              <TailwindButton
                fullWidth={false}
                width={"w-10"}
                padding={["p-0"]}
                alignItems={"items-center"}
                justifyContent={"justify-center"}
                rounded={true}
                opacity={!canMembeReciveMsg ? "opacity-30" : "opacity-100"}
                cursor={
                  !canMembeReciveMsg ? "cursor-not-allowed" : "cursor-pointer"
                }
                rightIconProps={{
                  fontSize: "text-3xl",
                  name: CustomIconName.emoji,
                }}
                style={{
                  backgroundColor: "#84a3baa6",
                }}
                onClick={() =>
                  setShowEmojiMenu(!canMembeReciveMsg ? false : !showEmojiMenu)
                }
              />
              <TailwindBox
                ref={emojiBoxRef}
                inset={["bottom-20"]}
                position={"absolute"}
                zIndex={"z-100"}
                display={showEmojiMenu ? "block" : "hidden"}>
                <Picker
                  skinTonePosition={"search"}
                  data={data}
                  onEmojiSelect={onSelectEmoji}
                  icons={"solid"}
                  theme={"auto"}
                  noCountryFlags={true}
                />
              </TailwindBox>
            </TailwindBox>
            <BroadcastChatFontSizePopover
              fontSize={messageFontSize as TFontSize}
              onSetFont={onSetMessageFontSize}>
              <TailwindButton
                fullWidth={false}
                style={{
                  backgroundColor: "#84a3baa6",
                }}
                borderColor={"border-main-color"}
                rounded={true}
                padding={["p-0"]}
                width={"w-10"}
                alignItems={"items-center"}
                justifyContent={"justify-center"}
                rightIconProps={{
                  fontSize: "text-base",
                  name: CustomIconName["text-size-icon"],
                }}
              />
            </BroadcastChatFontSizePopover>

            <FileUploadMessages
              textColor={"text-white"}
              style={{
                backgroundColor: "#84a3baa6",
              }}
              borderColor={"border-white"}
              shape={"circle"}
              customIcon={<TailwindIcon name={CustomIconName["upload-icon"]} />}
              width={"w-10"}
              height={"h-10"}
              dropzoneProps={{
                accept: ACCEPTED_IMAGES,
                onDrop: msgUploadImages,
                maxFiles: 20,
              }}
              opacity={!canMembeReciveMsg ? "opacity-30" : "opacity-100"}
              acceptedText={"Photo Uploaded"}
              disable={!canMembeReciveMsg}
              cursor={
                !canMembeReciveMsg ? "cursor-not-allowed" : "cursor-pointer"
              }
            />
          </TailwindFlex>
          <TailwindBox
            opacity={
              loading || !canMembeReciveMsg || isSendButtonDisabled
                ? "opacity-70"
                : "opacity-100"
            }>
            <TailwindButton
              onClick={() => sendMsg()}
              fullWidth={false}
              backgroundColor={"bg-modal-button-color"}
              borderRadius={"rounded"}
              textColor={"text-white"}
              width={"w-auto"}
              alignItems={"items-center"}
              justifyContent={"justify-center"}
              padding={["px-6"]}
              disabled={loading || !canMembeReciveMsg || isSendButtonDisabled}
              settings={true}
              disabledProps={{
                backgroundColor: "bg-modal-button-color",
                textColor: "text-white",
              }}>
              {intl.formatMessage({
                id: "common.send",
                defaultMessage: "Send",
              })}
            </TailwindButton>
          </TailwindBox>
        </TailwindFlex>
      </TailwindFlex>
    );
  }
  return null;
};

export default injectIntl(
  inject(
    "messageStore",
    "snackbarStore",
    "layoutStore"
  )(observer(MessageCenterReaderFooter))
);
