import React, { useCallback, useMemo, useRef, useState } from "react";
import { dateProcess } from "library/core/utility";
import { MessageCenterMessageDetails } from "./stores/messages/types";
import Avatar from "library/components/avatar";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import classnames from "classnames";
import tw, { TFontSize } from "library/styles/tailwind-classnames";
import { inject, observer } from "mobx-react";
import MessageStore from "./stores/messages/MessageStore";
import { injectIntl, WrappedComponentProps } from "react-intl";
import MessageCenterMessageThumbnails from "./messages-reader-message-thumbnails";
import sanitizeHtml from "sanitize-html";
import { toArray } from "react-emoji-render";

type Props = {
  messageStore?: MessageStore;
  userName: string;
  message: MessageCenterMessageDetails;
  highlightedWord?: string;
};

const MessageCenterMessage: React.ComponentType<Props & WrappedComponentProps> =
  ({ userName, message, highlightedWord, messageStore, intl }) => {
    const attachmentsRef = useRef<HTMLDivElement>(null);
    const [maxWidth, setMaxWidth] = useState<number>(0);
    const { messageFontSize } = messageStore!;
    const { getHumanReadableDate } = dateProcess;
    const isSenderCurrentUser = useMemo(() => {
      return message?.sender?.username === userName;
    }, [userName, message]);

    const messageDate = useMemo(
      () =>
        message?.created_at ? getHumanReadableDate(message?.created_at) : "",
      [message]
    );

    const isDeniedMessage = useMemo(
      () => !isSenderCurrentUser && message?.status == "DENIED",
      [message, isSenderCurrentUser]
    );

    const hasImageAttachments = useMemo(
      () =>
        !isDeniedMessage &&
        message?.signed_urls &&
        message?.signed_urls?.length > 0,
      [message, isDeniedMessage]
    );

    const deniedMsg = useMemo(() => {
      const msg = intl.formatMessage({
        id: "message-center.not-allowed",
        defaultMessage: "Message is not allowed",
      });
      return msg;
    }, []);

    const messageBoxStyle = useMemo(() => {
      const styles = {};

      if (maxWidth) {
        styles["maxWidth"] = `${maxWidth}px`;
      }

      return styles;
    }, [maxWidth]);

    const formatMessagePreview = useCallback(
      (message: string) => {
        let conversation_msg = message || "";

        if (highlightedWord && conversation_msg) {
          const content = toArray(conversation_msg);
          const new_msg = content.reduce((previous, current) => {
            const searchRegEx = new RegExp(highlightedWord, "ig");
            const keywordMatch =
              typeof current === "string" ? current?.match(searchRegEx) : null;

            if (
              typeof current === "string" &&
              keywordMatch &&
              keywordMatch?.length
            ) {
              const current_html = current.replace(
                searchRegEx,
                `<span class="highlighted ${
                  isSenderCurrentUser ? "text-main-color" : "text-white"
                }" style="background:${
                  isSenderCurrentUser ? "#fff" : "#3190d4"
                }">${keywordMatch[0]}</span>`
              );
              return previous + current_html;
            } else if (typeof current === "string") {
              return previous + current;
            }
            return previous + (current?.["props"]?.children || "");
          }, "");
          conversation_msg = new_msg?.toString() || conversation_msg;
        }

        return `${sanitizeHtml(conversation_msg, {
          allowedTags: ["span"],
          allowedAttributes: {
            span: ["class", "style"],
          },
          allowedStyles: {
            span: {
              // Match HEX and RGB
              background: [
                /^#(0x)?[0-9a-f]+$/i,
                /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
              ],
            },
          },
        })}`;
      },
      [highlightedWord]
    );

    const handleImageLoaded = () => {
      const attachmentsWidth =
        attachmentsRef?.current?.querySelector?.(
          ".MessagesReader__GalleryGrid > div"
        )?.clientWidth || 0;

      if (attachmentsWidth && message?.data?.message) {
        setMaxWidth(attachmentsWidth);
      } else {
        setMaxWidth(0);
      }
    };

    return (
      <TailwindFlex
        className={["MessagesReaderMessage"]}
        width={"w-auto"}
        textColor='text-main-color'
        justifyContent={isSenderCurrentUser ? "justify-end" : "justify-start"}
        margin={["mb-10"]}
        alignItems='items-end'>
        {!isSenderCurrentUser && (
          <TailwindFlex
            width={"w-auto"}
            margin={["mr-2"]}
            flexDirection={"flex-col"}
            alignItems={"items-center"}
            justifyContent={"justify-end"}>
            <Avatar
              photoURL={message?.sender?.profile_picture}
              username={message?.sender?.username}
              size={"small"}
              randomBackgroundColor={true}
            />
          </TailwindFlex>
        )}
        <TailwindFlex
          flexDirection='flex-col'
          minWidth={"min-w-60"}
          maxWidth={"max-w-60"}
          width={"w-auto"}>
          <TailwindFlex
            width={"w-auto"}
            margin={["mb-0.5"]}
            padding={hasImageAttachments ? ["p-0.5"] : ["px-4", "py-2"]}
            borderRadius={
              isSenderCurrentUser
                ? ["rounded-bl-2xl", "rounded-tr-2xl", "rounded-tl-2xl"]
                : ["rounded-br-2xl", "rounded-tr-2xl", "rounded-tl-2xl"]
            }
            flexDirection={"flex-col"}
            position={"relative"}
            backgroundColor={"bg-white"}
            style={{
              marginLeft: isSenderCurrentUser ? "unset" : "6px",
              marginRight: isSenderCurrentUser ? "6px" : "unset",
              backgroundColor: isDeniedMessage
                ? "#ccc"
                : isSenderCurrentUser
                ? "#3190d4"
                : "bg-white",
              ...messageBoxStyle,
            }}>
            {!isDeniedMessage && (
              <div ref={attachmentsRef}>
                <MessageCenterMessageThumbnails
                  backgroundColor={isSenderCurrentUser ? "#3190d4" : "bg-white"}
                  message={message}
                  onImageLoad={handleImageLoaded}
                />
              </div>
            )}

            <div
              className={`msg-container ${
                isDeniedMessage && "msg-container-denied"
              } ${isSenderCurrentUser ? `msg-container-right` : ""}`}>
              <TailwindText
                padding={hasImageAttachments ? ["pl-2"] : []}
                wordBreak={"break-words"}
                textColor={
                  isDeniedMessage
                    ? "text-main-color"
                    : isSenderCurrentUser
                    ? "text-white"
                    : "text-main-color"
                }
                whiteSpace={"whitespace-pre-wrap"}
                className={classnames(tw(messageFontSize as TFontSize))}
                dangerouslySetInnerHTML={{
                  __html: isDeniedMessage
                    ? deniedMsg
                    : formatMessagePreview(message?.data?.message || ""),
                }}
              />
            </div>
          </TailwindFlex>
          <TailwindFlex
            width={"w-full"}
            flexDirection={"flex-col"}
            alignItems={isSenderCurrentUser ? "items-end" : "items-start"}
            style={{
              fontSize: "10px",
            }}>
            <TailwindText
              margin={["mb-0"]}
              fontSize={"text-xs"}
              textColor={"text-gray-500"}>
              {messageDate}
            </TailwindText>
          </TailwindFlex>
        </TailwindFlex>

        {isSenderCurrentUser && (
          <TailwindFlex
            width={"w-auto"}
            margin={["ml-2"]}
            flexDirection={"flex-col"}
            alignItems={"items-center"}></TailwindFlex>
        )}
      </TailwindFlex>
    );
  };

export default injectIntl(
  inject("messageStore")(observer(MessageCenterMessage))
);
