import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindImage from "library/components/_tailwind/image";

type Props = {
  imageURL: string;
};

const MessageCenterImage: React.ComponentType<Props> = ({ imageURL }) => {
  return (
    <TailwindFlex>
      <TailwindImage
        src={imageURL}
        style={{
          maxHeight: "100vh",
        }}
      />
    </TailwindFlex>
  );
};

export default MessageCenterImage;
