import config from "core/config";
import TailwindBox from "library/components/_tailwind/box";
import TailwindDivider from "library/components/_tailwind/divider";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindKebabMenu from "library/components/_tailwind/kebab-menu";
import TailwindList from "library/components/_tailwind/list";
import TailwindListItem from "library/components/_tailwind/list/list-item";
import TailwindParagraph from "library/components/_tailwind/paragraph";
import TailwindText from "library/components/_tailwind/text";
import Modal from "library/components/modal";
import ModalCloseButton from "library/components/modal/modal-close-button";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import MessageStore from "./stores/messages/MessageStore";
import { inject, observer } from "mobx-react";
import React, { useCallback, useState } from "react";

type Props = {
  layoutStore?: LayoutStore;
  messageStore?: MessageStore;
};

const MessageCenterMenuButton: React.ComponentType<Props> = ({
  layoutStore,
  messageStore,
}) => {
  const { isMobileDevice, screenOrientation } = layoutStore!;

  const { logArchivedMsgVisit } = messageStore!;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  return (
    <TailwindBox className={["MessageCenterMenuButton"]}>
      <TailwindKebabMenu
        margin={isMobileDevice ? ["ml-2"] : []}
        width={"w-auto"}
        fontSize={isMobileDevice ? "text-3xl" : "text-5xl"}
        textColor={"text-secondary-color"}
        backgroundColor={
          isMobileDevice ? "bg-tertiary-bg-color" : "bg-quaternary-bg-color"
        }
        borderRadius={"rounded-full"}
        padding={["p-1"]}>
        <TailwindFlex
          flexDirection={"flex-col"}
          justifyContent={"justify-start"}
          alignItems='items-start'>
          <TailwindText
            textColor={"text-pink-color"}
            onClick={handleOpenDialog}
            cursor='cursor-pointer'>
            Guidelines
          </TailwindText>
          <TailwindDivider
            color='bg-header-bg-color'
            margin={["my-4"]}
            opacity='opacity-50'
            style={{ height: "2px" }}
          />
          <a
            href={`${config.legacyModelsUrl}/p/mc/folder.cgi?folder=inbox`}
            target='_blank'
            rel='noreferrer'
            style={{
              display: "flex",
            }}>
            <TailwindText
              textColor={"text-pink-color"}
              onClick={logArchivedMsgVisit}>
              Archived Messages
            </TailwindText>
          </a>
        </TailwindFlex>
      </TailwindKebabMenu>
      <Modal open={isDialogOpen}>
        <TailwindBox
          // className={["BroadcastShowTypeModal"]}
          backgroundColor={"bg-primary-bg-color"}
          maxWidth='max-w-3xl'
          borderRadius={"rounded-3xl"}>
          <ModalCloseButton
            className={`p-2 ${
              isMobileDevice && screenOrientation === "landscape" ? "mt-8" : ""
            }`}
            closeModal={handleCloseDialog}
          />
          <TailwindBox
            padding={
              isMobileDevice && screenOrientation === "landscape"
                ? ["py-24", "px-10"]
                : ["py-16", "px-10"]
            }>
            <TailwindParagraph margin={["my-2"]}>
              Connect with members using our message center. All messages are
              reviewed by our support team to ensure they meet our communication
              guidelines (e.g., no contact information allowed).
            </TailwindParagraph>

            <TailwindParagraph margin={["my-2"]}>
              <TailwindText
                margin={["mb-2"]}
                as={"div"}
                fontWeight='font-bold'
                textAlign={"text-left"}>
                Our communication guidelines prohibit the following:
              </TailwindText>
              <TailwindList fontSize={"text-sm"}>
                <TailwindListItem listStyleType={"list-disc"}>
                  Sending solicitations to any website
                </TailwindListItem>
                <TailwindListItem listStyleType={"list-disc"}>
                  Sending harassing or threatening messages
                </TailwindListItem>
                <TailwindListItem listStyleType={"list-disc"}>
                  Posting fake/inappropriate accounts or photos
                </TailwindListItem>
                <TailwindListItem listStyleType={"list-disc"}>
                  Being abusive or sending solicitations
                </TailwindListItem>
              </TailwindList>
            </TailwindParagraph>
          </TailwindBox>
        </TailwindBox>
      </Modal>
    </TailwindBox>
  );
};

export default inject(
  "layoutStore",
  "messageStore"
)(observer(MessageCenterMenuButton));
