import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { inject, observer } from "mobx-react";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import MessageCenterNavigator from "./messages-navigator";
import MessageCenterReader from "./messages-reader";
import MessageStore from "./stores/messages/MessageStore";
import TailwindCard from "library/components/_tailwind/card";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindHeading from "library/components/_tailwind/heading";
import Showcase from "library/components/_tailwind/showcase";
import { AppCommonRouteKey } from "core/stores/route/enums";
import ShowcaseStore from "core/stores/showcase/ShowcaseStore";
import { SHOWCASE_TARGET_CLASSNAMES } from "core/stores/showcase/const";
import TailwindIcon from "library/components/_tailwind/icon";
import { BreakPoints } from "library/core/stores/layout/types";
import { MessageTabEnum } from "./stores/messages/consts";
import MessageCenterMenuButton from "./message-navigator-menu-button";
import RouteStore from "core/stores/route/RouteStore";
import { useHistory } from "react-router-dom";
import NotificationStore from "./stores/notifications/NoticationStore";

type Props = {
  layoutStore?: LayoutStore;
  messageStore?: MessageStore;
  showcaseStore?: ShowcaseStore;
  routeStore?: RouteStore;
  notificationStore?: NotificationStore;
};

const MessagesContainer: React.ComponentType<Props> = ({
  layoutStore,
  messageStore,
  showcaseStore,
  routeStore,
  notificationStore,
}) => {
  const history = useHistory();
  const { setBodyPadding, isMobileDevice, screenOrientation, windowWidth } =
    layoutStore!;
  const {
    getWelcomeShowcaseSteps,
    nextWelcomeShowcasePage,
    playingShowcase,
    disableShowcase,
  } = showcaseStore!;
  const { currentRoutePathname, getSiteRouteURL } = routeStore!;

  const {
    selectedConversations,
    setSelectedConversations,
    getConversations,
    isComposing,
    setIsComposing,
    activeConversation,
    readConversation,
    getUnreadConversations,
    resetCrucialVariblesState,
    activeNewsAndPolicy,
    isSearchingView,
    setIsSearchingView,
    resetActiveConversation,
    setConversationsSearchTerm,
    setContactsSearchTerm,
    setIsBulkMessageView,
    logMsgCenterVisit,
    isConversationsLoading,
    conversations,
  } = messageStore!;

  const { init: initNotificationStore, resetStore: resetNotificationStore } = notificationStore!;

  const navigatorContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setBodyPadding(0);
    getConversations();
    getUnreadConversations();
    logMsgCenterVisit();

    resetNotificationStore();
    setTimeout(() => {
      initNotificationStore();
    });

    return () => {
      setBodyPadding(15);
      resetCrucialVariblesState();
      resetNotificationStore();
    };
  }, []);

  const goBack = useCallback(() => {
    if (isMobileDevice) {
      history.goBack();
    } else {
      if (isComposing) {
        setIsComposing(!isComposing);
      } else if (isSearchingView) {
        setIsSearchingView(!isSearchingView);
        setConversationsSearchTerm("");
        setContactsSearchTerm("");
      } else if (
        (selectedConversations && selectedConversations.length > 0) ||
        activeConversation
      ) {
        setSelectedConversations([]);
        readConversation(null);
      }
    }
  }, [selectedConversations, activeConversation, isComposing, isSearchingView]);

  const toggleIsSearching = useCallback(() => {
    if (isMobileDevice) {
      history.push(
        `${getSiteRouteURL(AppCommonRouteKey.messages)}${getSiteRouteURL(
          AppCommonRouteKey.searchMessages
        )}`
      );
    } else {
      setIsSearchingView(!isSearchingView);
    }
  }, [isSearchingView]);

  useEffect(() => {
    if (
      isMobileDevice &&
      screenOrientation === "landscape" &&
      windowWidth &&
      windowWidth <= BreakPoints.md &&
      activeNewsAndPolicy &&
      navigatorContainerRef?.current?.parentElement
    ) {
      navigatorContainerRef.current.parentElement.scrollTop = 130;
    }
  }, [activeNewsAndPolicy]);

  useEffect(() => {
    if (
      currentRoutePathname ===
        `${getSiteRouteURL(AppCommonRouteKey.messages)}${getSiteRouteURL(
          AppCommonRouteKey.contacts
        )}` &&
      !isComposing
    ) {
      setIsComposing(true);
      setIsBulkMessageView(false);
      setIsSearchingView(false);
      setConversationsSearchTerm("");
      setContactsSearchTerm("");
    } else if (
      currentRoutePathname ===
        `${getSiteRouteURL(AppCommonRouteKey.messages)}${getSiteRouteURL(
          AppCommonRouteKey.searchMessages
        )}` &&
      !isSearchingView
    ) {
      setIsComposing(false);
      setIsBulkMessageView(false);
      setIsSearchingView(true);
      setConversationsSearchTerm("");
      setContactsSearchTerm("");
    } else {
      setIsComposing(false);
      setIsBulkMessageView(false);
      setIsSearchingView(false);
      setConversationsSearchTerm("");
      setContactsSearchTerm("");

      if (
        ((selectedConversations && selectedConversations.length > 0) ||
          activeConversation) &&
        currentRoutePathname !==
          `${getSiteRouteURL(AppCommonRouteKey.messages)}${getSiteRouteURL(
            AppCommonRouteKey.compose
          )}`
      ) {
        setSelectedConversations([]);
        readConversation(null);
      }
    }
  }, [currentRoutePathname]);

  useEffect(() => {
    resetActiveConversation();
  }, []);

  const shouldBefullWidth = useMemo(
    () =>
      isMobileDevice && (isSearchingView || isComposing || activeConversation),
    [activeConversation, isComposing, isSearchingView, isMobileDevice]
  );

  return (
    <TailwindCard
      ref={navigatorContainerRef}
      display={"flex"}
      height={"h-full"}
      flexDirection={"flex-col"}
      padding={shouldBefullWidth ? ["p-0"] : ["pt-3", "pb-4"]}
      backgroundColor={
        isMobileDevice ? "bg-quaternary-bg-color" : "bg-primary-bg-color"
      }>
      {playingShowcase && (
        <Showcase
          steps={getWelcomeShowcaseSteps(AppCommonRouteKey.messages)}
          onFinish={nextWelcomeShowcasePage}
          disableOverlay={true}
          showSkipButton={true}
          onSkipped={() => {
            disableShowcase();
          }}
        />
      )}

      <TailwindFlex
        className={[
          "MessageCenter",
          isMobileDevice ? "MessageCenter--mobile" : "",
        ]}
        borderWidth={
          isConversationsLoading || !conversations.length ? ["border-b-2"] : []
        }
        borderColor={"border-primary-bg-color"}
        margin={shouldBefullWidth || isMobileDevice ? ["m-0"] : ["my-2"]}
        padding={shouldBefullWidth || isMobileDevice ? ["p-0"] : ["px-5"]}
        flexDirection={"flex-col"}
        style={
          isMobileDevice
            ? {
                paddingLeft: "15px",
                paddingRight: "15px",
              }
            : {}
        }>
        {isMobileDevice &&
          !isSearchingView &&
          !isComposing &&
          !activeConversation && (
            <TailwindFlex flexDirection={"flex-row"} padding={["pb-3"]}>
              <TailwindFlex alignItems='items-center'>
                <TailwindHeading
                  level={5}
                  className={SHOWCASE_TARGET_CLASSNAMES.messagesTitle}>
                  Message Center
                </TailwindHeading>

                {/* <TailwindIcon
                  onClick={handleOpenDialog}
                  margin={["ml-1"]}
                  name={"question-mark"}
                /> */}
              </TailwindFlex>
              {isMobileDevice && !isComposing && (
                <TailwindIcon
                  onClick={toggleIsSearching}
                  margin={["ml-1"]}
                  backgroundColor={"bg-tertiary-bg-color"}
                  padding={["py-2", "px-2.5"]}
                  borderRadius={"rounded-full"}
                  name={"search-alt"}
                />
              )}
              <MessageCenterMenuButton />
            </TailwindFlex>
          )}
        {!isMobileDevice && (
          <TailwindFlex flexDirection={"flex-row"}>
            <TailwindFlex alignItems='items-center'>
              <TailwindHeading
                level={5}
                className={SHOWCASE_TARGET_CLASSNAMES.messagesTitle}>
                Message Center
              </TailwindHeading>
            </TailwindFlex>
            <TailwindFlex justifyContent='justify-end'></TailwindFlex>
          </TailwindFlex>
        )}
      </TailwindFlex>
      <TailwindFlex
        width={"w-full"}
        flexGrow={"flex-grow"}
        position={"relative"}
        padding={isMobileDevice ? ["p-0"] : ["px-5"]}
        overflow={
          isMobileDevice &&
          screenOrientation === "landscape" &&
          windowWidth &&
          windowWidth <= BreakPoints.md
            ? []
            : ["overflow-hidden"]
        }>
        <TailwindFlex
          md={"w-full"}
          lg={"w-4/12"}
          width={"w-4/12"}
          flexGrow={"flex-grow"}>
          <MessageCenterNavigator
            goBack={goBack}
            messageType={MessageTabEnum.MESSAGE}
          />
        </TailwindFlex>
        {!isMobileDevice && (
          <TailwindFlex
            md={"w-full"}
            lg={"w-8/12"}
            width={"w-8/12"}
            overflow={["overflow-hidden"]}
            flexGrow={"flex-grow"}
            flexDirection={"flex-col"}>
            <MessageCenterReader />
          </TailwindFlex>
        )}
      </TailwindFlex>
    </TailwindCard>
  );
};
export default inject(
  "messageStore",
  "layoutStore",
  "showcaseStore",
  "routeStore",
  "notificationStore"
)(observer(MessagesContainer));
