import React, { Component } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import "./styles.scss";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export type SwipeableSliderArrowSize =
  | "large"
  | "medium"
  | "small"
  | "extra-small";
export type SwipeableSliderProps = {
  children?: any;
  arrowSize?: SwipeableSliderArrowSize;
  iconClassName?: string;
  className?: string;
  swipeableViewsProps?: SwipeableViewsProps;
};

type SwipeableViewsAutoplayProps = {
  autoplay?: boolean;
  direction?: "incremental" | "decremental";
  interval?: number;
};

export type SwipeableViewsProps = SwipeableViewsAutoplayProps & {
  index?: number;
  onChangeIndex?: (index: number, indexLatest: number, meta: any) => void;
};

type SwipeableSliderState = {
  activeSlide: number;
  shouldHideArrows: {
    left: boolean;
    right: boolean;
  };
  swipeableViewsProps?: SwipeableViewsProps;
};

class SwipeableSlider extends Component<
  SwipeableSliderProps,
  SwipeableSliderState
> {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: props.swipeableViewsProps?.index || 0,
      shouldHideArrows: {
        left: true,
        right: false,
      },
      swipeableViewsProps: {
        ...props.swipeableViewsProps,
        autoplay: props.swipeableViewsProps?.autoplay || false,
        direction: props.swipeableViewsProps?.direction || "incremental",
        interval: props.swipeableViewsProps?.interval || 3000,
        onChangeIndex: (index: number, indexLatest: number, meta: any) => {
          this.setState(
            {
              activeSlide: index,
            },
            () => {
              this.checkArrowVisibility();
            }
          );
          if (props.swipeableViewsProps?.onChangeIndex) {
            props.swipeableViewsProps.onChangeIndex(index, indexLatest, meta);
          }
        },
      },
    };
  }

  componentDidMount(): void {
    this.checkArrowVisibility();
  }

  componentDidUpdate(
    prevProps: Readonly<SwipeableSliderProps>,
    _prevState: Readonly<SwipeableSliderState>,
    _snapshot?: any
  ): void {
    if (
      prevProps.children &&
      this.props.children &&
      prevProps.children.length !== this.props.children.length
    ) {
      this.checkArrowVisibility();
    }

    if (
      prevProps.swipeableViewsProps?.index !==
        this.props.swipeableViewsProps?.index &&
      this.props.swipeableViewsProps?.index !== undefined
    ) {
      this.setState({
        activeSlide: this.props.swipeableViewsProps?.index,
      });
    }

    const previousSlideCount = React.Children.count(prevProps.children);
    const currentSlideCount = React.Children.count(this.props.children);
    const activeSlide = this.state.activeSlide + 1;

    if (
      activeSlide > currentSlideCount ||
      (currentSlideCount > previousSlideCount &&
        activeSlide < currentSlideCount)
    ) {
      // if a slide gets removed due to its children being deleted
      // or a new slide gets added due to having more slides
      // move back to the last slide
      const lastSlide = currentSlideCount - 1;
      this.setState({
        activeSlide: lastSlide,
      });
      this.checkArrowVisibility(lastSlide);
    }
  }

  swipeLeft() {
    const { activeSlide } = this.state;
    if (activeSlide === 1) {
      this.setState(
        {
          activeSlide: 0,
        },
        () => {
          this.checkArrowVisibility();
        }
      );
    } else if (activeSlide > 0) {
      this.setState(
        {
          activeSlide: activeSlide - 1,
        },
        () => {
          this.checkArrowVisibility();
        }
      );
    }
  }

  swipeRight() {
    const { activeSlide } = this.state;
    if (this.props.children && activeSlide < this.props.children.length - 1) {
      this.setState(
        {
          activeSlide: activeSlide + 1,
        },
        () => {
          this.checkArrowVisibility();
        }
      );
    }
  }

  checkArrowVisibility(slideNum?: number) {
    const activeSlideIndex = slideNum || this.state.activeSlide;

    const childCount = React.Children.count(this.props.children);
    const activeSlide = activeSlideIndex + 1;
    const isActiveSlideFirst = activeSlide === 1;
    const isActiveSlideLast = activeSlide === childCount;
    const isActiveSlideBetween = !isActiveSlideFirst && !isActiveSlideLast;
    const hasOneSlide = childCount === 1;

    if (isActiveSlideBetween) {
      this.setState({
        shouldHideArrows: {
          left: hasOneSlide ? true : false,
          right: hasOneSlide ? true : false,
        },
      });
    } else if (isActiveSlideFirst) {
      this.setState({
        shouldHideArrows: {
          left: true,
          right: hasOneSlide ? true : false,
        },
      });
    } else if (isActiveSlideLast) {
      this.setState({
        shouldHideArrows: {
          left: hasOneSlide ? true : false,
          right: true,
        },
      });
    }
  }

  render() {
    const { state, props } = this;
    return (
      <React.Fragment>
        <div
          className={`Swipeable-slider h-full w-full ${
            props.className ? props.className : ""
          }`}
        >
          <div
            onClick={() => this.swipeLeft()}
            className={
              "Swipeable-slider__arrow Swipeable-slider__arrow-left flex items-center " +
              (props.arrowSize
                ? `Swipeable-slider__arrow--${props.arrowSize}`
                : "Swipeable-slider__arrow--extra-small") +
              (!state.shouldHideArrows.left
                ? ""
                : " Swipeable-slider__arrow-left--hidden")
            }
          >
            <svg
              className={`${!!props.iconClassName ? props.iconClassName : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="6 0 12 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
          </div>
          <AutoPlaySwipeableViews
            {...this.state.swipeableViewsProps}
            index={state.activeSlide}
            className={"Swipeable-slider__body w-full h-full"}
          >
            {this.props.children}
          </AutoPlaySwipeableViews>
          <div
            onClick={() => this.swipeRight()}
            className={
              "Swipeable-slider__arrow Swipeable-slider__arrow-right flex items-center " +
              (props.arrowSize
                ? `Swipeable-slider__arrow--${props.arrowSize}`
                : "Swipeable-slider__arrow--extra-small") +
              (!state.shouldHideArrows.right
                ? ""
                : " Swipeable-slider__arrow-right--hidden")
            }
          >
            <svg
              className={`${!!props.iconClassName ? props.iconClassName : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="6 0 12 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SwipeableSlider;
