import React, { FunctionComponent, useEffect, useState } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import PreLiveChatForm from "./PreLiveChatForm";
import { snackbarStore } from "library/core/stores/snackbar/SnackbarStore";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import { TailwindTextProps } from "library/components/_tailwind/text";
import { SnackbarVariants } from "library/core/stores/snackbar/enums";
import TailwindIcon from "library/components/_tailwind/icon";
import LayoutStore from "library/core/stores/layout/LayoutStore";

type Props = {
  textProps?: TailwindTextProps;
  onButtonReady: () => void;
  onButtonHide: () => void;
  broadcastButton?: boolean;
  isButtonInitialized: boolean;
  setIsButtonInitialized: any;
  layoutStore?: LayoutStore;
};

const LiveChat: FunctionComponent<Props & WrappedComponentProps> = ({
  textProps,
  isButtonInitialized,
  setIsButtonInitialized,
  onButtonReady,
  onButtonHide,
  layoutStore,
  broadcastButton,
}) => {
  let isRingCentralReady = false;
  const [showForm, setShowForm] = useState(false);
  const { isLiveChatOpen, setIsLiveChatOpen } = layoutStore!;
  const live_chat_bubble = document.querySelector(
    ".dimelo-chat-wrapper"
  ) as HTMLElement;
  useEffect(() => {
    const _chatq = window["_chatq"] || [];

    if (isLiveChatOpen) {
      _chatq.push([
        "_onEvent",
        "button_shown",
        () => {
          const live_chat_bubble = document.querySelector(
            ".dimelo-chat-wrapper"
          ) as HTMLElement;
          if (!live_chat_bubble) {
            // do not initialize button if chat has previously been open since user information has already been sent to ring central and chat is in minimized state
            setIsButtonInitialized(true);
            onButtonReady();
          }
        },
      ]);
      showLiveChat();
    }

    return () => {
      hideLiveChat();
    };
  }, []);

  const showLiveChat = () => {
    setIsLiveChatOpen(true);
  };

  const hideLiveChat = () => {
    setIsButtonInitialized(false);
    setIsLiveChatOpen(false);
    localStorage.clear();
  };

  const showPreLiveChatForm = () => {
    setIsButtonInitialized(true);

    setShowForm(true);
    if (live_chat_bubble) {
      live_chat_bubble.style.zIndex = "-1";
    }
  };

  const hidePreLiveChatForm = () => {
    const live_chat_bubble = document.querySelector(
      ".dimelo-chat-wrapper"
    ) as HTMLElement;
    setShowForm(false);
    if (live_chat_bubble) {
      live_chat_bubble.style.zIndex = "9999";
    }
  };

  const submitPreLiveChatForm = (
    username: string,
    email: string,
    message: string
  ) => {
    const _chatq = window["_chatq"] || [];
    _chatq.push(["_fillCustomVariable", "category", ""]);
    _chatq.push([
      "_onEvent",
      "chat_shown",
      function (trigger) {
        // ring central has a bug where chat shown triggers multiple at once and trigger_continuation is not set
        if (!trigger.trigger_continuation && !isRingCentralReady) {
          isRingCentralReady = true;
          _chatq.push(["_addMessage", { body: message, engage: true }]);
          showLiveChat();
          setIsButtonInitialized(false);
          onButtonHide();
        }
      },
    ]);
    _chatq.push([
      "_onEvent",
      "chat_closed",
      function () {
        isRingCentralReady = false;
      },
    ]);

    _chatq.push(["_fillCustomVariable", "category", "model_studio"]);
    _chatq.push([
      "_setIdentity",
      {
        screenname: username,
        email: email,
        extra_values: {
          username: username,
        },
      },
    ]);

    // ring central triggers sometimes don't fire so warn users after an unexpected amount of time passes
    setTimeout(() => {
      if (!isRingCentralReady) {
        snackbarStore.enqueueSimpleSnackbar(
          "liveChat.live-agent-connection-failure",
          SnackbarVariants.ERROR
        );
      }
    }, 5000);

    hidePreLiveChatForm();
  };

  return (
    <>
      {broadcastButton && (
        <TailwindIcon
          onClick={() =>
            isButtonInitialized ? hideLiveChat() : showPreLiveChatForm()
          }
          fontSize={"text-xl"}
          className={"text-main-color cursor-pointer"}
          name={"support"}
        />
      )}
      {!broadcastButton && (
        <TailwindTranslatedText
          {...textProps}
          className={isButtonInitialized ? "" : "hidden"}
          cursor={"cursor-pointer"}
          onClick={() => showPreLiveChatForm()}
          descriptor={{
            id: "landing.questions.liveChatSupport",
            defaultMessage: "Chat with an Agent",
          }}
        />
      )}
      {isButtonInitialized && (
        <>
          <div
            id='dimelo_chat_item_markup_5c967d6bb2f5604e9a767815'
            className={"dimelo_chat_item_markup hidden z-1"}></div>

          <PreLiveChatForm
            display={showForm}
            hidePreLiveChatForm={hidePreLiveChatForm}
            onConfirm={submitPreLiveChatForm}
          />
        </>
      )}
    </>
  );
};
export default injectIntl(
  inject("modalStore", "authStore", "layoutStore")(observer(LiveChat))
);
