import {
  IAstrologicalSign,
  IBodyType,
  IBreastSize,
  IBuildSize,
  ICountry,
  ICountryPhoneNumber,
  IEthnicity,
  IEyeColor,
  IGender,
  IHairColor,
  ILanguage,
  IMaritalStatus,
  IPenisSize,
  ISexualOrientation,
} from "../interfaces";

export const GENDER_LIST: IGender = {
  male: "Male",
  female: "Female",
  trans: "Trans",
};

export const GENDER_VALUE_OPTIONS = {
  male: true,
  female: true,
  trans: true,
};

// do not change the order of countries, per product decision, top 5 countries are at top
// US, CA, AU, NZ, UK
// this is used as a fallback and country list is being fetched from BE on Cams Models/Studios
export const COUNTRY_LIST: ICountry = {
  US: "United States",
  CA: "Canada",
  AU: "Australia",
  NZ: "New Zealand",
  GB: "United Kingdom",
  AW: "Aruba",
  AF: "Afghanistan",
  AO: "Angola",
  AI: "Anguilla",
  AX: "Åland Islands",
  AL: "Albania",
  AD: "Andorra",
  AE: "United Arab Emirates",
  AR: "Argentina",
  AM: "Armenia",
  AS: "American Samoa",
  AQ: "Antarctica",
  TF: "French Southern Territories",
  AG: "Antigua and Barbuda",
  AT: "Austria",
  AZ: "Azerbaijan",
  BI: "Burundi",
  BE: "Belgium",
  BJ: "Benin",
  BQ: "Bonaire, Sint Eustatius and Saba",
  BF: "Burkina Faso",
  BD: "Bangladesh",
  BG: "Bulgaria",
  BH: "Bahrain",
  BS: "Bahamas",
  BA: "Bosnia and Herzegovina",
  BL: "Saint Barthélemy",
  BY: "Belarus",
  BZ: "Belize",
  BM: "Bermuda",
  BO: "Bolivia, Plurinational State of",
  BR: "Brazil",
  BB: "Barbados",
  BN: "Brunei Darussalam",
  BT: "Bhutan",
  BV: "Bouvet Island",
  BW: "Botswana",
  CF: "Central African Republic",
  CC: "Cocos (Keeling) Islands",
  CH: "Switzerland",
  CL: "Chile",
  CN: "China",
  CI: "Côte d'Ivoire",
  CM: "Cameroon",
  CD: "Congo, The Democratic Republic of the",
  CG: "Congo",
  CK: "Cook Islands",
  CO: "Colombia",
  KM: "Comoros",
  CV: "Cabo Verde",
  CR: "Costa Rica",
  CU: "Cuba",
  CW: "Curaçao",
  CX: "Christmas Island",
  KY: "Cayman Islands",
  CY: "Cyprus",
  CZ: "Czechia",
  DE: "Germany",
  DJ: "Djibouti",
  DM: "Dominica",
  DK: "Denmark",
  DO: "Dominican Republic",
  DZ: "Algeria",
  EC: "Ecuador",
  EG: "Egypt",
  ER: "Eritrea",
  EH: "Western Sahara",
  ES: "Spain",
  EE: "Estonia",
  ET: "Ethiopia",
  FI: "Finland",
  FJ: "Fiji",
  FK: "Falkland Islands (Malvinas)",
  FR: "France",
  FO: "Faroe Islands",
  FM: "Micronesia, Federated States of",
  GA: "Gabon",
  GE: "Georgia",
  GG: "Guernsey",
  GH: "Ghana",
  GI: "Gibraltar",
  GN: "Guinea",
  GP: "Guadeloupe",
  GM: "Gambia",
  GW: "Guinea-Bissau",
  GQ: "Equatorial Guinea",
  GR: "Greece",
  GD: "Grenada",
  GL: "Greenland",
  GT: "Guatemala",
  GF: "French Guiana",
  GU: "Guam",
  GY: "Guyana",
  HK: "Hong Kong",
  HM: "Heard Island and McDonald Islands",
  HN: "Honduras",
  HR: "Croatia",
  HT: "Haiti",
  HU: "Hungary",
  ID: "Indonesia",
  IM: "Isle of Man",
  IN: "India",
  IO: "British Indian Ocean Territory",
  IE: "Ireland",
  IR: "Iran, Islamic Republic of",
  IQ: "Iraq",
  IS: "Iceland",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JE: "Jersey",
  JO: "Jordan",
  JP: "Japan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KG: "Kyrgyzstan",
  KH: "Cambodia",
  KI: "Kiribati",
  KN: "Saint Kitts and Nevis",
  KR: "Korea, Republic of",
  KW: "Kuwait",
  LA: "Lao People's Democratic Republic",
  LB: "Lebanon",
  LR: "Liberia",
  LY: "Libya",
  LC: "Saint Lucia",
  LI: "Liechtenstein",
  LK: "Sri Lanka",
  LS: "Lesotho",
  LT: "Lithuania",
  LU: "Luxembourg",
  LV: "Latvia",
  MO: "Macao",
  MF: "Saint Martin (French part)",
  MA: "Morocco",
  MC: "Monaco",
  MD: "Moldova, Republic of",
  MG: "Madagascar",
  MV: "Maldives",
  MX: "Mexico",
  MH: "Marshall Islands",
  MK: "North Macedonia",
  ML: "Mali",
  MT: "Malta",
  MM: "Myanmar",
  ME: "Montenegro",
  MN: "Mongolia",
  MP: "Northern Mariana Islands",
  MZ: "Mozambique",
  MR: "Mauritania",
  MS: "Montserrat",
  MQ: "Martinique",
  MU: "Mauritius",
  MW: "Malawi",
  MY: "Malaysia",
  YT: "Mayotte",
  NA: "Namibia",
  NC: "New Caledonia",
  NE: "Niger",
  NF: "Norfolk Island",
  NG: "Nigeria",
  NI: "Nicaragua",
  NU: "Niue",
  NL: "Netherlands",
  NO: "Norway",
  NP: "Nepal",
  NR: "Nauru",
  OM: "Oman",
  PK: "Pakistan",
  PA: "Panama",
  PN: "Pitcairn",
  PE: "Peru",
  PH: "Philippines",
  PW: "Palau",
  PG: "Papua New Guinea",
  PL: "Poland",
  PR: "Puerto Rico",
  KP: "Korea, Democratic People's Republic of",
  PT: "Portugal",
  PY: "Paraguay",
  PS: "Palestine, State of",
  PF: "French Polynesia",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Romania",
  RU: "Russian Federation",
  RW: "Rwanda",
  SA: "Saudi Arabia",
  SD: "Sudan",
  SN: "Senegal",
  SG: "Singapore",
  GS: "South Georgia and the South Sandwich Islands",
  SH: "Saint Helena, Ascension and Tristan da Cunha",
  SJ: "Svalbard and Jan Mayen",
  SB: "Solomon Islands",
  SL: "Sierra Leone",
  SV: "El Salvador",
  SM: "San Marino",
  SO: "Somalia",
  PM: "Saint Pierre and Miquelon",
  RS: "Serbia",
  SS: "South Sudan",
  ST: "Sao Tome and Principe",
  SR: "Suriname",
  SK: "Slovakia",
  SI: "Slovenia",
  SE: "Sweden",
  SZ: "Eswatini",
  SX: "Sint Maarten (Dutch part)",
  SC: "Seychelles",
  SY: "Syrian Arab Republic",
  TC: "Turks and Caicos Islands",
  TD: "Chad",
  TG: "Togo",
  TH: "Thailand",
  TJ: "Tajikistan",
  TK: "Tokelau",
  TM: "Turkmenistan",
  TL: "Timor-Leste",
  TO: "Tonga",
  TT: "Trinidad and Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TV: "Tuvalu",
  TW: "Taiwan, Province of China",
  TZ: "Tanzania, United Republic of",
  UG: "Uganda",
  UA: "Ukraine",
  UM: "United States Minor Outlying Islands",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VA: "Holy See (Vatican City State)",
  VC: "Saint Vincent and the Grenadines",
  VE: "Venezuela, Bolivarian Republic of",
  VG: "Virgin Islands, British",
  VI: "Virgin Islands, U.S.",
  VN: "Viet Nam",
  VU: "Vanuatu",
  WF: "Wallis and Futuna",
  WS: "Samoa",
  YE: "Yemen",
  ZA: "South Africa",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};

// taken from https://github.com/datasets/country-codes/blob/master/data/country-codes.csv
export const COUNTRY_PHONE_NUMBERS_LIST: ICountryPhoneNumber = {
  TW: "886",
  AF: "93",
  AL: "355",
  DZ: "213",
  AS: "1-684",
  AD: "376",
  AO: "244",
  AI: "1-264",
  AQ: "672",
  AG: "1-268",
  AR: "54",
  AM: "374",
  AW: "297",
  AU: "61",
  AT: "43",
  AZ: "994",
  BS: "1-242",
  BH: "973",
  BD: "880",
  BB: "1-246",
  BY: "375",
  BE: "32",
  BZ: "501",
  BJ: "229",
  BM: "1-441",
  BT: "975",
  BO: "591",
  BQ: "599",
  BA: "387",
  BW: "267",
  BV: "47",
  BR: "55",
  IO: "246",
  VG: "1-284",
  BN: "673",
  BG: "359",
  BF: "226",
  BI: "257",
  CV: "238",
  KH: "855",
  CM: "237",
  CA: "1",
  KY: "1-345",
  CF: "236",
  TD: "235",
  CL: "56",
  CN: "86",
  HK: "852",
  MO: "853",
  CX: "61",
  CC: "61",
  CO: "57",
  KM: "269",
  CG: "242",
  CK: "682",
  CR: "506",
  HR: "385",
  CU: "53",
  CW: "599",
  CY: "357",
  CZ: "420",
  CI: "225",
  KP: "850",
  CD: "243",
  DK: "45",
  DJ: "253",
  DM: "1-767",
  DO: "1-809,1-829,1-849",
  EC: "593",
  EG: "20",
  SV: "503",
  GQ: "240",
  ER: "291",
  EE: "372",
  SZ: "268",
  ET: "251",
  FK: "500",
  FO: "298",
  FJ: "679",
  FI: "358",
  FR: "33",
  GF: "594",
  PF: "689",
  TF: "262",
  GA: "241",
  GM: "220",
  GE: "995",
  DE: "49",
  GH: "233",
  GI: "350",
  GR: "30",
  GL: "299",
  GD: "1-473",
  GP: "590",
  GU: "1-671",
  GT: "502",
  GG: "44",
  GN: "224",
  GW: "245",
  GY: "592",
  HT: "509",
  HM: "672",
  VA: "39-06",
  HN: "504",
  HU: "36",
  IS: "354",
  IN: "91",
  ID: "62",
  IR: "98",
  IQ: "964",
  IE: "353",
  IM: "44",
  IL: "972",
  IT: "39",
  JM: "1-876",
  JP: "81",
  JE: "44",
  JO: "962",
  KZ: "7",
  KE: "254",
  KI: "686",
  KW: "965",
  KG: "996",
  LA: "856",
  LV: "371",
  LB: "961",
  LS: "266",
  LR: "231",
  LY: "218",
  LI: "423",
  LT: "370",
  LU: "352",
  MG: "261",
  MW: "265",
  MY: "60",
  MV: "960",
  ML: "223",
  MT: "356",
  MH: "692",
  MQ: "596",
  MR: "222",
  MU: "230",
  YT: "262",
  MX: "52",
  FM: "691",
  MC: "377",
  MN: "976",
  ME: "382",
  MS: "1-664",
  MA: "212",
  MZ: "258",
  MM: "95",
  NA: "264",
  NR: "674",
  NP: "977",
  NL: "31",
  NC: "687",
  NZ: "64",
  NI: "505",
  NE: "227",
  NG: "234",
  NU: "683",
  NF: "672",
  MP: "1-670",
  NO: "47",
  OM: "968",
  PK: "92",
  PW: "680",
  PA: "507",
  PG: "675",
  PY: "595",
  PE: "51",
  PH: "63",
  PN: "870",
  PL: "48",
  PT: "351",
  PR: "1",
  QA: "974",
  KR: "82",
  MD: "373",
  RO: "40",
  RU: "7",
  RW: "250",
  RE: "262",
  BL: "590",
  SH: "290",
  KN: "1-869",
  LC: "1-758",
  MF: "590",
  PM: "508",
  VC: "1-784",
  WS: "685",
  SM: "378",
  ST: "239",
  SA: "966",
  SN: "221",
  RS: "381",
  SC: "248",
  SL: "232",
  SG: "65",
  SX: "1-721",
  SK: "421",
  SI: "386",
  SB: "677",
  SO: "252",
  ZA: "27",
  GS: "500",
  SS: "211",
  ES: "34",
  LK: "94",
  PS: "970",
  SD: "249",
  SR: "597",
  SJ: "47",
  SE: "46",
  CH: "41",
  SY: "963",
  TJ: "992",
  TH: "66",
  MK: "389",
  TL: "670",
  TG: "228",
  TK: "690",
  TO: "676",
  TT: "1-868",
  TN: "216",
  TR: "90",
  TM: "993",
  TC: "1-649",
  TV: "688",
  UG: "256",
  UA: "380",
  AE: "971",
  GB: "44",
  TZ: "255",
  UM: "",
  VI: "1-340",
  US: "1",
  UY: "598",
  UZ: "998",
  VU: "678",
  VE: "58",
  VN: "84",
  WF: "681",
  EH: "212",
  YE: "967",
  ZM: "260",
  ZW: "263",
  AX: "358",
};

export const US_STATES: ICountry = {
  "US-AL": "Alabama",
  "US-AK": "Alaska",
  "US-AZ": "Arizona",
  "US-AR": "Arkansas",
  "US-CA": "California",
  "US-CO": "Colorado",
  "US-CT": "Connecticut",
  "US-DE": "Delaware",
  "US-FL": "Florida",
  "US-GA": "Georgia",
  "US-HI": "Hawaii",
  "US-ID": "Idaho",
  "US-IL": "Illinois",
  "US-IN": "Indiana",
  "US-IA": "Iowa",
  "US-KS": "Kansas",
  "US-KY": "Kentucky",
  "US-LA": "Louisiana",
  "US-ME": "Maine",
  "US-MD": "Maryland",
  "US-MA": "Massachusetts",
  "US-MI": "Michigan",
  "US-MN": "Minnesota",
  "US-MS": "Mississippi",
  "US-MO": "Missouri",
  "US-MT": "Montana",
  "US-NE": "Nebraska",
  "US-NV": "Nevada",
  "US-NH": "New Hampshire",
  "US-NJ": "New Jersey",
  "US-NM": "New Mexico",
  "US-NY": "New York",
  "US-NC": "North Carolina",
  "US-ND": "North Dakota",
  "US-OH": "Ohio",
  "US-OK": "Oklahoma",
  "US-OR": "Oregon",
  "US-PA": "Pennsylvania",
  "US-RI": "Rhode Island",
  "US-SC": "South Carolina",
  "US-SD": "South Dakota",
  "US-TN": "Tennessee",
  "US-TX": "Texas",
  "US-UT": "Utah",
  "US-VT": "Vermont",
  "US-VA": "Virginia",
  "US-WA": "Washington",
  "US-WV": "West Virginia",
  "US-WI": "Wisconsin",
  "US-WY": "Wyoming",
  "US-DC": "District of Columbia",
  "US-AS": "American Samoa",
  "US-GU": "Guam",
  "US-MP": "Northern Mariana Islands",
  "US-PR": "Puerto Rico",
  "US-UM": "United States Minor Outlying Islands",
  "US-VI": "U.S. Virgin Islands",
};

export const MIN_AGE = 18;
export const MAX_AGE = 99;
export const AGE_LIST: number[] = [...Array(MAX_AGE + 1 - MIN_AGE).keys()].map(
  x => x + MIN_AGE
);

export const ASTROLOGICAL_SIGN_LIST: IAstrologicalSign = {
  aquarius: "Aquarius",
  pisces: "Pisces",
  aries: "Aries",
  taurus: "Taurus",
  gemini: "Gemini",
  cancer: "Cancer",
  leo: "Leo",
  virgo: "Virgo",
  libra: "Libra",
  scorpio: "Scorpio",
  sagittarius: "Sagittarius",
  capricorn: "Capricorn",
};

export const BREAST_SIZE_LIST: IBreastSize = {
  small: "Small",
  average: "Average",
  large: "Large",
  huge: "Huge",
};

export const MARITAL_STATUS_LIST: IMaritalStatus = {
  single: "Single",
  married: "Married",
  divorced: "Divorced",
  widowed: "Widowed",
};

/*
	## Enabled Languages
	ARABIC
	CHINESE
	ENGLISH
	FRENCH
	GERMAN
	HINDI
	ITALIAN
	JAPANESE
	KOREAN
	PORTUGUESE
	RUSSIAN
	SPANISH
 */
export const LANGUAGE_LIST: ILanguage = {
  /*aar: "Afar",
	abk: "Abkhazian",
	afr: "Afrikaans",
	aka: "Akan",
	amh: "Amharic",*/
  ara: "Arabic",
  /*arg: "Aragonese",
	asm: "Assamese",
	ava: "Avaric",
	ave: "Avestan",
	aym: "Aymara",
	aze: "Azerbaijani",
	bak: "Bashkir",
	bam: "Bambara",
	bel: "Belarusian",
	ben: "Bengali",
	bis: "Bislama",
	bod: "Tibetan",
	bos: "Bosnian",
	bre: "Breton",
	bul: "Bulgarian",
	cat: "Catalan",
	ces: "Czech",
	cha: "Chamorro",
	che: "Chechen",
	chu: "Church Slavic",
	chv: "Chuvash",
	cor: "Cornish",
	cos: "Corsican",
	cre: "Cree",
	cym: "Welsh",
	dan: "Danish",*/
  deu: "German",
  /*div: "Dhivehi",
	dzo: "Dzongkha",
	ell: "Modern Greek (1453-)",*/
  eng: "English",
  /*epo: "Esperanto",
	est: "Estonian",
	eus: "Basque",
	ewe: "Ewe",
	fao: "Faroese",
	fas: "Persian",
	fij: "Fijian",
	fin: "Finnish",*/
  fra: "French",
  /*fry: "Western Frisian",
	ful: "Fulah",
	gla: "Scottish Gaelic",
	gle: "Irish",
	glg: "Galician",
	glv: "Manx",
	grn: "Guarani",
	guj: "Gujarati",
	hat: "Haitian",
	hau: "Hausa",
	hbs: "Serbo-Croatian",
	heb: "Hebrew",
	her: "Herero",*/
  hin: "Hindi",
  /*hmo: "Hiri Motu",
	hrv: "Croatian",
	hun: "Hungarian",
	hye: "Armenian",
	ibo: "Igbo",
	ido: "Ido",
	iii: "Sichuan Yi",
	iku: "Inuktitut",
	ile: "Interlingue",
	ina: "Interlingua (International Auxiliary Language Association)",
	ind: "Indonesian",
	ipk: "Inupiaq",
	isl: "Icelandic",*/
  ita: "Italian",
  /*jav: "Javanese",*/
  jpn: "Japanese",
  /*kal: "Kalaallisut",
	kan: "Kannada",
	kas: "Kashmiri",
	kat: "Georgian",
	kau: "Kanuri",
	kaz: "Kazakh",
	khm: "Central Khmer",
	kik: "Kikuyu",
	kin: "Kinyarwanda",
	kir: "Kirghiz",
	kom: "Komi",
	kon: "Kongo",*/
  kor: "Korean",
  /*kua: "Kuanyama",
	kur: "Kurdish",
	lao: "Lao",
	lat: "Latin",
	lav: "Latvian",
	lim: "Limburgan",
	lin: "Lingala",
	lit: "Lithuanian",
	ltz: "Luxembourgish",
	lub: "Luba-Katanga",
	lug: "Ganda",
	mah: "Marshallese",
	mal: "Malayalam",
	mar: "Marathi",
	mkd: "Macedonian",
	mlg: "Malagasy",
	mlt: "Maltese",
	mon: "Mongolian",
	mri: "Maori",
	msa: "Malay (macrolanguage)",
	mya: "Burmese",
	nau: "Nauru",
	nav: "Navajo",
	nbl: "South Ndebele",
	nde: "North Ndebele",
	ndo: "Ndonga",
	nep: "Nepali (macrolanguage)",
	nld: "Dutch",
	nno: "Norwegian Nynorsk",
	nob: "Norwegian Bokmål",
	nor: "Norwegian",
	nya: "Nyanja",
	oci: "Occitan (post 1500)",
	oji: "Ojibwa",
	ori: "Oriya (macrolanguage)",
	orm: "Oromo",
	oss: "Ossetian",
	pan: "Panjabi",
	pli: "Pali",
	pol: "Polish",*/
  por: "Portuguese",
  /*pus: "Pushto",
	que: "Quechua",
	roh: "Romansh",
	ron: "Romanian",
	run: "Rundi",*/
  rus: "Russian",
  /*sag: "Sango",
	san: "Sanskrit",
	sin: "Sinhala",
	slk: "Slovak",
	slv: "Slovenian",
	sme: "Northern Sami",
	smo: "Samoan",
	sna: "Shona",
	snd: "Sindhi",
	som: "Somali",
	sot: "Southern Sotho",*/
  spa: "Spanish",
  /*sqi: "Albanian",
	srd: "Sardinian",
	srp: "Serbian",
	ssw: "Swati",
	sun: "Sundanese",
	swa: "Swahili (macrolanguage)",
	swe: "Swedish",
	tah: "Tahitian",
	tam: "Tamil",
	tat: "Tatar",
	tel: "Telugu",
	tgk: "Tajik",
	tgl: "Tagalog",
	tha: "Thai",
	tir: "Tigrinya",
	ton: "Tonga (Tonga Islands)",
	tsn: "Tswana",
	tso: "Tsonga",
	tuk: "Turkmen",
	tur: "Turkish",
	twi: "Twi",
	uig: "Uighur",
	ukr: "Ukrainian",
	urd: "Urdu",
	uzb: "Uzbek",
	ven: "Venda",
	vie: "Vietnamese",
	vol: "Volapük",
	wln: "Walloon",
	wol: "Wolof",
	xho: "Xhosa",
	yid: "Yiddish",
	yor: "Yoruba",
	zha: "Zhuang",*/
  zho: "Chinese",
  /*zul: "Zulu",*/
};

export const EYE_COLOR_LIST: IEyeColor = {
  black: "Black",
  blue: "Blue",
  brown: "Brown",
  green: "Green",
  gray: "Gray",
  hazel: "Hazel",
  violet: "Violet",
};

export const HAIR_COLOR_LIST: IHairColor = {
  black: "Black",
  blonde: "Blonde",
  brown: "Brown",
  gray: "Gray",
  red: "Red",
  white: "White",
};

export const PENIS_SIZE_LIST: IPenisSize = {
  small: "Small",
  average: "Average",
  large: "Large",
  huge: "Huge",
};

export const ETHNICITY_LIST: IEthnicity = {
  asian: "Asian",
  ebony: "Ebony",
  latin: "Latin",
  white: "White",
  other: "Other",
};

export const FEMALE_SEXUAL_ORIENTATION_LIST: ISexualOrientation = {
  straight: "Straight",
  lesbian: "Lesbian",
  bisexual: "Bisexual",
};

export const MALE_SEXUAL_ORIENTATION_LIST: ISexualOrientation = {
  straight: "Straight",
  gay: "Gay",
  bisexual: "Bisexual",
};

export const TRANS_SEXUAL_ORIENTATION_LIST: ISexualOrientation = {
  straight: "Straight",
  lesbian: "Lesbian",
  gay: "Gay",
  bisexual: "Bisexual",
};

export const FEMALE_BODY_BUILD_LIST: IBodyType = {
  slim: "Slim",
  athletic: "Athletic",
  average: "Average",
  curvy: "Curvy",
  voluptuous: "Voluptuous",
};

export const MALE_BODY_BUILD_LIST: IBuildSize = {
  slim: "Slim",
  athletic: "Athletic",
  average: "Average",
  stocky: "Stocky",
  chubby: "Chubby",
};

export const TRANS_BODY_BUILD_LIST: IBuildSize = {
  slim: "Slim",
  athletic: "Athletic",
  average: "Average",
  stocky: "Stocky",
  chubby: "Chubby",
  voluptuous: "Voluptuous",
};

export const ORDINAL_NUMBERS = {
  0: "th",
  1: "st",
  2: "nd",
  3: "rd",
  4: "th",
  5: "th",
  6: "th",
  7: "th",
  8: "th",
  9: "th",
};

export const CONTACT_MODAL_KEYS = {
  "help.support.emailUs": "email",
  "help.support.liveChatSupport": "contact",
};

export const DAY_LIST = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

export const MONTH_LIST = {
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",
};

export const US_DOCUMENT_TYPES = {
  drivers_id: "Driver's License",
  state_id: "State ID",
  passport: "US Passport",
  green_card: "Residency Card (Green Card)",
  us_visa: "US Visa",
  us_military_id: "US Military ID (Front and Back)",
};

export const DOCUMENT_TYPES = {
  national_id: "National ID (Front and Back)",
  international_passport: "International Passport",
  domestic_passport: "Domestic Passport",
  drivers_license: "Drivers Licence",
};

export const BANNED_WORDS = [
  "chink",
  "chino",
  "coon",
  "gringo",
  "honky",
  "honkie",
  "jigaboo",
  "nigger",
  "nigga",
  "nazi",
  "polock",
  "punjab",
  "redneck",
  "anal",
  "anus",
  "arse",
  "bastard",
  "bitch",
  "clit",
  "cunt",
  "dick",
  "dyke",
  "faggot",
  "fagit",
  "queer",
  "fuck",
  "jackoff",
  "jerkoff",
  "puss",
  "prick",
  "scat",
  "shit",
  "twat",
  "whore",
  "arschloch",
  "wixer",
  "schwanz",
  "muschi",
  "spritzer",
  "schlampe",
  "neger",
  "nutte",
  "hure",
  "sahne",
  "busen",
  "blasen",
  "sperma",
  "porno",
  "champagner",
  "duschen",
  "pisse",
  "scheisse",
  "kaviar",
  "schlecken",
  "polla",
  "joder",
  "coger",
  "follar",
  "maricon",
  "guarro",
  "gilipollas",
  "mierda",
  "guarra",
  "cerda",
  "pendejo",
  "chingar",
  "cabron",
  "gabacho",
  "facha",
  "mayate",
  "pinche",
  "facho",
  "bougnoule",
  "youpin",
  "pisser",
  "rital",
  "salope",
  "putain",
  "gouine",
  "merde",
  "chier",
  "couille",
  "fils de pute",
  "couple",
  "ssipal",
  "ddalddalee",
  "jaji",
  "boji",
  "il bonnom",
  "waenom",
  "kkamdoongee",
  "jotna",
  "saekki",
  "mikuknom",
  "ssibal",
  "tarantado",
  "pookie",
  "bayag",
  "pekpek",
  "kantot",
  "bilyas",
  "malibog",
  "tanga",
  "pachiching",
  "patotoy",
  "admin",
  "streamray",
  "cams",
  "420",
  "chronic",
  "weed",
  "pothead",
  "marijuauna",
  "escort",
  "GFE",
  "minor",
  "yahoo",
  "yah00",
  "yaho0",
  "yah0o",
  "yhoo",
  "yh0o",
  "yho0",
  "yh00",
  "gmal",
  "gma1",
  "gmail",
  "gmai1",
  "htmail",
  "hotmail",
  "h0tmail",
  "hotmai1",
  "h0tmai1",
  "hotmaiI",
  "h0tmaiI",
  "hotmal",
  "h0tmal",
  "hotma1",
  "h0tma1",
  "dotcom",
  "0com",
  "lolita",
  "teen",
  "baby",
  "child",
  "incest",
  "molest",
  "underage",
  "infant",
  "toddler",
  "kid",
  "kiddie",
];

export const BannedWordsRegex = new RegExp(BANNED_WORDS.join("|"), "i");

// the values for LVS sites may change when someone from the management adds a new one or removes an existing one
// a proper BE solution needs to be implemented for this
export const LVS_SITES = [
  "739cams",
  "adultsearch",
  "bdsm",
  "bondage",
  "cams",
  "cams3d",
  "camsnetwork",
  "capriholding",
  "capriholdings",
  "cclub",
  "davedev",
  "dev",
  "empflix",
  "fcreations",
  "ffadult",
  "ffc",
  "flix",
  "francolive",
  "gd",
  "getiton",
  "globalpersonal",
  "hustler",
  "icams",
  "iconpal",
  "kiosk",
  "lavish",
  "lfc",
  "livecamdesire",
  "livecamshows",
  "mansion_test",
  "metrobabes",
  "mlmediaprod",
  "moviefap",
  "nakedandlive",
  "netaffair",
  "nscash",
  "out",
  "payg",
  "penthouse",
  "phletters",
  "pinktv",
  "pinktv_test",
  "pixelsofa",
  "playpen",
  "rude",
  "rude_dev",
  "rude_dev2",
  "tnaflix",
  "upforit",
  "upforit_dev",
  "upforit_dev2",
  "vsxlive",
  "webquest",
  "xvhub",
  "yarr",
];
