import React, { useCallback, useEffect, useMemo, useState } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import BroadcastStore from "common/broadcast/_stores/broadcast/BroadcastStore";
import { inject, observer } from "mobx-react";
import TailwindCheckboxRadio from "library/components/_tailwind/checkbox-radio";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import TailwindButton from "library/components/_tailwind/button";
import TailwindInput from "library/components/_tailwind/input";
import { api } from "core/utility";
import TailwindList, {
  TailwindListType,
} from "library/components/_tailwind/list";
import TailwindListItem from "library/components/_tailwind/list/list-item";
import { Icon, IconButton } from "@material-ui/core";

import ValidationStore from "library/core/stores/validation/ValidationStore";
import LanguageStore from "library/core/stores/language/LanguageStore";

type Props = {
  profileStore?: ProfileStore;
  broadcastStore?: BroadcastStore;
  validationStore?: ValidationStore;
  languageStore?: LanguageStore;
};

const BroadcastAloneOrWithGuestsSelector: React.ComponentType<Props> = ({
  profileStore,
  broadcastStore,
  validationStore,
  languageStore,
}) => {
  const { intl } = languageStore!;
  const { errors, validate, clearErrors, clearError, setErrorMessage } =
    validationStore!;
  const { modelProfile } = profileStore!;
  const {
    setGuestProfilesInCurrentSession: setGuestCredentials,
    guestProfilesInCurrentSession: guestCredentials,
    setIsBroadcastingAlone,
    isBroadcastingAlone,
    fetchGuestProfilesInCurrentSession,
    removeGuestProfile,
    setIsBroadcastingModeSelected,
    isBroadcastingWithGuestsDataProcessing,
  } = broadcastStore!;

  // do not set this in BroadcastStore until they click "Broadcast Now!"
  const [guestUsername, setGuestUsername] = useState<string>("");
  const [guestPassword, setGuestPassword] = useState<string>("");
  const [isSubmittingGuestCredentials, setIsSubmittingGuestCredentials] =
    useState<boolean>(false);
  const [currentModelRequestError, setCurrentModelRequestError] = useState<
    string[]
  >([]);

  const isBroadcastNowButtonDisabled = useMemo(() => {
    return (
      isBroadcastingWithGuestsDataProcessing ||
      (!isBroadcastingAlone && guestCredentials.length === 0)
    );
  }, [
    guestCredentials,
    isBroadcastingAlone,
    isBroadcastingWithGuestsDataProcessing,
  ]);

  const validateEmailOrUsername = (emailOrUsername: string) => {
    const isSelf =
      emailOrUsername.toLowerCase() ===
        modelProfile.screen_name.toLowerCase() ||
      emailOrUsername.toLowerCase() === modelProfile.email.toLowerCase();
    const modelAlreadyAGuest = guestCredentials.find(
      c => c.name === emailOrUsername
    );
    if (isSelf) {
      setErrorMessage(
        "emailOrUsername",
        false,
        true,
        undefined,
        "Cannot add self"
      );
    }
    if (modelAlreadyAGuest) {
      setErrorMessage(
        "emailOrUsername",
        false,
        true,
        undefined,
        "Model is already a guest"
      );
    }
  };

  const onChangeGuestUsername = useCallback(
    event => {
      clearError("emailOrUsername");
      setCurrentModelRequestError([]);
      const emailOrUsername = event.target.value;
      setGuestUsername(emailOrUsername);
      validateEmailOrUsername(emailOrUsername);
    },
    [guestCredentials]
  );

  const onChangeGuestPassword = useCallback(event => {
    clearError("password_login");
    setCurrentModelRequestError([]);
    setGuestPassword(event.target.value);
  }, []);

  const isGuestSubmitButtonDisabled = useMemo(() => {
    return (
      !!errors?.emailOrUsername ||
      !!errors?.password_login ||
      !!errors?.password ||
      isSubmittingGuestCredentials ||
      guestUsername.trim() === "" ||
      guestPassword.trim() === ""
    );
  }, [guestUsername, guestPassword, isSubmittingGuestCredentials, errors]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    clearErrors();
    if (!Object.keys(errors).length) {
      try {
        try {
          setIsSubmittingGuestCredentials(true);
          const res = await api.broadcastGuestModelConfirmation.post({
            guest_model_username: guestUsername,
            guest_model_password: guestPassword,
          });
          if (res && res.data) {
            setGuestCredentials([
              ...guestCredentials,
              {
                id: res.data.id,
                name: res.data.guest_model_screen_name,
              },
            ]);
            clearErrors();
            setCurrentModelRequestError([]);
            setGuestUsername("");
            setGuestPassword("");
          }
        } catch (error) {
          setCurrentModelRequestError(
            error?.response?.data?.non_field_errors || []
          );
        } finally {
          setIsSubmittingGuestCredentials(false);
        }
      } catch (error) {
        // do not remove as this is used to fallback to v2
        throw new Error(error);
      }
    }
  };

  const onClickBroadcastNow = () => {
    setIsBroadcastingModeSelected(true);
  };

  useEffect(() => {
    clearErrors();
    fetchGuestProfilesInCurrentSession();
  }, []);

  return (
    <TailwindFlex
      height={"h-full"}
      width={"w-full"}
      flexDirection={"flex-col"}
      justifyContent={isBroadcastingAlone ? "justify-center" : "justify-start"}
      pseudoClasses={['xs:px-4']}
      alignItems={"items-center"}>
      <TailwindFlex
        width={"w-full"}
        maxWidth={"max-w-3xl"}
        alignItems={"items-center"}
        flexDirection={"flex-col"}
        margin={["mx-6"]}>
        <TailwindText
          fontSize={"text-2xl"}
          margin={["mb-2"]}
          fontWeight={"font-bold"}
          textAlign={"text-center"}>
          {modelProfile.screen_name}
        </TailwindText>
        <TailwindText
          margin={["mb-12"]}
          textAlign={"text-center"}
          fontSize={"text-sm"}
          textColor={"text-red-500"}>
          {intl.formatMessage({
            id: "broadcast.broadcast-guest-warning",
            defaultMessage: "Warning: The primary model has to perform in the stream at ALL TIMES. If you have guest models appearing, please make sure you are also in the broadcast.",
          })}
        </TailwindText>
        <TailwindFlex
          margin={["mb-4"]}
          flexDirection={"flex-col"}
          justifyContent={"justify-center"}>
          <TailwindCheckboxRadio
            borderColor='border-main-color'
            type={TailwindInputType.radio}
            isChecked={isBroadcastingAlone}
            onChange={() => setIsBroadcastingAlone(true)}
            boxProps={{
              margin: ["mr-2", "mb-4"],
            }}
            label={intl.formatMessage({
              id: "broadcast.broadcast-alone",
              defaultMessage: "Broadcast Alone"
            })}
          />
          <TailwindCheckboxRadio
            borderColor='border-main-color'
            type={TailwindInputType.radio}
            isChecked={!isBroadcastingAlone}
            onChange={() => setIsBroadcastingAlone(false)}
            boxProps={{
              margin: ["mr-2"],
            }}
            label={intl.formatMessage({
              id: "broadcast.broadcast-guests",
              defaultMessage: "Broadcast with Guest(s)"
            })}
          />
        </TailwindFlex>
        {!isBroadcastingAlone && (
          <form className={"flex mb-5 gap-2 w-full xs:px-4 xs:flex-col xs:items-center"} onSubmit={handleSubmit}>
            {/* Don't remove to prevent chrome autofill */}
            <input
              placeholder="Username"
              name='username'
              style={{ visibility: "hidden", width: 0, height: 0 }}
            />
            <input placeholder="Password" name="username" type="hidden"/>
            <TailwindInput
              value={guestUsername}
              onChange={onChangeGuestUsername}
              backgroundColor={"primary"}
              borderColor={"border-gray-300"}
              borderWidth={["border"]}
              placeholder={"Guest Stream Name or Email"}
              error={errors?.emailOrUsername || undefined}
              onBlur={_e => validateEmailOrUsername(guestUsername)}
              autoComplete={"off"}
            />
            {/* Don't remove to prevent chrome autofill */}
            <input
              placeholder="Password"
              type='password'
              style={{ visibility: "hidden", width: 0, height: 0 }}
            />
            <input placeholder="Password" name='password' type="hidden"/>
            <TailwindInput
              value={guestPassword}
              onChange={onChangeGuestPassword}
              backgroundColor={"primary"}
              borderColor={"border-gray-300"}
              borderWidth={["border"]}
              placeholder={"Guest Password"}
              type={TailwindInputType.password}
              error={errors?.password_login || errors?.password || undefined}
              onBlur={_e => validate("password_login", guestPassword)}
              autoComplete={"off"}
            />
            <TailwindButton
              fullWidth={false}
              backgroundColor={"primary"}
              disabled={isGuestSubmitButtonDisabled}
              settings={true}
              type={"submit"}
              justifyContent="justify-center"
              showSpinner={isSubmittingGuestCredentials}
              pseudoClasses={['xs:w-36', 'xs:mt-5']}
              className={[`text-align-center`]}
              disabledProps={{
                backgroundColor: "bg-gray-300",
                textColor: "text-white",
              }}>
              {intl.formatMessage({
                id: "common.submit",
                defaultMessage: "Submit"
              })}
            </TailwindButton>
          </form>
        )}
        {!isBroadcastingAlone && currentModelRequestError.length > 0 && (
          <TailwindFlex margin={["mt-2"]}>
            {currentModelRequestError.map((error, index) => (
              <TailwindText key={index} textColor={"text-red-500"}>
                {error}
              </TailwindText>
            ))}
          </TailwindFlex>
        )}
        {!isBroadcastingAlone && guestCredentials.length > 0 && (
          <TailwindFlex margin={["mb-8", "mt-10"]} flexDirection={"flex-col"}>
            <TailwindFlex fontWeight={"font-bold"}>
              {`Verified Guest(s):`}
            </TailwindFlex>
            <TailwindList type={TailwindListType.ol} margin={["ml-4"]}>
              {guestCredentials.map(credential => (
                <TailwindListItem
                  key={credential.id}
                  textColor={"text-green-500"}>
                  {credential.name}

                  <IconButton
                    onClick={() => {
                      removeGuestProfile(credential);
                    }}>
                    <Icon>close</Icon>
                  </IconButton>
                </TailwindListItem>
              ))}
            </TailwindList>
          </TailwindFlex>
        )}
        {!isBroadcastingAlone && (
          <TailwindFlex
            alignItems='items-start'
            borderColor={"border-gray-300"}
            borderWidth={["border"]}
            padding={["p-4"]}>
            <TailwindText fontSize={"text-sm"}>
              By clicking “Broadcast Now!,” you agree to the following terms:
              <br />
              This invitation is an agreement between the inviting model, hereby
              referred to as “Primary Model,” and the recipient, hereby referred
              to as “Guest Model.” The Primary Model assumes all responsibility
              over the behavior of the guest, as well as distribution of any
              promised compensation earned during the live broadcast or recorded
              shows taken during the broadcast. Should any agreement be made to
              share earnings or otherwise compensate the guest, Streamray.com
              shall be held harmless and will not enforce or take action
              regarding breach of any agreement made between models.
            </TailwindText>
          </TailwindFlex>
        )}
        <TailwindFlex
          justifyContent={"justify-center"}
          margin={["mt-10", "mb-20"]}>
          <TailwindButton
            // disabledProps={{
            //   backgroundColor: "bg-gray-300",
            //   textColor: "text-white",
            // }}
            settings={true}
            backgroundColor={"secondary"}
            fullWidth={false}
            onClick={onClickBroadcastNow}
            disabled={isBroadcastNowButtonDisabled}>
            {intl.formatMessage({
              id: 'broadcast.broadcast-now',
              defaultMessage: "Broadcast now!"
            })}
          </TailwindButton>
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default inject(
  "profileStore",
  "broadcastStore",
  "validationStore",
  "languageStore"
)(observer(BroadcastAloneOrWithGuestsSelector));
