import React, { useCallback, useMemo, useState } from "react";
import ChatItemUsernameTooltip from "common/broadcast/broadcast-chat/chat-item-username-tooltip";
import { IChatMessage } from "common/broadcast/_stores/chat/interfaces";
import ChatStore from "common/broadcast/_stores/chat/ChatStore";
import { inject, observer } from "mobx-react";
import TailwindBox from "library/components/_tailwind/box";
import TailwindIcon from "library/components/_tailwind/icon";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import TailwindText from "library/components/_tailwind/text";
import TailwindFlex from "library/components/_tailwind/flex";
import LanguageStore from "library/core/stores/language/LanguageStore";

type Props = {
  message: IChatMessage;
  isTabModeActive?: boolean;
  onWhisperBack?: (username: string) => void;
  chatStore?: ChatStore;
  languageStore?: LanguageStore;
};

const ChatMessageText: React.ComponentType<Props> = ({
  message,
  isTabModeActive = false,
  onWhisperBack,
  chatStore,
  languageStore
}) => {
  const { chatFontSize: fontSize } = chatStore!;
  const { intl } = languageStore!;
  const [userNameColor, setUserNameColor] = useState<string>("");

  const cleanUsername = useMemo(() => {
    if (message.from) {
      return message.from.split(/\+|@/)[0];
    }
  }, [message?.from]);

  const cleanTranslation = useMemo(() => {
    if (message?.text?.includes("(auto-translated)")) {
      return message.text.replace("(auto-translated)", "");
    }
    return message.text;
  }, [message?.text]);

  const handleWhisperBackClick = useCallback(() => {
    if (!isTabModeActive) {
      onWhisperBack?.(cleanUsername ? cleanUsername : message?.from);
    }
  }, [isTabModeActive, cleanUsername, message]);

  const meText = useMemo(() => {
    if (!isTabModeActive) {
      return intl.formatMessage(
        {
          id: "broadcast.chatItem.to_user",
          defaultMessage: "to {username}",
        },
        {
          username: message?.to,
        }
      );
    } else {
      return intl.formatMessage({
        id: "broadcast.chatItem.me",
        defaultMessage: "Me",
      });
    }
  }, []);

  return (
    <div
      className={`ChatItem ${message.isAdmin ? "ChatItem--admin" : ""} ${
        message.isModel ? "ChatItem__from--model" : ""
      }`}
      style={
        !isTabModeActive ? { fontSize, fontStyle: "italic" } : { fontSize }
      }>
      <TailwindFlex>
        {!isTabModeActive ? (
          <TailwindFlex
            width={"w-auto"}
            alignItems={"items-center"}
            justifyContent={"justify-center"}>
            <TailwindIcon
              name={CustomIconName.whisper}
              margin={["m-0"]}
              style={{ maxWidth: "20px", color: userNameColor }}
            />
          </TailwindFlex>
        ) : null}
        <TailwindFlex
          width={"w-auto"}
          flexShrink={"flex-shrink-0"}
          alignItems={"items-center"}
          justifyContent={"justify-center"}>
          <ChatItemUsernameTooltip
            userId={message.userId}
            chatTooltip={true}
            onUserTierColor={color => {
              if (!message.isModel) {
                setUserNameColor(color);
              }
            }}>
            <span
              data-testid={"chat-message-text"}
              className={`mr-1 ChatItem__from ${
                message.isModel ? "ChatItem__from--model" : ""
              }`}>
              {message.isAdmin && `[Admin]`}
              {message.isModel
                ? meText
                : cleanUsername
                ? cleanUsername
                : message.from}
              :
            </span>
          </ChatItemUsernameTooltip>
        </TailwindFlex>
        <TailwindFlex>
          <TailwindBox
            as={"span"}
            wordBreak={"break-all"}
            style={{
              color: message.isSentSuccessfully
                ? "var(--text-main-color)"
                : "#6b7280",
            }}>
            {cleanTranslation}
            {!message.isModel && !isTabModeActive ? (
              <TailwindText
                margin={["ml-1"]}
                cursor={"cursor-pointer"}
                style={{ color: userNameColor }}
                onClick={handleWhisperBackClick}>
                {
                  <span>
                    (
                    {intl.formatMessage({
                      id: "broadcast.chatItem.whisperback",
                      defaultMessage: "whisper back",
                    })}
                    )
                  </span>
                }
              </TailwindText>
            ) : null}
          </TailwindBox>
        </TailwindFlex>
      </TailwindFlex>
      {/* Do not refactor to TailwindText as we want the parent's font-size to take effect */}
    </div>
  );
};

export default inject(
  "chatStore",
  "languageStore"
)(observer(ChatMessageText));
