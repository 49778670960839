import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import TailwindButton, { TailwindButtonProps } from "../button";
import TailwindBox from "../box";
import {
  TBackgroundColor,
  TBorderRadius,
  TPseudoClasses,
} from "../../../styles/tailwind-classnames";
import TailwindCheckboxRadio from "../checkbox-radio";
import { TailwindInputType } from "../input-base";
import TailwindIcon, { TailwindIconProps } from "../icon";
import TailwindPill, { TailwindPillProps } from "../pill";
import TailwindText, { TailwindTextProps } from "../text";
import { PrimitiveType } from "../../../core/types";
import TailwindFlex from "../flex";
import { inject, observer } from "mobx-react";
import ThemeStore from "../../../core/stores/theme/ThemeStore";
import TailwindMenu, { TailwindMenuProps } from "../menu";
import { getThemeColors } from "./utilities";
import TailwindInput from "../input";

export type TailwindDropdownItem = {
  label: string;
  value: any;
  leftIconProps?: TailwindIconProps;
  rightIconProps?: TailwindIconProps;
  disabled?: boolean;
};

export type TailwindDropdownValue = PrimitiveType | PrimitiveType[];

export type TailwindDropdownBackgroundColor =
  | "primary"
  | "secondary"
  | "transparent"
  | TBackgroundColor;

export type TailwindDropdownProps = Omit<
  TailwindMenuProps,
  | "isShowingMenuOnTop"
  | "setIsShowingMenuOnTop"
  | "isCollapsed"
  | "setIsCollapsed"
  | "containerRef"
  | "buttonRef"
> & {
  dataTestId?: string;
  isLoading?: boolean;
  items: TailwindDropdownItem[];
  value: TailwindDropdownValue;
  itemLabelProps?: TailwindTextProps;
  direction?: "top" | "bottom";
  focusBorderColor?: TPseudoClasses;
  backgroundColor?: TailwindDropdownBackgroundColor;
  itemHoverBackgroundColor?: TPseudoClasses;
  onChange?: (item: TailwindDropdownValue) => void;
  name: string;
  fullHeight?: boolean;
  multipleProps?: {
    placeholder: string;
    labelBadgeProps?: TailwindPillProps;
  };
  label?: string;
  labelPosition?: "top" | "left";
  labelProps?: TailwindTextProps;
  sublabel?: string;
  subLabelProps?: TailwindTextProps;
  warning?: string;
  warningProps?: TailwindTextProps;
  error?: string;
  errorProps?: TailwindTextProps;
  themeStore?: ThemeStore;
  selectedItemButtonProps?: TailwindButtonProps;
  showSearch?: boolean;
  placeholder?: string | number;
  disabled?: boolean;
  thin?: boolean;
  menuProps?: Omit<
    TailwindMenuProps,
    | "isCollapsed"
    | "setIsCollapsed"
    | "shape"
    | "containerRef"
    | "buttonRef"
    | "fullWidth"
    | "isShowingMenuOnTop"
    | "setIsShowingMenuOnTop"
  >;
};

const TailwindDropdown: React.ComponentType<TailwindDropdownProps> = ({
  dataTestId,
  isLoading = false,
  items,
  value,
  itemLabelProps,
  label,
  labelProps,
  labelPosition,
  sublabel,
  subLabelProps,
  direction,
  focusBorderColor,
  borderWidth,
  borderColor,
  backgroundColor,
  itemHoverBackgroundColor,
  shape,
  onChange,
  name,
  fullHeight = false,
  fullWidth = false,
  multipleProps,
  error,
  errorProps,
  warning,
  warningProps,
  placeholder,
  disabled,
  themeStore,
  menuProps,
  width,
  selectedItemButtonProps,
  showSearch = false,
  thin,
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { currentThemePalette } = themeStore!;

  const containerId = `dropdown-${name}`;
  const buttonId = `${containerId}-button`;

  const getInitialSelectedItems = (value: TailwindDropdownValue) => {
    const val = (
      Array.isArray(value) && value.length > 0
        ? value
        : !Array.isArray(value) && value !== undefined && value !== null
        ? [value]
        : []
    ) as PrimitiveType[];

    return val;
  };
  const containerRef = useRef<HTMLDivElement>(null);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [isShowingMenuOnTop, setIsShowingMenuOnTop] = useState<boolean>(false);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [selectedItems, setSelectedItems] = useState<PrimitiveType[]>([]);

  const onClickToggleMenu = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  const selectedItemLabel = useMemo(() => {
    if (multipleProps?.placeholder) {
      return multipleProps?.placeholder;
    }

    const firstItem = items.find(item => item.value === selectedItems[0]);
    return firstItem?.label;
  }, [selectedItems, items, multipleProps]);

  const onClickItem = useCallback(
    (item: TailwindDropdownItem) => {
      let items;
      if (multipleProps) {
        items = selectedItems.includes(item.value)
          ? selectedItems.filter(value => value !== item.value)
          : [...selectedItems, item.value];
      } else {
        items = [item.value];
      }
      setSelectedItems(items);
      if (onChange) {
        if (multipleProps) {
          onChange(items);
        } else {
          onChange(item.value);
        }
      }

      if (!multipleProps) {
        setIsCollapsed(true);
      }
    },
    [onChange, selectedItems, multipleProps]
  );

  const _borderRadius = useMemo(() => {
    if (shape === "rounded") {
      return ["rounded-3xl"];
    }

    return currentThemePalette?.["input-border-radius-tw-class"];
  }, [shape, isCollapsed]);

  const themeColors = useMemo(() => {
    const colors = getThemeColors(
      backgroundColor,
      currentThemePalette,
      itemLabelProps?.textColor || "text-main-color"
    );
    if (error) {
      colors.textColor = "text-red-500";
      colors.focusButtonBorderColor = "border-red-500";
    } else if (warning) {
      colors.textColor = "text-yellow-500";
      colors.focusButtonBorderColor = "border-yellow-500";
    }

    return colors;
  }, [backgroundColor, itemLabelProps, error, warning]);

  useEffect(() => {
    setSelectedItems(getInitialSelectedItems(value));
  }, [value]);

  const buttonHeight = buttonRef.current?.getBoundingClientRect().height || 0;

  const _width = useMemo(
    () => (fullWidth ? "w-full" : width || "w-52"),
    [fullWidth, width]
  );

  const filteredItems = useMemo(() => {
    return items.filter(item =>
      item.label?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
  }, [items, searchTerm]);

  const onChangeSearchTerm = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    },
    []
  );

  return (
    <TailwindFlex
      width={"w-full"}
      flexDirection={labelPosition === "left" ? "flex-row" : "flex-col"}
      alignItems={props.alignItems ? props.alignItems : "items-center"}>
      {label && (
        <TailwindFlex
          width={labelPosition === "left" ? "w-auto" : "w-full"}
          margin={labelPosition === "left" ? ["mr-4"] : undefined}>
          <TailwindText
            {...labelProps}
            as={"label"}
            htmlFor={name}
            margin={[...(labelProps?.margin || ["mb-1"])]}
            fontSize={labelProps?.fontSize || "text-base"}
            display={"flex"}
            textColor={labelProps?.textColor || "text-main-color"}
            fontWeight={labelProps?.fontWeight || "font-bold"}>
            {label}
          </TailwindText>
        </TailwindFlex>
      )}
      {sublabel && (
        <TailwindFlex
          width={labelPosition === "left" ? "w-auto" : "w-full"}
          margin={labelPosition === "left" ? ["mr-4"] : undefined}>
          <TailwindText
            {...subLabelProps}
            margin={[...(subLabelProps?.margin || ["mb-1"])]}
            fontSize={"text-base"}
            display={"flex"}
            textColor={subLabelProps?.textColor || "text-main-color"}>
            {sublabel}
          </TailwindText>
        </TailwindFlex>
      )}
      <TailwindBox
        {...props}
        ref={containerRef}
        position={"relative"}
        width={_width}
        borderColor={borderColor || "border-transparent"}
        height={fullHeight ? undefined : thin? "h-6" : "h-10"}
        display={fullHeight ? "flex" : undefined}
        flexGrow={fullHeight ? "flex-grow" : undefined}>
        <TailwindButton
          {...selectedItemButtonProps}
          data-testid={dataTestId}
          data-is-enabled={!disabled}
          ref={buttonRef}
          id={buttonId}
          rightIconProps={
            isLoading
              ? {
                  name: "spinner",
                  iconProps: {
                    size: 1,
                    color: disabled
                      ? {
                          bg: "white",
                          accent: "white",
                        }
                      : undefined,
                  },
                }
              : {
                  name: isCollapsed
                    ? "keyboard_arrow_down"
                    : "keyboard_arrow_up",
                  fontSize: "text-lg",
                }
          }
          width={_width}
          borderRadius={_borderRadius as TBorderRadius[]}
          borderWidth={[...(borderWidth || ["border-2"])]}
          borderColor={borderColor || "border-transparent"}
          pseudoClasses={[
            "focus:outline-none",
            !isCollapsed
              ? (themeColors.focusButtonBorderColor as any)
              : undefined,
          ]}
          rounded={shape === "rounded"}
          display={"flex"}
          height={fullHeight ? "h-full" : thin ? "h-6" : "h-10"}
          alignItems={"items-center"}
          justifyContent={"justify-between"}
          backgroundColor={
            selectedItemButtonProps?.backgroundColor ||
            themeColors.backgroundColor
          }
          padding={["px-3", "py-1"]}
          position={"absolute"}
          inset={["top-0", "left-0"]}
          style={{
            zIndex: 1,
          }}
          textProps={{
            fontSize: "text-sm",
            fontWeight: "font-bold",
          }}
          disabled={disabled}
          onClick={onClickToggleMenu}>
          {selectedItemLabel || placeholder}
          {!selectedItemLabel && "Select one"}
          {multipleProps && selectedItems.length > 0 && (
            <TailwindPill
              {...multipleProps?.labelBadgeProps}
              backgroundColor={"bg-secondary-bg-color"}
              textColor={"text-main-color"}
              margin={["ml-2"]}>
              {selectedItems.length}
            </TailwindPill>
          )}
        </TailwindButton>
        <TailwindBox
          position={"absolute"}
          width={_width}
          backgroundColor={themeColors.backgroundColor}
          visibility={isCollapsed ? "invisible" : "visible"}
          zIndex={"z-0"}
          style={{
            top: `${isShowingMenuOnTop ? 0 : buttonHeight / 2}px`,
            height: `${buttonHeight / 2}px`,
          }}
        />
        <TailwindMenu
          {...menuProps}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          setIsShowingMenuOnTop={setIsShowingMenuOnTop}
          buttonRef={buttonRef}
          shape={shape}
          fullWidth={fullWidth}
          width={_width}
          isShowingMenuOnTop={isShowingMenuOnTop}
          backgroundColor={backgroundColor}
          direction={direction}
          borderWidth={[
            ...(borderWidth || [
              "border-l-2",
              isShowingMenuOnTop ? "border-t-2" : "border-b-2",
              "border-r-2",
            ]),
          ]}
          borderColor={
            borderColor ? borderColor : themeColors.focusButtonBorderColor
          }>
          {showSearch && (
            <TailwindInput
              type={TailwindInputType.search}
              leftIconProps={{
                name: "search",
                textColor: "text-gray-400",
                fontSize: "text-sm",
              }}
              fontSize={"text-sm"}
              placeholder={"Search"}
              backgroundColor={backgroundColor}
              onChange={onChangeSearchTerm}
              value={searchTerm}
            />
          )}

          {filteredItems.map(item => (
            <TailwindButton
              leftIconProps={item.leftIconProps}
              rightIconProps={item.rightIconProps}
              disabled={item.disabled}
              width={"w-full"}
              display={"flex"}
              alignItems={"items-center"}
              justifyContent={"justify-start"}
              rounded={shape === "rounded"}
              pseudoClasses={[
                "focus:outline-none",
                "xs:py-1",
                "xs:px-1",
                itemHoverBackgroundColor as any,
              ]}
              padding={["py-2", "pr-3"]}
              textProps={{
                as: "div",
                width: "w-full",
                fontSize: "text-sm",
                fontWeight: "font-bold",
                display: "flex",
                alignItems: "items-center",
                textTransform: "capitalize",
              }}
              key={`dropdown-${name}-${item?.value}`}
              onClick={() => onClickItem(item)}>
              <TailwindBox width={"w-6"} display={"inline-flex"}>
                {multipleProps ? (
                  <TailwindCheckboxRadio
                    type={TailwindInputType.checkbox}
                    isChecked={selectedItems.includes(item.value)}
                    borderColor={themeColors.multipleCheckboxBorderColor}
                  />
                ) : selectedItems.includes(item.value) ? (
                  <TailwindIcon
                    name={"adjust"}
                    margin={["mr-2"]}
                    textColor={itemLabelProps?.textColor || "text-main-color"}
                  />
                ) : (
                  <TailwindBox width={"w-10"}>&nbsp;</TailwindBox> //add a space to fake a radio button so text can align
                )}
              </TailwindBox>
              <TailwindText
                {...itemLabelProps}
                fontSize={"text-sm"}
                textColor={itemLabelProps?.textColor || "text-main-color"}>
                {item?.label}
              </TailwindText>
            </TailwindButton>
          ))}
        </TailwindMenu>
      </TailwindBox>
      {error && (
        <TailwindText
          {...errorProps}
          textColor={errorProps?.textColor || themeColors.textColor}
          fontSize={"text-sm"}
          margin={["ml-2"]}>
          {error}
        </TailwindText>
      )}
      {warning && !error && (
        <TailwindText
          {...warningProps}
          textColor={warningProps?.textColor || themeColors.textColor}
          fontSize={"text-sm"}
          margin={["ml-2"]}>
          {warning}
        </TailwindText>
      )}
    </TailwindFlex>
  );
};

export default inject("themeStore")(observer(TailwindDropdown));
