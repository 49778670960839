import React from "react";
import "./styles.scss";
import classnames from "classnames";

type UploadIconProps = {
  className?: string;
};

const UploadIcon: React.ComponentType<UploadIconProps> = ({ className }) => (
  <span className={classnames("UploadIcon", className && className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      overflow="visible"
      preserveAspectRatio="none"
      version="1.2"
      viewBox="0 0 24 24"
    >
      <path
        fill="#121212"
        fillRule="evenodd"
        d="M18 6a1 1 0 00-1 1v10c0 2.762-2.238 5-5 5s-5-2.238-5-5V5a3 3 0 016 0v10a1 1 0 11-2 0V7a1 1 0 10-2 0v8a3 3 0 106 0V5A5 5 0 105 5v12a7 7 0 1014 0V7a1 1 0 00-1-1z"
        clipRule="evenodd"
        vectorEffect="non-scaling-stroke"
      ></path>
    </svg>
  </span>
);

export default UploadIcon;
