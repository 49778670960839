import React, { useCallback, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import TailwindSwitch from "library/components/_tailwind/switch";
import CookieStorage from "library/core/utility/cookie-storage";

type Props = {};

const UpcomingFeatures: React.ComponentType<Props> = ({}) => {
  const [bulkMessageOn, setBulkMessageOn] = useState<boolean>(false);
  const toggleBulkMessage = useCallback(() => {
    setBulkMessageOn(!bulkMessageOn);
    CookieStorage.set("bulkMessage", !bulkMessageOn);
  }, [bulkMessageOn]);

  useEffect(() => {
    const hasBulkMessageCookie = CookieStorage.get("bulkMessage");
    if (hasBulkMessageCookie) {
      setBulkMessageOn(hasBulkMessageCookie);
    }
  }, []);

  return (
    <>
      <div className='FeatureSwitch__Cobrand-item'>
        <TailwindSwitch
          size={"xs"}
          label={"Bulk Messaging"}
          onLabel={"On"}
          offLabel={"Off"}
          onChange={toggleBulkMessage}
          isChecked={bulkMessageOn}
        />
      </div>
    </>
  );
};

export default inject("broadcastStore")(observer(UpcomingFeatures));
