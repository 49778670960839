import React, { useCallback, useEffect, useMemo, useState } from "react";
import { inject, observer } from "mobx-react";
import PricingStore from "../_stores/pricing/PricingStore";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import BroadcastingSettingsModal from "common/broadcast/broadcasting-settings-modal";
import TailwindSwitch from "library/components/_tailwind/switch";
import TailwindBox from "library/components/_tailwind/box";
import TailwindIcon from "library/components/_tailwind/icon";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindButton from "library/components/_tailwind/button";
import ProductDropdown from "common/broadcast/broadcast-pricing-product-dropdown";
import TailwindInput from "library/components/_tailwind/input";
import TailwindPill from "library/components/_tailwind/pill";
import TailwindText from "library/components/_tailwind/text";
import { ValidateOptions } from "library/core/stores/validation/types";
import LanguageStore from "library/core/stores/language/LanguageStore";

type Props = {
  pricingStore?: PricingStore;
  validationStore?: ValidationStore;
  broadcastStore?: BroadcastStore;
  languageStore?: LanguageStore;
};

const BroadcastWheelOfFunPopover: React.FunctionComponent<
  Props
> = ({ pricingStore, validationStore, broadcastStore, languageStore }) => {
  const {
    setIsWheelOfFunPricingAllowed,
    isWheelOfFunPricingAllowed,
    submitNewPrice,
    wheelOfFunPriceOptions,
    onChangePendingReward,
    isWheelOfFunAllowed,
    setModelProducts,
    approvedWheelOfFunRewards,
    pendingWheelOfFunRewards,
    approvedAndPendingWheelOfFunRewards,
    getIsRewardPending,
    saveWheelOfFunRewards,
    changedWheelOfFunRewardIndexes,
    modelProducts,
    abortWheelOfFunRewardsChanges
  } = pricingStore!;

  const { intl } = languageStore!;
  
  const { hideWheelOfFunSettingsPopover, isShowStarted } = broadcastStore!;
  const { clearErrors, clearError, validate, errors } = validationStore!;
  const [changedPrice, setChangedPrice] = useState<boolean>(false);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [isAddingReward, setIsAddingReward] = useState<boolean>(false);

  useEffect(() => {
    clearErrors();

    return () => {
      clearErrors();
    };
  }, []);

  const onChangeRewardTitle = async (index: number, value: string) => {
    setIsAddingReward(true);

    const validationOptions: ValidateOptions = {};
    if (index > 2) {
      // reward3 and up are optional
      validationOptions.canBeEmpty = true;
    }
    clearError(`reward${index + 1}`);
    validate(`reward${index + 1}`, value, validationOptions);
    onChangePendingReward(index, value);
  };

  const onChangeIsWheelOfFunAllowed = useCallback(() => {
    setModelProducts({
      ...modelProducts,
      is_wheel_of_fun_allowed: !modelProducts.is_wheel_of_fun_allowed,
    });

    const price = modelProducts.wheel_of_fun;
    setIsWheelOfFunPricingAllowed(!isWheelOfFunPricingAllowed);
    const handlePriceAvailable = !price ? 20 : price;
    const handlePrice = isWheelOfFunPricingAllowed ? handlePriceAvailable : NaN;
    submitNewPrice(
      "wheel_of_fun",
      "is_wheel_of_fun_allowed",
      isShowStarted ? price : handlePrice,
      changedPrice,
      { disabled: true }
    );
  }, [modelProducts]);

  const onChangeWheelOfFunPrice = useCallback(
    (value: any) => {
      const changed = modelProducts.wheel_of_fun !== value;
      setChangedPrice(changed);
      setModelProducts({
        ...modelProducts,
        wheel_of_fun: value,
      });
      submitNewPrice(
        "wheel_of_fun",
        "is_wheel_of_fun_allowed",
        value,
        changedPrice,
        { disabled: true }
      );
    },
    [modelProducts]
  );
  const priceDropdownAllowed = useMemo(() => {
    if (!isShowStarted) {
      return false;
    } else {
      return isWheelOfFunAllowed;
    }
  }, [isWheelOfFunAllowed, isShowStarted]);

  const onClickSave = async () => {
    setIsloading(true);
    await saveWheelOfFunRewards();
    setIsloading(false);
    setIsAddingReward(false);
  }

  const onClickClose = async () => {
    abortWheelOfFunRewardsChanges();
    hideWheelOfFunSettingsPopover();
  };

  return (
    <BroadcastingSettingsModal onClose={onClickClose}>
      <TailwindButton
        position='absolute'
        padding={["p-0"]}
        inset={["top-1", "right-1"]}
        fullWidth={false}
        onClick={onClickClose}>
        <TailwindIcon fontSize={"text-3xl"} name={"close"} />
      </TailwindButton>
      <TailwindFlex
        justifyContent={"justify-start"}
        alignItems={"items-center"}
        gap={"gap-3"}>
        <TailwindTranslatedText
          descriptor={{
            id: "settings.wheel-of-fun",
            defaultMessage: "Wheel of Fun settings",
          }}
          paragraphProps={{
            fontSize: "text-xl",
            fontWeight: "font-bold",
          }}
        />
        <TailwindSwitch
          data-testid={"wheel-of-fun-toggle"}
          onLabel={"On"}
          offLabel={"Off"}
          size={"xs"}
          onChange={onChangeIsWheelOfFunAllowed}
          isChecked={isWheelOfFunAllowed}
          offBackgroundColor={"bg-gray-400"}
          offLabelProps={{
            textColor: "text-white",
          }}
          onBackgroundColor={"bg-gray-400"}
          onLabelProps={{
            textColor: "text-main-color",
          }}
          pillBackgroundColor={
            isWheelOfFunAllowed ? "bg-green-500" : "bg-gray-200"
          }
        />
      </TailwindFlex>

      <TailwindBox>
        <TailwindFlex
          justifyContent={"justify-between"}
          flexWrap={"flex-wrap"}
          gap={"gap-6"}>
          <TailwindBox flexGrow={"flex-grow"}>
            <ProductDropdown
              dataTestId={"wheel-of-fun-price-dropdown"}
              data-is-enabled={priceDropdownAllowed}
              showSearch={false}
              className={["mb-3", "h-10"]}
              disabled={priceDropdownAllowed}
              fullWidth={true}
              fullHeight={true}
              options={wheelOfFunPriceOptions}
              onChange={onChangeWheelOfFunPrice}
              price={modelProducts.wheel_of_fun}
              currency={intl.formatMessage({
                id: `common.units.tokens-long`,
                defaultMessage: "tokens",
              })}
              unit={intl.formatMessage({
                id: `common.units.click`,
                defaultMessage: "click",
              })}
              label={intl.formatMessage({
                id: "spin-game.tokens-to-spin",
                defaultMessage: "Tokens to spin wheel",
              })}
            />
            <TailwindBox>
              {[0, 1, 2, 3, 4, 5].map(index => {
                return (
                  <TailwindBox margin={["mb-4"]} key={`reward-${index}`}>
                    <TailwindInput
                      data-testid={`wheel-of-fun-reward-${index}`}
                      maxLength={35}
                      boxProps={{
                        height: "h-10",
                        borderWidth: ["border"],
                      }}
                      backgroundColor={"primary"}
                      disabled={!isWheelOfFunAllowed}
                      borderColor={"border-gray-500"}
                      className={["mb-3"]}
                      key={index}
                      required={index < 3}
                      variant={"contained"}
                      name={`reward${index + 1} name`}
                      error={approvedAndPendingWheelOfFunRewards.length != 0 && approvedAndPendingWheelOfFunRewards.length < 3 ? errors[`reward${index + 1}`]?.toString() : ''}
                      label={intl.formatMessage({
                        id: `editRewardModal.textLabel${index + 1}`,
                        defaultMessage: `Reward ${index + 1}`,
                      })}
                      value={
                        getIsRewardPending(index) &&
                        !changedWheelOfFunRewardIndexes.includes(index)
                          ? pendingWheelOfFunRewards[index]
                          : approvedWheelOfFunRewards[index]
                      }
                      onChange={e => onChangeRewardTitle(index, e.target.value)}
                    />
                    {getIsRewardPending(index) &&
                      !changedWheelOfFunRewardIndexes.includes(index) && (
                        <TailwindPill
                          borderRadius={"rounded-full"}
                          backgroundColor={"bg-gray-600"}
                          size='sm'
                          padding={["px-4", "py-2"]}
                          textColor={"text-white"}>
                          <TailwindText fontSize={"text-sm"}>
                          {intl.formatMessage({
                            id: "accountSettings.updateDocuments.pending",
                            defaultMessage: "Pending",
                          })}
                          </TailwindText>
                        </TailwindPill>
                      )}
                  </TailwindBox>
                );
              })}
            </TailwindBox>
          </TailwindBox>
          <TailwindFlex
            width="w-full"
            display="block"
            textAlign="text-right"
          >
          <TailwindButton
            data-testid={"wheel-of-fun-cancel-button"}
            backgroundColor={"bg-gray-100"}
            rounded={false}
            width={"w-auto"}
            onClick={onClickClose}
            fullWidth={false}
            margin={["mx-4"]}
          >
            {intl.formatMessage({
              id: "common.cancel",
              defaultMessage: "Cancel",
            })}
          </TailwindButton>

          <TailwindButton
            data-testid={"wheel-of-fun-save-button"}
            data-is-enabled={isAddingReward}
            backgroundColor={"primary"}
            rounded={false}
            width={"w-auto"}
            onClick={onClickSave}
            showSpinner={isLoading}
            disabled={!isAddingReward || isLoading}
            fullWidth={false}
          >
            {intl.formatMessage({
              id: "common.save",
              defaultMessage: "Save",
            })}
          </TailwindButton>

        </TailwindFlex>
        </TailwindFlex>
      </TailwindBox>
    </BroadcastingSettingsModal>
  );
};

export default inject(
    "pricingStore",
    "validationStore",
    "broadcastStore",
    "languageStore",
  )(observer(BroadcastWheelOfFunPopover));
