import { TFontSize } from "library/styles/tailwind-classnames";
import { makeAutoObservable, reaction } from "mobx";
import { api } from "core/utility";
import _ from "lodash";
import axios, { rawAxios } from "core/config/axios";
import {
  INITIAL_MESSAGE_FONT_SIZE,
  InitialData,
  MESSAGE_CENTER_AUDIT_NOTIFICATION_ID,
  SEARCH_POLLING_TIMEOUT_IN_SECS,
  SEARCH_START_REQUEST_AFTER_N_LETTERS,
  LoyalFanFiltersEnum,
  MESSAGES_PER_REQUEST,
  LoyalFanFiltersKey,
} from "./consts";
import {
  MessageBlockType,
  MessageCenterAuditWarning,
  MessageCenterContactDetails,
  MessageCenterConversationBlockedData,
  MessageCenterConversationDetails,
  MessageCenterMessageDetails,
  MessageCenterSystemMessageUser,
  MessageQueueChunk,
  MessagesLoadMoreType,
} from "./types";
import { snackbarStore } from "library/core/stores/snackbar/SnackbarStore";
import { history, logger } from "library/core/utility";
import { format } from "date-fns";
import { SnackbarVariants } from "library/core/stores/snackbar/enums";
import { Notification } from "common/my-page/stores/profile/types";
import { modalStore } from "library/core/stores/modal";
import AccountAuditedDisclaimer from "common/account-audited-disclaimer";
import React from "react";
import { authStore, profileStore, stores } from "core/stores";
import { createFormData, sanitizedInput } from "core/utility/misc";
import config from "core/config";
import { dateProcess } from "library/core/utility";

const logPrefix = "[MessageStore]";

export default class MessageStore {
  isComposing: boolean = InitialData.isComposing;
  isSearchingView: boolean = InitialData.isSearchingView;
  isDeleting: boolean = InitialData.isDeleting;
  isBulkMessageView: boolean = InitialData.isBulkMessageView;

  message: string = InitialData.message;
  unreadConversationMessagesCount: number =
    InitialData.unreadConversationMessagesCount;
  unreadNewsAndPolicyCount: number = InitialData.unreadNewsAndPolicyCount;

  contacts: MessageCenterContactDetails[] = InitialData.contacts;
  unFilteredContacts: MessageCenterContactDetails[] = InitialData.contacts;
  searchedContacts: MessageCenterContactDetails[] = InitialData.contacts;
  selectedContacts: string[] = InitialData.selectedContacts;
  nextContactsUrl: string = InitialData.nextContactsUrl;
  isContactsLoading: boolean = InitialData.isContactsLoading;
  isContactsLoadingMore: boolean = InitialData.isContactsLoadingMore;
  isSearchingContacts: boolean = InitialData.isSearchingContacts;
  contactsSearchTerm: string = InitialData.contactsSearchTerm;
  contactsSearchTermReaction: any = InitialData.contactsSearchTermReaction;
  contactsFilterTypes: string[] = InitialData.contactsFilterTypes;
  currentContactChain: number = 0;
  activeConversation: MessageCenterConversationDetails | null =
    InitialData.activeConversation;
  activeSearchedWord: string = InitialData.activeSearchedWord;

  newsAndPolicies: MessageCenterConversationDetails[] =
    InitialData.newsAndPolicies;
  activeNewsAndPolicy: MessageCenterConversationDetails | null =
    InitialData.activeNewsAndPolicy;
  systemMessageUser: MessageCenterSystemMessageUser | null =
    InitialData.systemMessageUser;
  systemMessageUserIds: string[] = [];

  conversations: MessageCenterConversationDetails[] = InitialData.conversations;
  selectedConversations: string[] = InitialData.selectedConversations;
  activeConversationMessages: MessageCenterMessageDetails[] =
    InitialData.activeConversationMessages;
  nextConversationsUrl: string = InitialData.nextConversationsUrl;
  isConversationsLoading: boolean = InitialData.isConversationsLoading;
  isConversationsLoadingMore: boolean = InitialData.isConversationsLoadingMore;
  conversationsSearchTerm: string = InitialData.conversationsSearchTerm;
  isSearchingConversations: boolean = InitialData.isSearchingConversations;
  conversationsSearchTermReaction: any =
    InitialData.conversationsSearchTermReaction;
  searchingMessagesStartDate: string = InitialData.searchingMessagesStartDate;
  isActiveConversationBlocked: boolean =
    InitialData.isActiveConversationBlocked;
  activeConversationBlockedType: MessageBlockType =
    InitialData.activeConversationBlockedType;
  activeConversationBlockedCountryId: string =
    InitialData.activeConversationBlockedCountryId;
  activeConversationBlockedCountryLabel: string =
    InitialData.activeConversationBlockedCountryLabel;

  nextMessagesUrl: string = InitialData.nextMessagesUrl;
  futureMessagesUrl: string = InitialData.futureMessagesUrl;
  isMessagesLoading: boolean = InitialData.isMessagesLoading;
  isMessagesLoadingMore: boolean = InitialData.isMessagesLoadingMore;
  isActiveConversationMessagesRefreshing: boolean =
    InitialData.isActiveConversationMessagesRefreshing;

  requestTimeout: any = InitialData.requestTimeout;
  isMessagesMenuInNavbarOpen: boolean = InitialData.isMessagesMenuInNavbarOpen;
  messageFontSize = INITIAL_MESSAGE_FONT_SIZE;
  audits: MessageCenterAuditWarning[] = InitialData.audits;
  notifications: Notification[] = [];
  messageInputFocused: boolean = false;
  canCurrentMemberReceiveMsg: boolean = true;
  canTempMemberReceiveMsg: string[] = [];
  attachedImagesToBeSent: string[] = [];
  imageKeysTobeSubmitted: string[] = [];
  sendMessageError: string | null = InitialData.sendMessageError;
  private bulkMessageId = "_bulk_message";
  private numberOfNowMessagesPreviously = 0;
  private disableMessageListRefresh: boolean = false;
  private isFetchingUnreadConversations = false;

  constructor() {
    makeAutoObservable(this);

    this.startObservingContactsSearchTermChanges();
    this.startObservingConversationsSearchTermChanges();
  }

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  resetStore = () => {
    Object.entries(InitialData).map(([key, value]) => (this[key] = value));
  };

  get notificationsThatAreNotDismissed() {
    return this.notifications.filter(n => !n.isDismissed);
  }

  dismissNotification = (id: string) => {
    const notification = this.notifications.find(n => n.id === id);
    if (notification?.isDismissable) {
      notification.isDismissed = true;
    }
  };

  get hasAcknowledgedAllAudits() {
    return profileStore.mock2257WarningHasAcknowledgedAllAudits !== null
      ? profileStore.mock2257WarningHasAcknowledgedAllAudits
      : this.audits.every(audit => !audit.unread);
  }

  getRegistrationNotifications = () => {
    const notifications: Notification[] = [];
    if (
      profileStore.isApplicationPending ||
      profileStore.isApplicationIncomplete
    ) {
      notifications.push({
        id: profileStore.isApplicationPending
          ? "application-pending"
          : "application-incomplete",
        type: profileStore.isApplicationPending ? "warning" : "error",
        content: profileStore.isApplicationIncomplete ? (
          <span>
            Your Application is incomplete, click read more for more details.
          </span>
        ) : profileStore.isApplicationPending ? (
          <span>
            Your Application is pending review, click read more for more
            details.
          </span>
        ) : (
          <span>
            Your Application is incomplete, click read more for more details.
          </span>
        ),
        onClickReadMore: () =>
          authStore?.openRegisterModalToCompleteProfile(true, true),
        isDismissed: false,
        onClickDismiss: this.dismissNotification,
        isDismissable: false,
      });
    } else if (profileStore.isApplicationDenied) {
      notifications.push({
        id: "application-denied",
        type: "error",
        content: (
          <span>
            Your Application has been denied, click read more for more details.
          </span>
        ),
        onClickReadMore: () =>
          authStore?.openRegisterModalToCompleteProfile(true, true),
        isDismissed: false,
        onClickDismiss: this.dismissNotification,
        isDismissable: false,
      });
    }

    return notifications;
  };

  setNotifications = (notifications: Notification[]) => {
    this.notifications = notifications;
  };

  initNotifications = async () => {
    const registrationNotifications = this.getRegistrationNotifications();
    const auditWarnings = await this.getAuditWarnings();
    this.setAuditWarnings(auditWarnings);
    const auditNotification =
      this.convertAuditWarningsToNotification(auditWarnings);
    const notifications = registrationNotifications;
    if (auditNotification) {
      notifications.push(auditNotification);
    }
    this.setNotifications(notifications);
  };

  convertAuditWarningsToNotification = (
    auditWarnings: Notification[]
  ): Notification | undefined => {
    if (auditWarnings.length > 0 && !this.hasAcknowledgedAllAudits) {
      const isWarning =
        profileStore.mock2257WarningResult !== undefined
          ? profileStore.mock2257WarningResult === "EMPTY" ||
            profileStore.mock2257WarningResult === "NONE" ||
            profileStore.mock2257WarningResult === ""
          : profileStore.modelProfile.is_broadcast_allowed === undefined
          ? true
          : profileStore.modelProfile.is_broadcast_allowed;
      const audit: Notification = {
        id: MESSAGE_CENTER_AUDIT_NOTIFICATION_ID,
        type: isWarning ? "warning" : "error",
        content: (
          <span>
            {isWarning
              ? "You have received a warning."
              : "You have received a suspension."}
          </span>
        ),
        onClickReadMore: () =>
          modalStore.openPrimaryModal(
            <AccountAuditedDisclaimer isModal={true} />,
            {
              showNativeCloseButton: this.hasAcknowledgedAllAudits,
            }
          ),
        isDismissed: false,
        onClickDismiss: this.dismissNotification,
        isDismissable: false,
      };

      return audit;
    }

    return undefined;
  };

  private startObservingContactsSearchTermChanges = () => {
    this.stopObservingContactsSearchTermChanges();
    this.contactsSearchTermReaction = reaction(
      () => this.contactsSearchTerm,
      term => {
        clearTimeout(this.requestTimeout);
        if (
          term !== "" &&
          term.length >= SEARCH_START_REQUEST_AFTER_N_LETTERS
        ) {
          this.requestTimeout = setTimeout(() => {
            this.getContacts();
            this.log("Message Center: getting searched contacts debounced");
          }, SEARCH_POLLING_TIMEOUT_IN_SECS * 1000);
        } else if (term === "") {
          this.getContacts();
          this.log("Message Center: getting searched contacts");
        }
      }
    );
  };

  private stopObservingContactsSearchTermChanges = () => {
    if (this.contactsSearchTermReaction) {
      this.contactsSearchTermReaction();
      this.contactsSearchTermReaction = undefined;
    }
  };

  private startObservingConversationsSearchTermChanges = () => {
    this.stopObservingConversationsSearchTermChanges();
    this.conversationsSearchTermReaction = reaction(
      () => this.conversationsSearchTerm,
      term => {
        clearTimeout(this.requestTimeout);
        if (
          term !== "" &&
          term.length >= SEARCH_START_REQUEST_AFTER_N_LETTERS
        ) {
          this.requestTimeout = setTimeout(() => {
            this.getConversations();
            this.log(
              "Message Center: getting searched conversations debounced"
            );
          }, SEARCH_POLLING_TIMEOUT_IN_SECS * 1000);
        } else if (term === "") {
          this.getConversations();
          this.log("Message Center: getting conversations");
        }
      }
    );
  };

  private stopObservingConversationsSearchTermChanges = () => {
    if (this.conversationsSearchTermReaction) {
      this.conversationsSearchTermReaction();
      this.conversationsSearchTermReaction = undefined;
    }
  };

  public sendMessageToMember = async (memberId: string) => {
    this.setSelectedContacts([memberId]);
    await this.createConversation();
  };

  public get isOnMessagesPage() {
    const url = history.location.pathname;
    return url.indexOf("/messages") !== -1;
  }

  get activeConversationId() {
    if (this.activeConversation) {
      return this.activeConversation.id;
    }
    return null;
  }

  private initSystemMessageUser = async () => {
    const url = "cs-contacts/";
    const { data } = await api.messages.get(url);
    if (data?.length) {
      const selectedIdx = Math.floor(Math.random() * data?.length);
      const { id, username, email, first_name, last_name } =
        data?.[selectedIdx];
      this.systemMessageUser = { id, username, email, first_name, last_name };
    }
    this.systemMessageUserIds = data?.map(cs_user => {
      return cs_user.id;
    });
  };

  getAuditWarnings = async () => {
    try {
      const url = `my-notifications/?type=audit_warning`;
      const { data } = await api.messages.get(url);
      return data?.results;
    } catch (error) {
      this.log("getAuditWarnings failed");
      return [];
    }
  };

  setAuditWarnings = (audits: MessageCenterAuditWarning[]) => {
    this.audits = audits;
  };

  getPublicNotices = async () => {
    const { userRole } = authStore!;
    const url = `my-notifications/?page_size=${20}&type=public_notice`;
    const { data } = await api.messages.get(url);
    const { data: unreadCountData } = await api.messages.get(
      `my-notifications/unread-count/`
    );
    const unreadCount = unreadCountData?.count;

    if (unreadCount !== undefined) {
      this.unreadNewsAndPolicyCount = unreadCount;
    }

    if (userRole) {
      const news = data?.results
        ?.filter(notice => notice?.action_object?.audience?.includes(userRole))
        .map(notice => {
          return {
            id: notice?.id,
            title: notice?.action_object?.subject,
            body: notice?.action_object?.body,
            start_date: notice?.action_object?.start_date,
            unread_count: notice?.unread ? 1 : 0,
            participants: [
              {
                username: "Streamray",
                first_name: "System",
                last_name: "Administrator",
              },
            ],
          };
        });
      this.newsAndPolicies = news || [];
    }

    if (this.newsAndPolicies?.length) {
      this.initSystemMessageUser();
    }
  };

  getAllMessagesCount = async () => {
    try {
      const { data: unreadCountData } = await api.messages.get(
        `conversations/unread-count/`
      );
      this.unreadConversationMessagesCount = unreadCountData?.count;
    } catch (error) {
      this.log("Message Center: Error when getting unread-count", error);
    }
  };

  private waitForUnreadConversations = async () => {
    return await new Promise(resolve => {
      if (!this.isFetchingUnreadConversations) {
        resolve(true);
      } else {
        let attempts = 0;
        const waitForUnreadInterval = setInterval(() => {
          attempts++;
          if (!this.isFetchingUnreadConversations || attempts >= 20) {
            clearInterval(waitForUnreadInterval);
            resolve(true);
          }
        }, 500);
      }
    });
  };

  private updateTempMemberBlock = (
    conversation: MessageCenterConversationDetails
  ) => {
    if (
      conversation.can_receive_message &&
      !this.canCurrentMemberReceiveMsg &&
      conversation?.id === this.activeConversationId
    ) {
      this.setCanCurrentMemberReceiveMsg(true);
    }

    if (
      conversation.can_receive_message &&
      this.canTempMemberReceiveMsg.includes(conversation?.id)
    ) {
      // unblock 24 hr or member reply restriction if a message was received
      const idx = this.canTempMemberReceiveMsg.indexOf(conversation?.id);
      this.canTempMemberReceiveMsg.splice(idx, 1);
      this.canTempMemberReceiveMsg = [...this.canTempMemberReceiveMsg];
    }
  };

  getUnreadConversations = async () => {
    try {
      if (this.conversationsSearchTerm !== "") {
        return;
      }

      this.isFetchingUnreadConversations = true;
      const { data: unreadConversations } = await api.messages.get(
        `conversations/?unread=true`
      );

      const conversations = unreadConversations?.results;
      const mappedConversations: MessageCenterConversationDetails[] =
        conversations?.map(this.getConversationWithoutMemberParticipant) || [];

      await this.getAllMessagesCount();

      let foundNewUnreadMessage = false;
      mappedConversations.forEach(unread_conversation => {
        const target = this.conversations?.find(
          convo => convo?.id === unread_conversation?.id
        );

        this.updateTempMemberBlock(unread_conversation);

        if (
          !target?.last_message ||
          target?.last_message?.id !== unread_conversation?.last_message?.id ||
          target?.unread_count !== unread_conversation?.unread_count
        ) {
          foundNewUnreadMessage = true;
        }
      });

      if (foundNewUnreadMessage) {
        this.conversations = _.unionBy(
          mappedConversations,
          this.conversations,
          "id"
        );
      }

      if (this.activeConversationId) {
        const matchActive = mappedConversations.filter(
          conversationItem => conversationItem.id === this.activeConversationId
        );
        if (matchActive.length) {
          const numberOfUnread = matchActive[0].unread_count;
          await this.getLatestConversationMessages(numberOfUnread);
        }
      }
    } catch (error) {
      this.log("Message Center: Error getting unread conversations", error);
    } finally {
      this.isFetchingUnreadConversations = false;
    }
  };

  get sortedConversations() {
    return [...this.conversations].sort((a, b) => {
      if (a.is_draft) {
        return -1;
      } else if (b.is_draft) {
        return 1;
      } else if (a.last_message && b.last_message) {
        return (
          new Date(b.last_message!.created_at).getTime() -
          new Date(a.last_message!.created_at).getTime()
        );
      } else if (!a.last_message) {
        return 1;
      } else if (!b.last_message) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  getConversations = async (
    loadingMore: boolean = false,
    isRefreshing = false
  ) => {
    if (
      (!loadingMore && !this.isConversationsLoading) ||
      (loadingMore && !this.isConversationsLoadingMore) ||
      !this.isSearchingConversations
    ) {
      try {
        if (
          (loadingMore &&
            this.nextConversationsUrl &&
            this.nextConversationsUrl !== "") ||
          !loadingMore
        ) {
          if (loadingMore) {
            this.isConversationsLoadingMore = true;
          } else if (!isRefreshing) {
            this.isConversationsLoading = true;
          }

          if (this.conversationsSearchTerm !== "") {
            this.isSearchingConversations = true;
          }

          const encodedSearchTerm = encodeURIComponent(
            this.conversationsSearchTerm
          );

          let url;
          if (this.nextConversationsUrl !== "" && loadingMore) {
            url = `conversations/${this.nextConversationsUrl.split("/").pop()}`;
          } else {
            url = `conversations/?page_size=${20}&search=${encodedSearchTerm}`;
          }

          const { data } = await api.messages.get(url);

          const conversations = data?.results;
          const mappedConversations =
            conversations?.map(this.getConversationWithoutMemberParticipant) ||
            [];
          const draftConversations = this.conversations.filter(
            conversation => conversation.is_draft
          );

          if (!loadingMore) {
            this.conversations = [
              ...draftConversations,
              ...mappedConversations,
            ];
          } else {
            this.conversations = [
              ...this.conversations,
              ...mappedConversations,
            ];
          }

          const nextUrl = data?.next;
          if (nextUrl && nextUrl !== "") {
            this.nextConversationsUrl = nextUrl;
          } else {
            this.nextConversationsUrl = "";
          }
        }
      } catch (error) {
        this.log("Message Center: Error getting conversations", error);
        /*snackbarStore.enqueueSnackbar({
          message: {
            id: 'messages.error.get-conversations',
            default: 'There was an error while getting conversations'
          },
          variant: SnackbarVariants.ERROR
        })*/
      } finally {
        if (loadingMore) {
          this.isConversationsLoadingMore = false;
        } else {
          this.isConversationsLoading = false;
        }

        if (this.conversationsSearchTerm !== "") {
          this.isSearchingConversations = false;
        }
      }
    }
  };

  createConversation = async () => {
    try {
      // if there is already a conversation that is draft, clear it out so
      this.conversations = this.conversations.filter(
        conversation => !conversation.is_draft
      );
      let conversation: MessageCenterConversationDetails;

      if (this.isBulkMessageView) {
        const participants: MessageCenterContactDetails[] = [];
        this.selectedContacts.forEach(id => {
          const selected_contact = this.contacts.find(
            _contact => _contact?.id === id
          );
          selected_contact && participants.push(selected_contact);
        });

        conversation = {
          id: this.bulkMessageId,
          participants,
          unread_count: 0,
          is_draft: true,
          can_receive_message: true,
        };

        this.conversations = [...this.conversations, conversation];
        this.resetActiveConversation();
      } else {
        const { data } = await api.messages.post(
          {
            participants: this.selectedContacts,
          },
          `conversations/`
        );
        conversation = this.getConversationWithoutMemberParticipant(data);

        if (conversation) {
          // if there is no last message
          // it means its a fresh conversation
          if (!conversation?.last_message) {
            conversation.is_draft = true;
          }

          const conversationExists = this.conversations.find(
            conversation_ => conversation_.id === conversation.id
          );

          if (!conversationExists) {
            this.conversations = [conversation, ...this.conversations];
          }

          this.setSelectedContacts([]);
          this.isComposing = false;
        }
      }

      this.isSearchingView = false;
      this.isSearchingConversations = false;
      this.setContactsSearchTerm("");
      this.setConversationsSearchTerm("");
      await this.readConversation(conversation?.id);
    } catch (error) {
      snackbarStore.enqueueSnackbar({
        message: {
          id: "messages.error.start-conversation",
          default: "There was an error while starting conversation",
        },
        variant: SnackbarVariants.ERROR,
      });
      this.log("Message Center: Error creating conversation", error);
    }
  };

  getLatestConversationMessages = async (unreadCount: number) => {
    const conversationId = this.activeConversationId;
    if (conversationId && !this.isMessagesLoading) {
      try {
        const url = `conversations/${conversationId}/messages/?page_size=${unreadCount}&ordering=-created_at`;
        const { data } = await api.messages.get(url);

        // messages need to be reversed to have whatsapp like behavior
        const sortedConversationMessages =
          this.sortConversationMessagesByNewest(data?.results).reverse();

        // add messages to list
        this.activeConversationMessages = [
          ...sortedConversationMessages,
          ...this.activeConversationMessages,
        ];

        // for protection you can only mark the messages you receive as read, not your own messages
        const receivedMessageIdsToMarkAsRead = sortedConversationMessages
          .filter(
            message =>
              message.sender.username !==
                profileStore.modelProfile.screen_name && message.unread
          )
          .map(message => message.id);

        if (receivedMessageIdsToMarkAsRead?.length > 0) {
          this.unreadConversationMessagesCount -=
            receivedMessageIdsToMarkAsRead?.length;
          await this.markMessagesAsRead(
            conversationId,
            receivedMessageIdsToMarkAsRead
          );
        }

        const mappedConversationMessages = this.activeConversationMessages.map(
          conversation => {
            if (receivedMessageIdsToMarkAsRead.includes(conversation.id)) {
              conversation.unread = false;
            }
            return conversation;
          }
        );

        // mark new messages as read
        this.activeConversationMessages = mappedConversationMessages;
      } catch (error) {
        this.log("Message Center: Error getting messages", error);
        snackbarStore.enqueueSnackbar({
          message: {
            id: "messages.error.get-messages",
            default: "There was an error while getting messages",
          },
          variant: SnackbarVariants.ERROR,
        });
      }
    }
  };

  refreshActiveConversationMessages = async () => {
    if (this.activeConversationId && !this.disableMessageListRefresh) {
      this.getActiveConversationMessages(
        false,
        this.activeConversationId,
        true
      );
    }
  };

  private handleActiveConversationMessages = async (
    data,
    conversationId: string,
    {
      loadingMore = false,
      isRefreshing = false,
      skipSort = false,
      loadingMoreType = undefined as MessagesLoadMoreType,
    }
  ) => {
    const activeConversationMember =
      this.activeConversation?.participants?.find(
        participant => participant?.profile_type === "member"
      );
    let messagesParticipant = ""; // the member who these messages are from

    // messages need to be reversed to have whatsapp like behavior
    const sortedConversationMessages = skipSort
      ? data?.results
      : this.sortConversationMessagesByNewest(data?.results).reverse();

    // for protection you can only mark the messages you receive as read, not your own messages
    const receivedMessageIdsToMarkAsRead = sortedConversationMessages
      .filter(
        message =>
          message.sender.username !== profileStore.modelProfile.screen_name &&
          message.unread
      )
      .map(message => message.id);

    if (receivedMessageIdsToMarkAsRead?.length > 0) {
      await this.markMessagesAsRead(
        conversationId,
        receivedMessageIdsToMarkAsRead
      );
    }

    const mappedConversationMessages = sortedConversationMessages.map(
      conversation => {
        if (
          !messagesParticipant &&
          conversation?.sender?.profile_type === "member"
        ) {
          messagesParticipant = conversation?.sender?.username;
        }

        if (receivedMessageIdsToMarkAsRead.includes(conversation.id)) {
          conversation.scroll_to = conversation.unread;
          conversation.unread = false;
        }
        return conversation;
      }
    );

    if (
      messagesParticipant &&
      messagesParticipant !== activeConversationMember?.username
    ) {
      return;
    }

    if (isRefreshing) {
      const numberOfDeniedMessagesPreviously =
        this.activeConversationMessages.filter(
          message => message.status === "DENIED"
        ).length;
      const numberOfDeniedMessagesCurrently = mappedConversationMessages.filter(
        message => message.status === "DENIED"
      ).length;

      const numberOfNowMessages = mappedConversationMessages.filter(
        message =>
          dateProcess?.getHumanReadableDate(message?.created_at) === "Now"
      ).length;

      const currentMostRecentMessage = this.activeConversationMessages?.[0];
      const incomingMostRecentMessage =
        mappedConversationMessages?.[0] as MessageCenterMessageDetails;

      // when polling message list, we want to limit the amount of times we repaint
      if (
        numberOfDeniedMessagesCurrently > numberOfDeniedMessagesPreviously ||
        numberOfNowMessages < this.numberOfNowMessagesPreviously ||
        currentMostRecentMessage?.id !== incomingMostRecentMessage?.id
      ) {
        this.activeConversationMessages = mappedConversationMessages;

        if (
          this.activeConversation &&
          incomingMostRecentMessage &&
          this.conversations
        ) {
          // update active conversation with last message received
          await this.getConversations(false, true);
          this.activeConversation =
            this.conversations.find(
              convo => convo?.id === this.activeConversationId
            ) || this.activeConversation;
          this.updateTempMemberBlock(this.activeConversation);

          this.activeConversation = {
            ...this.activeConversation,
            last_message: incomingMostRecentMessage,
          };
        }
      }

      this.numberOfNowMessagesPreviously = numberOfNowMessages;
    } else if (!loadingMore) {
      this.activeConversationMessages = mappedConversationMessages;
    } else if (loadingMore && loadingMoreType === "next") {
      this.activeConversationMessages = [
        ...mappedConversationMessages,
        ...this.activeConversationMessages,
      ];
    } else {
      this.activeConversationMessages = [
        ...this.activeConversationMessages,
        ...mappedConversationMessages,
      ];
    }

    const nextUrl = data?.next;
    if (
      loadingMore &&
      loadingMoreType === "next" &&
      nextUrl &&
      nextUrl !== ""
    ) {
      this.futureMessagesUrl = nextUrl;
    } else if (loadingMore && loadingMoreType === "next") {
      this.futureMessagesUrl = "";
    } else if (nextUrl && nextUrl !== "") {
      this.nextMessagesUrl = nextUrl;
    } else {
      this.nextMessagesUrl = "";
    }
  };

  handleFutureMessagesData = async (data, conversationId) => {
    // created_at filter has issues omitting some messages when using -created_at ordering so we need to reverse them
    const sorted_results = {
      ...data,
      results: data?.results?.reverse() || [],
    };
    await this.handleActiveConversationMessages(
      sorted_results,
      conversationId,
      {
        loadingMore: true,
        isRefreshing: false,
        skipSort: true,
        loadingMoreType: "next",
      }
    );
  };

  getActiveConversationMessagesFromStartDate = async (
    startAtDate: string,
    loadingMore: boolean = false,
    loadingMoreType?: MessagesLoadMoreType
  ) => {
    const conversationId =
      this.activeConversation?.id || this.activeConversationId || "";
    const defaultLimit = MESSAGES_PER_REQUEST;
    this.disableMessageListRefresh = true;
    this.searchingMessagesStartDate = startAtDate;

    if (!loadingMore) {
      this.activeConversationMessages = [];
    }

    if (
      (conversationId && !loadingMore && !this.isMessagesLoading) ||
      (loadingMore && !this.isMessagesLoadingMore)
    ) {
      try {
        if (
          (loadingMore &&
            this.nextMessagesUrl &&
            this.nextMessagesUrl !== "") ||
          (loadingMore &&
            this.futureMessagesUrl &&
            this.futureMessagesUrl !== "") ||
          !loadingMore
        ) {
          if (loadingMore) {
            this.isMessagesLoadingMore = true;
          } else {
            // reset next and previous urls when new search result is opened
            this.nextMessagesUrl = "";
            this.futureMessagesUrl = "";
          }

          const url =
            loadingMoreType === "next"
              ? `conversations/${conversationId}/messages/?page_size=${defaultLimit}&created_at__gt=${startAtDate}&ordering=created_at`
              : loadingMoreType === "previous"
              ? `conversations/${conversationId}/messages/?page_size=${defaultLimit}&created_at__lt=${startAtDate}&ordering=-created_at`
              : `conversations/${conversationId}/messages/?page_size=${defaultLimit}&created_at__lte=${startAtDate}&ordering=-created_at`;
          const { data } = await api.messages.get(url);

          if (loadingMoreType === "next") {
            await this.handleFutureMessagesData(data, conversationId);
          } else {
            await this.handleActiveConversationMessages(data, conversationId, {
              loadingMore,
              isRefreshing: false,
              skipSort: true,
              loadingMoreType,
            });
          }

          if (!loadingMore && !loadingMoreType) {
            // fetch a second page for search results so we can scroll down
            const future_messages_url = `conversations/${conversationId}/messages/?page_size=${defaultLimit}&created_at__gt=${startAtDate}&ordering=created_at`;
            const { data: data_future } = await api.messages.get(
              future_messages_url
            );
            await this.handleFutureMessagesData(data_future, conversationId);
          }
        }
      } catch (error) {
        this.log(
          "Message Center: Error getting messages from search result position",
          error
        );
        snackbarStore.enqueueSnackbar({
          message: {
            id: "messages.error.get-messages",
            default: "There was an error while getting messages",
          },
          variant: SnackbarVariants.ERROR,
        });
      } finally {
        if (loadingMore) {
          this.isMessagesLoadingMore = false;
        } else {
          this.isMessagesLoading = false;
        }
      }
    }
  };

  getSearchedConversationPreviousMessages = async () => {
    if (this.activeConversationMessages.length) {
      this.getActiveConversationMessagesFromStartDate(
        this.activeConversationMessages[
          this.activeConversationMessages.length - 1
        ].created_at,
        true,
        "previous"
      );
    }
  };

  getSearchedConversationFutureMessages = async () => {
    if (this.activeConversationMessages.length) {
      this.getActiveConversationMessagesFromStartDate(
        this.activeConversationMessages[0].created_at,
        true,
        "next"
      );
    }
  };

  getActiveConversationMessages = async (
    loadingMore: boolean = false,
    activeConversationId?: string,
    isRefreshing: boolean = false
  ) => {
    const conversationId =
      activeConversationId || this.activeConversationId || "";
    const defaultLimit = MESSAGES_PER_REQUEST;
    const refreshingLimit =
      this.activeConversationMessages.length > defaultLimit
        ? this.activeConversationMessages.length
        : defaultLimit;
    this.disableMessageListRefresh = false;
    this.isActiveConversationMessagesRefreshing = isRefreshing;

    if (!loadingMore && !isRefreshing) {
      this.sendMessageError = null;
    }

    if (loadingMore && this.searchingMessagesStartDate) {
      await this.getSearchedConversationPreviousMessages();
    } else if (
      (conversationId && !loadingMore && !this.isMessagesLoading) ||
      (loadingMore && !this.isMessagesLoadingMore)
    ) {
      try {
        this.searchingMessagesStartDate = "";
        if (
          (loadingMore &&
            this.nextMessagesUrl &&
            this.nextMessagesUrl !== "") ||
          !loadingMore
        ) {
          if (loadingMore) {
            this.isMessagesLoadingMore = true;
          } else if (!isRefreshing) {
            this.isMessagesLoading = true;
          }

          const url =
            this.nextMessagesUrl !== "" && loadingMore
              ? this.nextMessagesUrl.split("notifications/")[1]
              : isRefreshing
              ? `conversations/${conversationId}/messages/?page_size=${refreshingLimit}&ordering=-created_at`
              : `conversations/${conversationId}/messages/?page_size=${defaultLimit}&ordering=-created_at`;
          const { data } = await api.messages.get(url);

          await this.handleActiveConversationMessages(data, conversationId, {
            loadingMore,
            isRefreshing,
          });
        }
      } catch (error) {
        this.log("Message Center: Error getting messages", error);
        snackbarStore.enqueueSnackbar({
          message: {
            id: "messages.error.get-messages",
            default: "There was an error while getting messages",
          },
          variant: SnackbarVariants.ERROR,
        });
      } finally {
        if (loadingMore) {
          this.isMessagesLoadingMore = false;
        } else {
          this.isMessagesLoading = false;
        }

        if (this.isActiveConversationMessagesRefreshing) {
          this.isActiveConversationMessagesRefreshing = false;
        }
      }
    }
  };

  get conversationsGroupedByParticipants() {
    const groupedConversations = _.groupBy(
      this.conversations,
      (conversation: MessageCenterConversationDetails) => {
        return conversation.participants.map(
          participant => participant.username
        );
      }
    );
    return groupedConversations;
  }

  get hasMoreMessages(): boolean {
    return this.nextMessagesUrl !== "";
  }

  get hasMoreFutureMessages(): boolean {
    return this.futureMessagesUrl !== "";
  }

  get hasMoreConversations(): boolean {
    return this.nextConversationsUrl !== "";
  }

  get hasMoreContacts(): boolean {
    return this.nextContactsUrl !== "";
  }

  public markMessagesAsRead = async (
    conversationId,
    messageIdOrIds: string[] | number[]
  ) => {
    try {
      if (this.isFetchingUnreadConversations) {
        // wait for unread conversations to complete to avoid race condition with unread count
        await this.waitForUnreadConversations();
      }

      const countOfMessages = messageIdOrIds.length;
      this.conversations = this.conversations.map(conversation => {
        if (conversation.id === conversationId) {
          conversation.unread_count -= countOfMessages;
        }

        return conversation;
      });

      if (this.activeConversation) {
        this.activeConversation = {
          ...this.activeConversation,
          unread_count: this.activeConversation.unread_count - countOfMessages,
        };
      }

      this.activeConversationMessages = this.activeConversationMessages.map(
        message => {
          if (messageIdOrIds.includes(message.id as never)) {
            message.unread = false;
          }
          return message;
        }
      );

      this.unreadConversationMessagesCount -= countOfMessages;
    } catch (error) {
      this.log("Message Center: Error marking messages as read", error);
    }
  };

  public sortConversationMessagesByNewest = (
    messages: MessageCenterMessageDetails[]
  ) => {
    if (messages) {
      return messages.sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );
    }

    return messages;
  };

  private getConversationWithoutMemberParticipant = (
    conversation: MessageCenterConversationDetails
  ) => {
    if (conversation) {
      const userName = profileStore.modelProfile?.screen_name || "";
      return {
        ...conversation,
        participants: conversation?.participants?.filter(
          participant => participant.username !== userName
        ),
      };
    }

    return conversation;
  };

  public getContacts = async (
    loadingMore: boolean = false,
    type?: string[]
  ) => {
    this.contactsFilterTypes = type || [];

    if (type?.length) {
      let filtered_contacts: MessageCenterContactDetails[] = [];
      type.forEach(t => {
        const contact_list = this.contactsSearchTerm
          ? this.searchedContacts
          : this.unFilteredContacts;
        const type_filtered = contact_list.filter(
          contact => contact[LoyalFanFiltersKey[t]]
        );
        filtered_contacts = _.unionBy(type_filtered, filtered_contacts, "id");
        filtered_contacts = filtered_contacts.sort((a, b) =>
          a.username.localeCompare(b.username)
        );
      });
      this.contacts = filtered_contacts;

      return filtered_contacts;
    } else if (
      !loadingMore &&
      this.unFilteredContacts.length &&
      !this.contactsSearchTerm
    ) {
      this.contacts = this.unFilteredContacts;
      return this.unFilteredContacts;
    }

    if (
      (!loadingMore && !this.isContactsLoading) ||
      (loadingMore && !this.isContactsLoadingMore) ||
      !this.isSearchingContacts
    ) {
      try {
        if (
          (loadingMore &&
            this.nextContactsUrl &&
            this.nextContactsUrl !== "") ||
          !loadingMore
        ) {
          if (loadingMore) {
            this.isContactsLoadingMore = true;
          } else {
            this.isContactsLoading = true;
          }

          if (this.contactsSearchTerm !== "") {
            this.isSearchingContacts = true;
          }

          const encodedSearchTerm = encodeURIComponent(this.contactsSearchTerm);
          const selectedType = type ? type.map(type => `&type=${type}`) : "";
          let url;

          if (this.nextContactsUrl !== "" && loadingMore) {
            url = `contacts/${this.nextContactsUrl.split("/").pop()}${[
              ...selectedType,
            ].join("")}`;
          } else {
            url = `contacts/?limit=${100}&search=${encodedSearchTerm}${[
              ...selectedType,
            ].join("")}`;
          }
          const { data } = await api.messages.get(url);

          const contacts = data?.results;

          if (this.isSearchingContacts) {
            this.searchedContacts = loadingMore
              ? [...this.searchedContacts, ...contacts]
              : contacts;
          } else {
            // unfiltered contact list
            this.unFilteredContacts = loadingMore
              ? [...this.unFilteredContacts, ...contacts]
              : contacts;
          }

          const nextUrl = data?.next;
          if (nextUrl && nextUrl !== "") {
            this.nextContactsUrl = nextUrl;
            //   this.contacts = [...this.contacts, contacts];
          } else {
            this.nextContactsUrl = "";
            this.contacts = this.isSearchingContacts
              ? this.searchedContacts
              : this.unFilteredContacts;
          }
        }
      } catch (error) {
        snackbarStore.enqueueSnackbar({
          message: {
            id: "messages.error.get-contacts",
            default: "There was an error while getting contacts",
          },
          variant: SnackbarVariants.ERROR,
        });
        this.log("Message Center: Error getting contacts", error);
      } finally {
        if (loadingMore) {
          this.isContactsLoadingMore = false;
        } else {
          this.isContactsLoading = false;
        }

        if (this.contactsSearchTerm !== "") {
          this.isSearchingContacts = false;
        }

        // automatically load next page until we fetch all contacts
        if (this.nextContactsUrl) {
          this.getContacts(true);
        }
      }
    }
  };

  public getLoyalFansContacts = async () => {
    const types = Object.keys(LoyalFanFiltersEnum).filter(
      key => LoyalFanFiltersEnum[key]
    );
    this.getContacts(false, types);
  };

  get unreadConversations() {
    let unreadConversations: MessageCenterConversationDetails[] = [];
    if (this.conversations && this.conversations.length) {
      unreadConversations = this.conversations.filter(
        conversation => conversation.unread_count > 0
      );
    }

    return unreadConversations;
  }

  readConversation = async (id: string | null, startAtDate?: string) => {
    if (this.activeConversation?.id !== id || startAtDate) {
      this.unBlockActiveConversation();
      const conversation = this.conversations.find(
        conversation => conversation.id === id
      );
      this.activeConversation = conversation || null;
      this.activeSearchedWord = "";
      this.activeNewsAndPolicy = null;
      if (conversation && !this.isBulkMessageView && startAtDate) {
        this.activeSearchedWord = this.conversationsSearchTerm;
        this.getActiveConversationMessagesFromStartDate(startAtDate);
      } else if (conversation && !this.isBulkMessageView) {
        await this.getActiveConversationMessages();
        if (this.isSearchingView) {
          this.setIsSearchingView(false);
          this.setConversationsSearchTerm("");
        }
      } else {
        this.activeConversationMessages = [];
      }
      this.setMessage("");
      this.setSelectedConversations([]);

      if (this.attachedImagesToBeSent.length > 0) {
        this.setAttachImagesToBeSent([]);
      }

      // get blocked status
      if (
        this.activeConversation &&
        this.activeConversation?.participants?.length > 0
      ) {
        const activeConvoWithoutUser =
          this.getConversationWithoutMemberParticipant(this.activeConversation);
        this.getBlockedConversationStatus(
          activeConvoWithoutUser?.participants?.[0]?.id
        );
      }
    }
  };

  readNewsAndPolicy = (id: string | null) => {
    const notice = this.newsAndPolicies.find(notice => notice.id === id);
    this.activeConversation = this.activeNewsAndPolicy = notice || null;
    this.setMessage("");
    this.setSelectedConversations([]);
    if (notice?.unread_count) {
      this.markNotificationsAsRead([notice.id]);
    }
  };

  getBlockedConversationStatus = async memberId => {
    const url = `conversations/is-receiver-blocked/?receiver_id=${memberId}`;
    const { data } = await await api.messages.get(url);
    const blocked_data = data as MessageCenterConversationBlockedData;

    if (
      blocked_data?.is_member_blocked_by_model ||
      blocked_data?.is_member_country_blocked_by_model ||
      blocked_data?.is_member_state_blocked_by_model
    ) {
      this.isActiveConversationBlocked = true;

      if (blocked_data?.is_member_blocked_by_model) {
        this.activeConversationBlockedType = "member";
      } else if (blocked_data?.is_member_country_blocked_by_model) {
        const country_data =
          blocked_data?.blocked_state_or_country_data?.country;
        this.activeConversationBlockedType = "country";
        this.activeConversationBlockedCountryId = country_data?.country_id;
        this.activeConversationBlockedCountryLabel =
          country_data?.country_label;
      } else if (blocked_data?.is_member_state_blocked_by_model) {
        const state_data = blocked_data?.blocked_state_or_country_data?.state;
        this.activeConversationBlockedType = "state";
        this.activeConversationBlockedCountryId = state_data?.country_id;
        this.activeConversationBlockedCountryLabel = state_data?.state_label;
      }
    }
  };

  unBlockActiveConversation = () => {
    this.isActiveConversationBlocked = false;
    this.activeConversationBlockedType = null;
    this.activeConversationBlockedCountryId = "";
    this.activeConversationBlockedCountryLabel = "";
  };

  resetActiveConversation = () => {
    this.activeConversation = null;
    this.activeNewsAndPolicy = null;
    this.activeConversationMessages = [];
    this.unBlockActiveConversation();
  };

  markNotificationsAsRead = async (
    notifications: string[],
    inMemoryUpdate: boolean = false,
    refreshPublicNotices: boolean = true
  ) => {
    try {
      if (!inMemoryUpdate) {
        await api.messages.post(
          { notifications },
          `my-notifications/mark-read/`
        );
      }
      if (refreshPublicNotices) {
        await this.getPublicNotices();
      }

      this.setNotifications(
        this.notifications.map(n => ({
          ...n,
          isDismissed: notifications.includes(n.id) ? true : n.isDismissed,
        }))
      );
    } catch (error) {
      this.log("Message Center: Error marking notifications as read", error);
    }
  };

  setMessage = (message: string) => {
    this.message = message;
  };

  sendMessage = async (noticeBlock?: string) => {
    if (!sanitizedInput(this.message)) {
      snackbarStore.enqueueSnackbar({
        message: {
          id: "messages.error.not-allowed",
          default: "Message is not allowed",
        },
        variant: SnackbarVariants.ERROR,
      });
      this.message = "";
      return;
    }
    if (
      noticeBlock ||
      this.message.trim() !== "" ||
      this.attachedImagesToBeSent
    ) {
      try {
        const limit = config.mcCharLimit;
        const queue: MessageQueueChunk[] = [];
        const toBeSent = noticeBlock || this.message || null;
        if (toBeSent === null || toBeSent.length <= limit) {
          queue.push({
            data: {
              message: toBeSent,
            },
            image_keys: this.imageKeysTobeSubmitted,
          });
        } else {
          const words = toBeSent.split(" ");
          let sink = "";
          words.forEach((_word: string) => {
            const word = _word.replace(/ /g, "");
            if (sink.length + word.length + 1 < limit) {
              sink += `${word} `;
            } else if (word.length > limit) {
              for (let i = 0; i < word.length; i++) {
                if (sink.length < limit) {
                  sink += word[i];
                } else {
                  queue.push({
                    data: {
                      message: sink,
                    },
                    image_keys: [],
                  });
                  sink = "";
                  sink += word[i];
                }
                if (i === word.length - 1) {
                  sink += " ";
                }
              }
            } else {
              queue.push({
                data: {
                  message: sink,
                },
                image_keys: [],
              });
              sink = "";
              sink += `${word} `;
            }
          });
          if (sink.length > 0) {
            queue.push({
              data: {
                message: sink,
              },
              image_keys: [],
            });
          }
          if (
            this.attachedImagesToBeSent &&
            this.attachedImagesToBeSent.length > 0 &&
            queue.length > 0
          ) {
            queue[queue.length - 1].image_keys = this.attachedImagesToBeSent;
          }
        }

        const sendChunk = async (chunk: MessageQueueChunk) => {
          let send_endpoint;
          let req_data = {};
          const isBulkMessage =
            this.activeConversation?.id === this.bulkMessageId;

          if (isBulkMessage) {
            send_endpoint = "conversations/send-bulk-message-to-loyal-members/";
            req_data = {
              message_data: {
                message: chunk?.data?.message,
              },
              image_keys: chunk?.image_keys,
              participants: this.selectedContacts,
            };
          } else {
            send_endpoint = `conversations/${this.activeConversationId}/messages/`;
            req_data = chunk;
          }
          const { data } = await api.messages.post(req_data, send_endpoint);

          if (isBulkMessage) {
            this.setIsBulkMessageView(false);
            this.setIsComposing(false);
            this.setIsSearchingView(false);
            this.unSelectAllContacts();
            this.readConversation(null);
          } else {
            const newMessages = [data, ...this.activeConversationMessages];
            this.activeConversationMessages =
              this.sortConversationMessagesByNewest(newMessages).reverse();
            this.conversations = this.conversations.map(conversation => {
              if (conversation.id === this.activeConversationId) {
                conversation.last_message = data;
                if (conversation.is_draft) {
                  conversation.is_draft = false;
                }
              }
              return conversation;
            });
          }
          if (!data.can_receive_message && this.activeConversationId)
            this.setTempCanMemberReceiveMsg(this.activeConversationId);
          return;
        };
        for (const x of queue) {
          if (x?.image_keys?.length || x.data?.message) await sendChunk(x);
        }
        this.setMessage("");
        this.sendMessageError = null;
      } catch (error) {
        snackbarStore.enqueueSnackbar({
          message: {
            id: "messages.error.send-message",
            default: "There was an error while sending message",
          },
          variant: SnackbarVariants.ERROR,
        });

        this.log("Message Center: Error sending message", error);
        this.sendMessageError =
          error?.response?.data?.non_field_errors?.[1]?.message_i18n ||
          error?.response?.data?.non_field_errors?.[0] ||
          null;
      } finally {
        this.setAttachImagesToBeSent([]);
      }
    }
  };

  setContactsSearchTerm = (term: string) => {
    this.contactsSearchTerm = term;

    if (term === "") {
      this.nextContactsUrl = "";
    }
  };

  setConversationsSearchTerm = (term: string) => {
    this.conversationsSearchTerm = term;

    if (term === "") {
      this.nextConversationsUrl = "";
    }
  };

  deleteNewsAndPolicy = async (notice: MessageCenterConversationDetails) => {
    try {
      if (this.systemMessageUser) {
        await this.sendMessageToMember(this.systemMessageUser?.id);
        this.setMessage("");
        const noticeBlock = `<h6><strong>${notice?.title}</strong></h6> <div>${
          notice?.start_date
            ? format(new Date(notice?.start_date), "MM/dd/yyyy")
            : ""
        }</div> <div>${notice?.body}</div>`;

        await this.sendMessage(noticeBlock);
      }

      await api.messages.post(
        {
          notifications: [notice?.id],
        },
        `my-notifications/archive/`
      );
      this.newsAndPolicies = this.newsAndPolicies.filter(
        item => !notice?.id.includes(item?.id)
      );

      this.isDeleting = false;
      this.readConversation(null);

      const { data: unreadCountData } = await api.messages.get(
        `my-notifications/unread-count/`
      );
      const unreadCount = unreadCountData?.count;

      if (unreadCount !== undefined) {
        this.unreadNewsAndPolicyCount = unreadCount;
      }

      snackbarStore.enqueueSnackbar({
        message: {
          id: "messages.success.delete-conversation",
          default: "Deleted Conversation Successfully",
        },
        variant: SnackbarVariants.SUCCESS,
      });
      this.log("Message Center: Successfully deleted notice");
    } catch (error) {
      snackbarStore.enqueueSnackbar({
        message: {
          id: "messages.error.delete-conversation",
          default: "There was an error while deleting conversation",
        },
        variant: SnackbarVariants.ERROR,
      });
      this.log("Message Center: Error deleting notice", error);
    }
  };

  deleteConversations = async () => {
    window["deleteConversations"] = this.deleteConversations;
    const isDeletingMultiple =
      this.selectedConversations && this.selectedConversations.length > 1;
    const isDeletingActiveConversation =
      this.selectedConversations && this.selectedConversations.length <= 0;

    try {
      let conversationsToDelete = isDeletingActiveConversation
        ? [this.activeConversationId]
        : this.selectedConversations;

      // if active conversation is a draft, just remove it from the list of conversations
      // no need to delete
      const activeConversation = this.conversations.find(
        conversation => conversation.id === this.activeConversationId
      );
      if (
        conversationsToDelete.includes(this.activeConversationId) &&
        activeConversation?.is_draft
      ) {
        conversationsToDelete = conversationsToDelete.filter(
          conversationId => conversationId !== this.activeConversationId
        );
      }

      if (conversationsToDelete.length > 0) {
        await api.messages.post(
          {
            conversations: conversationsToDelete,
          },
          `conversations/archive/`
        );
        this.conversations = this.conversations.filter(
          conversation => !conversationsToDelete.includes(conversation.id)
        );
      }

      this.setSelectedConversations([]);

      this.isDeleting = false;
      this.readConversation(null);
      this.conversations = this.conversations.filter(
        conversation => conversation.id !== this.activeConversationId
      );
      snackbarStore.enqueueSnackbar({
        message: {
          id: isDeletingMultiple
            ? "messages.success.delete-conversations"
            : "messages.success.delete-conversation",
          default: isDeletingMultiple
            ? "Removed {count} conversations successfully"
            : "Removed this conversation successfully",
          parameters: {
            count: conversationsToDelete.length,
          },
        },
        variant: SnackbarVariants.SUCCESS,
      });
    } catch (error) {
      snackbarStore.enqueueSnackbar({
        message: {
          id: isDeletingMultiple
            ? "messages.error.delete-conversations"
            : "messages.error.delete-conversation",
          default: isDeletingMultiple
            ? "messages.error.delete-conversations"
            : "messages.error.delete-conversation",
        },
        variant: SnackbarVariants.ERROR,
      });
      this.log("Message Center: Error deleting conversations", error);
    }
  };

  selectContacts = (
    contact: MessageCenterContactDetails,
    multiple: boolean = false
  ) => {
    if (!this.selectedContacts) {
      this.setSelectedContacts([]);
    }
    if (this.selectedContacts) {
      const contactId = contact?.id;
      const idIndex = this.selectedContacts.indexOf(contactId).toString();
      if (multiple) {
        if (idIndex === "-1") {
          this.setSelectedContacts([...this.selectedContacts, contactId]);
        } else {
          const filteredContacts = this.selectedContacts.filter(
            id => id !== contactId
          );
          this.setSelectedContacts(filteredContacts);
        }
      } else {
        this.setSelectedContacts([contactId]);
      }
    }
  };

  unSelectAllContacts = () => {
    this.setSelectedContacts([]);
  };

  selectAllContacts = () => {
    if (this.isBulkMessageView) {
      this.contacts.forEach(
        contact =>
          this.selectedContacts.indexOf(contact?.id) < 0 &&
          contact.can_receive_bulk_message &&
          this.selectContacts(contact, true)
      );
    } else {
      this.contacts.forEach(
        contact =>
          this.selectedContacts.indexOf(contact?.id) < 0 &&
          this.selectContacts(contact, true)
      );
    }
  };

  setSelectedContacts = (contacts: string[]) => {
    this.selectedContacts = contacts;
  };

  setIsComposing = (composing: boolean) => {
    this.isComposing = composing;

    if (composing) {
      this.getContacts();
    }
  };

  setIsSearchingView = (searching: boolean) => {
    this.isSearchingView = searching;
  };

  setIsBulkMessageView = (is_bulk_view: boolean) => {
    this.isBulkMessageView = is_bulk_view;
  };

  selectConversations = (conversation: MessageCenterConversationDetails) => {
    if (this.selectedConversations) {
      const conversationId = conversation?.id;
      const idIndex = this.selectedConversations
        .indexOf(conversationId)
        .toString();
      if (idIndex === "-1") {
        this.setSelectedConversations([
          ...this.selectedConversations,
          conversationId,
        ]);
      } else {
        const filteredConversations = this.selectedConversations.filter(
          id => id !== conversationId
        );
        this.setSelectedConversations(filteredConversations);
      }
    }
  };

  selectAllConversations = () => {
    if (this.selectedConversations) {
      this.unSelectAllConversations();
      this.conversations.forEach(conversation => {
        this.selectConversations(conversation);
      });
    }
  };

  unSelectAllConversations = () => {
    this.setSelectedConversations([]);
  };

  setSelectedConversations = (conversations: string[]) => {
    this.selectedConversations = conversations;
  };

  resetCrucialVariblesState = () => {
    const variables: Array<keyof MessageStore> = [
      "isDeleting",
      "isComposing",
      "isSearchingView",
      "message",

      "selectedContacts",
      "nextContactsUrl",
      "isContactsLoading",
      "isContactsLoadingMore",
      "isSearchingContacts",
      "contactsSearchTerm",

      "selectedConversations",
      "activeConversation",
      "activeConversationMessages",
      "nextConversationsUrl",
      "isConversationsLoading",
      "isConversationsLoadingMore",
      "conversationsSearchTerm",
      "isSearchingConversations",

      "nextMessagesUrl",
      "isMessagesLoading",
      "isMessagesLoadingMore",

      "requestTimeout",
    ];

    variables.forEach((variable: any) => {
      const instanceVar = this[variable];
      const initialDataVar = InitialData[variable];

      if (
        typeof instanceVar !== "undefined" &&
        (typeof initialDataVar !== "undefined" || initialDataVar === undefined)
      ) {
        this[variable] = initialDataVar;
      }
    });
  };

  public uploadImages = async (
    image: File,
    name: string,
    fileSize?: number
  ) => {
    const { data } = await api.messages.post(
      {
        file_name: name,
        file_size: fileSize,
      },
      `conversations/sign-image-upload-new/`
    );

    const { key, presigned_post, view_url } = data;

    const uploadData = createFormData(image, presigned_post.form_data);
    await rawAxios.post(presigned_post.post_url, uploadData);

    return { view_url, key };
  };

  public setMessageFontSize = (fontSize: TFontSize) => {
    this.messageFontSize = fontSize;
  };

  public setMessageInputFocused = (isFocused: boolean) => {
    this.messageInputFocused = isFocused;
  };

  public setAttachImagesToBeSent = (imgs: string[]) => {
    this.attachedImagesToBeSent = imgs;
  };

  public setimageKeysTobeSubmitted = (imgKeys: string[]) => {
    this.imageKeysTobeSubmitted = imgKeys;
  };

  public setCanCurrentMemberReceiveMsg = (canReceiveMsg: boolean) => {
    this.canCurrentMemberReceiveMsg = canReceiveMsg;
  };
  public setTempCanMemberReceiveMsg = (canMemeberIDReceiveMsg: string) => {
    this.canTempMemberReceiveMsg.push(canMemeberIDReceiveMsg);
  };

  public logArchivedMsgVisit = async () => {
    await axios.post(`logging/track-archived-messages-clicks/`);
  };

  public logMsgCenterVisit = async () => {
    if (this.isOnMessagesPage || this.isMessagesMenuInNavbarOpen) {
      await axios.post(`logging/track-message-center-visit/`);
    }
  };

  public handleNewMessageReceived = data => {
    // don't update messages if broadcaster is open, can be removed once new model center as a whole is deployed
    try {
      this.log("Message Center: handleNewMessageReceived start", data);
      if (!stores?.layoutStore?.isBroadcastPage) {
        this.getUnreadConversations();
      }
    } catch (error) {
      this.log("Message Center: handleNewMessageReceived fail", error);

      throw error;
    } finally {
      this.log("Message Center: handleNewMessageReceived finished");
    }
  };

  public handleMessageRejection = data => {
    try {
      const message = data as MessageCenterMessageDetails;
      this.log("Message Center: handleMessageRejection start", message);

      const target_message = this.activeConversationMessages?.find(
        msg => msg?.id === message?.id
      );
      if (!!target_message) {
        this.refreshActiveConversationMessages();
      }
    } catch (error) {
      this.log("Message Center: handleMessageRejection fail", error);

      throw error;
    } finally {
      this.log("Message Center: handleMessageRejection finished");
    }
  };
}
