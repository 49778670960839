import { ModelImage } from "containers/_components/media-manager/store/types";
import { HashMap } from "library/core/interfaces";
import { Alpha3Language, NullableString } from "library/core/types";
import { SIGNUP_AGREEMENT_TYPE } from "common/sign-up/enums";
import { UserAccountStatus } from "common/my-page/stores/profile/enums";

export type ModelKinkSpecialityCommon = {
  id: string;
  name: string;
};
export type ModelKink = ModelKinkSpecialityCommon;
export type ModelProductPricing = {
  buzz_price: number;
  buzz_status: "on" | "off";
  call_me: number;
  cam2cam_chat: number;
  chat_settings: null | Object;
  connexion_one_way: number;
  connexion_two_way: number;
  is_happy_hour: boolean;
  is_party_allowed: boolean;
  is_voyeur_allowed: boolean;
  is_wheel_of_fun_allowed: boolean;
  nude_chat: number;
  party_chat: number;
  private_chat: number;
  private_chat_status: "on" | "off";
  superbuzz_price: number;
  text_me: number;
  voyeur_chat: number;
  wheel_of_fun: number;
  wheel_of_fun_rewards: null | any[];
  whisper_message: number;
};

export type StudioModelAccessRights = {
  is_earnings_and_transactions_visible: boolean;
  is_pricing_settings_change_allowed: boolean;
  is_quick_text_changes_allowed: boolean;
  is_transaction_detail_visible: boolean;
  is_allow_to_be_invited_allowed: boolean;
  is_allow_to_invite_allowed: boolean;
};

export type ModelSpeciality = ModelKinkSpecialityCommon;
export type ModelAttribute = ModelKinkSpecialityCommon;

type UserProfileBase = {
  id: string;
  country: string;
  iso_country: string;
  first_name: string;
  last_name: string;
  other_name: string;
  name: string;
  username: string;
  gender: Gender;
  region: string;
  profile_image: any;
  signed_agreements: Object[];
  tax_form: TaxForm;
  missing_signup_fields: string[];
  status: UserAccountStatus;
  email: string;
  last_login?: {
    country: string;
    ip: string;
    subdivision: string;
  };
};

export type NonManagerProfileBase = {
  age: number;
  languages: Alpha3Language[];
  non_nude_profile_image: ModelImage | null;
  profile_image: ModelImage | null;
  pending_non_nude_profile_image: ModelImage | null;
  pending_profile_image: ModelImage | null;
};

export type RegulatedProfile = {
  deny_reasons: DenyReason[];
};

export type DenyReasonKeys =
  | "document_front"
  | "document_back"
  | "document_verification"
  | "document_id_cert"
  | "Broadcasting Agreement"
  | "Performer's Acknowledgement and Agreement"
  | "Broadcast Release"
  | "miscellaneous"
  | "additional_notes"
  | "expire_date"
  | "document"
  | "issue_country";

export type DenyReason = {
  property: DenyReasonKeys;
  text: string;
  category:
    | "document"
    | "signed_agreement"
    | "miscellaneous"
    | "additional_notes";
};

export type Agreement = {
  agreement_type: SIGNUP_AGREEMENT_TYPE;
  text: string;
  id: string;
  version: number;
};

export type ModelProfile = RegulatedProfile &
  UserProfileBase &
  NonManagerProfileBase & {
    access_rights?: StudioModelAccessRights;
    fun_fact: string;
    pending_fun_fact: string;
    about_me: string;
    screen_name: string;
    astrological_sign: string;
    sexual_orientation: string;
    body_type: string;
    hair_color: string;
    eye_color: string;
    penis_size?: string;
    breast_size?: string;
    public_birthdate: string;
    birthdate: string;
    ethnicity: string;
    shaved: boolean;
    description: string;
    favorites_stats: { growth: number; last_30_days: number };
    happy_hour_price: number;
    whisper_message_price: number;
    private_chat_price: number;
    private_show_rating: number;
    voyeur_chat_price: number;
    party_chat_price: number;
    nude_chat_price: number;
    fan_club_private_chat_price: number;
    fan_club_party_chat_price: number;
    call_me_price: number;
    text_me_price: number;
    buzzmode_price: number;
    wheel_of_fun_price: number;
    connexion_price: number;
    public_profile?: ModelProfile;
    kinks: ModelKink[];
    attributes?: ModelAttribute[];
    product_pricing: ModelProductPricing;
    specialities: ModelSpeciality[];
    grouped_star_ratings: HashMap<number>;
    public_profile_image: string;
    public_profile_images: any;
    is_model_profile: boolean;
    is_model_studio: boolean | null;
    marital_status: string;
    height: number;
    is_broadcast_allowed?: boolean;
    is_broadcast_disallowed_until?: string;
  };

export type StudioProfile = RegulatedProfile &
  UserProfileBase & {
    address: string | null;
    address2: string | null;
    phone_number: string | null;
    state: string | null;
    city: string | null;
    zip_code: string | null;
    tax_form: TaxForm;
  };

export type NonManagerProfile = ModelProfile;

export type Profile = NonManagerProfile | StudioProfile;

export type MyFansPageMemberProfileBase = {
  id: string;
  username: string;
  cobrand_domain: string | null;
  site: string;
  last_login: string;
  profile_picture: string | null;
  created_at: string;
  registration_date: string;
  approval_date: string;
  age: number;
  gender: Gender;
  user_id?: string;
};

export type Gender = "male" | "female" | "trans";
export enum GenderEnum {
  M = "Male",
  F = "Female",
  T = "Trans",
}

export enum GenderShortEnum {
  M = "M",
  F = "F",
  T = "T",
}

export type TaxForm = {
  created_at: NullableString;
  modified_at: NullableString;
  deny_reasons?: {
    reason: TaxFormDenyReasons;
    additional_notes: NullableString;
  };
  status: TaxFormStatus;
  tax_file: NullableString;
};

export enum TaxFormStatus {
  INCOMPLETE = "INCOMPLETE",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
}

export enum TaxFormDenyReasons {
  SUBMITTED_INCORRECT_TAX_FORM = "submitted_incorrect_tax_form",
  NO_TAX_FORM_SUBMITTED = "no_tax_form_submitted",
  TAX_FORM_HAS_MISSING_OR_INCORRECT_DATA = "tax_form_has_missing_or_incorrect_data",
  SUBMITTED_TAX_FORM_IS_NOT_CLEAR = "submitted_tax_form_is_not_clear",
}

export type NotificationType = "warning" | "info" | "success" | "error";

export type Notification = {
  id: string;
  type: NotificationType;
  content: JSX.Element;
  onClickReadMore: (id: string) => void;
  onClickDismiss: (id: string) => void;
  isDismissed: boolean;
  isDismissable: boolean;
};
