import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import TailwindText from "library/components/_tailwind/text";
import { MessageCenterConversationDetails } from "./stores/messages/types";
import { inject, observer } from "mobx-react";
import MessageCenterDeleteModal from "./messages-delete-modal";
import ModalStore from "library/core/stores/modal";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import TailwindBox from "library/components/_tailwind/box";

type Props = {
  goBack: () => void;
  activeConversation: MessageCenterConversationDetails;
  modalStore?: ModalStore;
  layoutStore?: LayoutStore;
};

const MessageCenterReaderSubHeader: React.ComponentType<Props> = ({
  goBack,
  activeConversation,
  modalStore,
  layoutStore,
}) => {
  const { openPrimaryModal } = modalStore!;
  const { isMobileDevice } = layoutStore!;
  return (
    <TailwindFlex
      width={"w-full"}
      justifyContent={"justify-start"}
      padding={["p-2"]}
      backgroundColor='bg-lightBlue'
      borderWidth={["border-b-2"]}
      borderColor={"border-primary-bg-color"}>
      <TailwindFlex width={"w-full"} justifyContent={"justify-start"}>
        <TailwindButton
          width='w-8'
          height='h-8'
          maxWidth='max-w-min'
          padding={["p-0"]}
          onClick={goBack}
          alignSelf='self-center'
          rightIconProps={{
            name: "keyboard_arrow_left",
            fontSize: "text-4xl",
            textColor: "text-gray-400",
          }}
        />
        <TailwindText
          fontSize={"text-lg"}
          margin={["ml-1", "mt-1"]}
          fontWeight='font-bold'
          as={"span"}
          textAlign={"text-left"}>
          {activeConversation?.participants?.length > 1
            ? "Compose Bulk Message"
            : activeConversation?.participants[0].username}
        </TailwindText>
      </TailwindFlex>
      {/* @TODO */}
      {isMobileDevice && activeConversation?.last_message?.id && (
        <TailwindBox style={{ width: "40px", height: "40px" }}>
          <TailwindButton
            backgroundColor='bg-tertiary-bg-color'
            padding={["p-0"]}
            width={"w-full"}
            height={"h-full"}
            rounded={true}
            justifyContent='justify-center'
            alignItems='items-center'
            onClick={() => {
              openPrimaryModal(<MessageCenterDeleteModal />, {
                showNativeCloseButton: false,
              });
            }}
            rightIconProps={{
              name: "delete",
              fontSize: "text-3xl",
              textColor: "text-secondary-color",
              style: { width: "40px", height: "40px" },
            }}
          />
        </TailwindBox>
      )}
    </TailwindFlex>
  );
};

export default inject(
  "modalStore",
  "layoutStore"
)(observer(MessageCenterReaderSubHeader));
