export enum IShowType {
  FREE = "FREE",
  TIPPING = "GOAL_UP",
  CURTAIN_DROPPED = "GOAL_DOWN",
  NUDE = "NUDE",
  NONE = "NONE",
  PRIVATE = "PRIVATE",
  GROUP = "GROUP",
  VOYEUR = "VOYEUR",
  C2C = "C2C",
  PAUSED = "PAUSED",
  ADMIN = "ADMIN",
}
export enum IGameSettings {
  PLEASURE = "PLEASURE",
  CONNEXION = "CONNEXION",
  WHEEL = "WHEEL",
  NONE = "NONE",
}
export enum BroadcastType {
  Tipped,
  Gift,
  Buzz,
  SuperBuzz,
  ViewStop,
  WheelOfFun,
  TopAdmirer,
  BroadcastStart,
  BroadcastStop,
  ShowStart,
  ShowStop,
  ViewStart,
}
export enum ShowStopReasons {
  ENTERED_GOAL = "ENTERED_GOAL",
  ENTERED_NUDE = "ENTERED_NUDE",
  ENTERED_CURTAIN_DOWN = "ENTERED_CURTAIN_DOWN",
  LAST_USER_LEFT = "LAST_USER_LEFT",
  ENTERED_PRIVATE = "ENTERED_PRIVATE",
  ENTERED_FREE = "ENTERED_FREE",
  ENTERED_GROUP = "ENTERED_GROUP",
  ADMIN_KICKED_STREAM = "ADMIN_KICKED_STREAM",
  ENTERED_ADMIN = "ENTERED_ADMIN"
}

export enum RecordingReasonsEnum {
  AUTOMATIC = "AUTOMATIC", //meaning all paid shows
  ON_DEMAND = "ON_DEMAND",
}

export enum MainTab {
  VIEWERS = "viewers",
  SHOW_TYPES = "showTypes",
}

export enum ShowTab {
  FREE = "free",
  NUDE = "nude",
  TIPPING = "goal",
}

export enum EnterShowSubType {
  NONE = "none",
  PRIVATE = "Private shows",
  GOAL_SHOW = "Goal show",
  SPIN = "Spin game",
  TIPS = "Tips only",
  CURTAIN_DROPPED = "Curtain dropped",
}
export enum PrivateShowSelection {
  NONE = "",
  ASK = "Ask me first",
  YES = "Just start the show",
  NO = "No",
}

export enum IQuickMessageStatuses {
  incomplete = "INCOMPLETE",
  pending = "PENDING",
  approved = "APPROVED",
}

export enum BroadcastStreamState {
  "stopped" = "stopped",
  "started" = "started",
  "stoppingStream" = "stoppingStream",
  "startingStream" = "startingStream",
}

export enum WebRTCStatus {
  Playing = "playing",
  Stopped = "stopped",
}
