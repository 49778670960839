import React, { useCallback } from "react";
import "./styles.scss";
import { inject, observer } from "mobx-react";
import MemberBox from "./member-box";
import BroadcastMemberItem from "./member-item";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import { BroadcastStreamState, IShowType } from "../_stores/broadcast/enums";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindBox from "library/components/_tailwind/box";
import TailwindText from "library/components/_tailwind/text";
import NodeChatStore from "../_stores/nodechat/NodeChatStore";
import LanguageStore from "library/core/stores/language/LanguageStore";

type Props = {
  nodeChatStore?: NodeChatStore;
  broadcastStore?: BroadcastStore;
  languageStore?: LanguageStore;
};

const BroadcastViewers: React.FC<Props> = ({
  nodeChatStore,
  broadcastStore,
  languageStore,
}) => {
  const {
    selectedMemberId,
    selectMemberProfile,
    currentShowType,
    streamState,
  } = broadcastStore!;
  const { intl } = languageStore!;
  const { members, voyeurMembers } = nodeChatStore!;

  const handleCloseEvent = useCallback(() => {
    selectMemberProfile("");
  }, []);

  return (
    <TailwindFlex
      width='w-full'
      className={["BroadcastViewers"]}
      flexDirection={"flex-col"}>
      <TailwindFlex
        className={["BroadcastViewers__member-list"]}
        flexDirection={"flex-col"}>
        {streamState === BroadcastStreamState.started &&
          (members || []).length > 0 &&
          members?.filter(member => member.online === true)
            .map(member => {
              return (
                <BroadcastMemberItem
                  key={member.id}
                  member={member}
                  index={member.rank || 0}
                />
              );
            })}
        {streamState === BroadcastStreamState.started &&
          (members || []).length === 0 && (
            <TailwindBox
              width='w-full'
              padding={["p-4"]}
              textAlign='text-center'
              textColor='text-main-color'>
              {intl.formatMessage({
                id: "broadcast.broadcastViewer.thereAreNoMembers",
                defaultMessage: "There are no members in the room",
              })}
            </TailwindBox>
          )}
        {streamState === BroadcastStreamState.stopped && (
          <TailwindBox
            width='w-full'
            padding={["p-4"]}
            textAlign='text-center'
            textColor='text-main-color'>
            {intl.formatMessage({
              id: "broadcast.broadcastViewer.youAreOffline",
              defaultMessage:
                "You are currently offline, broadcast to go online and attract viewers.",
            })}
          </TailwindBox>
        )}

        {(currentShowType === IShowType.PRIVATE ||
          currentShowType === IShowType.GROUP) && (
          <TailwindBox
            className={["BroadcastViewers__voyeur-list"]}
            textColor={"text-main-color"}>
            <TailwindBox
              className={["BroadcastViewers__voyeur-list-title"]}
              textColor={"text-main-color"}>
              {intl.formatMessage({
                id: "broadcast.broadcastViewer.voyeurMembers",
                defaultMessage: "Voyeur Members",
              })}
              <TailwindText margin={["ml-1"]}>
                {voyeurMembers.length}
              </TailwindText>
            </TailwindBox>
            {streamState === BroadcastStreamState.started &&
              voyeurMembers.map(member => {
                return (
                  <BroadcastMemberItem
                    key={member.id}
                    member={member}
                    index={member.rank || 0}
                    isVoyeur
                  />
                );
              })}
            {voyeurMembers.length === 0 && (
              <TailwindBox
                width='w-full'
                padding={["p-4"]}
                textAlign='text-center'>
                {intl.formatMessage({
                  id: "broadcast.broadcastViewer.noVoyeurMembers",
                  defaultMessage: "There are no voyeur members",
                })}
              </TailwindBox>
            )}
          </TailwindBox>
        )}
      </TailwindFlex>
      <TailwindFlex
        className={["BroadcastViewers__member-box"]}
        flexDirection={"flex-col"}>
        {!!selectedMemberId && (
          <MemberBox
            onClose={handleCloseEvent}
            key={selectedMemberId}
            memberId={selectedMemberId}
          />
        )}
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default inject("nodeChatStore", "broadcastStore", "languageStore")(observer(BroadcastViewers));
