import React, { useCallback, useMemo } from "react";
import { toArray } from "react-emoji-render";
import {
  MessageCenterConversationDetails,
  MessageCenterMessageDetails,
} from "./stores/messages/types";
import TailwindFlex from "library/components/_tailwind/flex";
// import MessagesCheckbox from "./messages-checkbox";
import Avatar from "library/components/avatar";
import TailwindText from "library/components/_tailwind/text";
import { inject, observer } from "mobx-react";
import ProfileStore from "../my-page/stores/profile/ProfileStore";
import classnames from "classnames";
import MessageStore from "./stores/messages/MessageStore";
import sanitizeHtml from "sanitize-html";
import { TPseudoClasses } from "library/styles/tailwind-classnames";
import TailwindBox from "library/components/_tailwind/box";
import moment from "moment";
import MessageCenterNavigatorCheckMark from "./message-navigator-checkmark";
import { WrappedComponentProps, injectIntl } from "react-intl";

type Props = {
  conversation: MessageCenterConversationDetails;
  readConversation: (conversation: MessageCenterConversationDetails) => void;
  selectConversation?: (conversation: MessageCenterConversationDetails) => void;
  onMultipleMatchesClick?: (
    conversation: MessageCenterConversationDetails
  ) => void;
  isActive?: boolean;
  isSelected?: boolean;
  showSelectionCheckBox?: boolean;
  profileStore?: ProfileStore;
  messageStore?: MessageStore;
  conversationsSearchTerm?: string;
  isMobileAndSearching?: boolean;
  canReceiveMessage: boolean;
  setCanCurrentMemberReceiveMsg: (canReceiveMsg: boolean) => void;
  isBulkMessageView?: boolean;
  userName?: string;
  showBorder?: boolean;
};

const MessageCenterConversation: React.ComponentType<
  Props & WrappedComponentProps
> = ({
  conversation,
  readConversation,
  selectConversation,
  onMultipleMatchesClick,
  isActive,
  isSelected,
  // showSelectionCheckBox = true,
  profileStore,
  messageStore,
  setCanCurrentMemberReceiveMsg,
  canReceiveMessage,
  conversationsSearchTerm,
  // isMobileAndSearching,
  userName,
  showBorder,
  intl,
}) => {
  const MSG_PREVIEW_CHAR_COUNT_BUFFER = 13;
  const { profile } = profileStore!;
  const { systemMessageUserIds } = messageStore!;

  const searchedMessagesCount = useMemo(() => {
    const num_searched_messages = conversation?.searched_messages?.length || 0;

    return num_searched_messages;
  }, [conversation?.searched_messages]);

  const onClickConversation = useCallback(() => {
    if (searchedMessagesCount > 1) {
      onMultipleMatchesClick?.(conversation);
    } else {
      readConversation(conversation);
      setCanCurrentMemberReceiveMsg(canReceiveMessage);
    }
  }, [readConversation, searchedMessagesCount]);

  const toggleSelectedConversations = useCallback(() => {
    if (selectConversation) {
      selectConversation(conversation);
    }
  }, [selectConversation]);

  const formatMessagePreview = useCallback(
    (message: MessageCenterMessageDetails) => {
      let conversation_msg = message?.preview || message?.data?.message || "";
      const sender = message?.sender;

      // last_message.sender is an id instead of a sender dict so we need to check for string
      const senderUsername = sender?.username;
      const is_model_sender = senderUsername
        ? senderUsername === userName
        : isUserTheSender(sender);

      conversation_msg =
        conversation_msg && conversation_msg !== "null"
          ? conversation_msg
          : intl.formatMessage({
              id: "messageCenter.attachments",
              defaultMessage: "Attachments",
            });

      if (conversationsSearchTerm && conversation_msg) {
        // find the part of the string where the keyword is visible
        const keyword_index = conversation_msg.indexOf(conversationsSearchTerm);
        if (keyword_index > MSG_PREVIEW_CHAR_COUNT_BUFFER) {
          conversation_msg = `...${conversation_msg.slice(
            keyword_index - MSG_PREVIEW_CHAR_COUNT_BUFFER
          )}`;
        }

        const content = toArray(conversation_msg);
        const new_msg = content.reduce((previous, current) => {
          if (
            typeof current === "string" &&
            conversationsSearchTerm &&
            current?.includes(conversationsSearchTerm)
          ) {
            const current_html = current.replace(
              `${conversationsSearchTerm}`,
              `<strong>${conversationsSearchTerm}</strong>`
            );
            return previous + current_html;
          } else if (typeof current === "string") {
            return previous + current;
          }
          return previous + (current?.["props"]?.children || "");
        }, "");
        conversation_msg = new_msg?.toString() || conversation_msg;
      }

      return `${
        is_model_sender
          ? `${intl.formatMessage({
              id: "verbiage.you",
              defaultMessage: "You",
            })}: `
          : ""
      }${sanitizeHtml(conversation_msg, {
        allowedTags: ["strong"],
      })}`;
    },
    []
  );

  const conversationParticipants = useMemo(
    () =>
      conversation?.participants.filter(
        participant => participant.username !== profile.username
      ),
    [conversation.participants]
  );

  const firstConversationParticipant = useMemo(
    () => conversationParticipants[0],
    [conversationParticipants]
  );

  const lastConversationMessage = useMemo(
    () => conversation?.last_message,
    [conversation?.last_message]
  );

  const lastConversationMessageDate = useMemo(
    () =>
      lastConversationMessage?.created_at
        ? moment(lastConversationMessage?.created_at).format("MMM D")
        : "",
    [lastConversationMessage]
  );

  const lastSearchedMessage = useMemo(() => {
    return searchedMessagesCount > 0 &&
      conversation?.searched_messages?.[searchedMessagesCount - 1].preview
      ? conversation?.searched_messages?.[searchedMessagesCount - 1]
      : "";
  }, [conversation?.searched_messages, searchedMessagesCount]);

  const lastSearchedMessageDate = useMemo(
    () =>
      lastSearchedMessage && lastSearchedMessage?.created_at
        ? moment(lastSearchedMessage?.created_at).format("MMM D")
        : "",
    [lastSearchedMessage]
  );

  const conversationParticipantsNames = useMemo(
    () =>
      conversationParticipants
        .map(participant => participant.username)
        .join(", "),
    [conversationParticipants]
  );

  const classNameValues = useMemo(() => {
    if (!conversation.unread_count && !isActive)
      return [
        "bg-quaternary-bg-color",
        "hover:bg-highlight-color",
      ] as TPseudoClasses[];

    if (isActive) ["hover:bg-highlight-color"] as TPseudoClasses[];

    return ["hover:bg-highlight-color"] as TPseudoClasses[];
  }, [isActive, conversation]);

  const participantName = useMemo(() => {
    if (firstConversationParticipant?.username) {
      return firstConversationParticipant?.username;
    }
  }, [firstConversationParticipant]);

  const isAdmin = useMemo(() => {
    if (systemMessageUserIds.includes(conversationParticipants?.[0]?.id)) {
      return true;
    }

    return false;
  }, [conversationParticipants]);

  const showCount = useMemo(
    () => (conversation.unread_count > 0 ? conversation.unread_count : false),
    [lastConversationMessage, conversation.unread_count]
  );

  const isUserTheSender = useCallback(
    userId => {
      const foundParticipant = conversationParticipants?.find(
        participant => participant?.id === userId
      );

      return !foundParticipant; // user will not be found since it is filtered from messageStore
    },

    [conversationParticipants]
  );

  return (!conversationsSearchTerm && lastConversationMessage) ||
    (conversationsSearchTerm && lastSearchedMessage) ? (
    <TailwindFlex
      className={["MessageNavigatorConversation"]}
      backgroundColor={
        isActive || isSelected ? "bg-highlight-color" : "bg-quaternary-bg-color"
      }
      cursor={"cursor-pointer"}
      pseudoClasses={classNameValues}>
      <TailwindFlex
        borderWidth={showBorder ? ["border-t-2"] : []}
        borderColor={"border-primary-bg-color"}
        onClick={onClickConversation}
        height={"h-full"}
        style={{
          paddingRight: "15px",
        }}
        justifyContent='justify-center'
        alignItems='items-center'>
        {/* {showSelectionCheckBox && !isMobileAndSearching && (
        <TailwindFlex
          width={"w-auto"}
          alignItems={"items-center"}
          justifyContent={"justify-start"}
          padding={["pl-1"]}>
          <MessagesCheckbox
            isChecked={isSelected}
            onChange={toggleSelectedConversations}
            backgroundColor={"bg-white"}
          />
        </TailwindFlex>
      )} */}
        <TailwindFlex
          width={"w-auto"}
          alignItems={"items-center"}
          justifyContent={"justify-center"}
          position={"relative"}
          onClick={e => {
            e.stopPropagation();
            toggleSelectedConversations();
          }}
          style={{
            paddingLeft: "15px",
          }}>
          {isSelected && (
            <TailwindFlex
              position='absolute'
              zIndex='z-20'
              style={{
                top: "-2px",
                left: "0",
              }}>
              <MessageCenterNavigatorCheckMark />
            </TailwindFlex>
          )}
          <Avatar
            photoURL={firstConversationParticipant?.profile_picture}
            username={firstConversationParticipant?.username}
            size={"small"}
            randomBackgroundColor={true}
          />
        </TailwindFlex>
        <TailwindFlex
          width={"w-auto"}
          flexGrow={"flex-grow"}
          flexShrink={"flex-shrink"}
          alignItems={"items-center"}
          overflow={["overflow-x-hidden"]}
          style={{
            paddingLeft: "15px",
          }}>
          <TailwindFlex width={"w-9/12"} flexDirection={"flex-col"}>
            <TailwindFlex padding={["pr-2"]}>
              <TailwindText
                style={{
                  fontSize: "14px",
                }}
                fontWeight={
                  conversation.unread_count ? "font-bold" : "font-normal"
                }
                textOverflow={"truncate"}>
                {conversationParticipants.length === 1
                  ? participantName
                  : conversationParticipantsNames}
              </TailwindText>
              {isAdmin && (
                <TailwindText
                  verticalAlign={"align-middle"}
                  borderRadius={"rounded-sm"}
                  margin={["ml-2"]}
                  whiteSpace={"whitespace-nowrap"}
                  padding={["p-1"]}
                  backgroundColor={"bg-yellow-300"}
                  fontSize={"text-xs"}>
                  Admin
                </TailwindText>
              )}
            </TailwindFlex>

            <TailwindFlex
              className={["MessageNavigatorConversationPreview"]}
              maxWidth={"max-w-xs"}
              pseudoClasses={["md:max-w-lg"]}
              textColor={"text-secondary-color"}
              style={{
                fontSize: "13px",
              }}>
              {conversationsSearchTerm && searchedMessagesCount > 1 ? (
                <span>
                  {intl.formatMessage(
                    {
                      id: "message-center.number-of-matched-messages",
                      defaultMessage: "{count} matched messages",
                    },
                    {
                      count: searchedMessagesCount,
                    }
                  )}
                </span>
              ) : conversationsSearchTerm && lastSearchedMessage ? (
                // {conversationsSearchTerm && lastSearchedMessage ? (
                <span
                  className={classnames("truncate")}
                  dangerouslySetInnerHTML={{
                    __html: formatMessagePreview(lastSearchedMessage),
                  }}></span>
              ) : lastConversationMessage ? (
                <span
                  className={classnames("truncate")}
                  dangerouslySetInnerHTML={{
                    __html: formatMessagePreview(lastConversationMessage),
                  }}></span>
              ) : null}
            </TailwindFlex>
          </TailwindFlex>

          <TailwindFlex
            width={"w-3/12"}
            flexDirection={"flex-col"}
            alignItems={"items-end"}
            justifyContent={"justify-end"}>
            <TailwindText
              width={"w-full"}
              fontSize={"text-xs"}
              textColor={"text-gray-500"}
              textAlign={"text-right"}>
              {conversationsSearchTerm && lastSearchedMessage
                ? lastSearchedMessageDate
                : lastConversationMessageDate}
            </TailwindText>
            {showCount ? (
              <TailwindBox
                style={{
                  background: "#e9a23b",
                  color: "white",
                  fontStyle: "normal",
                  fontSize: 12,
                }}
                margin={["mt-1"]}
                fontWeight='font-bold'
                textAlign='text-center'
                borderRadius={"rounded-xl"}
                padding={["px-2", "py-0.5"]}>
                {showCount}
              </TailwindBox>
            ) : (
              <TailwindFlex>&nbsp;</TailwindFlex>
            )}
          </TailwindFlex>
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  ) : null;
};
export default injectIntl(
  inject("profileStore", "messageStore")(observer(MessageCenterConversation))
);
