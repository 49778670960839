import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import BroadcastStreamStore from "common/broadcast/_stores/broadcast-stream/BroadcastStreamStore";
import { NANOPLAYER_ELEMENT_ID } from "common/broadcast/_stores/broadcast-stream/consts";
import BroadcastStore from "common/broadcast/_stores/broadcast/BroadcastStore";
import { BroadcastStreamState } from "common/broadcast/_stores/broadcast/enums";

interface Props {
  broadcastStreamStore?: BroadcastStreamStore;
  broadcastStore?: BroadcastStore;
}

const BroadcastOBSPlayback: React.FunctionComponent<Props> = ({
  broadcastStreamStore,
  broadcastStore,
}) => {
  const { streamState } = broadcastStore!;
  const { initObsBroadcastAndInitWebrtcStream } = broadcastStreamStore!;
  useEffect(() => {
    if (streamState === BroadcastStreamState.started) {
      initObsBroadcastAndInitWebrtcStream();
    }
  }, [streamState]);
  return (
    <div
      id={NANOPLAYER_ELEMENT_ID}
      className={`BroadcastOBSTimer__video`}></div>
  );
};

export default inject(
  "broadcastStreamStore",
  "broadcastStore"
)(observer(BroadcastOBSPlayback));
