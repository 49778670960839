import React from "react";
import { inject, observer } from "mobx-react";
import ProfileStore from "../my-page/stores/profile/ProfileStore";
import TailwindCard from "library/components/_tailwind/card";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindHeading from "library/components/_tailwind/heading";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindSpinner from "library/components/_tailwind/spinner";

type StudioModelAccessRightsProps = {
  children?: React.ReactNode;
  shouldShow: boolean;
  isIndividualModel?: boolean;
  hideContent?: boolean;
  comingSoon?: boolean;
  profileStore?: ProfileStore;
};

const StudioModelAccessRights: React.FunctionComponent<StudioModelAccessRightsProps> = ({
  children,
  shouldShow,
  isIndividualModel = false,
  hideContent,
  comingSoon,
  profileStore,
}) => {
  const { profileLoaded } = profileStore!;

  if (!profileLoaded) {
    return (
      <TailwindFlex
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        alignContent={"content-center"}
        width={"w-full"}
        height={"h-full"}>
        <TailwindSpinner />
      </TailwindFlex>
    );
  } else if (shouldShow || isIndividualModel) {
    return <React.Fragment>{children}</React.Fragment>;
  } else {
    if (hideContent) {
      if (comingSoon) {
        return (
          <TailwindHeading level={6} textAlign={"text-center"}>
            Coming Soon
          </TailwindHeading>
        );
      }
      return null;
    }

    return (
      <TailwindCard
        width={"w-full"}
        height={"h-full"}
        display={"flex"}
        alignItems={"items-center"}
        justifyContent={"justify-center"}>
        <TailwindTranslatedText
          headingProps={{ level: 6, textAlign: "text-center" }}
          descriptor={{
            id: "access-rights.model-no-rights",
            defaultMessage:
              "You don't have access to this resource. Please contact your studio representative for access.",
          }}
        />
      </TailwindCard>
    );
  }
};

export default inject("profileStore")(observer(StudioModelAccessRights));
