import React, { useCallback, useEffect, useMemo, useState } from "react";
import TailwindCard from "library/components/_tailwind/card";
import TailwindSwitch from "library/components/_tailwind/switch";
import TailwindText from "library/components/_tailwind/text";
import BroadcastStore from "common/broadcast/_stores/broadcast/BroadcastStore";
import PricingStore from "common/broadcast/_stores/pricing/PricingStore";
import { inject, observer } from "mobx-react";
import TailwindBox from "library/components/_tailwind/box";
import { IShowType } from "./_stores/broadcast/enums";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTooltip from "library/components/_tailwind/tooltip";
import { TInset } from "library/styles/tailwind-classnames";
import LiveChat from "common/live-chat";
import CountUpTimer from "../../../src/library/src/components/count-up-timer";
import NodeChatStore from "./_stores/nodechat/NodeChatStore";
import LanguageStore from "library/core/stores/language/LanguageStore";
type Props = {
  broadcastStore?: BroadcastStore;
  nodeChatStore?: NodeChatStore;
  pricingStore?: PricingStore;
  languageStore?: LanguageStore;
};

const BroadcastBuzzPrivateRecordOptionsBar: React.ComponentType<Props> = ({
  broadcastStore,
  nodeChatStore,
  pricingStore,
  languageStore,
}) => {
  const { intl } = languageStore!;

  const { modelProducts, submitBuzzPriceDebounced } = pricingStore!;
  const { buzz_status } = modelProducts;
  const {
    isRecording,
    countdownStarted,
    setCountdownStarted,
    stopRecording,
    isShowStarted,
    currentShowType,
    isCountUpTimerOn,
  } = broadcastStore!;

  const { sessionEarnings, sessionPayoutPercent } = nodeChatStore!;

  const [isStoppingRecording, setIsStoppingRecording] =
    useState<boolean>(false);
  const [isButtonInitialized, setIsButtonInitialized] =
    useState<boolean>(false);

  const [buzzModeStatus, setBuzzModeStatus] = useState<boolean>(
    buzz_status === "on"
  );

  const onChangeToggleRecording = useCallback(async () => {
    if (isRecording) {
      setIsStoppingRecording(true);
      await stopRecording();
      setIsStoppingRecording(false);
    } else if (countdownStarted) {
      setCountdownStarted(false);
    } else {
      setCountdownStarted(true);
    }
  }, [isRecording, stopRecording, countdownStarted]);

  const isInRecording = useMemo(
    () => isRecording || countdownStarted,
    [isRecording, countdownStarted]
  );

  const canAutoRecordPrivateShows = useMemo(() => {
    return modelProducts?.chat_settings?.is_recording_all_private_shows;
  }, [modelProducts?.chat_settings?.is_recording_all_private_shows]);

  const onChangeToggleBuzzMode = useCallback(() => {
    setBuzzModeStatus(!buzzModeStatus);
    submitBuzzPriceDebounced(!buzzModeStatus);
  }, [buzzModeStatus]);

  const isBuzzModeEnabled = useMemo(() => {
    return buzzModeStatus;
  }, [buzzModeStatus]);

  useEffect(() => {
    setBuzzModeStatus(buzz_status === "on");
  }, [buzz_status]);

  const shouldShowRecordingButton = useMemo(() => {
    const isFreeShowType = [
      IShowType.FREE,
      IShowType.PAUSED,
      IShowType.NONE,
      IShowType.TIPPING,
    ].includes(currentShowType);
    const isPrivateWithVoyeurOff =
      [IShowType.PRIVATE, IShowType.GROUP].includes(currentShowType) &&
      !modelProducts?.is_voyeur_allowed;
    return isShowStarted && !isFreeShowType && !isPrivateWithVoyeurOff;
  }, [currentShowType, isShowStarted, modelProducts]);

  return (
    <TailwindCard
      width={"w-full"}
      display={"flex"}
      margin={["my-2"]}
      alignItems={"items-center"}
      justifyContent={"justify-between"}>
      <TailwindFlex gap={"gap-2"} width={"w-auto"} alignItems={"items-center"}>
        <TailwindTooltip
          content={
            <TailwindText textColor='text-white'>{`${
              intl.formatMessage({
                id: "settings.enable-buzzmode",
                defaultMessage: "Enable / disable members from buzzing you. Currently"
              })
            } ${
              isBuzzModeEnabled ? 
                intl.formatMessage({
                  id: "settings.on",
                  defaultMessage: "ON"
                }) : 
                intl.formatMessage({
                  id: "settings.off",
                  defaultMessage: "OFF"
                })
            }`}</TailwindText>
          }>
          <TailwindBox>
            <TailwindSwitch
              data-testid="buzz-mode-toggle"
              onLabel={"BUZZ"}
              offLabel={"BUZZ"}
              size={"xs-wide"}
              onChange={onChangeToggleBuzzMode}
              isChecked={isBuzzModeEnabled}
              offBackgroundColor={"bg-toggle-disabled"}
              offLabelProps={{
                textColor: "text-white",
              }}
              onBackgroundColor={"bg-secondary-bg-color"}
              onLabelProps={{
                textColor: "text-main-color",
                padding: ["pr-2.5"],
              }}
              pillBackgroundColor={
                isBuzzModeEnabled ? "bg-pink-600" : "bg-gray-200"
              }
            />
          </TailwindBox>
        </TailwindTooltip>
        {shouldShowRecordingButton && !canAutoRecordPrivateShows && (
          <TailwindTooltip
            content={
              <TailwindText textColor='text-white'>
                {isRecording || isInRecording || isStoppingRecording
                  ? intl.formatMessage({
                      id: "settings.stop-record",
                      defaultMessage: "Stop recording your current show."
                    })
                  : intl.formatMessage({
                      id: "settings.start-record",
                      defaultMessage: "Begin recording your current show."
                    })
                }
              </TailwindText>
            }>
            <TailwindBox>
              <TailwindSwitch
                data-testid={"broadcast-record-toggle"}
                onLabel={"REC"}
                offLabel={"REC"}
                size={"xs"}
                onChange={onChangeToggleRecording}
                isChecked={isRecording || isInRecording || isStoppingRecording}
                offBackgroundColor={"bg-toggle-disabled"}
                offLabelProps={{
                  textColor: "text-white",
                  inset: ["right-1.5" as TInset],
                }}
                onBackgroundColor={"bg-secondary-bg-color"}
                onLabelProps={{
                  textColor: "text-main-color",
                  inset: ["right-7" as TInset],
                }}
                pillBackgroundColor={
                  !(isRecording || isInRecording || isStoppingRecording)
                    ? "bg-gray-300"
                    : "bg-pink-600"
                }
              />
            </TailwindBox>
          </TailwindTooltip>
        )}
      </TailwindFlex>
      <TailwindFlex flexDirection="flex-col" alignItems="items-center" justifyContent="justify-center">
        {isCountUpTimerOn &&
          <CountUpTimer
            currentShowType={currentShowType}
            fontSize={"text-xs"}
            isCountUpTimerOn={isCountUpTimerOn}/>}
        {!!sessionPayoutPercent && !!sessionEarnings && sessionEarnings > 0 && (
          <TailwindText fontSize="text-xs">{intl.formatMessage({
            id: 'broadcast.est-earnings',
            defaultMessage: 'Estimated Earnings - '
          })}<TailwindText fontSize="text-xs" fontWeight="font-bold">&nbsp;${sessionEarnings.toFixed(2)}</TailwindText></TailwindText>
        )}
      </TailwindFlex>
      
      <TailwindTooltip
        content={
          <TailwindText textColor='text-white'>{`${
            !isButtonInitialized ? 
              intl.formatMessage({
                id: "landing.questions.open-live-chat",
                defaultMessage: "Open Live Chat Support"
              }) : 
              intl.formatMessage({
                id: "landing.questions.close-live-chat",
                defaultMessage: "Close Live Chat Support"
              })
          }`}</TailwindText>
        }>
        <TailwindBox position='relative'>
          <LiveChat
            onButtonReady={() => null}
            onButtonHide={() => null}
            isButtonInitialized={isButtonInitialized}
            setIsButtonInitialized={setIsButtonInitialized}
            broadcastButton
          />
        </TailwindBox>
      </TailwindTooltip>
    </TailwindCard>
  );
};

export default inject(
  "broadcastStore",
  "nodeChatStore",
  "pricingStore",
  "languageStore"
)(observer(BroadcastBuzzPrivateRecordOptionsBar));
