import Formatter from "../formatter";
import { utcToZonedTime } from "date-fns-tz";
import moment from "moment";

export default class DateProcess {
  public timezone: string;
  public today: Date;
  constructor(private formatter: Formatter) {
    const newDate = new Date();
    this.timezone = "GMT";
    this.today = utcToZonedTime(newDate, this.timezone);
  }

  public static today = new Date();
  public static eighteenYearsBeforeToday = moment(DateProcess.today).subtract("18", "years").format("YYYY-MM-DD");

  monthsLong = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

  public findDifferenceTheDateAsDay(epochDate1: number, epochDate2: number) {
    return Math.floor(Math.abs(epochDate1 - epochDate2) / 1000 / 86400);
  }
  public dateFormatChange(date?: string) {
    const newDate = !!date ? new Date(date) : new Date();
    return this.monthsLong[newDate.getMonth()] + " " + newDate.getUTCDate() + ", " + newDate.getFullYear();
  }

  public getMonth(date?: string) {
    const newDate = !!date ? new Date(date) : new Date();
    return this.monthsLong[newDate.getMonth()];
  }

  public getMonthShort(date?: string) {
    const newDate = !!date ? new Date(date) : new Date();
    return this.monthsShort[newDate.getMonth()];
  }

  public getHourInTwelveHour = date => {
    return (date.getHours() + 24) % 12 || 12;
  };

  public formatDate = (date: string, format = "M/D/YYYY H:mmP", twelveHourFormat: boolean = true) => {
    if (date) {
      // format can contain the following:
      // DD for days padded with zero
      // D for days without zero
      // MMMM for month name long
      // MMM for month name short
      // MM for months number padded with zero
      // M for months number without zero
      // YYYY for four digit years
      // YY for last to digits of year
      // H for two digit hours padded with zero
      // mm for two minutes padded with zero
      // ss for seconds padded with zero
      // P for meridian time uppercase
      // p for meridian time lowercase

      const dt = new Date(date);
      const month = dt.getMonth() + 1;
      const day = dt.getDate();
      const year = dt.getFullYear();
      const hourInTwentyFourFormat = dt.getHours();
      const hour = twelveHourFormat ? this.getHourInTwelveHour(dt) : hourInTwentyFourFormat;
      const minute = dt.getMinutes();
      const second = dt.getSeconds();

      return format
        .replace(/MMMM/g, `${this.monthsLong[month]}`)
        .replace(/MMM/g, `${this.monthsShort[month]}`)
        .replace(/MM/g, `${this.formatter.padZero(month)}`)
        .replace(/M/g, `${month}`)
        .replace(/DD/g, `${this.formatter.padZero(day)}`)
        .replace(/D/g, `${day}`)
        .replace(/YYYY/g, `${year}`)
        .replace(/YY/g, `${year.toString().slice(-2)}`)
        .replace(/H/g, `${this.formatter.padZero(hour)}`)
        .replace(/mm/g, `${this.formatter.padZero(minute)}`)
        .replace(/ss/g, `${this.formatter.padZero(second)}`)
        .replace(/P/g, `${hourInTwentyFourFormat < 12 ? "AM" : "PM"}`)
        .replace(/p/g, `${hourInTwentyFourFormat < 12 ? "am" : "pm"}`);
    }

    return date;
  };

  public getHumanReadableDate = (date: string, format = "M/D/YYYY H:mmP", twelveHourFormat: boolean = true) => {
    const dt = new Date(date);
    const now = new Date();

    const month = dt.getMonth() + 1;
    const day = dt.getDate();
    const year = dt.getFullYear();
    const hour = dt.getHours();
    const minute = dt.getMinutes();

    const nowMonth = now.getMonth() + 1;
    const nowDay = now.getDate();
    const nowYear = now.getFullYear();
    const nowHour = now.getHours();
    const nowMinute = now.getMinutes();

    if (day === nowDay && month === nowMonth && year === nowYear) {
      if (hour === nowHour && nowMinute - minute < 2) {
        return "Now";
      }
      return this.formatDate(date, "H:mmP", twelveHourFormat);
    }

    return this.formatDate(date, format, twelveHourFormat);
  };

  public calculateAge = (birthday: string) => {
    const birth = new Date(birthday);
    const curr = new Date();
    const diff = curr.getTime() - birth.getTime();
    return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
  };

  public createDate = (
    year: number,
    month: number,
    day: number,
    hours: number = 0,
    minutes: number = 0,
    seconds: number = 0,
    ms: number = 0
  ) => {
    const date = new Date(year, month, day, hours, minutes, seconds, ms);
    return date;
  };
}
