import { makeAutoObservable } from "mobx";
import { api } from "core/utility";
import {
  InitialData,
  NOT_ALLOWED_KEY,
  PRODUCT_DEFAULTS,
  REWARD_FIELD_TO_BE_VERIFIED,
} from "./consts";
//import { validationStore } from "library/core/stores/validation/ValidationStore";
import { snackbarStore } from "library/core/stores/snackbar/SnackbarStore";
import { logger } from "library/core/utility";
import {
  ModelPricingResponse,
  ModelPricingResponseChatSettingsTipping,
  ModelPricingResponseChatSystemSettings,
  PricingOptions,
} from "./types";
import { SnackbarVariants } from "library/core/stores/snackbar/enums";
import { validationStore } from "library/core/stores/validation/ValidationStore";
import debounce from "lodash/debounce";
import { isEqual } from "lodash";
import { nodeChatSock, nodeChatStore } from "core/stores";

const logPrefix = "[PricingStore]:";

export default class PricingStore {
  constructor() {
    makeAutoObservable(this);
  }
  public modelProducts: ModelPricingResponse = InitialData.modelProducts;
  public pricingOptions: PricingOptions = InitialData.pricingOptions;
  public isLoading: boolean = InitialData.isLoading;
  public isNudePriceCorrect: boolean | undefined =
    InitialData.isNudePriceCorrect;
  public changedWheelOfFunRewardIndexes: number[] =
    InitialData.changedWheelOfFunRewardIndexes;
  public allowedWheelOfFunPricing: boolean =
    InitialData.allowedWheelOfFunPricing;
  public tippingGoalError: string | undefined = InitialData.tippingGoalError;
  public tippingEarlyEntryError: string | undefined =
    InitialData.tippingEarlyEntryError;
  public tippingLateEntryError: string | undefined =
    InitialData.tippingLateEntryError;

  resetStore = () => {
    Object.assign(this, InitialData);
  };

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  private enhancePriceDataFromBackend = (data: ModelPricingResponse): ModelPricingResponse => {
    data.chat_settings = {
      ...PRODUCT_DEFAULTS.chat_settings,
      ...data.chat_settings
    }
    return data;
  }

  public getPriceData = async () => {
    this.log("getPriceData started");
    try {
      this.isLoading = true;
      const { data }: { data: ModelPricingResponse } = await api.pricing.get();
      this.modelProducts = this.enhancePriceDataFromBackend(data);
    } catch (error) {
      this.log("getPriceData failed", error);
      this.modelProducts = PRODUCT_DEFAULTS;
    } finally {
      this.isLoading = false;
      this.log("getPriceData finished");
    }
  };

  get areTippingFieldsValid() {
    if (
      this.tippingGoalError ||
      this.tippingEarlyEntryError ||
      this.tippingLateEntryError
    ) {
      return false;
    } else {
      return true;
    }
  }
  get isWheelOfFunPricingAllowed() {
    return this.allowedWheelOfFunPricing;
  }
  setIsWheelOfFunPricingAllowed = (allowed: boolean) => {
    this.allowedWheelOfFunPricing = allowed;
  };
  setTippingPricingFieldError = (
    field: "late" | "early" | "goal",
    error: string | undefined
  ) => {
    if (field === "late") {
      this.tippingLateEntryError = error;
    }
    if (field === "early") {
      this.tippingEarlyEntryError = error;
    }
    if (field === "goal") {
      this.tippingGoalError = error;
    }
  };

  get isWheelOfFunAllowed() {
    return this.modelProducts.is_wheel_of_fun_allowed;
  }

  submitPrice = async (product_id: string, value: any) => {
    try {
      this.log("submitPrice started");
      if (this.modelProducts[product_id]) {
        this.modelProducts[product_id] = value;
      }
      // @TODO Create a separate function to handle the cases for party and voyeur.
      if (product_id === 'party_chat' && value === NOT_ALLOWED_KEY) {
        await api.pricing.patch({ is_party_allowed: false });
      } else if (product_id === 'party_chat' && value) {
        await api.pricing.patch({
          is_party_allowed: true,
          [product_id]: value
        });
      }
      else {
        await api.pricing.patch({ [product_id]: value });
      }
      snackbarStore.enqueueSimpleSnackbar(
        "messages.success.priceUpdated",
        SnackbarVariants.SUCCESS
      );
    } catch (error) {
      this.log("submitPrice failed", error);
    } finally {
      this.log("submitPrice finished");
    }
  };

  submitVoyeurPrice = async (value: any) => {
    try {
      this.log("submitPrice started");
      if (value === NOT_ALLOWED_KEY) {
        await api.pricing.patch({ is_voyeur_allowed: false });
      } else {
        await api.pricing.patch({
          is_voyeur_allowed: true,
          voyeur_chat: value,
        });
      }
      snackbarStore.enqueueSimpleSnackbar(
        "messages.success.priceUpdated",
        SnackbarVariants.SUCCESS
      );
    } catch (error) {
      this.log("submitPrice failed", error);
    } finally {
      this.log("submitPrice finished");
    }
  };

  submitTippingPrice = async (
    prices: ModelPricingResponseChatSettingsTipping
  ) => {
    try {
      this.log("submitTippingPrice started");
      if (!this.modelProducts.chat_settings.tipping)
        this.modelProducts.chat_settings.tipping =
          PRODUCT_DEFAULTS.chat_settings.tipping;
      this.modelProducts.chat_settings.tipping = {
        ...this.modelProducts.chat_settings.tipping,
        ...prices,
      };
      await api.pricing.patch({
        chat_settings: {
          ...this.modelProducts.chat_settings,
          system_settings:
            this.modelProducts.chat_settings?.system_settings ||
            PRODUCT_DEFAULTS.chat_settings.system_settings,
        },
      });
      snackbarStore.enqueueSimpleSnackbar(
        "messages.success.priceUpdated",
        SnackbarVariants.SUCCESS
      );
    } catch (error) {
      this.log("submitTippingPrice failed", error);
    } finally {
      this.log("submitTippingPrice finished");
    }
  };

  submitSystemSettings = async (
    system_settings: Partial<ModelPricingResponseChatSystemSettings>
  ) => {
    this.log("submitSystemSettings started");
    try {
      if (!this.modelProducts.chat_settings.system_settings)
        this.modelProducts.chat_settings.system_settings =
          PRODUCT_DEFAULTS.chat_settings.system_settings;

      if (this.modelProducts.chat_settings.system_settings) {
        this.modelProducts.chat_settings.system_settings = {
          ...this.modelProducts.chat_settings.system_settings,
          ...system_settings,
        };
      }
      await api.pricing.patch({
        chat_settings: {
          ...this.modelProducts.chat_settings,
        },
      });
      snackbarStore.enqueueSimpleSnackbar(
        "messages.success.chatSettingsUpdated",
        SnackbarVariants.SUCCESS
      );
    } catch (error) {
      this.log("submitSystemSettings failed", error);
    } finally {
      this.log("submitSystemSettings finished");
    }
  };

  submitIsRecordingPrivate = async value => {
    this.log("submitIsRecordingPrivate started");
    try {
      await api.pricing.patch({
        chat_settings: {
          is_recording_all_private_shows: value,
        },
      });
      this.modelProducts.chat_settings.is_recording_all_private_shows = value;
      snackbarStore.enqueueSimpleSnackbar(
        value
          ? "messages.success.recordedShowSettingUpdatedOn"
          : "messages.success.recordedShowSettingUpdatedOff",
        SnackbarVariants.SUCCESS
      );
    } catch (error) {
      this.log("submitIsRecordingPrivate failed", error);
    } finally {
      this.log("submitIsRecordingPrivate finished");
    }
  };

  submitChatTopic = async chatTopic => {
    try {
      this.log("submitChatTopic started");
      await api.pricing.patch({
        chat_settings: {
          ...this.modelProducts?.chat_settings,
          pendingChatTopic: chatTopic,
        },
      });

      this.modelProducts.chat_settings.pendingChatTopic = chatTopic;
      snackbarStore.enqueueSimpleSnackbar(
        "messages.success.chatTopicUpdated",
        SnackbarVariants.SUCCESS
      );
    } catch (error) {
      this.log("submitChatTopic failed", error);
    } finally {
      this.log("submitChatTopic finished");
    }
  };

  removeChatTopic = async () => {
    await api.pricing.patch({
      chat_settings: {
        ...this.modelProducts.chat_settings,
        chatTopic: null,
      },
    });
  };

  setIsNudePriceCorrect = (value: boolean) => {
    this.isNudePriceCorrect = value;
  };

  setModelProducts = (products: ModelPricingResponse) => {
    this.modelProducts = products;
  };

  submitEnabled = async (product_id: string, shouldEnable?: boolean) => {
    this.log("submitEnabled started");
    if (this.modelProducts[product_id] === undefined) {
      this.log(
        "submitEnabled could not find product_id in model products",
        product_id,
        this.modelProducts
      );
    } else {
      this.modelProducts[product_id] =
        shouldEnable === undefined
          ? !this.modelProducts[product_id]
          : shouldEnable;

      try {
        await api.pricing.patch({
          [product_id]: this.modelProducts[product_id],
        });
        if (product_id === "is_private_allowed") {
          snackbarStore.enqueueSimpleSnackbar(
            this.modelProducts[product_id]
              ? "messages.success.privateOn"
              : "messages.success.privateOff",
            SnackbarVariants.SUCCESS
          );
          nodeChatStore.setAllowPrivate(this.modelProducts[product_id]);
        } else {
          snackbarStore.enqueueSimpleSnackbar(
            "messages.success.changesSaved",
            SnackbarVariants.SUCCESS
          );
        }
      } catch (error) {
        this.log("submitEnabled failed", error);
      } finally {
        this.log("submitEnabled finished");
      }
    }
  };

  public submitEnabledDebounced = debounce(this.submitEnabled, 1000);

  submitField = async (product_id: string, enabled: boolean) => {
    this.log("submitField started");

    if (this.modelProducts[product_id] === undefined) {
      // in case we got a boolean value for the product_id using !this.modelProducts[product_id] return always false
      this.log("submitField could not find product_id in model products");
    } else {
      this.modelProducts[product_id] = enabled;
      try {
        await api.pricing.patch({
          [product_id]: this.modelProducts[product_id],
        });
        snackbarStore.enqueueSimpleSnackbar(
          "messages.success.changesSaved",
          SnackbarVariants.SUCCESS
        );
      } catch (error) {
        this.log("submitField failed", error);
      } finally {
        this.log("submitField finished");
      }
    }
  };

  submitWheelOfFunEnabled = async (isEnabled = true) => {
    this.log("submitWheelOfFun started");
    this.modelProducts["is_wheel_of_fun_allowed"] = isEnabled;
    try {
      await api.pricing.patch({
        is_wheel_of_fun_allowed: isEnabled,
      });
    } catch (error) {
      this.log("submitBuzzPrice failed", error);
    } finally {
      this.log("submitBuzzPrice finished");
    }
  }

  submitNewPrice = async (
    pricing_product_id: string,
    enable_product_id: string,
    value?: any,
    changedPrice?: boolean,

    success_message_options?: {
      message_id?: string;
      disabled?: boolean;
    }
  ) => {
    this.log("submitNewPrice started");
    const body = {};

    if (!isNaN(value)) {
      this.modelProducts[pricing_product_id] = value;
      body[pricing_product_id] = value;
    }

    if (enable_product_id !== "is_wheel_of_fun_allowed") {
      this.modelProducts[enable_product_id] = !isNaN(value);
      body[enable_product_id] = !isNaN(value);
    } else {
      this.modelProducts[enable_product_id] = this.allowedWheelOfFunPricing;
      body[enable_product_id] = this.allowedWheelOfFunPricing;
    }

    try {
      await api.pricing.patch(body);
      if (!success_message_options?.disabled && changedPrice) {
        snackbarStore.enqueueSimpleSnackbar(
          "messages.success.priceUpdated",
          SnackbarVariants.SUCCESS
        );
      }
    } catch (error) {
      this.log("submitNewPrice failed", error);
    } finally {
      this.log("submitNewPrice finished");
    }
  };

  get buzzPriceOptions() {
    return this.pricingOptions?.buzz_price || [];
  }

  get superBuzzPriceOptions() {
    return this.pricingOptions?.superbuzz_price || [];
  }

  get wheelOfFunPriceOptions() {
    return this.pricingOptions?.wheel_of_fun || [];
  }

  get connexionOneWayPriceOptions() {
    return this.pricingOptions?.connexion_one_way || [];
  }

  get connexionTwoWayPriceOptions() {
    return this.pricingOptions?.connexion_two_way || [];
  }

  get voyeurPriceOptions() {
    return this.pricingOptions?.voyeur_chat || [];
  }

  get nudeChatPriceOptions() {
    return this.pricingOptions?.nude_chat || [];
  }

  get privateChatPriceOptions() {
    return this.pricingOptions?.private_chat || [];
  }

  get partyChatPriceOptions() {
    return this.pricingOptions?.party_chat || [];
  }

  get whisperMessagePriceOptions() {
    return this.pricingOptions?.whisper_message || [];
  }

  get approvedWheelOfFunRewards() {
    if (!this.modelProducts.wheel_of_fun_rewards?.rewards.length) {
      this.modelProducts.wheel_of_fun_rewards.rewards = [];
    }
    return this.modelProducts.wheel_of_fun_rewards?.rewards;
  }

  get pendingWheelOfFunRewards() {
    if (!this.modelProducts.pending_wheel_of_fun_rewards?.rewards.length) {
      this.modelProducts.pending_wheel_of_fun_rewards.rewards = [];
    }
    return this.modelProducts.pending_wheel_of_fun_rewards?.rewards;
  }

  onChangePendingReward = (index: number, value: string) => {
    this.log("onChangePendingReward started", index, value);
    // if we set using pendingWheelOfFunRewards then in the UI it will immediately show pending
    // we use approvedWheelOfFunRewards so when they exit out and the rewards are saved
    // and model reopens the popup, then she'll see the pending
    if (this.approvedWheelOfFunRewards) {
      this.modelProducts.wheel_of_fun_rewards.rewards =
        this.approvedWheelOfFunRewards.map((reward, _index) => {
          if (_index === index) {
            return value;
          }
          return reward;
        });
      if (!this.changedWheelOfFunRewardIndexes.includes(index)) {
        this.changedWheelOfFunRewardIndexes.push(index);
      }
    }
    this.log("onChangePendingReward finished");
  };

  get approvedAndPendingWheelOfFunRewards() {
    const rewards: string[] = [];

    this.pendingWheelOfFunRewards.forEach((pendingReward, index) => {
      const approvedReward = this.approvedWheelOfFunRewards[index];
      if (approvedReward.length > 0) {
        if (
          approvedReward !== pendingReward &&
          !this.changedWheelOfFunRewardIndexes.includes(index)
        ) {
          rewards[index] = pendingReward;
        } else {
          rewards[index] = approvedReward;
        }
      }
    });
    return rewards.filter(reward => reward.length !== 0);
  }

  getIsRewardPending = (rewardIndex: number) => {
    return (
      this.pendingWheelOfFunRewards[rewardIndex] !==
        this.approvedWheelOfFunRewards[rewardIndex] &&
      this.pendingWheelOfFunRewards[rewardIndex].trim() !== ""
    );
  };

  submitBuzzPrice = async (isEnabled: boolean, value?: number) => {
    this.log("submitBuzzPrice started");

    const shouldUpdateValue = value && typeof value === "number";

    this.modelProducts["buzz_status"] = isEnabled ? "on" : "off";

    const patchObject: any = {
      buzz_status: this.modelProducts["buzz_status"],
    };

    if (shouldUpdateValue) {
      this.modelProducts["buzz_price"] = value as number;
      patchObject.buzz_price = this.modelProducts["buzz_price"];
    }

    try {
      await api.pricing.patch(patchObject);

      await this.getPriceData();
      nodeChatSock.setBuzzMode(isEnabled);
      snackbarStore.enqueueSimpleSnackbar(
        isEnabled
          ? "messages.success.buzzModeOn"
          : "messages.success.buzzModeOff",
        SnackbarVariants.SUCCESS
      );
    } catch (error) {
      this.log("submitBuzzPrice failed", error);
    } finally {
      this.log("submitBuzzPrice finished");
    }
  };

  public submitBuzzPriceDebounced = debounce(this.submitBuzzPrice, 1000);

  submitToggle = async (product_id: string, value?: boolean) => {
    this.log("submitToggle started");
    if (!this.modelProducts[product_id]) {
      this.log("submitToggle could not find product_id in model products");
    } else {
      this.modelProducts[product_id] = !value
        ? value
        : !this.modelProducts[product_id];
      try {
        await api.pricing.patch({
          [product_id]: this.modelProducts[product_id] ? "on" : "off",
        });
      } catch (error) {
        this.log("submitToggle failed", error);
      } finally {
        this.log("submitToggle finished");
      }
    }
  };

  get areAllWheelOfFunRewardsValid() {
    // refer to comment inside onChangePendingReward for details
    // as to why we are sending approvedWheelOfFunRewards instead of pendingWheelOfFunRewards
    const areAllValid =
      this.approvedWheelOfFunRewards.filter(reward => reward.trim() !== "")
        .length > 0 &&
      this.approvedWheelOfFunRewards
        .slice(0, 3)
        .map((reward: string, index: number) =>
          validationStore.validate(REWARD_FIELD_TO_BE_VERIFIED, reward, {
            dynamicInput: `reward${index + 1}`,
          })
        )
        .every((isValid: Boolean) => isValid);

    return areAllValid;
  }

  saveWheelOfFunRewards = async (): Promise<void> => {
    if (this.areAllWheelOfFunRewardsValid) {
      try {
        const rewardsWithChangesApplied = this.pendingWheelOfFunRewards?.map(
          (reward, index) => {
            if (this.changedWheelOfFunRewardIndexes.includes(index)) {
              return this.approvedWheelOfFunRewards[index];
            }

            return reward;
          }
        );

        const { data }: { data: ModelPricingResponse } =
          await api.wheelOfFunRewards.put({
            wheel_of_fun_rewards: { rewards: rewardsWithChangesApplied },
          });

        if (this.changedWheelOfFunRewardIndexes.length > 0) {
          snackbarStore.enqueueSimpleSnackbar(
            "messages.success.changesSaved",
            SnackbarVariants.SUCCESS
          );
        }

        this.modelProducts.wheel_of_fun_rewards = data.wheel_of_fun_rewards;
        this.modelProducts.pending_wheel_of_fun_rewards =
          data.pending_wheel_of_fun_rewards;
        this.changedWheelOfFunRewardIndexes = [];
      } catch (error) {
        this.log("saveWheelOfFunRewards failed", error);
      } finally {
        this.log("saveWheelOfFunRewards finished");
      }
    }
  };

  get getpendingWheelOfFunRewards() {
    const pendingRewards: string[] = [];
    this.pendingWheelOfFunRewards.forEach((pendingReward, index) => {
      const approvedReward = this.approvedWheelOfFunRewards[index];
      if (approvedReward.length > 0) {
        if (
          approvedReward !== pendingReward &&
          !this.changedWheelOfFunRewardIndexes.includes(index)
        ) {
          pendingRewards[index] = pendingReward;
        }
      }
    });
    return pendingRewards;
  }

  get isPendingAndApprovalAreEqual() {
    return isEqual(this.modelProducts.pending_wheel_of_fun_rewards.rewards, this.approvedWheelOfFunRewards);
  }

  public formatPricesByID = (
    pricing_product_id: string,
    enable_product_id: string
  ): any => {
    const isAllowed =
      typeof this.modelProducts[enable_product_id] === "boolean"
        ? this.modelProducts[enable_product_id]
        : this.modelProducts[enable_product_id] === "on"
        ? true
        : false;
    const currentPrice = this.modelProducts[pricing_product_id];
    const defaults = this.pricingOptions[pricing_product_id];

    return isAllowed ? Number(currentPrice) : Number(defaults[0]);
  };

  abortWheelOfFunRewardsChanges = async () => {
    this.resetStore();
    await this.getPriceData();
  }
}
