import React, { useMemo } from "react";
import ModalCloseButton, { ModalCloseButtonProps } from "./modal-close-button";
import { BackdropVariant } from "../../core/stores/modal/types";
import TailwindBox from "../_tailwind/box";
import ReactModal from "react-modal";

import "./styles.scss";
import { MODAL_WRAPPER_CLASSNAME } from "../../core/stores/modal/consts";
import classNames from "classnames";

type CustomModalProps = {
  open: boolean;
  children?: any;
  bgOpacity?: number;
  backdropVariant?: BackdropVariant;
  modalTop?: string;
  size?: string;
  backgroundColor?: string;
  closeModal?: Function;
  showNativeCloseButton?: boolean;
  className?: string;
  closeButtonProps?: Omit<ModalCloseButtonProps, "closeModal">;
};

const customStyles = {
  overlay: {
    backgroundColor: "0, 0 ,0 ",
  },
  content: {
    border: "none",
    inset: 0,
    backgroundColor: "rgba(0,0,0,0)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

ReactModal.setAppElement(document.getElementById("root"));

const Modal: React.ComponentType<CustomModalProps> = ({
  open,
  children,
  bgOpacity = 0.8,
  modalTop = "0",
  size,
  backgroundColor,
  backdropVariant = "blur",
  closeModal,
  closeButtonProps,
  showNativeCloseButton = true,
  // className,
}) => {
  const _customStyles = useMemo(() => {
    const _overlay = {
      ...customStyles.overlay,
      backgroundColor: `rgba(${customStyles.overlay.backgroundColor}, ${bgOpacity})`,
      backdropFilter: backdropVariant == "blur" ? "blur(5px)" : "none",
      top: modalTop,
    };
    const _content = { ...customStyles.content };
    return {
      overlay: _overlay,
      content: _content,
    };
  }, [bgOpacity, backdropVariant, modalTop]);
  return (
    <ReactModal
      isOpen={open}
      style={_customStyles}
      size={size}
      backgroundColor={backgroundColor}
      className={classNames(MODAL_WRAPPER_CLASSNAME)}
      shouldCloseOnOverlayClick={showNativeCloseButton}
      onRequestClose={closeModal}>
      <TailwindBox
        position={"relative"}
        width={"w-full"}
        height={"h-auto"}
        pseudoClasses={["sm:px-4"]}
        maxHeight={"max-h-screen"}
        overflow={"overflow-auto"}>
        {closeModal && showNativeCloseButton && (
          <ModalCloseButton
            {...closeButtonProps}
            closeModal={() => closeModal()}
          />
        )}
        {children}
      </TailwindBox>
    </ReactModal>
  );
};

export default Modal;
