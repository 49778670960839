import { computed, makeAutoObservable } from "mobx";
import _ from "lodash";
import {
  BreakPoints,
  ScreenBreakpoint,
  ScreenOrientation,
  UserAgent,
} from "./types";
import { InitialData } from "./consts";
import { isUserAgentFirefox } from "./utils";
import CookieStorage from "../../../core/utility/cookie-storage";
import { TFlexGrow, TOverflow } from "../../../styles/tailwind-classnames";
import { AppVariantRouteKey } from "../../../../../core/stores/route/enums";
import { routeStore } from "../../../../../core/stores/route/RouteStore";
import { logger } from "../../utility";

const logPrefix = "[LayoutStore]:";

export default class LayoutStore {
  isThemeDark = false;
  private initialWindowHeight: number | null = InitialData.initialWindowHeight;
  screenSize: ScreenBreakpoint | null = InitialData.screenSize;
  screenOrientation: ScreenOrientation | null = InitialData.screenOrientation;
  isKeyboardOpen: boolean = InitialData.isKeyboardOpen;
  windowHeight: number | null = InitialData.windowHeight;
  windowWidth: number | null = InitialData.windowWidth;
  navigatorAgent: string | null = InitialData.navigatorAgent;
  userAgent: UserAgent | null = InitialData.userAgent;
  scrollTop: number = 0;
  bodyPadding: number = 15;
  isAndroidOrIOS: boolean = InitialData.isAndroidOrIOS;
  // some devices have ability to turn off keyboard whilst having focus on input
  // this results in isKeyboardOpen being false, the input focus is needed to calculate orientationchange correctly
  hasFocusedInput: boolean = InitialData.hasFocusedInput;
  isMenuOpen: boolean = InitialData.isMenuOpen;
  isLiveChatOpen: boolean = InitialData.isLiveChatOpen;
  isOpenMenuOnMouseOver: boolean = InitialData.isOpenMenuOnMouseOver;
  isLiteMode: boolean = InitialData.isLiteMode;
  activeSidebarDropdownKey: string | null =
    InitialData.activeSidebarDropdownKey;
  isOffline: boolean = InitialData.isOffline;
  isBroadcastPage: boolean = InitialData.isBroadcastPage;

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  constructor() {
    makeAutoObservable(this);
    const liteModeCookie = CookieStorage.get("dolitemode");
    if (liteModeCookie && liteModeCookie === "true") {
      this.setIsLiteMode(true);
    }
    this.init();
  }

  get bodyOverflow(): TOverflow[] {
    if (
      routeStore.currentRoutePathname ===
      routeStore.getSiteRouteURL(AppVariantRouteKey.tutorial)
    ) {
      return ["overflow-hidden"];
    }
    return ["overflow-x-hidden", "overflow-y-auto"];
  }
  get getIsLiveChatOpen(): boolean {
    return this.isLiveChatOpen;
  }
  @computed get bodyFlexGrow(): TFlexGrow {
    if (
      routeStore.currentRoutePathname ===
      routeStore.getSiteRouteURL(AppVariantRouteKey.tutorial)
    ) {
      return "flex-grow-0";
    }
    return "flex-grow";
  }

  public setBodyPadding = (padding: number) => {
    this.bodyPadding = padding;
  };

  toggleMenu = (shouldOpen: boolean = !this.isMenuOpen): void => {
    this.isMenuOpen = shouldOpen;
  };
  toggleMenuOnMouseOver = (
    shouldOpen: boolean = !this.isOpenMenuOnMouseOver
  ): void => {
    this.isOpenMenuOnMouseOver = shouldOpen;
  };

  setActiveSidebarDropdownKey = (key: string | null) => {
    this.activeSidebarDropdownKey = key;
  };
  setIsLiveChatOpen = (shouldOpen: boolean) => {
    this.isLiveChatOpen = shouldOpen;
    if (!shouldOpen) {
      const live_chat_bubble = document.querySelector(
        ".dimelo-chat-wrapper"
      ) as HTMLElement;
      const live_chat_icon = document.querySelector(
        ".dimelo-chat-round-button"
      ) as HTMLElement;
      if (live_chat_bubble) {
        live_chat_bubble.style.display = "none";
        live_chat_icon.style.display = "none";
      }
    } else {
      const live_chat_bubble = document.querySelector(
        ".dimelo-chat-wrapper"
      ) as HTMLElement;
      const live_chat_icon = document.querySelector(
        ".dimelo-chat-round-button"
      ) as HTMLElement;
      if (live_chat_bubble) {
        live_chat_bubble.style.display = "block";
        live_chat_icon.style.display = "block";
      }
    }
  };

  public static scrollToTop = (smoothScroll = false) => {
    if (typeof window !== "undefined") {
      window.scrollTo({
        top: 0,
        behavior: smoothScroll ? "smooth" : "auto",
      });
    }
  };

  public static scrollToTopMobile = () => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: -50 });
    }
  };

  get isMobileDevice(): boolean {
    return (
      this.screenSize === "sm" ||
      this.screenSize === "xs" ||
      this.userAgent === "AppleMobile" ||
      this.userAgent === "Android"
    );
  }

  get isMobileScreenSize(): boolean {
    return (
      this.screenSize === "md" ||
      this.screenSize === "sm" ||
      this.screenSize === "xs"
    );
  }

  get isTabletScreenSize(): boolean {
    return (
      this.screenSize === "lg" ||
      this.screenSize === "md" ||
      this.screenSize === "sm" ||
      this.screenSize === "xs"
    );
  }

  public static get isWebkit(): boolean {
    return Boolean(
      LayoutStore.isAppleMobile() ||
        LayoutStore.isAppleTablet() ||
        LayoutStore.isAppleMac()
    );
  }

  get isPortrait(): boolean {
    if (typeof window !== undefined) {
      return this.screenOrientation === "portrait";
    } else {
      return false;
    }
  }

  init() {
    this.setInitialWindowHeight();
    this.initScreenSize();
    this.initUserAgent();
    this.attachEventListeners();
    this.listenToOnlineOfflineEvents();
    this.setIsLiveChatOpen(false);
  }

  listenToOnlineOfflineEvents = () => {
    window.removeEventListener("offline", () => this.setIsOffline(true));
    window.addEventListener("offline", () => this.setIsOffline(true));
    window.removeEventListener("online", () => this.setIsOffline(false));
    window.addEventListener("online", () => this.setIsOffline(false));
    window.addEventListener("onmouseover", () => this.setIsLiveChatOpen(false));
  };

  setIsOffline = (isOffline: boolean) => {
    this.log("setIsOffline started", isOffline);
    this.isOffline = isOffline;
    this.log("setIsOffline finished");
  };

  private setInitialWindowHeight = () => {
    if (typeof window !== "undefined") {
      this.initialWindowHeight = window.innerHeight;
    }
  };

  public initScreenSize = () => {
    if (typeof window !== "undefined") {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const breakpoint = this.calculateBreakpoint(width);
      const orientation = this.calculateOrientation(width, height);
      this.setScreenSize(breakpoint);
      if (this.screenOrientation !== orientation) {
        if (!this.isKeyboardOpen || !this.hasFocusedInput) {
          this.setScreenOrientation(orientation);
          this.setInitialWindowHeight();
        }
      }
      // some devices have a button to turn keyboard off
      // it causes height change but does not blur the input
      // manually close the keyboard
      const isFullHeight = height === this.initialWindowHeight;
      if (this.isKeyboardOpen && isFullHeight) {
        this.isKeyboardOpen = false;
      }
      this.setWindowDimensions(width, height);

      if (this.isTabletScreenSize) {
        this.toggleMenu(false);
      } else if (!this.isMenuOpen) {
        this.toggleMenu(true);
      }
    } else {
      this.setScreenSize("md");
      this.setScreenOrientation("landscape");
    }
  };

  private initScrollTop = () => {
    if (typeof document !== "undefined") {
      this.scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
    }
  };

  private initUserAgent = () => {
    if (typeof window !== "undefined" && navigator) {
      const isAppleMobile =
        LayoutStore.isAppleMobile() || LayoutStore.isAppleTablet();
      const isAppleMac = LayoutStore.isAppleMac();
      const isAndroid = LayoutStore.isAndroid();
      const isWindows = LayoutStore.isWindows();

      let userAgent: UserAgent | null = null;

      if (isAppleMobile) {
        userAgent = "AppleMobile";
      } else if (isAppleMac) {
        userAgent = "AppleMac";
      } else if (isAndroid) {
        userAgent = "Android";
      } else if (isWindows) {
        userAgent = "Windows";
      }

      this.userAgent = userAgent;
      this.isAndroidOrIOS =
        LayoutStore.isAndroid() ||
        LayoutStore.isAppleMobile() ||
        LayoutStore.isAppleTablet() ||
        false;
      this.navigatorAgent = navigator.userAgent;
    }
    return null;
  };

  public static isChrome = () => {
    if (typeof navigator !== "undefined") {
      return /Chrome/i.test(navigator.userAgent);
    }
  };

  public static isAppleMobile = () => {
    if (typeof navigator !== "undefined") {
      return /iPhone|iPod/i.test(navigator.userAgent);
    }
  };

  public static isAppleTablet = () => {
    if (typeof navigator !== "undefined") {
      return /iPad/i.test(navigator.userAgent);
    }
  };

  public static isAppleMac = () => {
    if (typeof navigator !== "undefined") {
      return /Mac/i.test(navigator.userAgent);
    }
  };

  public static isAndroid = () => {
    if (typeof navigator !== "undefined") {
      return /Android/i.test(navigator.userAgent);
    }
  };

  public static isWindows = () => {
    if (typeof navigator !== "undefined") {
      return /Windows/i.test(navigator.userAgent);
    }
  };

  private calculateBreakpoint(width: number): ScreenBreakpoint {
    if (width <= BreakPoints["xs"]) {
      return "xs";
    } else if (width <= BreakPoints["sm"]) {
      return "sm";
    } else if (width <= BreakPoints["md"]) {
      return "md";
    } else if (width <= BreakPoints["lg"]) {
      return "lg";
    } else if (width <= BreakPoints["xl"]) {
      return "xl";
    } else if (width <= BreakPoints["4k"]) {
      return "4k";
    } else {
      return "widescreen";
    }
  }

  private calculateOrientation(
    width: number,
    height: number
  ): ScreenOrientation {
    const isPortrait = width < height;
    return isPortrait ? "portrait" : "landscape";
  }

  private setWindowDimensions(width: number, height: number): void {
    this.windowHeight = height;
    this.windowWidth = width;
  }

  private removeScrollListener() {
    window.removeEventListener("scroll", _.throttle(this.initScrollTop, 200));
  }

  private attachScrollListener() {
    window.addEventListener("scroll", _.throttle(this.initScrollTop, 200));
  }

  private attachEventListeners = () => {
    if (typeof window !== "undefined") {
      this.removeEventListeners();
      this.attachResizeListener();
      this.attachScrollListener();
    }
  };

  private removeEventListeners = () => {
    if (typeof window !== undefined) {
      this.removeResizeListener();
      this.removeScrollListener();
    }
  };

  private setScreenSize = (size: ScreenBreakpoint) => {
    this.screenSize = size;
  };

  private setScreenOrientation = (orientation: ScreenOrientation) => {
    this.screenOrientation = orientation;
  };

  private attachResizeListener = () => {
    const resizeFunc = _.debounce(this.initScreenSize, 100);
    window.addEventListener("resize", resizeFunc);
  };

  private removeResizeListener = () => {
    const resizeFunc = _.debounce(this.initScreenSize, 100);
    window.removeEventListener("resize", resizeFunc);
  };

  public setKeyboardIsOpen = (
    isKeyboardOpen: boolean,
    isInputFocused?: boolean
  ) => {
    this.isKeyboardOpen = isKeyboardOpen;
    if (isInputFocused !== undefined) {
      this.setHasFocusedInput(isInputFocused);
    }
  };

  public setHasFocusedInput = (isFocused: boolean) => {
    this.hasFocusedInput = isFocused;
  };

  get isFirefox() {
    return isUserAgentFirefox(this.navigatorAgent);
  }

  activeTabs = {
    payout: "Earnings",
    gallery: "My Profiles",
  };

  setActiveTab(page: string, tab: string): void {
    this.activeTabs[page] = tab;
  }

  setIsLiteMode = (val: boolean) => {
    CookieStorage.set("dolitemode", val);
    this.isLiteMode = val;
  };

  setIsBroadcastPage = (val: boolean) => {
    this.isBroadcastPage = val;
  };
}

export const layoutStore = new LayoutStore();
