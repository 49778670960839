import { Gender } from "../../stores/validation/ValidationStore";
import sanitizeHtml from "sanitize-html";

const priceMultiplier = 10;

export default class Formatter {
  private replaceTag(key, formattedText, tags) {
    const re = new RegExp("{" + key + "}", "g");
    return formattedText.replace(re, tags[key]);
  }

  public replaceVars(text: string, tags: {}) {
    return Object.keys(tags).reduce(
      (key, formattedText) => this.replaceTag(key, formattedText, tags),
      text
    );
  }

  static getPercentageString(amount: number, total: number) {
    return (amount / total) * 100 + "%";
  }

  public addCommasToNumber(x: number | string) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  public tokens(cost: number) {
    return cost;
  }

  static toTokens(cost: number) {
    return cost * 100;
  }

  public replaceBrTagsWithNewLines = (str: string) => {
    return str.replace(/<br\s*\/?>/gi, "\n");
  };

  public capitalizeText = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1) || "";
  };

  public padZero = (str: string | number, len?: number) => {
    len = len || 2;
    const zeros = new Array(len).join("0");
    return (zeros + str).slice(-len);
  };

  public kFormatter(num: number) {
    return num > 999 ? "" + Math.round(num / 100) / 10 + "K" : num;
  }

  public tokenToCurrency(amount) {
    return amount ? parseFloat(amount) / priceMultiplier : 0;
  }
  public currencyToToken(amount) {
    return amount ? Math.round(parseFloat(amount) * priceMultiplier) : 0;
  }

  public formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  public toBase64 = (file): Promise<any> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  public getGenderShort = (gender: Gender) => {
    switch (gender) {
      case "female":
        return "F";
      case "male":
        return "M";
      case "trans":
        return "TS";
    }
  };

  public sanitizeHtml = (
    html: string,
    options?: {
      allowedTags?: any[];
      allowedAttributes?: Object;
      cleanBrTags?: boolean;
    }
  ) => {
    options = {
      ...options,
      allowedTags: options?.allowedTags || [],
      allowedAttributes: options?.allowedAttributes || {},
    };

    const cleanOfBrTags = options?.cleanBrTags
      ? this.replaceBrTagsWithNewLines(html)
      : html;
    const sanitizedHtml = sanitizeHtml(cleanOfBrTags, options);

    return sanitizedHtml;
  };

  public splitURLToUploadImageKey = (str: string) => {
    const withoutParams = str.split("?")[0].split("-documents/");
    return withoutParams[withoutParams.length - 1];
  };
}
