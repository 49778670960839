import ChatSocket, {
  IChatLanguageTranslation,
} from "common/broadcast/_stores/chat/ChatSocket";
import { api } from "core/utility";
import {
  computed,
  IReactionDisposer,
  makeAutoObservable,
  observable,
  reaction,
} from "mobx";
import camsAudio from "library/core/utility/audio";
import {
  IBufferedChatMessage,
  IChatMessage,
  IChatMessageOrNotification,
  IChatNotification,
  IChatSocketMethods,
  IMember,
  IMemberBalanceHash,
  IMemberHash,
  IMemberNotes,
  ITopAdmirer,
  ITopAdmirerHash,
  IWhisperConversation,
  IWhisperHash,
} from "./interfaces";
import { ChatModeEnum, ChatState, IChatTab, MemberActionEnum } from "./enums";
import {
  BroadcastStreamState,
  BroadcastType, 
  IShowType,
} from "../broadcast/enums";
import axios from "core/config/axios";
import moment from "moment";
import { walletAxios } from "core/config/axios";
import { snackbarStore } from "library/core/stores/snackbar/SnackbarStore";
import { logger, uuid } from "library/core/utility";
import { SnackbarVariants } from "library/core/stores/snackbar/enums";
import {
  CHAT_MEMBER_COLOR_MAPPING,
  InitialData,
  MEMBER_SILENCE_STRING,
  SHOW_TYPE_CHAT_TYPE_MAP,
  MIN_HOURS_TO_SHOW_TIME_REMAINING,
  SHOW_TYPES_WITHOUT_MEMBER_TIME_LEFT_NOTIFICATION,
} from "./consts";
import { languageStore } from "library/core/stores/language/LanguageStore";
import AuthStore from "core/stores/auth/AuthStore";
import {
  authStore,
  broadcastStore,
  broadcastStreamStore,
  logToGraylog,
  pricingStore,
  profileStore,
} from "core/stores";
import { ChatRoomConnectionStatusResponse } from "common/broadcast/_stores/chat/types";
import { enableFrequentSocketLogs } from "core/config";
import _ from "lodash";

const logPrefix = "[ChatStore]:";
const { intl } = languageStore!;

export default class ChatStore {
  chatSocket: ChatSocket | undefined = InitialData.chatSocket;

  memberSentGiftReactionDisposer?: IReactionDisposer =
    InitialData.memberSentGiftReactionDisposer;
  memberJoinedShowReactionDisposer?: IReactionDisposer =
    InitialData.memberJoinedShowReactionDisposer;

  constructor() {
    makeAutoObservable(this);
  }

  publicMemberCount: number = InitialData.publicMemberCount;
  privateMemberCount: number = InitialData.privateMemberCount;

  notifications: IChatNotification[] = InitialData.notifications;

  numberOfOnlineGuests: number = InitialData.numberOfOnlineGuests;
  numberOfOnlinePublicMembers: number = InitialData.numberOfOnlinePublicMembers;
  numberOfOnlinePrivateMembers: number =
    InitialData.numberOfOnlinePrivateMembers;
  supportChatMessagesUnread: number = InitialData.supportChatMessagesUnread;

  publicChatMessages: IChatMessage[] = InitialData.publicChatMessages;
  privateChatMessages: IChatMessage[] = InitialData.privateChatMessages;
  supportChatMessages: IChatMessage[] = InitialData.supportChatMessages;
  bufferedMessages: IBufferedChatMessage[] = InitialData.bufferedMessages;

  publicMemberHash: IMemberHash = InitialData.publicMemberHash;
  privateMemberHash: IMemberHash = InitialData.privateMemberHash;
  privateMemberHashCache: IMemberHash = InitialData.privateMemberHash;
  voyeurMemberHash: IMemberHash = InitialData.voyeurMemberHash;
  isNotesDrawerOpen: string = InitialData.isNotesDrawerOpen;
  isEditingNotes: string = InitialData.isEditingNotes;
  chatState: ChatState = InitialData.chatState;

  publicWhisperHash: IWhisperHash = InitialData.publicWhisperHash;
  privateWhisperHash: IWhisperHash = InitialData.privateWhisperHash;
  @observable MemberNotes: IMemberNotes[] = InitialData.MemberNotes;
  currentChatLanguage: string = InitialData.currentChatLanguage;

  activeChatTab: IChatTab | string = InitialData.activeChatTab;

  gift: IChatNotification | undefined = InitialData.gift;

  isPrivateChatReady: boolean = InitialData.isPrivateChatReady;
  privateShowRequesterName: string = InitialData.privateShowRequesterName;
  memberJoinedShowInfo: IChatNotification | undefined =
    InitialData.memberJoinedShowInfo;
  membersBalance: number = InitialData.membersBalance;
  membersBalanceHash: IMemberBalanceHash = InitialData.membersBalanceHash;
  topAdmirers: ITopAdmirer[] = InitialData.topAdmirers;
  topAdmirersHash: ITopAdmirerHash = InitialData.topAdmirersHash;

  chatFontSize: number = InitialData.chatFontSize;

  message: string = InitialData.message;

  wheelOfFunSpinnerUser = InitialData.wheelOfFunSpinnerUser;
  wheelOfFunSpinnerReward: string | undefined =
    InitialData.wheelOfFunSpinnerReward;
  wheelOfFunSpinnerTimestamp: number | undefined =
    InitialData.wheelOfFunSpinnerTimestamp;

  getRoomConnectionStatusInterval: any =
    InitialData.getRoomConnectionStatusInterval;
  roomConnectionStatus: ChatRoomConnectionStatusResponse =
    InitialData.roomConnectionStatus;
  modelRoomDisconnectedReaction?: IReactionDisposer =
    InitialData.modelRoomDisconnectedReaction;

  checkChatSocketStartingHangsTimeout: any;

  shouldShowPrivatememberLeftConfirmation: boolean =
    InitialData.shouldShowPrivatememberLeftConfirmation;

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  setChatState = (state: ChatState) => {
    this.log("setChatState started", state);
    this.chatState = state;

    if (this.chatState === ChatState.started) {
      if (
        typeof broadcastStreamStore.broadcastError === "object" &&
        broadcastStreamStore.broadcastError?.id ===
          "broadcast-error.stopping-stream"
      ) {
        broadcastStreamStore.setBroadcastError(null);
      }
    }

    if (this.chatState === ChatState.startingChat) {
      this.addCheckChatSocketStartingHangsTimeout();
    }

    this.log("setChatState finished");
  };

  addCheckChatSocketStartingHangsTimeout = () => {
    if (this.checkChatSocketStartingHangsTimeout) {
      clearTimeout(this.checkChatSocketStartingHangsTimeout);
    }
    this.checkChatSocketStartingHangsTimeout = setTimeout(() => {
      if (this.chatState === ChatState.startingChat) {
        this.init();
      }
    }, 5 * 1000);
  };

  init = async () => {
    this.log("init started");
    this.setChatState(ChatState.startingChat);
    this.getNotes();
    try {
      if (this.chatSocket) {
        await this.resetStore();
      }
    } catch (error) {
      this.log("reset previous chat error", error);
    }

    Object.assign(this, InitialData);
    try {
      const accessToken = AuthStore.getAccessToken();
      if (!!accessToken && accessToken !== "") {
        this.chatSocket = new ChatSocket();
        this.chatSocket?.connect(
          profileStore.modelProfile.username,
          accessToken,
          this.handleAuthFailed,
          this.handleConnected,
          this.handleDisconnected,
          this.onMiddlewareTriggered,
          this.chatSocketMethods
        );
        await this.getChatLanguage();
        await this.getTopAdmirers();
      } else {
        this.log(
          "init did not find access token, getting a new access token and retrying"
        );
        await this.handleAuthFailed();
        await this.init();
      }
    } catch (error) {
      this.log("init failed", error);
    }
    this.log("init finished");
  };

  get chatSocketMethods(): IChatSocketMethods {
    return {
      receiveMessagePublic: this.handleMessageReceived,
      memberJoinedPublic: this.handleMemberJoined,
      memberDisconnectedPublic: this.handleMemberDisconnected,
      memberLeftPublic: this.handleMemberLeft,
      receiveMessageSupport: this.handleSupportMessageReceived,
      giftSent: this.handleGiftSent,
      receiveMessagePrivate: this.handlePrivateMessageReceived,
      memberJoinedPrivate: this.handleMemberPrivateJoined,
      memberBalanceStatus: this.handleMemberBalanceStatusReceived,
      modelJoinedPrivate: this.handleModelPrivateJoined,
      memberDisconnectedPrivate: this.handleMemberPrivateDisconnected,
      modelPrivateRoomDestroyed: this.handleModelPrivateRoomDestroyed,
      memberLeftPrivate: this.handleMemberPrivateLeft,
      topAdmirers: this.handleTopAdmirers,
      modelPrivateRoomCreated: this.handleModelPrivateRoomCreated,
      favouredModel: this.handleFavouredModel,
      memberInfo: this.handleMemberInfo,
    };
  }

  resetStore = async () => {
    this.log("resetStore started");
    await this.handleDisconnected();
    this.chatSocket?.closeSocket();
    Object.assign(this, InitialData);
    this.setChatState(ChatState.stopped);
    this.log("resetStore finished");
  };

  handleConnected = async () => {
    this.log("handleConnected started");
    this.addChatReactions();
    this.setGetRoomConnectionStatusInterval(true);
    try {
      await this.subscribeToPublic();
      await this.subscribeToSupport();
      this.setChatState(ChatState.started);
      this.log("handleConnected success");
    } catch (error) {
      this.log("handleConnected failed", error);
    } finally {
      this.log("handleConnected finished");
    }
  };

  handleDisconnected = async () => {
    this.log("handleDisconnected started");
    this.setChatState(ChatState.stoppingChat);
    this.removeGetRoomConnectionStatusInterval();
    this.disposeChatNotifications();
    this.clearNotifications();
    this.membersBalanceHash = {};

    try {
      await this.unsubscribeToPublic();
    } catch (error) {
      this.log("handleDisconnected unsubscribeToPublic failed", error);
    }
    try {
      await this.unsubscribeToSupport();
    } catch (error) {
      this.log("handleDisconnected unsubscribeToSupport failed", error);
    }
    try {
      await this.unsubscribeToPrivate();
    } catch (error) {
      this.log("handleDisconnected unsubscribeToPrivate failed", error);
    }
    this.log("handleDisconnected finished");
  };

  handleAuthFailed = async () => {
    const authResponse =
      await authStore.getAuthResponseFromBackendViaRefreshToken();
    return authResponse.access;
  };

  setisNotesDrawerOpen = memberId => {
    this.isNotesDrawerOpen = memberId;
  };
  setisEditingNotes = noteid => {
    if (this.isEditingNotes === noteid) {
      this.isEditingNotes = "";
    } else {
      this.isEditingNotes = noteid;
    }
  };

  removeModelRoomDisconnectedReaction = () => {
    this.log("removeModelRoomDisconnectedReaction started");
    if (this.modelRoomDisconnectedReaction) {
      this.modelRoomDisconnectedReaction();
      this.modelRoomDisconnectedReaction = undefined;
    }
    this.log("removeModelRoomDisconnectedReaction finished");
  };

  addModelRoomDisconnectedReaction = () => {
    this.log("addModelRoomDisconnectedReaction started");
    this.removeModelRoomDisconnectedReaction();
    this.modelRoomDisconnectedReaction = reaction(
      () => [
        this.roomConnectionStatus.public,
        this.roomConnectionStatus.private,
      ],
      () => {
        if (broadcastStore.isInPaidShow && !this.roomConnectionStatus.private) {
          this.subscribeToPrivate();
        } else if (
          !broadcastStore.isInPaidShow &&
          !this.roomConnectionStatus.public
        ) {
          this.subscribeToPublic();
        }
      }
    );
    this.log("addModelRoomDisconnectedReaction finished");
  };

  get whisperMembers(): Array<keyof IWhisperHash> {
    const members = Object.keys(this.whisperHash);
    this.log("whisperMembers returning", members);
    return members;
  }

  get whisperConversations(): IWhisperConversation[] {
    const _conversations = Object.values(this.whisperHash);
    const conversations = _conversations.filter(c => c?.member);
    const mappedConversations = conversations.map(conversation => {
      _.debounce(conversation => {
        const bufferedMessages = this.bufferedMessages.filter(
          m =>
            m.bufferChannelType === "whisper-private" ||
            (m.bufferChannelType === "whisper-public" &&
              m.toMemberId === conversation.member.id)
        );
        if (bufferedMessages.length > 0) {
          conversation.messages = this.sortMessagesAndNotificationsByCreateDate(
            [...conversation.messages, ...bufferedMessages]
          ) as IChatMessage[];
        }
      }, 100);
      return conversation;
    });
    this.log("whisperConversations returning", mappedConversations);
    return conversations;
  }

  get whisperHash(): IWhisperHash {
    const hash = broadcastStore.isInPaidShow
      ? this.privateWhisperHash
      : this.publicWhisperHash;
    this.log("whisperHash returning", hash);
    return hash;
  }

  get memberHash(): IMemberHash {
    const hash =
      broadcastStore.currentShowType === IShowType.PRIVATE ||
      broadcastStore.currentShowType === IShowType.GROUP ||
      broadcastStore.currentShowType === IShowType.NUDE ||
      broadcastStore.currentShowType === IShowType.CURTAIN_DROPPED
        ? this.privateMemberHash
        : this.publicMemberHash;

    this.log("memberHash returning", hash);
    return hash;
  }

  get numberOfOnlineMembers(): number {
    const count = broadcastStore.isInPaidShow
      ? this.numberOfOnlinePrivateMembers
      : this.numberOfOnlinePublicMembers;
    this.log("numberOfOnlineMembers returning", count);
    return count;
  }

  get bufferedPublicChatMessages(): IChatMessage[] {
    return this.bufferedMessages.filter(m => m.bufferChannelType === "public");
  }

  get bufferedPrivateChatMessages(): IChatMessage[] {
    return this.bufferedMessages.filter(m => m.bufferChannelType === "private");
  }

  get bufferedSupportChatMessages(): IChatMessage[] {
    return this.bufferedMessages.filter(m => m.bufferChannelType === "support");
  }

  get publicChatMessagesAndNotifications(): IChatMessageOrNotification[] {
    return this.sortMessagesAndNotificationsByCreateDate([
      ...this.bufferedPublicChatMessages,
      ...this.publicChatMessages,
      ...this.notifications,
    ]);
  }

  get privateChatMessagesAndNotifications(): IChatMessageOrNotification[] {
    return this.sortMessagesAndNotificationsByCreateDate([
      ...this.bufferedPrivateChatMessages,
      ...this.privateChatMessages,
      ...this.notifications,
    ]);
  }
  get supportChatMessagesAndNotifications(): IChatMessageOrNotification[] {
    return this.sortMessagesAndNotificationsByCreateDate([
      ...this.bufferedSupportChatMessages,
      ...this.supportChatMessages,
      ...this.notifications,
    ]);
  }

  setMessage = (message: string) => {
    this.log("setMessage started");
    this.message = message;
    this.log("setMessage finished");
  };

  sendMessage = async (
    message: string,
    chatMode: ChatModeEnum,
    whisperMemberUsername?: string,
    whisperMemberId?: string
  ) => {
    try {
      this.log("sendMessage started");
      if (message.trim() === "") {
        this.log("sendMessage cancel, empty message");
        return;
      }
      const isWhispering =
        chatMode === ChatModeEnum.WHISPER &&
        whisperMemberUsername &&
        whisperMemberUsername.trim() !== "";
      if (broadcastStore.streamState === BroadcastStreamState.started) {
        const bufferedMessage: IBufferedChatMessage = {
          from: profileStore.modelProfile.username,
          text: message,
          isModel: true,
          type: "text",
          nameColor: "",
          userId: profileStore.modelProfile.id,
          isMessageOrNotification: "message",
          created: new Date(),
          isSentSuccessfully: false,
          bufferChannelType: "public",
        };
        if (isWhispering) {
          if (broadcastStore.isInPaidShow) {
            bufferedMessage.bufferChannelType = "whisper-private";
            bufferedMessage.toMemberId = whisperMemberId;
            this.addBufferedChatMessage(bufferedMessage);
            await this.sendWhisperMessagePrivate(message, whisperMemberId);
          } else {
            bufferedMessage.bufferChannelType = "whisper-public";
            bufferedMessage.toMemberId = whisperMemberId;
            this.addBufferedChatMessage(bufferedMessage);
            await this.sendWhisperMessage(message, whisperMemberId);
          }
        } else if (
          chatMode === ChatModeEnum.PRIVATE &&
          broadcastStore.isInPaidShow
        ) {
          bufferedMessage.bufferChannelType = "private";
          this.addBufferedChatMessage(bufferedMessage);
          await this.sendMessagePrivate(message);
        } else if (chatMode === ChatModeEnum.ADMIN) {
          bufferedMessage.bufferChannelType = "support";
          this.addBufferedChatMessage(bufferedMessage);
          await this.sendSupportMessage(message);
        } else {
          bufferedMessage.bufferChannelType = "public";
          this.addBufferedChatMessage(bufferedMessage);
          await this.sendPublicMessage(message);
        }
      }
    } catch (error) {
      if (error) {
        this.addNotification({
          id: uuid.getNew(),
          created: new Date(),
          fixedContentKey: `notificationMessage.floodDetection`,
          isMessageOrNotification: "notification",
        });
      }
      this.log("sendMessage failed", error);
    } finally {
      this.log("sendMessage finished");
    }
  };

  sendPublicMessage = async message => {
    this.log("sendMessage started");
    try {
      await this.chatSocket?.emit("sendPublicMessage", {
        message,
        ...this.chatSocketTrackingParams,
      });
      this.log("sendPublicMessage success");
    } catch (error) {
      this.log("sendPublicMessage failed", error);
      throw error;
    } finally {
      this.log("sendPublicMessage finished");
    }
  };

  sortMessagesAndNotificationsByCreateDate = (
    messagesAndNotifications: IChatMessageOrNotification[]
  ) => {
    return messagesAndNotifications.sort((a, b) => {
      return a.created.getTime() - b.created.getTime();
    });
  };

  subscribeToSupport = async () => {
    this.log("subscribeToSupport started");
    try {
      await this.chatSocket?.emit("subscribeToSupportRoom", {
        ...this.chatSocketTrackingParams,
      });
      this.log("subscribeToSupport success");
    } catch (error) {
      if (error) {
        if (error.code === 400) {
          this.log(
            "subscribeToSupport received error, already subscribed to support, passing"
          );
        } else {
          this.log("subscribeToSupport received error", error);
          throw error;
        }
      }
    } finally {
      this.log("subscribeToSupport finished");
    }
  };

  unsubscribeToSupport = async () => {
    this.log("unsubscribeToSupport started");
    try {
      if (this.chatSocket?.socket?.connected) {
        await this.chatSocket?.emit("unsubscribeFromSupportRoom", {
          ...this.chatSocketTrackingParams,
        });
      }
      this.log("unsubscribeToSupport success");
    } catch (error) {
      this.log("unsubscribeToSupport failed", error);
    } finally {
      this.log("unsubscribeToSupport finished");
    }
  };

  subscribeToPublic = async () => {
    if (!this.roomConnectionStatus.public) {
      this.log("subscribeToPublic started");
      try {
        const data = await this.chatSocket?.emit("subscribeToPublicRoom", {
          ...this.chatSocketTrackingParams,
        });
        await this.handlePublicRoomSubscribedSuccess(data);
        this.log("subscribeToPublic success");
      } catch (error) {
        this.log("subscribeToPublic failed", error);
        this.handlePublicRoomSubscribeFailed(error);
      } finally {
        this.log("subscribeToPublic finished");
      }
    }
  };

  unsubscribeToPublic = async () => {
    if (this.roomConnectionStatus.public) {
      this.log("unsubscribeToPublic started");
      try {
        if (this.chatSocket?.socket?.connected) {
          await this.chatSocket?.emit("unsubscribeFromPublicRoom", {
            ...this.chatSocketTrackingParams,
          });
          this.setRoomConnectionStatus("public", false);
        }
        this.log("unsubscribeToPublic success");
      } catch (error) {
        this.log("unsubscribeToPublic failed", error);
      } finally {
        this.log("unsubscribeToPublic finished");
      }
    }
  };

  sendSupportMessage = async message => {
    this.log("sendSupportMessage started");
    try {
      await this.chatSocket?.emit("sendSupportMessage", {
        message,
        ...this.chatSocketTrackingParams,
      });
      this.log("sendSupportMessage success");
    } catch (error) {
      this.log("sendSupportMessage failed", error);
      throw error;
    } finally {
      this.log("sendSupportMessage finished");
    }
  };

  sendWhisperMessage = async (message, whisper_to) => {
    this.log("sendWhisperMessage started");
    try {
      await this.chatSocket?.emit("sendPublicMessage", {
        message,
        whisper_to,
        ...this.chatSocketTrackingParams,
      });
      this.log("sendWhisperMessage success");
    } catch (error) {
      this.log("sendWhisperMessage failed", error);
      throw error;
    } finally {
      this.log("sendWhisperMessage finished");
    }
  };

  // Private
  subscribeToPrivate = async (requesterUserName?: string) => {
    this.log("subscribeToPrivate started");
    if (!this.roomConnectionStatus.private) {
      this.voyeurMemberHash = {};
      this.setIsPrivateChatReady(false);
      this.privateShowRequesterName = requesterUserName || "";
      // unsubscribe from public is necessary, do not leave room hanging
      await this.unsubscribeToPublic();
      try {
        await this.chatSocket?.emit("subscribeToPrivateRoom", {
          ...this.chatSocketTrackingParams,
          location:
            broadcastStore.currentShowType === IShowType.TIPPING ||
            broadcastStore.currentShowType === IShowType.CURTAIN_DROPPED
              ? "goal_show"
              : broadcastStore.currentShowType,
        });
        await this.handlePrivateRoomSubscribedSuccess();
        this.log("subscribeToPrivate success");
      } catch (error) {
        if (error.code === 400) {
          this.log(
            "subscribeToPrivate received error, already subscribed to private, passing"
          );
        } else {
          this.log("subscribeToPrivate failed", error);
          this.handlePrivateRoomSubscribedFailed();
        }
      } finally {
        this.log("subscribeToPrivate finished");
      }
    }
  };

  unsubscribeToPrivate = async () => {
    if (this.roomConnectionStatus.private) {
      try {
        this.log("unsubscribeToPrivate started");
        if (this.chatSocket?.socket?.connected) {
          await this.chatSocket?.emit("unsubscribeFromPrivateRoom", {
            ...this.chatSocketTrackingParams,
          });
          this.setRoomConnectionStatus("private", false);
        }
        this.setIsPrivateChatReady(false);
        this.log("unsubscribeToPrivate success");
      } catch (error) {
        this.log("unsubscribeToPrivate failed", error);
      } finally {
        this.log("unsubscribeToPrivate finished");
      }
    }
  };

  sendMessagePrivate = async message => {
    this.log("sendMessagePrivate started");
    try {
      await this.chatSocket?.emit("sendPrivateMessage", {
        message,
        ...this.chatSocketTrackingParams,
      });
      this.log("sendMessagePrivate success");
    } catch (error) {
      this.log("sendMessagePrivate failed", error);
      throw error;
    } finally {
      this.log("sendMessagePrivate finished");
    }
  };

  sendWhisperMessagePrivate = async (message, whisper_to) => {
    this.log("sendWhisperMessagePrivate started");
    try {
      await this.chatSocket?.emit("sendPrivateMessage", {
        message,
        whisper_to,
        ...this.chatSocketTrackingParams,
      });
      this.log("sendWhisperMessagePrivate success");
    } catch (error) {
      this.log("sendWhisperMessagePrivate failed", error);
      throw error;
    } finally {
      this.log("sendWhisperMessagePrivate finished");
    }
  };

  removeGetRoomConnectionStatusInterval = () => {
    this.log("removeGetRoomConnectionStatusInterval started");
    if (this.getRoomConnectionStatusInterval) {
      clearInterval(this.getRoomConnectionStatusInterval);
      this.getRoomConnectionStatusInterval = undefined;
    }
    this.log("removeGetRoomConnectionStatusInterval finished");
  };

  setGetRoomConnectionStatusInterval = (runImmediate: boolean = false) => {
    this.log("setGetRoomConnectionStatusInterval started");
    this.removeGetRoomConnectionStatusInterval();
    if (runImmediate) {
      this.getAndSetModelRoomConnectionStatus();
    }
    if (!this.getRoomConnectionStatusInterval) {
      this.getRoomConnectionStatusInterval = setInterval(() => {
        this.getAndSetModelRoomConnectionStatus();
      }, 5 * 1000);
    }
    this.log("setGetRoomConnectionStatusInterval finished");
  };

  getAndSetModelRoomConnectionStatus = async () => {
    this.log("getAndSetModelRoomConnectionStatus started");
    try {
      const modelId = profileStore.modelProfile.id;
      const result: ChatRoomConnectionStatusResponse =
        (await this.chatSocket?.emit(
          "getSubscriptionStatus",
          {
            model: modelId,
            ...this.chatSocketTrackingParams,
          },
          "callGetInfo"
        )) as unknown as ChatRoomConnectionStatusResponse;
      Object.keys(result).forEach(key => {
        this.setRoomConnectionStatus(
          key as keyof ChatRoomConnectionStatusResponse,
          result[key]
        );
      });
    } catch (error) {
      this.log("getAndSetModelRoomConnectionStatus failed", error);
    } finally {
      this.log("getAndSetModelRoomConnectionStatus finished");
    }
  };

  setRoomConnectionStatus = (
    room: "public" | "private" | "support",
    isSubscribed: boolean
  ) => {
    if (enableFrequentSocketLogs) {
      this.log(
        "setRoomConnectionStatus started for room",
        room,
        "isSubscribed",
        isSubscribed
      );
    }
    this.roomConnectionStatus[room] = isSubscribed;
    if (enableFrequentSocketLogs) {
      this.log("setRoomConnectionStatus finished");
    }
  };

  onMiddlewareTriggered = (eventName: string, args: any) => {
    this.log(
      "onMiddlewareTriggered started with eventName",
      eventName,
      "and args",
      args
    );
    const eventNames: string[] = [];
    const logExceptionEvents: string[] = ["ping", "pong"];
    if (eventName) {
      eventNames.push(eventName);
      if (!logExceptionEvents.includes(eventName)) {
        logToGraylog(
          logPrefix,
          `middleware received event with event name: ${eventName} and event data:`,
          args
        );
      }
    }
  };

  addNotification = (notification: IChatNotification) => {
    this.log("addNotification started");
    this.notifications = [
      { ...notification, created: new Date() },
      ...this.notifications,
    ];
    this.log("addNotification finished");
  };

  clearNotifications = () => {
    this.log("clearNotifications started");
    this.notifications = [];
    this.log("clearNotifications finished");
  };

  handleNotifications = (
    message: any,
    broadcastType: BroadcastType,
    fixedContentKey: string,
    fixedContentParams?: any
  ) => {
    this.log("handleNotifications started");
    const event = {
      id: uuid.getNew(),
      broadcastType,
      created: new Date(message.startTime),
      fixedContentKey: `notificationMessage.${fixedContentKey}`,
      fixedContentParams,
    } as IChatNotification;

    this.addNotification(event);
    this.log("handleNotifications finished");
  };

  getModelBufferedMessage = data => {
    const bufferedMessage = this.bufferedMessages.find(m => {
      return (
        m.text === data.originalMessage &&
        m.isModel &&
        data.senderType === "model"
      );
    });

    return bufferedMessage;
  };
  @computed getNotes = async () => {
    this.log("getting Notes started");
    try {
      const { data } = await axios.get("/models/me/member-notes/");
      this.MemberNotes = data;
    } catch (error) {
      this.log("getting Notes failed", error);
    } finally {
      this.log("getting Notes finished");
    }
  };
  editNote = async (value, member_id, noteId) => {
    const editedIndex = this.MemberNotes.findIndex(p => p.id === noteId);
    this.MemberNotes[editedIndex].note = value;
    this.log("submiteNewNote started");
    try {
      await axios.put(`/models/me/member-notes/${noteId}/`, {
        member: member_id,
        note: value,
      });
    } catch (error) {
      this.log("submiteNewNote failed", error);
    } finally {
      this.log("submiteNewNote finished");
      this.getNotes();
      this.setisEditingNotes("");
    }
  };
  submitNewNote = async (value, member_id) => {
    this.log("submiteNewNote started");
    try {
      await axios.post("/models/me/member-notes/", {
        member: member_id,
        note: value,
      });
    } catch (error) {
      this.log("submiteNewNote failed", error);
    } finally {
      this.log("submiteNewNote finished");
      this.getNotes();
    }
  };
  deleteNote = async noteId => {
    this.log("deleteNote started");
    this.MemberNotes = this.MemberNotes.filter(p => p.id !== noteId);
    try {
      await axios.delete(`/models/me/member-notes/${noteId}`);
    } catch (error) {
      this.log("deleteNote failed", error);
    } finally {
      this.log("deleteNote finished");
      this.getNotes();
    }
  };

  findModelBufferedMessageAndRemoveIfExists = data => {
    const bufferedMessage = this.getModelBufferedMessage(data);
    if (bufferedMessage) {
      const index = this.bufferedMessages.indexOf(bufferedMessage);
      this.bufferedMessages.splice(index, 1);
    }
    return bufferedMessage;
  };

  handleMessageReceived = data => {
    this.log("handleReceiveMessage started");
    if (data.senderType === "system") {
      this.addPublicChatMessage({
        from: "System",
        text: data.message,
        isModel: false,
        type: "text",
        isMessageOrNotification: "message",
        isSentSuccessfully: true,
      });
    } else {
      this.findModelBufferedMessageAndRemoveIfExists(data);

      const isFromUser = data.senderType !== "model" && !!data.user?.id;

      if (isFromUser) {
        this.handleMemberInfoUpdate(data.user?.id, {
          spendingTier: data.spendingTier,
          isPremiere: data.isPremiere,
          isFanClub: data.isFanClub,
          hasTokens: data.hasTokens,
        });
      }

      const message: IChatMessage = {
        from: data.senderType === "system" ? "System" : data.user.username,
        text:
          (data.senderType === "model" ? data.originalMessage : data.message) ||
          "",
        isModel: data.senderType === "model",
        type: "text",
        nameColor: isFromUser
          ? this.getMemberNameColor(this.memberHash[data.user.id])
          : "",
        userId: data.user?.id,
        isMessageOrNotification: "message",
        created: new Date(),
        isSentSuccessfully: true,
      };
      if (!!data.whisperToMember) {
        this.addModelChatMessageToWhisper(message, data);
      } else if (!!data.isWhisper) {
        this.addMemberChatMessageToWhisper(message, data);
      } else {
        this.addPublicChatMessage(message);
      }
    }
    this.log("handleReceiveMessage finished");
  };

  handleSupportMessageReceived = data => {
    this.log("handleReceiveSupportMessage started");
    const message: IChatMessage = {
      from: data.senderType === "system" ? "System" : data.user.username,
      text:
        (data.senderType === "model" ? data.originalMessage : data.message) ||
        "",
      isModel: data.senderType === "model",
      isAdmin: data.senderType !== "model",
      type: "text",
      nameColor: "black",
      isMessageOrNotification: "message",
      created: new Date(),
      isSentSuccessfully: true,
    };
    this.addSupportChatMessage(message);
    this.log("handleReceiveSupportMessage finished");
  };

  handlePrivateMessageReceived = data => {
    this.log("handleReceivePrivateMessage started");
    if (data.senderType === "system") {
      this.addPrivateChatMessage({
        from: "System",
        text: data.message,
        isModel: false,
        type: "text",
        isMessageOrNotification: "message",
        isSentSuccessfully: true,
      });
    } else {
      const isWhisperSentByModel = !!data.whisperToMember;
      const bufferedMessage =
        this.findModelBufferedMessageAndRemoveIfExists(data);
      if (bufferedMessage) {
        if (
          isWhisperSentByModel &&
          bufferedMessage.toMemberId &&
          this.whisperHash[bufferedMessage.toMemberId]
        ) {
          this.whisperHash[bufferedMessage.toMemberId].messages =
            this.whisperHash[bufferedMessage.toMemberId].messages.filter(
              m =>
                !(
                  m.text === data.originalMessage &&
                  m.isModel &&
                  data.senderType === "model"
                )
            );
        }
      }

      const isFromUser = data.senderType !== "model" && !!data.user?.id;
      const message: IChatMessage = {
        from: data.senderType === "system" ? "System" : data.user.username,
        text:
          (data.senderType === "model" ? data.originalMessage : data.message) ||
          "",
        isModel: data.senderType === "model",
        type: "text",
        nameColor: isFromUser
          ? this.getMemberNameColor(this.memberHash[data.user.id])
          : "",
        userId: data.user?.id,
        isMessageOrNotification: "message",
        created: new Date(),
        isSentSuccessfully: true,
      };
      if (!!data.whisperToMember) {
        this.addModelChatMessageToWhisper(message, data, true);
      } else if (!!data.isWhisper) {
        this.addMemberChatMessageToWhisper(message, data, true);
      } else {
        this.addPrivateChatMessage(message);
      }
    }
    this.log("handleReceivePrivateMessage finished");
  };

  handleMemberJoined = data => {
    this.log("handleMemberJoined started");
    if (data.user?.id) {
      this.publicMemberHash[data.user?.id] = {
        ...data.user,
        isInvisible: data.memberIsInvisible,
        hasTokens: data.memberHasTokens,
        isBounty: data.memberIsBounty,
        isPremiere: data.memberIsPremiere,
        isFanClub: data.memberIsFanClub,
        spendingTier: data.memberSpendingTier || 0,
      };
      if (
        pricingStore.modelProducts?.chat_settings?.system_settings
          ?.sound_enter_leave &&
        broadcastStore.streamState === BroadcastStreamState.started &&
        data.memberHasTokens
      )
        camsAudio.playMemberEnter();
    }

    this.notifyWhisperForMember(data.user?.id, {
      online: true,
    });
    this.handleUserCountChange(data);
    this.log("handleMemberJoined finished");
  };

  getMemberTimeLeftPrivateChat = (username, balance, chatPrice) => {
    this.log("getMemberTimeLeftPrivateChat started");
    let timeLeft = "";
    const minutesLeft = balance / chatPrice;

    if (minutesLeft / 60 >= MIN_HOURS_TO_SHOW_TIME_REMAINING) {
      if (!this.membersBalanceHash[username]) {
        this.memberJoinedShowInfo = {
          id: uuid.getNew(),
          created: new Date(),
          content: `${username} has ${MIN_HOURS_TO_SHOW_TIME_REMAINING} hours remaining`,
          fixedContentKey: `notificationMessage.minMemberBalance`,
          fixedContentParams: {
            member: `${username}`,
            hours: `${MIN_HOURS_TO_SHOW_TIME_REMAINING}`,
          },
        } as IChatNotification;
      }
      this.membersBalanceHash[username] = minutesLeft;
    } else if (
      chatPrice &&
      balance > chatPrice &&
      broadcastStore.isInPaidShow
    ) {
      timeLeft = moment
        .utc()
        .startOf("day")
        .add({ minutes: minutesLeft })
        .format("H:mm:ss");

      this.memberJoinedShowInfo = {
        id: uuid.getNew(),
        created: new Date(),
        fixedContentKey: intl.formatMessage(
          {
            id: `notificationMessage.memberBalance`,
          },
          {
            member: `${username}`, 
            balance: `${timeLeft}`
          }
        ),
        fixedContentParams: { member: `${username}`, balance: `${timeLeft}` },
      } as IChatNotification;

      delete this.membersBalanceHash[username];
    }
    this.log("getMemberTimeLeftPrivateChat finished");
  };

  handleMemberDisconnected = data => {
    this.log("handleMemberDisconnected started");
    if (data.user?.id) {
      const hasTokens = this.publicMemberHash[data?.user?.id]?.hasTokens;
      delete this.publicMemberHash[data.user?.id];
      this.notifyWhisperForMember(data.user?.id, {
        isPrivate: false,
        online: false,
      });
      broadcastStore.handleMemberDestroy(data.user);
      if (
        pricingStore.modelProducts?.chat_settings?.system_settings
          ?.sound_enter_leave &&
        broadcastStore.streamState === BroadcastStreamState.started &&
        hasTokens
      ) {
        camsAudio.playMemberLeave();
      }
    }
    this.handleUserCountChange(data);
    this.log("handleMemberDisconnected finished");
  };

  handleMemberLeft = data => {
    this.log("handleMemberLeft started");
    if (data.user?.id) {
      delete this.publicMemberHash[data.user?.id];
      this.notifyWhisperForMember(data.user?.id);
      broadcastStore.handleMemberDestroy(data.user);
    }
    this.handleUserCountChange(data);
    this.log("handleMemberLeft finished");
  };

  handleModelPrivateJoined = data => {
    this.log("handleModelJoinedPrivate started", data);
    const show = broadcastStore.currentShowType;
    if (show === IShowType.PRIVATE || show === IShowType.GROUP)
      camsAudio.playStartPrivateChat();
    this.log("handleModelJoinedPrivate finished");
  };

  handleMemberPrivateJoined = data => {
    this.log("handleMemberJoinedPrivate started");
    this.addMemberNotification(data.user, MemberActionEnum.JOIN);
    if (data.user?.id) {
      const memberInfoFromPublic = this.publicMemberHash[data.user?.id] || {};
      this.privateMemberHash = {
        ...this.privateMemberHash,
        [data.user?.id]: {
          ...memberInfoFromPublic,
          ...data.user,
          isInvisible: !!data.memberIsInvisible,
          hasTokens: data.memberHasTokens,
          isBounty: data.memberIsBounty,
          isPremiere: data.memberIsPremiere,
          isFanClub: data.memberIsFanClub,
          spendingTier: data.memberSpendingTier || 0,
        },
      };
    }
    this.notifyWhisperForMember(data.user?.id, {
      isPrivate: true,
      online: true,
    });
    this.handlePrivateUserCountChange(data);
    this.log("handleMemberJoinedPrivate finished");
  };

  handleMemberBalanceStatusReceived = data => {
    this.log("handleMemberBalanceStatus started");
    if (broadcastStore.currentShowType === IShowType.PRIVATE) {
      if (data.pay_per_minute_price_type === "private_chat") {
        const memberHasCam2Cam = !!broadcastStore.c2cHash[data.member];
        const cam2CamCost = memberHasCam2Cam
          ? pricingStore.modelProducts.cam2cam_chat
          : 0;
        this.getMemberTimeLeftPrivateChat(
          data.member_username,
          data.member_balance,
          data.pay_per_minute_price + cam2CamCost
        );
      }
    } else if (!SHOW_TYPES_WITHOUT_MEMBER_TIME_LEFT_NOTIFICATION.includes(broadcastStore.currentShowType)) {
      this.getMemberTimeLeftPrivateChat(
        data.member_username,
        data.member_balance,
        data.pay_per_minute_price
      );
    }
    this.log("handleMemberBalanceStatus finished");
  };

  checkVoyeurMembersWhenPrivateMemberLeft = () => {
    if (this.voyeurMembers.length > 0) {
      this.setShouldShowPrivatememberLeftConfirmation(true);
    }
  };

  handleMemberPrivateDisconnected = data => {
    this.log("handleMemberDisconnectedPrivate started");

    this.addMemberNotification(data.user, MemberActionEnum.LEFT);

    if (broadcastStore.currentShowType === IShowType.PRIVATE) {
      this.checkVoyeurMembersWhenPrivateMemberLeft();
    }

    if (data.user?.id) {
      this.notifyWhisperForMember(data.user?.id, {
        isPrivate: true,
      });
      delete this.privateMemberHash[data.user?.id];
      delete this.membersBalanceHash[data.user?.username];
    }
    this.handlePrivateUserCountChange(data);
    this.log("handleMemberDisconnectedPrivate finished");
  };

  handleMemberPrivateLeft = data => {
    this.log("handleMemberLeftPrivate started");

    if (
      // Handle pay per minute disconnect in viewStop in broadcastStore
      ![IShowType.GROUP, IShowType.NUDE, IShowType.PRIVATE].includes(
        broadcastStore.currentShowType
      )
    ) {
      this.addMemberNotification(data.user, MemberActionEnum.LEFT);
    }

    if (broadcastStore.currentShowType === IShowType.PRIVATE) {
      this.checkVoyeurMembersWhenPrivateMemberLeft();
    }

    if (data.user?.id) {
      this.notifyWhisperForMember(data.user?.id, {
        isPrivate: true,
      });
      delete this.privateMemberHash[data.user?.id];
      delete this.membersBalanceHash[data.user?.username];
    }
    this.handlePrivateUserCountChange(data);
    this.log("handleMemberLeftPrivate finished");
  };

  addMemberNotification = (
    member,
    action: MemberActionEnum,
    reason: string = ""
  ) => {
    this.log("addMemberNotification started");
    let chatType = "Private";
    switch (broadcastStore.currentShowType) {
      case IShowType.GROUP:
        chatType = "Party";
        break;
      case IShowType.NUDE:
        chatType = "Nude";
        break;
      case IShowType.CURTAIN_DROPPED:
      case IShowType.TIPPING:
        chatType = "Tipping";
        break;
      default:
        chatType = "Private";
    }
    const event = {
      id: uuid.getNew(),
      created: new Date(),
      fixedContentKey: `notificationMessage.member${action}${chatType}`,
      fixedContentParams: {
        member: member?.username,
        reason,
      },
    } as IChatNotification;
    this.addNotification(event);
    this.log("addMemberNotification finished");
  };

  handlePublicRoomSubscribedSuccess = async data => {
    this.log("handlePublicRoomSubscribedSuccess started");
    this.handleUserCountChange(data.data);
    this.setChatState(ChatState.started);
    this.setRoomConnectionStatus("public", true);
    this.log("handlePublicRoomSubscribedSuccess finished");
  };

  handlePublicRoomSubscribeFailed = async error => {
    this.log("handlePublicRoomSubscribeFailed started");
    // when model is already subscribed to the public, show error message
    // as they may be broadcasting from another tab/browser
    if (error.message === "Model is already subscribed to the room") {
      this.setRoomConnectionStatus("public", true);
    } else {
      this.setRoomConnectionStatus("public", false);
      this.setChatState(ChatState.errored);
    }
    this.log("handlePublicRoomSubscribeFailed finished");
  };

  handleUserCountChange = data => {
    this.log("handleUserCountChange started");

    this.numberOfOnlineGuests = parseInt(data.numberOfOnlineGuests) || 0;
    this.numberOfOnlinePublicMembers = parseInt(
      data.numberOfOnlineMembers || 0
    );
    this.publicMemberCount =
      this.numberOfOnlineGuests + this.numberOfOnlinePublicMembers;
    this.log("handleUserCountChange finished");
  };

  handlePrivateUserCountChange = data => {
    this.log("handlePrivateUserCountChange started");
    this.numberOfOnlinePrivateMembers =
      parseInt(data.numberOfOnlineMembers) || 0;
    this.log("handlePrivateUserCountChange finished");
  };

  addSupportChatMessage = (message: Omit<IChatMessage, "created">) => {
    this.log("addSupportChatMessage started");
    this.supportChatMessages = this.getMessagesWithNewMessageAdded(
      message,
      this.supportChatMessages
    );
    if (!message.isModel) {
      this.supportChatMessagesUnread += 1;
    }
    this.log("addSupportChatMessage finished");
  };

  get isChatTabWhisper() {
    return !Object.keys(IChatTab).includes(this.activeChatTab);
  }

  resetActiveWhisperChatMessageUnread = () => {
    this.log("resetActiveWhisperChatMessageUnread started");
    if (this.isChatTabWhisper && this.whisperHash[this.activeChatTab]) {
      this.whisperHash[this.activeChatTab] = {
        ...this.whisperHash[this.activeChatTab],
        unread: 0,
      };
    }
    this.log("resetActiveWhisperChatMessageUnread finished");
  };

  resetSupportChatMessageUnread = () => {
    this.log("resetSupportChatMessageUnread started");
    this.supportChatMessagesUnread = 0;
    this.log("resetSupportChatMessageUnread finished");
  };

  addBufferedChatMessage = (message: IBufferedChatMessage) => {
    this.log("addBufferedChatMessage started");
    this.bufferedMessages.push(message);
    this.log("addBufferedChatMessage finished");
  };

  addPublicChatMessage = (message: Omit<IChatMessage, "created">) => {
    this.log("addPublicChatMessage started");
    this.publicChatMessages = this.getMessagesWithNewMessageAdded(
      message,
      this.publicChatMessages
    );
    this.log("addPublicChatMessage finished");
  };

  addPrivateChatMessage = (message: Omit<IChatMessage, "created">) => {
    this.log("addPrivateChatMessage started");
    this.privateChatMessages = this.getMessagesWithNewMessageAdded(
      message,
      this.privateChatMessages
    );
    this.log("addPrivateChatMessage finished");
  };

  getMessagesWithNewMessageAdded = (
    message: Omit<IChatMessage, "created">,
    messages: IChatMessage[]
  ) => {
    const _message = {
      ...message,
      created: new Date(),
    };
    return [...messages, _message];
  };

  addModelChatMessageToWhisper = (
    message: IChatMessage,
    data,
    isPrivate?: boolean
  ) => {
    this.log("addModelChatMessageToWhisper started");
    const whisperHashkey = !!isPrivate
      ? "privateWhisperHash"
      : "publicWhisperHash";
    if (this[whisperHashkey][data.whisperToMember]) {
      this[whisperHashkey] = {
        ...this[whisperHashkey],
        [data.whisperToMember]: {
          ...this[whisperHashkey][data.whisperToMember],
          messages: [
            ...this[whisperHashkey][data.whisperToMember].messages,
            message,
          ],
        },
      };
    }
    this.log("addModelChatMessageToWhisper finished");
  };

  addMemberChatMessageToWhisper = (
    message: IChatMessage,
    data,
    isPrivate?: boolean
  ) => {
    this.log("addMemberChatMessageToWhisper started");
    const member: IMember = data.user;
    const whisperHash = !!isPrivate
      ? this.privateWhisperHash
      : this.publicWhisperHash;
    const originalWhisperObject = whisperHash[member.id];
    const whisperObject = originalWhisperObject
      ? { ...originalWhisperObject }
      : {
          messages: [],
          member,
          unread: 0,
          online: true,
        };

    if (whisperObject) {
      whisperObject.messages = [...whisperObject.messages, message];
      if (this.activeChatTab !== member.id) {
        whisperObject.unread += 1;
      }
      whisperHash[member.id] = whisperObject;
    }
    this.log("addMemberChatMessageToWhisper finished");
  };

  notifyWhisperForMember = (
    uid: string,
    params: {
      isPrivate?: boolean;
      online?: boolean;
    } = {}
  ) => {
    const { isPrivate = false, online = false } = params;
    this.log("notifyWhisperForMember started", uid, params);
    const whisperHashkey = !!isPrivate
      ? "privateWhisperHash"
      : "publicWhisperHash";
    if (this[whisperHashkey][uid]) {
      this[whisperHashkey] = {
        ...this[whisperHashkey],
        [uid]: {
          ...this[whisperHashkey][uid],
          online,
        },
      };
    }
    this.log("notifyWhisperForMember finished");
  };

  resetWhisperUnreadForMember = (uid: string, isPrivate?: boolean) => {
    this.log("resetWhisperUnreadForMember started");
    const whisperHash = !!isPrivate
      ? this.privateWhisperHash
      : this.publicWhisperHash;
    if (whisperHash[uid]) {
      whisperHash[uid].unread = 0;
    }
    this.log("resetWhisperUnreadForMember finished");
  };

  get members(): IMember[] {
    const _members = Object.keys(this.memberHash)
      .map(memberKey => ({
        ...this.memberHash[memberKey],
        rank: this.topAdmirersHash[memberKey]?.rank || 100,
      }))
      .filter(member => !this.voyeurMemberHash[member.id])
      .sort((a, b) => {
        if (a.rank === 100 && b.rank === 100) {
          if (a.isFanClub && !b.isFanClub) {
            return -1;
          } else if (a.isBounty && !b.isBounty) {
            return -1;
          } else if (a.isPremiere && !b.isPremiere) {
            return -1;
          } else {
            return 0;
          }
        } else {
          return a.rank - b.rank;
        }
      });
    this.log("members returning", _members);
    return _members;
  }

  get whisperUnread(): number {
    const count = Object.keys(this.whisperHash).reduce(
      (sum, whisper) => sum + this.whisperHash[whisper].unread,
      0
    );
    this.log("whisperUnread returning", count);
    return count;
  }

  startWhisperTo = (member: IMember) => {
    this.log("startWhisperTo started");
    const whisperHash = broadcastStore.isInPaidShow
      ? this.privateWhisperHash
      : this.publicWhisperHash;
    if (!whisperHash[member.id]) {
      whisperHash[member.id] = {
        messages: [],
        member,
        unread: 0,
        online: true,
      };
    }
    this.setActiveChatTab(member.id);
    this.log("startWhisperTo finished");
  };

  setLanguage = async (language: IChatLanguageTranslation) => {
    this.log("setLanguage started");
    try {
      await this.chatSocket?.emit("setLanguage", {
        language,
        ...this.chatSocketTrackingParams,
      });
      this.currentChatLanguage = language;
    } catch (error) {
      this.log("setLanguage failed", error);
    }
    this.log("setLanguage finished");
  };

  setActiveChatTab = (tab: IChatTab | string) => {
    this.log("setActiveChatTab started");
    this.activeChatTab = tab;
    this.resetActiveWhisperChatMessageUnread();
    this.log("setActiveChatTab finished");
  };

  removeWhisper = (whisperId: string) => {
    this.log("removeWhisper started");
    const newPrivateWhisperHash = { ...this.privateWhisperHash };
    delete newPrivateWhisperHash[whisperId];
    this.privateWhisperHash = newPrivateWhisperHash;

    const newPublicWhisperHash = { ...this.publicWhisperHash };
    delete newPublicWhisperHash[whisperId];
    this.publicWhisperHash = newPublicWhisperHash;

    this.setActiveChatTab(IChatTab.PUBLIC_OR_PRIVATE);
    this.log("removeWhisper finished");
  };

  handleGiftSent = (data: any): void => {
    this.log("handleGiftSent started", data);
    let broadcastType = BroadcastType.Tipped;
    let content = data.message;
    let image = "";
    let fixedContentKey = "";
    let fixedContentParams = {};

    this.getTopAdmirers();

    if (data.type === "tip") {
      broadcastStore.isShowStarted && camsAudio.playGreatIdea();
    } else if (data.type === "virtual_gift") {
      broadcastType = BroadcastType.Gift;
      image = data.product.image;
      broadcastStore.isShowStarted && camsAudio.playGiftReceived();
    } else if (data.type === "wheel_of_fun") {
      broadcastType = BroadcastType.WheelOfFun;
      fixedContentKey = "broadcast.chatItem.wheelOfFun";
      fixedContentParams = {
        chatfrom: data.user.username,
        wheelReward: data.product.choosen_reward,
        silence: data.invisible ? MEMBER_SILENCE_STRING : "",
      };
      content = `Yay! ${data.user.username.split('@')[0]} has won '${data.product.choosen_reward}'`;
      this.setWheelOfFunSpinner(
        data.user.username,
        data.product.choosen_reward
      );
    } else if (data.type === "buzz") {
      broadcastType = BroadcastType.Buzz;
      fixedContentKey = intl.formatMessage(
        {
          id: `broadcast.chatItem.buzz`,
        },
        {
          member: data.user.username,
          buzzPrice: data.product.price,
          silence: data.invisible ? MEMBER_SILENCE_STRING : "",
        }
      );
      fixedContentParams = {
        member: data.user.username,
        buzzPrice: data.product.price,
        silence: data.invisible ? MEMBER_SILENCE_STRING : "",
      };
      camsAudio.playBuzz();
    } else if (data.type === "super_buzz") {
      broadcastType = BroadcastType.SuperBuzz;
      fixedContentKey = intl.formatMessage(
        {
          id: `broadcast.chatItem.superBuzz`,
        },
        {
          member: data.user.username,
          superBuzzPrice: data.product.price,
          silence: data.invisible ? MEMBER_SILENCE_STRING : "",
        }
      );
      fixedContentParams = {
        member: data.user.username,
        superBuzzPrice: data.product.price,
        silence: data.invisible ? MEMBER_SILENCE_STRING : "",
      };
      camsAudio.playSuperBuzz();
    }

    this.gift = {
      id: uuid.getNew(),
      content,
      fixedContentKey,
      fixedContentParams,
      broadcastType,
      created: new Date(),
      image,
    } as IChatNotification;

    this.log("handleGiftSent finished");
  };

  addChatReactions = () => {
    this.log("addChatReactions started");
    this.disposeChatNotifications();
    this.memberSentGiftReactionDisposer = reaction(
      () => this.gift,
      () => {
        this.log("addChatReactions reacting to gift change");
        if (this.gift) {
          this.addNotification(this.gift as IChatNotification);
        }
      }
    );
    this.memberJoinedShowReactionDisposer = reaction(
      () => this.memberJoinedShowInfo,
      () => {
        this.log("addChatReactions reacting member joining show");
        if (this.memberJoinedShowInfo) {
          this.addNotification(this.memberJoinedShowInfo as IChatNotification);
        }
      }
    );
    this.addModelRoomDisconnectedReaction();
  };

  disposeChatNotifications = () => {
    this.log("disposeChatNotifications started");
    if (this.memberJoinedShowReactionDisposer) {
      this.log(
        "disposeChatNotifications memberJoinedShowReactionDisposer started"
      );
      this.memberJoinedShowReactionDisposer();
      this.memberJoinedShowReactionDisposer = undefined;
    }
    if (this.memberSentGiftReactionDisposer) {
      this.log(
        "disposeChatNotifications memberSentGiftReactionDisposer started"
      );
      this.memberSentGiftReactionDisposer();
      this.memberSentGiftReactionDisposer = undefined;
      this.log(
        "disposeChatNotifications memberSentGiftReactionDisposer finished"
      );
    }
    this.log("disposeChatNotifications finished");
  };

  setIsPrivateChatReady = (isReady: boolean) => {
    this.log("setIsPrivateChatReady started", isReady);
    this.isPrivateChatReady = isReady;
    this.log("setIsPrivateChatReady finished");
  };

  handlePrivateRoomSubscribedSuccess = async () => {
    this.log("handleSubscribeToPrivateRoom started");
    this.setRoomConnectionStatus("private", true);
    this.setIsPrivateChatReady(true);
    this.privateChatMessages = [];
    this.numberOfOnlineGuests = 0;
    this.numberOfOnlinePrivateMembers = 0;
    if (Object.keys(this.privateMemberHash).length > 0) {
      this.privateMemberHashCache = this.privateMemberHash;
    }
    this.privateMemberHash = {};
    this.privateWhisperHash = {};
    this.membersBalanceHash = {};

    if (this.activeChatTab !== IChatTab.PUBLIC_OR_PRIVATE) {
      this.activeChatTab = IChatTab.PUBLIC_OR_PRIVATE;
    }
    await this.getPrivateMembers();
    this.log("handleSubscribeToPrivateRoom finished");
  };

  handlePrivateRoomSubscribedFailed = () => {
    this.log("handlePrivateRoomSubscribeFailed started");
    this.setRoomConnectionStatus("private", false);
    this.log("handlePrivateRoomSubscribeFailed finished");
  };

  handleModelPrivateRoomDestroyed = () => {
    this.log("handleModelPrivateRoomDestroyed started");
    this.setRoomConnectionStatus("private", false);
    const show = broadcastStore.currentShowType;
    if (show === IShowType.PRIVATE) {
      camsAudio.playEndPrivateChat();
    }
    this.publicMemberHash = {};
    this.subscribeToPublic();
    this.membersBalanceHash = {};
    this.log("handleModelPrivateRoomDestroyed finished");
  };

  handleModelPrivateRoomCreated = async () => {
    this.log("handleModelPrivateRoomCreated started");
    await this.subscribeToPrivate();
    this.log("handleModelPrivateRoomCreated finished");
  };

  get memberCount() {
    const membersCount = this.members.length;
    const voyeurCount = this.voyeurMembers?.length || 0;
    const count = voyeurCount + membersCount;
    this.log("memberCount returning", count);
    return count;
  }

  getPublicMembers = async () => {
    try {
      this.log("getPublicMembers started");
      const {
        data: {
          data: { members },
        },
      } = await api.chatPublicMembers.get();
      members.forEach(member => {
        this.publicMemberHash[member?.id] = {
          ...this.publicMemberHash[member?.id],
          ...member,
        };
      });
      this.log("getPublicMembers success");
    } catch (err) {
      this.log("getPublicMembers failed", err);
    } finally {
      this.log("getPublicMembers finished");
    }
  };

  getPrivateMembers = async () => {
    try {
      this.log("getPrivateMembers started");
      const {
        data: {
          data: { members },
        },
      } = await api.chatPrivateMembers.get();
      members.forEach(member => {
        this.privateMemberHash[member?.id] = {
          ...(this.privateMemberHash[member?.id] ||
            this.privateMemberHashCache[member?.id] ||
            {}),
          ...member,
        };
      });
      this.log("getPrivateMembers success");
    } catch (err) {
      this.log("getPrivateMembers failed", err);
    } finally {
      this.log("getPrivateMembers finished");
    }
  };

  reloadChat = () => {
    this.log("reloadChat started");
    this.chatSocket?.restartSocket();
    this.log("reloadChat finished");
  };

  handleVoyeurMembersJoined = (member: IMember) => {
    this.log("handleVoyeurMembersJoined started", member);
    const handle = member.username?.toLowerCase();
    this.voyeurMemberHash[handle] = {
      ...(this.publicMemberHash[handle] || {}),
      ...member,
    };
    this.log("handleVoyeurMembersJoined finished");
  };

  handleVoyeurMembersLeft = (member: IMember) => {
    this.log("handleVoyeurMembersLeft started");
    const newVoyeurMemberHash = { ...this.voyeurMemberHash };
    delete newVoyeurMemberHash[member.id];
    delete this.membersBalanceHash[member.username];
    this.voyeurMemberHash = newVoyeurMemberHash;
    this.log("handleVoyeurMembersLeft finished");
  };

  get voyeurMembers(): IMember[] {
    const members = Object.keys(this.voyeurMemberHash)
      .map(memberKey => ({
        ...this.voyeurMemberHash[memberKey],
        rank: this.topAdmirersHash[memberKey]?.rank || 100,
      }))
      .sort((a, b) => a.rank - b.rank);
    this.log("voyeurMembers returning", members);
    return members;
  }

  getTopAdmirers = async () => {
    try {
      this.log("getTopAdmirers started");
      const { data } = await api.chatTopAdmirers.get("?top=5");
      this.topAdmirers = data.data;
      data.data.forEach(item => {
        this.topAdmirersHash[item.member] = item;
      });
      this.log("getTopAdmirers success");
    } catch (err) {
      this.log("getTopAdmirers failed", err);
      this.topAdmirers = [];
      this.topAdmirersHash = {};
    } finally {
      this.log("getTopAdmirers finished");
    }
  };

  handleTopAdmirers = async items => {
    this.log("handleTopAdmirers started");
    items.forEach(item => {
      this.topAdmirersHash[item.member] = item;
    });
    this.log("handleTopAdmirers finished");
  };

  muteMember = async (member: IMember) => {
    try {
      this.log("muteMember started");
      await walletAxios.post(`/chat/model/${member.id}/invisible`);
      this.memberHash[member.id].isInvisible = true;
      this.log("muteMember success");
    } catch (error) {
      this.log("muteMember failed", error);
    } finally {
      this.log("muteMember finished");
      snackbarStore.enqueueSimpleSnackbar(
        "messages.success.muteMember",
        SnackbarVariants.SUCCESS
      );
    }
  };
  kickMember = async (member: IMember) => {
    try {
      this.log("kickMember started");
      await walletAxios.post(`/chat/model/${member.id}/kick`);
      this.log("kickMember success");
    } catch (error) {
      this.log("kickMember failed", error);
      snackbarStore.enqueueSnackbar({
        message: {
          id: "messages.error.kickMember",
          parameters: {
            member: member.username,
          },
          default: `Unable to kick ${member.username} out at this time, try again later.`,
        },
        variant: SnackbarVariants.ERROR,
      });
    } finally {
      this.log("kickMember finished");
      snackbarStore.enqueueSnackbar({
        message: {
          id: "messages.success.kickMember",
          parameters: {
            member: member.username,
          },
          default: `${member.username} has been kicked out.`,
        },
        variant: SnackbarVariants.SUCCESS,
      });
    }
  };

  unmuteMember = async (member: IMember) => {
    try {
      this.log("unmuteMember started");
      await walletAxios.post(`/chat/model/${member.id}/visible`);
      this.memberHash[member.id].isInvisible = false;
      this.log("unmuteMember success");
    } catch (error) {
      this.log("unmuteMember failed", error);
    } finally {
      this.log("unmuteMember finished");
      snackbarStore.enqueueSimpleSnackbar(
        "messages.success.unmuteMember",
        SnackbarVariants.SUCCESS
      );
    }
  };

  getMemberNameColor = (member?: IMember) => {
    this.log("getMemberNameColor started");
    let color;
    if (member) {
      if (member.spendingTier == 1) {
        return CHAT_MEMBER_COLOR_MAPPING.SPENT_TIER_ONE;
      } else if (member.spendingTier == 2) {
        return CHAT_MEMBER_COLOR_MAPPING.SPENT_TIER_TWO;
      } else if (member.spendingTier === 3) {
        return CHAT_MEMBER_COLOR_MAPPING.SPENT_TIER_THREE;
      } else if (member.isFanClub) {
        color = CHAT_MEMBER_COLOR_MAPPING.PAID_COLOR_DEFAULT;
      } else if (member.isPremiere) {
        color = CHAT_MEMBER_COLOR_MAPPING.BOUNTY_COLOR_DEFAULT;
      } else if (member.isBounty) {
        color = CHAT_MEMBER_COLOR_MAPPING.PREM_COLOR_DEFAULT;
      } else if (member.hasTokens) {
        color = CHAT_MEMBER_COLOR_MAPPING.FAN_COLOR_DEFAULT;
      }
    } else {
      color = CHAT_MEMBER_COLOR_MAPPING.DEFAULT_COLOR_DEFAULT;
    }
    this.log("getMemberNameColor finished");
    return color;
  };

  setChatFontSize = (fontSize: number) => {
    this.log("setChatFontSize started");
    this.chatFontSize = fontSize;
    this.log("setChatFontSize finished");
  };

  getChatLanguage = async () => {
    try {
      this.log("getChatLanguage started");
      const { data } = await api.chatLanguage.get();
      this.currentChatLanguage = data.data.language;
      this.log("getChatLanguage success");
    } catch (error) {
      this.log("getChatLanguage failed", error);
      this.currentChatLanguage = "en";
    } finally {
      this.log("getChatLanguage finished");
    }
  };

  setWheelOfFunSpinner = (wheelOfFunSpinnerUser, wheelOfFunSpinnerReward) => {
    this.log("setWheelOfFunSpinner started");
    this.wheelOfFunSpinnerTimestamp = new Date().getTime();
    this.wheelOfFunSpinnerUser = wheelOfFunSpinnerUser;
    this.wheelOfFunSpinnerReward = wheelOfFunSpinnerReward;
    this.log("setWheelOfFunSpinner finished");
  };

  get shouldShowWheelOfFunSpinner(): boolean {
    const shouldShow =
      !!this.wheelOfFunSpinnerReward && !!this.wheelOfFunSpinnerUser;
    this.log("shouldShowWheelOfFunSpinner returning", shouldShow);
    return shouldShow;
  }

  handleMemberPrivateBlocked = (memberId: string) => {
    const memberName = this.memberHash[memberId]?.username || "A member";
    this.addPublicChatMessage({
      from: "System",
      text: intl.formatMessage(
        {
          id: "messages.memberPrivateBlocked",
          defaultMessage: `{memberName} just tried to take you private but was declined because of your setting to not accept private shows.`,
        },
        {
          memberName,
        }
      ),
      isModel: false,
      type: "text",
      isMessageOrNotification: "message",
      isSentSuccessfully: true,
    });
  };

  handleMemberInfoUpdate = (memberId: string, memberInfo: Partial<IMember>) => {
    if (this.publicMemberHash[memberId]) {
      this.publicMemberHash[memberId] = {
        ...this.publicMemberHash[memberId],
        spendingTier: memberInfo.spendingTier || 0,
        hasTokens: !!memberInfo.hasTokens,
        isFanClub: !!memberInfo.isFanClub,
        isPremiere: !!memberInfo.isPremiere,
      };
    }

    if (this.privateMemberHash[memberId]) {
      this.privateMemberHash[memberId] = {
        ...this.privateMemberHash[memberId],
        spendingTier: memberInfo.spendingTier || 0,
        hasTokens: !!memberInfo.hasTokens,
        isFanClub: !!memberInfo.isFanClub,
        isPremiere: !!memberInfo.isPremiere,
      };
    }
  };

  handleFavouredModel = data => {
    this.log("handleFavouredModel started");
    const event = {
      id: uuid.getNew(),
      created: new Date(),
      fixedContentKey: "notificationMessage.memberFavoredModel",
      fixedContentParams: {
        memberName: data.username,
      },
      content: "Member {memberName} favorited you.",
    } as IChatNotification;
    this.addNotification(event);
    this.log("handleFavouredModel finished");
  };

  handleMemberInfo = data => {
    this.log("handleMemberInfo started");
    this.handleMemberInfoUpdate(data.user?.id, {
      spendingTier: data.spendingTier,
      isPremiere: data.isPremiere,
      isFanClub: data.isFanClub,
      hasTokens: data.hasTokens,
      isBounty: data.isBounty,
    });
    this.log("handleMemberInfo finished");
  };

  setShouldShowPrivatememberLeftConfirmation = (bool: boolean) => {
    this.shouldShowPrivatememberLeftConfirmation = bool;
  };

  @computed get trackingLocation() {
    let location = "other";
    const showType = broadcastStore.currentShowType;
    if (showType === IShowType.NONE) {
      location = "offline";
    } else if (showType === IShowType.FREE) {
      location = "online_public";
    } else if (SHOW_TYPE_CHAT_TYPE_MAP[showType]) {
      location = SHOW_TYPE_CHAT_TYPE_MAP[showType];
    }
    return location;
  }

  @computed get chatSocketTrackingParams() {
    return {
      location: this.trackingLocation,
      broadcast_session_id: broadcastStore.currentShowSessionId ? broadcastStore.currentShowSessionId : null,
      broadcast_id: broadcastStore.broadcastSessionId
        ? broadcastStore.broadcastSessionId
        : null,
    };
  }
}
