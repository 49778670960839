import React, { useCallback, useEffect, useMemo, useState } from "react";
import PricingStore from "../_stores/pricing/PricingStore";
import { inject, observer } from "mobx-react";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import TailwindInput from "library/components/_tailwind/input";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindIcon from "library/components/_tailwind/icon";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import {
  BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES,
  BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE,
  BROADCASTER_DEFAULT_MIN_TIPPING_SHOW_GOAL_PRICE,
} from "../_stores/pricing/consts";
import TailwindTooltip from "library/components/_tailwind/tooltip";
import TailwindText from "library/components/_tailwind/text";
import TailwindBox from "library/components/_tailwind/box";
import { IShowType, ShowTab } from "common/broadcast/_stores/broadcast/enums";
import BroadcastTippingControlActionButton from "common/broadcast/broadcast-control/action-button";
import { ModelPricingResponseChatSettingsTipping } from "common/broadcast/_stores/pricing/types";
import LanguageStore from "library/core/stores/language/LanguageStore";

interface Props {
  pricingStore?: PricingStore;
  broadcastStore?: BroadcastStore;
  validationStore?: ValidationStore;
  languageStore?: LanguageStore;
}

const BroadcastTippingSettings: React.FunctionComponent<Props> = ({
  pricingStore,
  broadcastStore,
  languageStore,
}) => {
  const {
    showTippingSum,
    isCurtainDropped,
    isStartingOrStoppingStream,
    isTippingShowStarted,
    tryStartGoalDown,
    currentShowType,
    previousShowType,
    switchToShow,
  } = broadcastStore!;

  const {
    modelProducts,
    submitTippingPrice,
    setTippingPricingFieldError,
    areTippingFieldsValid,
    tippingLateEntryError,
    tippingGoalError,
    tippingEarlyEntryError,
  } = pricingStore!;
  const { intl } = languageStore!;

  const [hasSetPrices, setHasSetPrices] = useState<boolean>(true);

  const [goal, setGoal] = useState<number | undefined>(
    modelProducts?.chat_settings?.tipping?.goal ||
      BROADCASTER_DEFAULT_MIN_TIPPING_SHOW_GOAL_PRICE
  );
  const [earlyEntry, setEarlyEntry] = useState<number | undefined>(
    modelProducts?.chat_settings?.tipping?.earlyEntry ||
      BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE
  );
  const [lateEntry, setLateEntry] = useState<number | undefined>(
    modelProducts?.chat_settings?.tipping?.lateEntry ||
      BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE
  );

  useEffect(() => {
    validatePrice("earlyEntry", earlyEntry!);
    validatePrice("lateEntry", lateEntry!);
    validatePrice("goal", goal!);

    return () => {
      setTippingPricingFieldError("early", undefined);
      setTippingPricingFieldError("late", undefined);
      setTippingPricingFieldError("goal", undefined);
    };
  }, []);

  const validatePrice = (
    priceType: keyof ModelPricingResponseChatSettingsTipping,
    value: number
  ) => {
    let error;
    switch (priceType) {
      case "goal": {
        if (value < BROADCASTER_DEFAULT_MIN_TIPPING_SHOW_GOAL_PRICE) {
          error = intl.formatMessage(
            {
              id: "broadcast-show-type-control.tipping.has-to-be-over",
              defaultMessage: "{key} has to be over {value}",
            },
            {
              value: BROADCASTER_DEFAULT_MIN_TIPPING_SHOW_GOAL_PRICE,
              key: "Goal",
            }
          );
        } else if (value > BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES) {
          error = intl.formatMessage(
            {
              id: "broadcast-show-type-control.tipping.has-to-be-less-than",
              defaultMessage: "{key} has to be less than {value}",
            },
            {
              value: BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES,
              key: "Goal",
            }
          );
        } else if (earlyEntry && value < +earlyEntry) {
          error = intl.formatMessage(
            {
              id: "broadcast-show-type-control.tipping.has-to-be-over",
              defaultMessage: "{key} has to be over {value}",
            },
            {
              value: `Early Entry ${earlyEntry}`,
              key: "Goal",
            }
          );
        }
        setTippingPricingFieldError("goal", error);
        break;
      }
      case "earlyEntry": {
        if (value < BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE) {
          error = intl.formatMessage(
            {
              id: "broadcast-show-type-control.tipping.has-to-be-over",
              defaultMessage: "{key} has to be over {value}",
            },
            {
              value: BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE,
              key: "Early Entry",
            }
          );
        } else if (value > BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES) {
          error = intl.formatMessage(
            {
              id: "broadcast-show-type-control.tipping.has-to-be-less-than",
              defaultMessage: "{key} has to be less than {value}",
            },
            {
              value: BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES,
              key: "Early Entry",
            }
          );
        } else if (goal && value > +goal) {
          error = intl.formatMessage(
            {
              id: "broadcast-show-type-control.tipping.has-to-be-less-than",
              defaultMessage: "{key} has to be less than {value}",
            },
            {
              value: `Goal ${goal}`,
              key: "Early Entry",
            }
          );
        }
        setTippingPricingFieldError("early", error);
        break;
      }
      case "lateEntry": {
        if (value < BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE) {
          error = intl.formatMessage(
            {
              id: "broadcast-show-type-control.tipping.has-to-be-over",
              defaultMessage: "{key} has to be over {value}",
            },
            {
              value: BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE,
              key: "Late Entry",
            }
          );
        } else if (value > BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES) {
          error = intl.formatMessage(
            {
              id: "broadcast-show-type-control.tipping.has-to-be-less-than",
              defaultMessage: "{key} has to be less than {value}",
            },
            {
              value: BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES,
              key: "Late Entry",
            }
          );
        }

        setTippingPricingFieldError("late", error);
        break;
      }
    }
  };

  const formatEntryPrice = (
    currentGoal: number | undefined,
    savedGoal: number | undefined
  ) => {
    let val: number | string | undefined =
      goal !== undefined ? currentGoal : savedGoal;
    val = val?.toString();
    return val && val.length > 0 && val[0] === "0" ? val.substring(1) : val;
  };

  const onChangeGoal = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const _value = +event.target.value as unknown as number;
      if (_value <= BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES) {
        setGoal(_value);
        setHasSetPrices(false);
        validatePrice("goal", _value);
      }
    },
    [validatePrice]
  );

  const onChangeEarlyEntry = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const _value = +event.target.value as unknown as number;
      if (_value <= BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES) {
        setEarlyEntry(_value);
        setHasSetPrices(false);
        validatePrice("earlyEntry", _value);
      }
    },
    [validatePrice]
  );

  const onChangeLateEntry = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const _value = +event.target.value as unknown as number;
      if (_value <= BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES) {
        setLateEntry(_value);
        setHasSetPrices(false);
        validatePrice("lateEntry", _value);
      }
    },
    [validatePrice]
  );

  const goalValue = useMemo(
    () => formatEntryPrice(goal, modelProducts?.chat_settings?.tipping?.goal),
    [goal, modelProducts]
  );

  const earlyEntryValue = useMemo(
    () =>
      formatEntryPrice(
        earlyEntry,
        modelProducts?.chat_settings?.tipping?.earlyEntry
      ),
    [earlyEntry, modelProducts]
  );

  const lateEntryValue = useMemo(
    () =>
      formatEntryPrice(
        lateEntry,
        modelProducts?.chat_settings?.tipping?.lateEntry
      ),
    [lateEntry, modelProducts]
  );

  const submitTippingPrices = useCallback(() => {
    submitTippingPrice({
      goal: +goal!,
      earlyEntry: +earlyEntry!,
      lateEntry: +lateEntry!,
    });
  }, [goal, earlyEntry, lateEntry]);

  const onClickSubmitTippingPrices = useCallback(() => {
    if (areTippingFieldsValid) {
      submitTippingPrices();
      setHasSetPrices(true);
    }
  }, [areTippingFieldsValid, submitTippingPrices]);

  const onClickStartNow = useCallback(() => {
    tryStartGoalDown();
  }, []);

  const areInputsEnabled = useMemo(() => {
    return !isTippingShowStarted;
  }, [isTippingShowStarted]);

  useEffect(() => {
    if (
      (previousShowType === IShowType.TIPPING ||
        previousShowType === IShowType.CURTAIN_DROPPED) &&
      currentShowType !== IShowType.TIPPING &&
      currentShowType !== IShowType.CURTAIN_DROPPED
    ) {
      setHasSetPrices(false);
    } else if (
      previousShowType !== IShowType.TIPPING &&
      previousShowType !== IShowType.CURTAIN_DROPPED &&
      (currentShowType === IShowType.TIPPING ||
        currentShowType === IShowType.CURTAIN_DROPPED)
    ) {
      setHasSetPrices(true);
      setEarlyEntry(
        modelProducts?.chat_settings?.tipping?.earlyEntry ||
          BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE
      );
      setLateEntry(
        modelProducts?.chat_settings?.tipping?.lateEntry ||
          BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE
      );
      setGoal(
        modelProducts?.chat_settings?.tipping?.goal ||
          BROADCASTER_DEFAULT_MIN_TIPPING_SHOW_GOAL_PRICE
      );
    }
  }, [currentShowType, modelProducts]);

  return (
    <>
      <TailwindFlex width={"w-full"} flexDirection={"flex-col"}>
        <TailwindFlex width={"w-full"} flexDirection={"flex-col"}>
          <TailwindFlex
            alignItems={"items-center"}
            justifyContent={"justify-between"}
            margin={["mb-2"]}>
            <TailwindFlex alignItems={"items-center"}>
              <TailwindTranslatedText
                fontWeight={"font-bold"}
                textColor={"text-gray-600"}
                margin={["mr-1"]}
                descriptor={{
                  id: "broadcast-show-type-control.tipping.detail.tip-goal.title",
                  defaultMessage: "Goal",
                }}
              />
            </TailwindFlex>
            <TailwindInput
              data-testid={"tipping-goal-input"}
              required
              rounded={false}
              width={"w-full"}
              flexProps={{ width: "w-auto" }}
              boxProps={{ height: "h-10", width: "w-48" }}
              backgroundColor={"bg-transparent"}
              borderColor={"border-gray-300"}
              borderWidth={["border-2"]}
              onChange={onChangeGoal}
              disabled={!areInputsEnabled}
              type={TailwindInputType.text}
              value={(goalValue === undefined || goalValue === null
                ? BROADCASTER_DEFAULT_MIN_TIPPING_SHOW_GOAL_PRICE
                : goalValue
              ).toString()}
              error={tippingGoalError}
              rightIconProps={{
                name: CustomIconName.token,
                textColor: "text-yellow-500",
              }}
              max={BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES}
            />
          </TailwindFlex>
          <TailwindFlex
            alignItems={"items-center"}
            justifyContent={"justify-between"}
            margin={["mb-2"]}>
            <TailwindFlex alignItems={"items-center"}>
              <TailwindTranslatedText
                fontWeight={"font-bold"}
                textColor={"text-gray-600"}
                margin={["mr-1"]}
                descriptor={{
                  id: "broadcast-show-type-control.tipping.detail.early-entry",
                  defaultMessage: "Early Entry",
                }}
              />
              <TailwindTooltip
                content={intl.formatMessage({
                  id: "broadcast-show-type-control.tipping.detail.what-is-early-entry",
                  defaultMessage:
                    "Early Entry - this is the amount of tokens a single member pays to start a goal show.",
                })}>
                <TailwindFlex width={"w-auto"} alignItems={"items-center"}>
                  <TailwindIcon
                    textColor={"text-gray-400"}
                    name={CustomIconName["question-mark"]}
                  />
                </TailwindFlex>
              </TailwindTooltip>
            </TailwindFlex>
            <TailwindInput
              data-testid={"tipping-early-entry-input"}
              required
              rounded={false}
              width={"w-full"}
              flexProps={{ width: "w-auto" }}
              boxProps={{ height: "h-10", width: "w-48" }}
              backgroundColor={"bg-transparent"}
              borderColor={"border-gray-300"}
              borderWidth={["border-2"]}
              onChange={onChangeEarlyEntry}
              disabled={!areInputsEnabled}
              type={TailwindInputType.text}
              value={(earlyEntryValue === undefined || earlyEntryValue === null
                ? BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE
                : earlyEntryValue
              ).toString()}
              error={tippingEarlyEntryError}
              rightIconProps={{
                name: CustomIconName.token,
                textColor: "text-yellow-500",
              }}
              max={BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES}
            />
          </TailwindFlex>
          <TailwindFlex
            alignItems={"items-center"}
            justifyContent={"justify-between"}>
            <TailwindFlex alignItems={"items-center"}>
              <TailwindTranslatedText
                fontWeight={"font-bold"}
                textColor={"text-gray-600"}
                margin={["mr-1"]}
                descriptor={{
                  id: "broadcast-show-type-control.tipping.detail.late-entry",
                  defaultMessage: "Late Entry",
                }}
              />
              <TailwindTooltip
                content={intl.formatMessage({
                  id: "broadcast-show-type-control.tipping.detail.what-is-late-entry",
                  defaultMessage:
                    "Late Entry - when your show is in progress, this is the number of tokens a single member pays to join.",
                })}>
                <TailwindFlex width={"w-auto"} alignItems={"items-center"}>
                  <TailwindIcon
                    textColor={"text-gray-400"}
                    name={CustomIconName["question-mark"]}
                  />
                </TailwindFlex>
              </TailwindTooltip>
            </TailwindFlex>
            <TailwindInput
              data-testid={"tipping-late-entry-input"}
              required
              rounded={false}
              width={"w-full"}
              flexProps={{ width: "w-auto" }}
              boxProps={{ height: "h-10", width: "w-48" }}
              backgroundColor={"bg-transparent"}
              borderColor={"border-gray-300"}
              borderWidth={["border-2"]}
              value={(lateEntryValue === undefined || lateEntryValue === null
                ? BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE
                : lateEntryValue
              ).toString()}
              onChange={onChangeLateEntry}
              disabled={!areInputsEnabled}
              type={TailwindInputType.text}
              error={tippingLateEntryError}
              rightIconProps={{
                name: CustomIconName.token,
                textColor: "text-yellow-500",
              }}
              min={BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE}
              max={BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES}
            />
          </TailwindFlex>
        </TailwindFlex>
        <TailwindFlex
          width={"w-full"}
          margin={["mt-4"]}
          flexDirection={"flex-col"}>
          {isTippingShowStarted && (
            <TailwindFlex
              flexDirection={"flex-row"}
              width={"w-auto"}
              alignItems={"items-center"}
              justifyContent={"justify-end"}>
              <TailwindBox margin={["mr-4"]} fontWeight={"font-bold"}>
                <TailwindTranslatedText
                  textColor='text-main-color'
                  fontWeight={"font-bold"}
                  descriptor={{
                    id: "broadcast-show-type-control.tipping.current-tips",
                    defaultMessage: "Current Tips",
                  }}
                />
                :
              </TailwindBox>
              <TailwindText
                fontSize={"text-lg"}
                fontWeight={"font-bold"}
                textColor={"text-yellow-500"}>
                {showTippingSum}
              </TailwindText>
              <TailwindIcon
                className={"ml-1"}
                fontSize={"text-sm"}
                textColor={"text-yellow-500"}
                name={CustomIconName.token}
              />
            </TailwindFlex>
          )}

          <TailwindFlex gap={"gap-2"}>
            <BroadcastTippingControlActionButton
              dataTestId={"tipping-update-prices-button"}
              onClick={onClickSubmitTippingPrices}
              label={intl.formatMessage({
                id: "broadcast-show-type-control.tipping.set-goal",
                defaultMessage: "Update Prices",
              })}
              disabled={isStartingOrStoppingStream || hasSetPrices}
            />
            {isCurtainDropped ? (
              <BroadcastTippingControlActionButton
                dataTestId={"tipping-end-goal-show-button"}
                disabled={isStartingOrStoppingStream || !isTippingShowStarted}
                onClick={() => switchToShow(ShowTab.FREE)}
                label={intl.formatMessage({
                  id: "broadcast-show-type-control.tipping.curtain-end",
                  defaultMessage: "End Goal Show",
                })}
              />
            ) : (
              <TailwindFlex width={"w-full"} justifyContent={"justify-between"}>
                <BroadcastTippingControlActionButton
                  dataTestId={"tipping-start-goal-show-button"}
                  disabled={isStartingOrStoppingStream || !isTippingShowStarted}
                  onClick={onClickStartNow}
                  label={intl.formatMessage({
                    id: "broadcast-show-type-control.tipping.curtain",
                    defaultMessage: "Start Goal Show",
                  })}
                />
                <TailwindTooltip
                  content={intl.formatMessage({
                    id: "broadcast-show-type-control.tipping.tool-tip",
                    defaultMessage:
                      "Start your special show once the goal has been reached.",
                  })}>
                  <TailwindFlex
                    width={"w-auto"}
                    alignItems={"items-center"}
                    margin={["ml-2"]}>
                    <TailwindIcon
                      textColor={"text-gray-400"}
                      name={CustomIconName["question-mark"]}
                    />
                  </TailwindFlex>
                </TailwindTooltip>
              </TailwindFlex>
            )}
          </TailwindFlex>
        </TailwindFlex>
      </TailwindFlex>
    </>
  );
};

export default inject(
  "pricingStore",
  "broadcastStore",
  "chatStore",
  "validationStore",
  "languageStore"
)(observer(BroadcastTippingSettings));
