import React, { FunctionComponent, useCallback, useState } from "react";
import { inject, observer } from "mobx-react";
import "./styles.scss";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import TailwindInput from "library/components/_tailwind/input";
import TailwindParagraph from "library/components/_tailwind/paragraph";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import TailwindIcon from "library/components/_tailwind/icon";
import LanguageStore from "library/core/stores/language/LanguageStore";

type Props = {
  onConfirm: (username: string, email: string, message: string) => void;
  profileStore?: ProfileStore;
  validationStore?: ValidationStore;
  display?: boolean;
  hidePreLiveChatForm?: any;
  languageStore?: LanguageStore;
};

const PreLiveChatForm: FunctionComponent<Props> = ({
  onConfirm,
  profileStore,
  validationStore,
  hidePreLiveChatForm,
  display,
  languageStore
}) => {
  const { intl } = languageStore!;
  const { profile } = profileStore!;
  const { clearError, validate, errors, validateMultipleObj } =
    validationStore!;
  const [username, setUsername] = useState<string>(profile?.username || "");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const submitForm = useCallback(
    event => {
      event.preventDefault();
      const isValid = validateMultipleObj({
        username,
        email,
        message,
      });
      if (isValid) {
        onConfirm(username, email, message);
        hidePreLiveChatForm();
      }
    },
    [username, email, message]
  );

  return (
    <TailwindFlex
      width={"w-96"}
      sm={"w-full"}
      display={display ? "block" : "hidden"}
      className={["PreLiveChatFormModal"]}>
      <TailwindFlex
        className={["PreLiveChatFormModal__form"]}
        width={"w-full"}
        flexDirection={"flex-col"}>
        <TailwindFlex width='w-full' justifyContent='justify-end'>
          <TailwindIcon
            onClick={() => hidePreLiveChatForm()}
            name={"close-thicker"}
            inset={["right-2", "top-2"]}
            pseudoClasses={["sm:top-4", "sm:right-8"]}
            position={"relative"}
            cursor={"cursor-pointer"}
            textColor={"text-main-color"}
            fontSize={"text-lg"}
            zIndex={"z-900"}
          />
        </TailwindFlex>
        <TailwindFlex
          className={["PreLiveChatFormModal__heading"]}
          width={"w-full"}>
          <TailwindTranslatedText
            headingProps={{
              level: 5,
              textAlign: "text-center",
            }}
            descriptor={{
              id: "preLiveChatForm.heading",
              defaultMessage: "Live Support",
            }}
          />
        </TailwindFlex>
        <form onSubmit={submitForm}>
          <TailwindParagraph margin={["mb-4"]}>
            <TailwindTranslatedText
              descriptor={{
                id: "preLiveChatForm.contact-us-below",
                defaultMessage: "For live support, please contact us below:",
              }}
            />
          </TailwindParagraph>
          <TailwindFlex margin={["mb-3"]}>
            <TailwindInput
              required
              rounded={false}
              backgroundColor={"transparent"}
              borderColor={"border-black"}
              borderWidth={["border"]}
              label={intl.formatMessage({
                id: "common.username",
                defaultMessage: "Username",
              })}
              labelProps={{
                margin: [],
              }}
              errorProps={{
                textAlign: "text-right",
              }}
              value={username || ""}
              type={TailwindInputType.text}
              name={"username"}
              onChange={e => {
                clearError("username");
                setUsername(e.target.value);
              }}
              error={errors?.username || undefined}
              onBlur={_e => validate("username", username)}
            />
          </TailwindFlex>
          <TailwindFlex margin={["mb-3"]}>
            <TailwindInput
              required
              rounded={false}
              backgroundColor={"transparent"}
              borderColor={"border-black"}
              borderWidth={["border"]}
              label={intl.formatMessage({
                id: "common.email",
                defaultMessage: "Email",
              })}
              labelProps={{
                margin: [],
              }}
              errorProps={{
                textAlign: "text-right",
              }}
              value={email || ""}
              type={TailwindInputType.email}
              name={"email"}
              onChange={e => {
                clearError("email");
                setEmail(e.target.value);
              }}
              error={errors?.email || undefined}
              onBlur={_e => validate("email", email)}
            />
          </TailwindFlex>
          <TailwindFlex margin={["mb-3"]}>
            <TailwindInput
              required
              rounded={false}
              backgroundColor={"transparent"}
              borderColor={"border-black"}
              borderWidth={["border"]}
              label={intl.formatMessage({
                id: "preLiveChatForm.your-message",
                defaultMessage: "Your Message",
              })}
              labelProps={{
                margin: [],
              }}
              boxProps={{
                height: "h-auto",
              }}
              errorProps={{
                textAlign: "text-right",
              }}
              value={message || ""}
              type={TailwindInputType.textarea}
              rows={5}
              minRows={5}
              maxRows={5}
              maxLength={2000}
              name={"reason_for_contact"}
              onChange={e => {
                clearError("message");
                setMessage(e.target.value);
              }}
              error={errors?.message || undefined}
              onBlur={_e => validate("message", message)}
            />
          </TailwindFlex>
          <TailwindButton
            type={"submit"}
            margin={["mt-4", "mb-4"]}
            fullWidth
            size={"lg"}
            backgroundColor={"primary"}
            justifyContent={"justify-center"}
            textColor={"text-white"}
            rounded={false}>
            <TailwindTranslatedText
              descriptor={{
                id: "common.submit",
                defaultMessage: "Submit",
              }}
              textTransform={"uppercase"}
              fontWeight={"font-bold"}
            />
          </TailwindButton>
        </form>
      </TailwindFlex>
    </TailwindFlex>
  );
};
export default inject(
    "profileStore",
    "validationStore",
    "languageStore"
  )(observer(PreLiveChatForm));
