import { DEFAULT_NANO_POOL, NANO_CONFIG } from "./config";
import config from "../../config";
import { logger } from "library/core/utility";
import { NanoPlayerSettings } from "common/broadcast/_stores/broadcast-stream/types";
export const NANO_HOST_ID = "NANO_HOST";
export const NANO_ROOT = "NANO_ROOT";
const NanoPlayer = (window as any).NanoPlayer;
type Resolution = 720 | 540 | 360;

interface ServerConfig {
  originPath?: string;
  edgeLBPath?: string;
  edgeApp?: string;
  originAppName?: string;
  channelName?: string;
  rtmpPort?: string;
  nanoPool?: string;
}

const RESOLUTIONS = [
  {
    index: 2,
    label: "low360",
    tag: "this is a low quality stream",
    info: { bitrate: 700, width: 640, height: 360, framerate: 30 },
    resolution: 720,
  },
];

const RELOAD_TIMEOUT = 2000;
const MAX_RELOAD = 3;

const logPrefix = "[CamsNanoPlayer]:";

class CamsNanoPlayer {
  public player;
  private hostId: string = NANO_HOST_ID;
  public config: any;
  public isCam2Cam: boolean = false;
  private reloadTimeout: any;
  private reloadCount: number = 0;
  private retryInterval: any;

  constructor({ hostId }: { hostId?: string }) {
    if (hostId) {
      this.hostId = hostId;
    }
  }

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  initAndPlay = (options: NanoPlayerSettings) => {
    this.reloadCount = 0;
    this.log("Initing nano player");
    this.player = new NanoPlayer(this.hostId);
    this.log(this.player);
    const config = this.constructConfig(options);
    this.config = config;
    this.log("Config", config);
    this.player.setup(config);
    this.log(this.player);
    return document.getElementById(this.hostId);
  };

  stop = () => {
    if (!!this.player) {
      this.clearRetryInterval();
      this.player.destroy();
    }
  };

  reload = () => {
    this.log("Start Reloading");
    this.player.updateSource(this.config.source);
  };

  private constructConfig = (settings: NanoPlayerSettings) => {
    const {
      origin,
      applicationName,
      edgeLBPath,
      channelName,
      streamName,
      token,
      isCam2Cam,
    } = settings;
    this.log("constructConfig started", settings);
    const {
      broadcastEdge: edgeApp,
      rtmpPort,
      nanoPool: nanoPoolAsString,
    } = config;
    let nanoPool;
    try {
      nanoPool = nanoPoolAsString.split(",");
    } catch {}

    this.isCam2Cam = !!isCam2Cam;

    const sourceEntries = {
      entries:
        isCam2Cam && channelName
          ? [
              {
                h5live: this.getC2CEntries(streamName, token, channelName, {
                  originPath: origin,
                  originAppName: applicationName,
                  edgeLBPath: edgeLBPath,
                  edgeApp,
                  rtmpPort,
                  nanoPool,
                }),
              },
            ]
          : RESOLUTIONS.map(res => ({
              ...res,
              h5live: this.geth5LiveObj(
                streamName,
                token,
                res.resolution as Resolution,
                {
                  originPath: origin,
                  originAppName: applicationName,
                  edgeLBPath,
                  edgeApp,
                  rtmpPort,
                  nanoPool,
                }
              ),
            })),
    };

    return {
      ...NANO_CONFIG,
      playback: {
        ...NANO_CONFIG.playback,
        muted: isCam2Cam ? false : true,
      },
      source: {
        ...NANO_CONFIG.source,
        ...sourceEntries,
      },
      events: this.getNanoEvents({
        modelName: channelName,
      }),
    };
  };

  geth5LiveObj = (
    streamName: string,
    token: string,
    resolution?: Resolution,
    serverConfig: Partial<ServerConfig> = {}
  ) => {
    const {
      originPath,
      originAppName,
      edgeLBPath,
      edgeApp,
      rtmpPort,
      nanoPool,
    } = serverConfig;
    const channelNameLC = streamName?.toLowerCase();
    const streamNameLC = streamName?.toLowerCase();
    const streamname = resolution
      ? `${streamNameLC}_${resolution}p`
      : streamNameLC;
    const url = `rtmp://${edgeLBPath}/${edgeApp}/${channelNameLC}?token=${token}`;
    const stream = `rtmp://${originPath}:${rtmpPort}/${originAppName}/${channelNameLC}/${streamname}`;
    const serverPools = nanoPool || DEFAULT_NANO_POOL;
    const selectedNanoServerIndex = Math.floor(
      Math.random() * serverPools.length
    );
    const selectedNanoServer = serverPools[selectedNanoServerIndex];
    return {
      server: {
        websocket: `wss://${selectedNanoServer}/h5live/stream`,
        hls: "",
        progressive: "",
      },
      rtmp: {
        url: url,
        streamname: stream,
      },
    };
  };

  getC2CEntries = (
    memberName: string,
    token: string,
    channelName: string,
    serverConfig: Partial<ServerConfig> = {}
  ) => {
    this.log(
      "getC2CEntries started, streamName:",
      memberName,
      "channelName:",
      channelName,
      "token:",
      token,
      "serverConfig:",
      serverConfig
    );
    const { originPath, originAppName, rtmpPort, nanoPool } = serverConfig;
    const channelNameLC = channelName?.toLowerCase();
    const streamNameLC = memberName?.toLowerCase();
    const streamname = `${streamNameLC}_${config.c2cResolution}`;
    const url = `rtmp://${originPath}:${rtmpPort}/${originAppName}/${channelNameLC}?token=${token}`;
    const serverPools = nanoPool || DEFAULT_NANO_POOL;
    const selectedNanoServerIndex = Math.floor(
      Math.random() * serverPools.length
    );
    const selectedNanoServer = serverPools[selectedNanoServerIndex];
    return {
      server: {
        websocket: `wss://${selectedNanoServer}/h5live/stream`,
        hls: "",
        progressive: "",
      },
      rtmp: {
        url: url,
        streamname,
      },
    };
  };

  getNanoEvents = ({ modelName }: any) => {
    return {
      onReady: () => {
        this.log("Ready");
      },
      onDestroy: () => {
        this.log(`DEV: nanostat: admin only onDestroy ${modelName}`);
        this.reloadTimeout && clearTimeout(this.reloadTimeout);
        this.clearRetryInterval();
      },
      onPause: () => {
        this.log("PAUSE");
      },
      onPlay: ({ state }) => {
        this.log(`DEV: nanostat: admin only onPlay ${modelName}`, state);
        this.clearRetryInterval();
      },
      onStreamInfo: event => {
        if (event?.data?.streamInfo?.haveVideo === false && this.reloadCount < MAX_RELOAD) {
          this.log("Met only audio, no video presented");
          this.reloadTimeout = setTimeout(() => {
            this.reloadCount += 1;
            this.reload();
          }, RELOAD_TIMEOUT);
        }
      },
      onStats: d => {
        if (d.data.stats) {
        }
      },
      onMute: _e => {
        this.log(`Nano ${modelName} onMute`);
      },
      onUnmute: () => {
        this.log(`Nano ${modelName} onUnmute`);
      },
      //   onStartBuffering:this.onStartBuffering,
      //   onStopBuffering:this.onStopBuffering,
      onVolumeChange: () => {},
      onError: e => {
        this.log("error!", e);
        this.startRetryInterval();
      },
    };
  };

  startRetryInterval = () => {
    this.retryInterval = setInterval(() => {
      this.reload();
    }, 2000)
  }

  clearRetryInterval = () => {
    clearInterval(this.retryInterval)
  }
}

export default CamsNanoPlayer;
