export const createFormData = (file: any, additionalFormData: any) => {
  const form = new FormData();
  Object.keys(additionalFormData).forEach(key => {
    const value = additionalFormData[key];
    form.append(key, value);
  });
  form.append("file", file);
  return form;
};

export const getExtension = (filename: string): string => {
  var parts = filename.split(".");
  return parts[parts.length - 1];
};

export const isImage = (filename: string): boolean => {
  const ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "jpg":
    case "jpeg":
    case "gif":
    case "png":
      return true;
  }
  return false;
};

export const isVideo = (filename: string, media?: Blob): boolean => {
  const ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "m4v":
    case "avi":
    case "mpg":
    case "mp4":
    case "mov":
      return true;
  }

  return media ? media.type.includes("video") : false;
};

export const dataURItoBlob = (dataURI: string) => {
  let byteString;

  if (dataURI.split(",")[0].indexOf("base64") >= 0) {
    byteString = atob(dataURI.split(",")[1]);
  } else {
    byteString = encodeURI(dataURI.split(",")[1]);
  }
  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
};

export const timeoutPromise = (timeout: number) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(null);
    }, timeout);
  });
};

export const sanitizedInput = (string) => {
  const specialCharacters = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '/': '&#x2F;',
  };
  const reg = /[&<>/]/ig;
  return string.match(reg, (match)=>(specialCharacters[match])) ? false : true;
}