import React, { PureComponent } from "react";
import * as Hls from "hls.js";
import TailwindFlex from "../flex";
import LayoutStore from "../../../core/stores/layout/LayoutStore";

const NON_HLS_SUBDOMAINS = ["minio.", "storage.googleapis.", "gcp.", "gcpcdn."];
type Props = {
  url: string;
  autoplay?: boolean;
  hlsConfig?: object;
  controls?: boolean;
  width?: any;
  height?: any;
  poster?: string;
  videoProps?: React.HTMLProps<HTMLVideoElement>;
  proportions?: number;
  className?: string;
};

interface IState {
  playerId: number;
}

class VideoPlayer extends PureComponent<Props, IState> {
  private hls: any;
  private resizeInterval: any;
  private video: React.RefObject<HTMLVideoElement>;
  private supportsHls: boolean = false;

  public static defaultProps = {
    autoplay: true,
    hlsConfig: {},
    controls: true,
    width: 500,
    height: 375,
    proportions: 0,
    className: "VideoPlayer",
  };

  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      playerId: Math.random(),
    };

    this.hls = {} as any;
    this.video = React.createRef();
  }

  componentDidMount() {
    if (this.isHLSPlayback()) {
      if (!LayoutStore.isAppleMobile()) {
        //IOS does not support MediaSource API hence hlsjs does not work
        this.supportsHls = true;
      }

      if (this.supportsHls) {
        this._initPlayer();
      }
    }

    this.onResized();
    this.resizeInterval = setInterval(this.onResized, 500);
  }

  componentWillUnmount() {
    if (this.isHLSPlayback() && this.hls && this.supportsHls) {
      this.hls.destroy();
    }
    if (this.resizeInterval) clearInterval(this.resizeInterval!);
  }

  onResized = () => {
    const video = this.video.current;
    const { proportions } = this.props;
    if (!proportions || !video || !video) return;
    const bcr = video.getBoundingClientRect();
    const width = Math.floor(bcr.width);
    const height = Math.floor(width / proportions);
    video.style.width = `${width}px`;
    video.style.height = `${height}px`;
    video.style.visibility = "visible";
  };

  isHLSPlayback() {
    const { url } = this.props;
    console.error(url);
    // static files are served from minio domains
    if (!url) return true;
    for (var i = 0; i < NON_HLS_SUBDOMAINS.length; i++) {
      if (url.indexOf(NON_HLS_SUBDOMAINS[i]) !== -1) {
        return false;
      }
    }
    return true;
  }

  _initPlayer() {
    if (
      this.hls &&
      typeof this.hls.destroy === "function" &&
      this.supportsHls
    ) {
      this.hls.destroy();
    }

    const { url, autoplay, hlsConfig } = this.props;
    const hls = new Hls(hlsConfig);
    hls.attachMedia(this.video.current);
    hls.on(Hls.Events.MEDIA_ATTACHED, () => {
      hls.loadSource(url);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (autoplay && this.video) {
          this.video.current!.play();
        }
      });
    });

    hls.on(Hls.Events.ERROR, (data: { fatal: any; type: any }) => {
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            hls.startLoad();
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            hls.recoverMediaError();
            break;
          default:
            this._initPlayer();
            break;
        }
      }
    });

    this.hls = hls;
  }

  render() {
    const { playerId } = this.state;
    const { controls, poster, videoProps, url, className } = this.props;
    return (
      <TailwindFlex key={playerId} className={[className]}>
        <video
          className={"w-full h-full object-contain"}
          ref={this.video}
          id={`hls-player-${playerId}`}
          controls={controls}
          poster={poster}
          {...videoProps}>
          {LayoutStore.isAppleMobile() && <source src={url} />}
        </video>
      </TailwindFlex>
    );
  }
}

export default VideoPlayer;
