import { ModelPricingResponse, PricingOptions } from "./types";

export const REWARD_FIELD_TO_BE_VERIFIED = "reward";
export const REWARD_MIN_TOKEN_PRICE = 20;
export const BROADCASTER_DEFAULT_WHPISPER_MESSAGE_PRICE = 5;
export const BROADCASTER_DEFAULT_PRIVATE_CHAT_PRICE = 35;
export const BROADCASTER_DEFAULT_CAM2CAM_PRICE = 10;
export const BROADCASTER_DEFAULT_VOYEUR_CHAT_PRICE = 20;
export const BROADCASTER_DEFAULT_PARTY_CHAT_PRICE = 35;
export const BROADCASTER_DEFAULT_NUDE_CHAT_PRICE = 30;
export const BROADCASTER_DEFAULT_CALL_ME_PRICE = 30;
export const BROADCASTER_DEFAULT_TEXT_ME_PRICE = 10;
export const BROADCASTER_DEFAULT_BUZZ_PRICE = 5;
export const BROADCASTER_DEFAULT_SUPER_BUZZ_PRICE = 30;
export const BROADCASTER_DEFAULT_CONNEXION_ONE_WAY_PRICE = 10;
export const BROADCASTER_DEFAULT_CONNEXION_TWO_WAY_PRICE = 10;
export const BROADCASTER_DEFAULT_WHEEL_OF_FUN_PRICE = 50;
export const BROADCASTER_DEFAULT_MIN_TIPPING_SHOW_GOAL_PRICE = 20;
export const BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE = 20;
export const BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES = 9999; //needs to be a 4 digit value as per product requirements

export const PRODUCT_DEFAULTS: ModelPricingResponse = {
  is_happy_hour: true,
  cam2cam_chat: BROADCASTER_DEFAULT_CAM2CAM_PRICE,
  whisper_message: BROADCASTER_DEFAULT_WHPISPER_MESSAGE_PRICE,
  private_chat: BROADCASTER_DEFAULT_PRIVATE_CHAT_PRICE,
  voyeur_chat: BROADCASTER_DEFAULT_VOYEUR_CHAT_PRICE,
  is_voyeur_allowed: true,
  party_chat: BROADCASTER_DEFAULT_PARTY_CHAT_PRICE,
  is_private_allowed: true,
  is_party_allowed: true,
  nude_chat: BROADCASTER_DEFAULT_NUDE_CHAT_PRICE,
  call_me: BROADCASTER_DEFAULT_CALL_ME_PRICE,
  text_me: BROADCASTER_DEFAULT_TEXT_ME_PRICE,
  buzz_price: BROADCASTER_DEFAULT_BUZZ_PRICE,
  superbuzz_price: BROADCASTER_DEFAULT_SUPER_BUZZ_PRICE,
  connexion_one_way: BROADCASTER_DEFAULT_CONNEXION_ONE_WAY_PRICE,
  connexion_two_way: BROADCASTER_DEFAULT_CONNEXION_TWO_WAY_PRICE,
  wheel_of_fun: BROADCASTER_DEFAULT_WHEEL_OF_FUN_PRICE,
  wheel_of_fun_rewards: {
    rewards: [],
  },
  pending_wheel_of_fun_rewards: {
    rewards: [],
  },
  is_wheel_of_fun_allowed: true,
  chat_settings: {
    tipping: {
      earlyEntry: BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE,
      lateEntry: BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE,
      goal: BROADCASTER_DEFAULT_MIN_TIPPING_SHOW_GOAL_PRICE,
    },
    system_settings: {
      fan_whisper_free: false,
      sound_enter_leave: true,
      only_allow_members_with_fund_to_chat: false,
      whispers_in_separate_tabs: false,
      member_sort_type: "top_spender",
    },
    chatTopic: null,
    pendingChatTopic: null,
    is_recording_all_private_shows: true,
  },
  buzz_status: "on",
  is_private_consent_needed: false,
  private_chat_status: "on",
  photo_album_default: 0,
  custom_video_default: 0,
  video_default: 0,
};

export const NOT_ALLOWED_KEY = "Not allowed";

export const PRODUCT_PRICE_OPTIONS_DEFAULTS: PricingOptions = {
  whisper_message: [5, 6, 7, 8, 9, 10],
  private_chat: [
    25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105,
    110, 115, 120, 125, 130, 135, 140, 145, 150,
  ],
  voyeur_chat: [
    NOT_ALLOWED_KEY,
    "20",
    "25",
    "30",
    "35",
    "40",
    "45",
    "50",
    "55",
    "60",
    "65",
    "70",
    "75",
    "80",
    "85",
    "90",
    "95",
    "100",
  ],
  party_chat: [
    NOT_ALLOWED_KEY,
    "25",
    "30",
    "35",
    "40",
    "45",
    "50",
    "55",
    "60",
    "65",
    "70",
    "75",
    "80",
    "85",
    "90",
    "95",
  ],
  nude_chat: [25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100],
  fan_club_private_chat: [20, 25, 30, 35, 40, 45],
  fan_club_party_chat: [
    18, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85,
  ],
  call_me: [30, 35, 40, 45, 50],
  text_me: [8, 10, 15, 20],
  buzz_price: ["2", "3", "4", "5", "10"],
  superbuzz_price: ["6", "9", "12", "15", "30"], // super buzz prices are 3 times the buzz price
  connexion_one_way: [10, 20, 30, 40, 50],
  connexion_two_way: [10, 20, 30, 40, 50],
  wheel_of_fun: ["20", "30", "40", "50", "60", "70", "80", "90", "100"],
  buzz_status: ["on", "off"],
  private_chat_status: ["one", "off"],
};

export const InitialData = {
  modelProducts: PRODUCT_DEFAULTS,
  pricingOptions: PRODUCT_PRICE_OPTIONS_DEFAULTS,
  isLoading: false,
  isNudePriceCorrect: undefined,
  chatTopic: null,
  pendingChatTopic: null,
  allowedWheelOfFunPricing: true,
  tippingEarlyEntryError: undefined,
  tippingLateEntryError: undefined,
  tippingGoalError: undefined,
  changedWheelOfFunRewardIndexes: [],
};
