import React, { useCallback, useMemo } from "react";
import ChatStore from "../_stores/chat/ChatStore";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import { IMember } from "../_stores/chat/interfaces";
import { inject, observer } from "mobx-react";
import TailwindIcon from "library/components/_tailwind/icon";
import TailwindButton from "library/components/_tailwind/button";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import TailwindTooltip from "library/components/_tailwind/tooltip";
import ChatItemUsernameTooltip from "../broadcast-chat/chat-item-username-tooltip";
import TailwindKebabMenu from "library/components/_tailwind/kebab-menu";
import NotesDrawer from "./notes-drawer";
import { IShowType } from "../_stores/broadcast/enums";
import NodeChatStore from "../_stores/nodechat/NodeChatStore";
import PricingStore from "../_stores/pricing/PricingStore";
import LanguageStore from "library/core/stores/language/LanguageStore";

interface Props {
  member: IMember;
  nodeChatStore?: NodeChatStore;
  chatStore?: ChatStore;
  broadcastStore?: BroadcastStore;
  pricingStore?: PricingStore;
  index: number;
  isVoyeur?: boolean;
  languageStore?: LanguageStore;
}

const BroadcastMemberItem: React.FunctionComponent<Props> = ({
  member,
  nodeChatStore,
  chatStore,
  broadcastStore,
  pricingStore,
  isVoyeur,
  languageStore,
}) => {
  const {
    isNotesDrawerOpen,
    //setisNotesDrawerOpen,
    MemberNotes,
    setisEditingNotes,
    isEditingNotes,
    deleteNote,
    editNote,
    submitNewNote,
    //getNotes,
  } = chatStore!;
  const {
    muteMember,
    kickMember,
    unmuteMember,
    startWhisperTo,
    activeMemberToWhisper,
    membersWhoWhispered,
  } = nodeChatStore!;
  const {
    inviteToPrivate,
    //selectMemberProfile
    isInPaidShow,
    isInPrivateOrAdminShow,
    currentShowType,
  } = broadcastStore!;

  const { modelProducts } = pricingStore!;
  const { intl } = languageStore!;

  const handleClickWhisper = useCallback(() => {
    startWhisperTo(member);
  }, [member]);
  // const handleClickNotes = useCallback(() => {
  //   getNotes();
  //   if (isNotesDrawerOpen.length === 0) {
  //     setisNotesDrawerOpen(member.id);
  //   } else {
  //     if (isNotesDrawerOpen === member.id) {
  //       setisNotesDrawerOpen("");
  //     }
  //     setisEditingNotes("");
  //   }
  // }, [isNotesDrawerOpen]);

  const handleClickNotes = useCallback(() => {
    if (member.pwsid) {
      (window as any).top.open(
        `https://models.streamray.com/p/cams/user.cgi?mid=${member.pwsid}`,
        "_blank"
      );
    }
  }, [member.pwsid]);

  // const handleClickMemberProfile = useCallback(() => {
  //   selectMemberProfile(member.id);
  // }, [broadcastStore, member]);

  const handleClickInviteToPrivate = useCallback(() => {
    inviteToPrivate(member);
  }, [member]);

  const handleClickMemberMute = useCallback(() => {
    if (!member.isInvisible) {
      muteMember(member.id);
    } else {
      unmuteMember(member.id);
    }
  }, [member]);
  const handleClickMemberKick = useCallback(() => {
    kickMember(member.id);
  }, []);
  const canInviteToPrivate = useMemo(() => {
    return !isInPaidShow && currentShowType !== IShowType.TIPPING;
  }, [isInPaidShow, currentShowType]);

  const canWhisper = useMemo(() => {
    return !isInPrivateOrAdminShow && !isVoyeur;
  }, [isInPrivateOrAdminShow, isVoyeur]);

  const username = useMemo(() => {
    if (member?.username) {
      return member?.username.split("@")[0];
    }
    return "";
  }, [member]);

  const activeWhipser = useMemo(() => {
    return membersWhoWhispered.some(username => username === username);
  }, [membersWhoWhispered, username]);

  const isWhisperInSeparateTabsActive = useMemo(
    () =>
      modelProducts?.chat_settings?.system_settings?.whispers_in_separate_tabs,
    [modelProducts]
  );

  return (
    <>
      <TailwindFlex
        borderRadius={"rounded-lg"}
        padding={["p-0.5", isVoyeur ? "pr-4" : "pr-2", "pl-4"]}
        flexDirection={"flex-col"}
        margin={["mt-1"]}
        key={member.id}
        position='relative'
        width='w-full'
        backgroundColor='bg-card-secondary-bg-color'
        className={[
          `BroadcastViewers__member-item ${activeWhipser ? "whispered" : ""} ${
            !isWhisperInSeparateTabsActive &&
            username === activeMemberToWhisper?.username
              ? "whisperedActive"
              : ""
          }`,
        ]}>
        <TailwindFlex
          alignItems={"items-center"}
          justifyContent={"justify-between"}
          width={"w-full"}>
          <TailwindFlex width='w-40' alignItems={"items-center"}>
            <TailwindFlex width='w-5' margin={["mr-2"]}>
              {member?.isFanClub && (
                <TailwindTooltip content={"Fan Club Member"}>
                  <TailwindIcon
                    style={{
                      color: "rgba(216, 209, 59)",
                    }}
                    name='star'></TailwindIcon>
                </TailwindTooltip>
              )}
              {member?.isBountyTarget && (
              <TailwindTooltip content={"Bounty Target"}>
                <span className='ChatItem__BountyIcon'>
                  <img src='\images\icons\exclamation_animation.png' alt="bounty target icon" />
                </span>
              </TailwindTooltip>
              )}
            </TailwindFlex>

            <TailwindText
              textColor={"text-main-color"}
              padding={["pr-1"]}
              width='w-full'
              borderRadius={"rounded"}
              textOverflow='truncate'
              backgroundColor={"bg-transparent"}
              fontWeight={"font-bold"}>
              <ChatItemUsernameTooltip userId={member?.id}>
                {username}
              </ChatItemUsernameTooltip>
            </TailwindText>

            {member?.isBounty && (
              <TailwindTooltip place='bottom' content={"Bounty"}>
                <div
                  style={{
                    transform: "translate(-10px, -10px)",
                    transformOrigin: "top left",
                  }}>
                  <svg width='15px' height='15px' viewBox='0 0 55 55'>
                    <g
                      stroke='none'
                      strokeWidth='1'
                      fill='none'
                      fillRule='evenodd'>
                      <g>
                        <circle
                          fill='#FFB1C6'
                          cx='27.5'
                          cy='27.5'
                          r='27.5'></circle>
                        <text
                          id='B'
                          fontFamily='Lato'
                          fontSize='36'
                          fontWeight='normal'
                          fill='#FFFFFF'>
                          <tspan x='16' y='40'>
                            B
                          </tspan>
                        </text>
                      </g>
                    </g>
                  </svg>
                </div>
              </TailwindTooltip>
            )}
          </TailwindFlex>
          <TailwindFlex
            width={"w-1/2"}
            alignItems={"items-center"}
            justifyContent={"justify-around"}>
            {member.isInvisible && (
              <TailwindFlex
                flexDirection='flex-row'
                borderRadius={"rounded-full"}
                backgroundColor={"bg-gray-400"}
                padding={["px-2", "py-2"]}
                textColor={"text-white"}>
                <TailwindText fontSize={"text-sm"}>{"Muted"}</TailwindText>
                <TailwindFlex
                  margin={["ml-1"]}
                  justifyContent='justify-center'
                  alignItems='items-center'
                  width='w-5'
                  height='h-5'
                  backgroundColor={"bg-gray-600"}
                  borderRadius={"rounded-full"}
                  onClick={handleClickMemberMute}>
                  <TailwindIcon name={"close"} fontSize={"text-sm"} />
                </TailwindFlex>
              </TailwindFlex>
            )}

            {!member.isInvisible && (
              <>
                {canInviteToPrivate && (
                  <TailwindTooltip content={"Invite to Private"}>
                    <TailwindFlex
                      padding={["pl-2"]}
                      flexDirection='flex-col'
                      justifyContent='justify-center'
                      alignItems='items-center'>
                      <TailwindButton
                        fullWidth={false}
                        backgroundColor={"bg-transparent"}
                        rounded={false}
                        onClick={handleClickInviteToPrivate}
                        borderRadius={"rounded-lg"}
                        textProps={{ whiteSpace: "whitespace-nowrap" }}
                        size={"xs"}
                        lineHeight={"leading-snug"}>
                        <TailwindIcon
                          name={"invite-to-private"}
                          textColor={"text-main-color"}
                        />
                      </TailwindButton>
                      <TailwindText
                        textColor='text-main-color'
                        fontSize='text-xs'>
                        Private
                      </TailwindText>
                    </TailwindFlex>
                  </TailwindTooltip>
                )}
                {canWhisper && (
                  <TailwindTooltip content={"Send Whisper"}>
                    <TailwindFlex
                      flexDirection='flex-col'
                      padding={["pl-2"]}
                      justifyContent='justify-center'
                      alignItems='items-center'>
                      <TailwindButton
                        fullWidth={false}
                        backgroundColor={"bg-transparent"}
                        rounded={false}
                        size={"xs"}
                        onClick={handleClickWhisper}
                        borderRadius={"rounded-lg"}
                        lineHeight={"leading-snug"}>
                        <TailwindIcon
                          name={"private-message"}
                          textColor={"text-main-color"}
                        />
                      </TailwindButton>
                      <TailwindText
                        textColor='text-main-color'
                        fontSize='text-xs'>
                        Whisper
                      </TailwindText>
                    </TailwindFlex>
                  </TailwindTooltip>
                )}
                <TailwindTooltip content={"Notes"}>
                  <TailwindFlex
                    flexDirection='flex-col'
                    padding={["pl-2"]}
                    justifyContent='justify-center'
                    alignItems='items-center'>
                    <TailwindButton
                      fullWidth={false}
                      backgroundColor={"bg-transparent"}
                      rounded={false}
                      size={"xs"}
                      onClick={handleClickNotes}
                      borderRadius={"rounded-lg"}
                      lineHeight={"leading-snug"}>
                      <TailwindIcon
                        name={"notes-pencil"}
                        textColor={"text-main-color"}
                      />
                    </TailwindButton>
                    <TailwindText
                      textColor='text-main-color'
                      fontSize='text-xs'>
                      Notes
                    </TailwindText>
                  </TailwindFlex>
                </TailwindTooltip>
              </>
            )}

            {!isVoyeur && (
              <TailwindKebabMenu fontSize='text-9xl'>
                <TailwindFlex
                  justifyContent='justify-between'
                  flexDirection='flex-row'
                  onClick={handleClickMemberMute}
                  alignItems={"items-center"}>
                  <TailwindText textColor='text-main-color' fontSize='text-sm'>
                    {!member.isInvisible ? 
                      intl.formatMessage({
                        id: "member-item.mute",
                        defaultMessage: "Mute"
                      }) : 
                      intl.formatMessage({
                        id: "member-item.unmute",
                        defaultMessage: "Unmute"
                      })}
                  </TailwindText>
                  <TailwindButton
                    fullWidth={false}
                    backgroundColor={"bg-transparent"}
                    rounded={false}
                    display='hidden'
                    size={"sm"}
                    borderRadius={"rounded-lg"}
                    lineHeight={"leading-snug"}>
                    <TailwindIcon
                      name={!member.isInvisible ? "mute" : "unmute"}
                      textColor={"text-main-color"}
                    />
                  </TailwindButton>
                </TailwindFlex>
                <TailwindFlex
                  justifyContent='justify-between'
                  flexDirection='flex-row'
                  onClick={
                    currentShowType === IShowType.PRIVATE
                      ? () => {}
                      : handleClickMemberKick
                  }
                  alignItems={"items-center"}
                  opacity={
                    currentShowType === IShowType.PRIVATE
                      ? "opacity-50"
                      : "opacity-100"
                  }
                  cursor={
                    currentShowType === IShowType.PRIVATE
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                  }>
                  <TailwindText textColor='text-main-color' fontSize='text-sm'>
                    {intl.formatMessage({
                      id: "member-item.kick",
                      defaultMessage: "Kick"
                    })}
                  </TailwindText>
                  <TailwindButton
                    fullWidth={false}
                    backgroundColor={"bg-transparent"}
                    rounded={false}
                    disabled={
                      currentShowType === IShowType.PRIVATE ||
                      currentShowType === IShowType.GROUP
                    }
                    size={"sm"}
                    borderRadius={"rounded-lg"}
                    lineHeight={"leading-snug"}>
                    <TailwindIcon name={"kick"} textColor={"text-main-color"} />
                  </TailwindButton>
                </TailwindFlex>
              </TailwindKebabMenu>
            )}
            {/*            <TailwindButton
              fullWidth={false}
              textColor={"text-white"}
              borderRadius={"rounded-lg"}
              backgroundColor={"bg-transparent"}
              rounded={false}
              onClick={handleClickMemberProfile}
              margin={["mr-2", "mb-2"]}
              textProps={{ whiteSpace: "whitespace-nowrap" }}
              size={"sm"}>
              {intl.formatMessage({
                id: "member-item.view-profile",
                defaultMessage: "View Profile",
              })}
            </TailwindButton>*/}
          </TailwindFlex>
        </TailwindFlex>
      </TailwindFlex>
      {isNotesDrawerOpen === member.id && (
        <NotesDrawer
          notes={MemberNotes}
          onAdd={submitNewNote}
          memberId={member.id}
          onDelete={deleteNote}
          onEdit={editNote}
          isEditingNotes={isEditingNotes}
          setisEditingNotes={setisEditingNotes}
          activeDrawer={isNotesDrawerOpen}
          onCloseDrawer={handleClickNotes}
        />
      )}
    </>
  );
};

export default inject(
  "nodeChatStore",
  "chatStore",
  "broadcastStore",
  "pricingStore",
  "languageStore"
)(observer(BroadcastMemberItem));
